import React, { useState, ChangeEvent, useEffect } from 'react';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';

interface Props {
	id: string;
	placeholder: string;
	value: string;
	existValues: string[];
	onSignatureChange: (value: string) => void;
}

export const OptionInput: React.FC<Props> = props => {
	const { id, placeholder, value, existValues, onSignatureChange } = props;

	const [inputValue, setInputValue] = useState(value);

	const onInputValueChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = event.currentTarget.value;

		// Записываем значение, если снимается фокус. Если дубликат - возвращаем дефолтное значение
		if (event.type === 'blur') {
			onSignatureChange(!!newValue && !existValues.includes(inputValue.trim()) ? newValue : placeholder);
			return;
		}

		// Пока фокус не снялся, записываем только внутри компонента
		setInputValue(newValue);
	};

	useEffect(() => {
		setInputValue(value);
	}, [value]);

	return (
		<div style={{ width: '100%' }}>
			<TextInput
				id={id}
				placeholder={placeholder}
				value={inputValue}
				maxLength={50}
				onChange={e => onInputValueChange(e)}
				onBlur={e => onInputValueChange(e)}
				errorBorder={inputValue.trim() !== value && existValues.includes(inputValue.trim())}
			/>
		</div>
	);
};
