import { useGetGroupsByGroupIdQuery } from '@/app/redux/queries/vacation-service/vacation_serviceAPI';
import { LineTabs } from '@/shared/ui/_tabs/lineTabs';
import { SingleTab } from '@/shared/ui/_tabs/lineTabs/LineTabs';
import { FILTER_PARAMS_NAMES } from '../../consts/consts';
import { useVacationPageContext } from '../../context/useGroupUsersContext';
import s from './Groups.module.scss';

export const Groups = () => {
	// * Context
	const { searchParams, setSearchParams } = useVacationPageContext();

	// * Routing
	const groupId = searchParams.get('groupId');
	const isFromDepartment = searchParams.get('fromDepartment');

	// * Query
	const { data } = useGetGroupsByGroupIdQuery({
		groupId: 'personal',
	});

	// @ts-ignore
	const groups = data?.body as GroupResponse[]; // ! Тип на возврата данный запрос если groupId === 'person' не корректен!

	if (groupId === 'null' && groups && groups) {
		setSearchParams(prev => {
			return {
				...Object.fromEntries(prev.entries()),
				groupId: groups[0].id,
			};
		});
	}

	const customGroupTabs: SingleTab[] = [];
	const departmentRelatedGroupTabs: SingleTab[] = [];

	groups?.forEach(group => {
		const tab = {
			id: group.id,
			name: group.shortName,
		};

		if (group.isCustom) customGroupTabs.push(tab);
		else departmentRelatedGroupTabs.push(tab);

		// if (isAdmin) {
		// 	customGroupTabs.push(tab);
		// }
	});

	const tabs = isFromDepartment ? [] : [...departmentRelatedGroupTabs, ...customGroupTabs];

	const resetFilters = () => {
		Object.keys(FILTER_PARAMS_NAMES).forEach(paramName => searchParams.delete(paramName));
		setSearchParams(searchParams);
	};

	const onTabClick = (id: string) => {
		resetFilters();
		setSearchParams(prev => {
			return {
				...Object.fromEntries(prev.entries()),
				groupId: id,
			};
		});
	};

	// * Render
	return (
		<>
			{groupId && (
				<LineTabs
					className={s.container}
					tabs={tabs}
					activeTabId={groupId}
					setActiveTabId={onTabClick}
				/>
			)}
		</>
	);
};
