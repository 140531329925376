import { useAppSelector } from '@/app/redux/utils';
import { routerPath } from '@/app/router/paths';
import { Button } from '@/shared/ui/_buttons/Button';
import { DatePickerTitleButton } from '@/shared/ui/_inputs/date_pickers/DatePickerTitleButton';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { DropDownMenu } from '@/shared/ui/DropDownMenu/DropDownMenu';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useVacationPageContext } from '../../context/useGroupUsersContext';
import { useGetGroupQuery } from '../../hooks/useGetGroupQuery';
import { useGetGroupUserRights } from '../../hooks/useGetGroupUserRights';
import { ConfirmPlanMC } from '../_modals/ConfirmPlanMC/ConfirmPlanMC';
import { EditPlanMC } from '../_modals/EditPlanMC/EditPlanMC';
import { SendMailModal } from '../_modals/SendMailModal';
import s from './SubHeader.module.scss';

// * Constant for December month
const DECEMBER_INDEX = 11;

export const SubHeader = () => {
	// * Context
	const { searchParams, setSearchParams } = useVacationPageContext();

	// * Navigation
	const navigate = useNavigate();
	const groupId = searchParams.get('groupId');
	const year = searchParams.get('year') as string;

	// * Rights
	const { hasExtendedRights, isPlanConfirmed, isLoading } = useGetGroupUserRights();

	const setYear = (year: number) => {
		setSearchParams(prev => {
			return {
				...Object.fromEntries(prev.entries()),
				year: String(year),
			};
		});
	};

	// * Context
	const { setPlannedVacationUserId: setActiveUserId } = useVacationPageContext();

	// * API
	const { data, refetch: refetchGroups } = useGetGroupQuery(groupId);

	const groupInfo = data?.body;
	const departmentId = groupInfo?.departmentId;

	// * Selectors
	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);

	// * Add or edit planned vacation
	const [isPlannedVacationAdded, setIsPlannedVacationAdded] = useState(false);

	useEffect(() => {
		if (departmentId) {
			const plannedVacations =
				groupInfo?.users
					?.find(user => user.id === userId)
					?.vacations?.filter(({ vacationBody }) => vacationBody?.type === 'Planned' && vacationBody?.time?.startTime?.includes(year)) ?? [];
			setIsPlannedVacationAdded(plannedVacations.length > 0);
		}
	}, [departmentId]);

	// * Year selection
	const selectedDate = year ? new Date(year) : undefined;
	const setSelectedDate = (date: Date) => setYear(date.getFullYear());

	// * Determine max possible year
	const currentDate = new Date();
	// If current month is december then calculate max possible year as current + 1 (as vacation period is opened)
	const maxYear = currentDate.getMonth() === DECEMBER_INDEX ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
	const maxDate = new Date(maxYear, 0, 1);

	// * Modals
	const [modalSendEmail, setModalSendEmail] = useState<boolean>(false);
	const toggleModalSendEmail = () => setModalSendEmail(prevState => !prevState);

	const [modalConfirmEditPlan, setModalConfirmEditPlan] = useState(false);
	const toggleModalConfirmEditPlan = () => setModalConfirmEditPlan(prevState => !prevState);

	// * Planned vacation modal
	const openModal = () => userId && setActiveUserId(userId);

	// * User actions
	const toGroup = () => {
		navigate(`${routerPath.vacation.page}${routerPath.vacation.group}/${groupId}`);
	};

	if (!groupInfo) {
		return null;
	}

	// * Menu
	const dropDownItem = [
		{
			isVisible: hasExtendedRights,
			title: 'Настроить группу',
			action: toGroup,
		},
		{
			isVisible: hasExtendedRights,
			title: 'Отправить e-mail',
			action: toggleModalSendEmail,
		},
		{
			isVisible: true,
			title: 'Выгрузить в Excel',
			action: () => console.log('Выгрузить в Excel'), // TODO
			disabled: true,
		},
		{
			isVisible: hasExtendedRights && departmentId,
			title: isPlanConfirmed ? 'Редактировать план' : 'Утвердить план',
			action: toggleModalConfirmEditPlan,
		},
	].filter(item => item.isVisible);

	// * Render
	return (
		<>
			{modalSendEmail && (
				<SendMailModal
					modalSendEmail={modalSendEmail}
					toggleModalSendEmail={toggleModalSendEmail}
				/>
			)}

			<ModalNewProto
				isOpen={modalConfirmEditPlan}
				onClose={toggleModalConfirmEditPlan}
				width="s"
			>
				{isPlanConfirmed ? (
					<EditPlanMC
						toggleModal={toggleModalConfirmEditPlan}
						group={groupInfo}
						refetchGroups={refetchGroups}
					/>
				) : (
					<ConfirmPlanMC
						toggleModal={toggleModalConfirmEditPlan}
						group={groupInfo}
						refetchGroups={refetchGroups}
					/>
				)}
			</ModalNewProto>

			<div className={s.container}>
				{selectedDate && (
					<DatePickerTitleButton
						prefix={groupInfo.name}
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						maxDate={maxDate}
						format="year"
						size="large"
					/>
				)}

				<div>
					<Button
						onClick={openModal}
						disabled={isPlanConfirmed || isLoading || !departmentId}
						variant={isPlannedVacationAdded ? 'secondary' : 'primary'}
					>
						{isPlannedVacationAdded ? 'Редактировать планируемый отпуск' : 'Внести планируемый отпуск'}
					</Button>
					<DropDownMenu items={dropDownItem} />
				</div>
			</div>
		</>
	);
};
