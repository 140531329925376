import { BirthdayUser } from '@/app/redux/queries/event-service/types/types';
import { useGetUserGetQuery } from '@/app/redux/queries/user-service/user_serviceAPI';
import { Avatar } from '@/shared/ui/Avatar/Avatar';
import { Heading } from '@/shared/ui/Heading';
import s from './SingleBirthdayUser.module.scss';

interface Props {
	user: BirthdayUser;
}

export const SingleBirthdayUser: React.FC<Props> = ({ user }) => {
	// * API
	const { data: userData } = useGetUserGetQuery({ userid: user.userId, includeavatars: true });
	const avatar = userData?.body?.user.avatar || null;

	// * Render
	return (
		<div
			className={s.card}
			key={user.userId}
		>
			<Avatar
				userAvatar={avatar}
				name={user.fullName}
				size="medium"
			/>
			<div>
				<Heading
					level={5}
					className={s.name}
				>
					{user.fullName}
				</Heading>
				<p className={s.position}>{user.positionName}</p>
			</div>
		</div>
	);
};
