import React from 'react';
import s from './AddButtons.module.scss';
import { Button } from '@/shared/ui/_buttons/Button';
import AddSVG from '@/shared/assets/svg/action/add.svg?react';
import MenuSVG from '@/shared/assets/svg/action/menu.svg?react';
import { QuestionItem, SectionProps } from '../../_types';
import { v4 } from 'uuid';
import { questionCategories } from '../../meta/surveyCategory';

interface Props {
	sections: SectionProps[];
	setSections: (sections: SectionProps[]) => void;
	indexSection: number;

	allRequiredFields: boolean;
}

export const AddButtons: React.FC<Props> = props => {
	const {
		sections,
		setSections,
		indexSection,

		allRequiredFields,
	} = props;

	// * Question
	const defaultQuestion: QuestionItem = {
		id: `new_${v4()}`,
		content: '',
		type: questionCategories[0],
		isObligatory: allRequiredFields,
		hasMultipleChoice: true,
		position: 1,
		options: [
			{
				id: `new_${v4()}`,
				content: 'Вариант 1',
				isCustom: false,
			},
		],
		gridOptions: [],
		scaleOptions: [],
		questionImages: [],
	};

	const defaultValuesSection = {
		id: `new_${v4()}`,
		subject: `Раздел ${sections.length + 1}`,
		position: sections.length + 1,
		questions: [defaultQuestion],
	};

	const addNewQuestion = (indexSection: number) => {
		const preparedSections = sections.map((section, i) => {
			if (i !== indexSection) return section;

			const { questions } = section;

			return {
				...section,
				questions: [
					...questions,
					{
						...defaultQuestion,
						position: questions.length + 1,
					},
				],
			};
		});

		setSections([...preparedSections]);
	};

	const addNewSection = () => {
		setSections([...sections, defaultValuesSection]);
	};

	return (
		<div className={s.container}>
			<Button
				type="button"
				variant="tertiary"
				LeftIcon={<AddSVG />}
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					sections.length < 1 ? addNewSection() : addNewQuestion(indexSection);
				}}
			>
				Добавить вопрос
			</Button>

			<Button
				type="button"
				variant="tertiary"
				LeftIcon={<MenuSVG />}
				onClick={e => {
					e.preventDefault();
					e.stopPropagation();
					addNewSection();
				}}
			>
				Добавить раздел
			</Button>
		</div>
	);
};
