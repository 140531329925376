import { FC, useState } from 'react';
import cn from 'classnames';
import { ArticleData, PublicArticleData } from '@/app/redux/queries/wiki-service/types/types';
import { useAppSelector } from '@/app/redux/utils';
import { getIsActive } from '@/pages/_wiki/lib/getIsActive';
import { useCreateArticle } from '@/pages/_wiki/ui/pages/wiki/_hooks/useCreateArticle';
import { Heading } from '@/shared/ui/Heading';
import { Article } from '../Article/Article';
import { EditButtons } from '../EditButtons/EditButtons';
import s from './Articles.module.scss';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { ModalEditSubRubric } from '@/pages/_wiki/ui/_modals/ModalEditSubRubric/ModalEditSubRubric';

interface Props {
	title?: string;
	articles: ArticleData[] | PublicArticleData[];
	isPrivate: boolean;
	archived?: boolean;
	archivedParent?: boolean;
	rubricId?: string;
	subRubricId?: string;
}

export const Articles: FC<Props> = props => {
	const {
		articles, //
		title,
		isPrivate,
		archived,
		archivedParent,
		rubricId,
		subRubricId,
	} = props;

	// * Selectors
	const editMode = useAppSelector(state => state.wiki.editModeWiki);

	// * Create article
	const { toNewArticlePage } = useCreateArticle(rubricId || null, subRubricId || null, null);

	// * edit sub-rubrics
	const [openModal, setOpenModal] = useState(false);
	const toggleModal = () => setOpenModal(prevState => !prevState);

	// * Render
	return (
		<>
			{openModal && (
				<ModalNewProto
					isOpen={openModal}
					onClose={toggleModal}
				>
					<ModalEditSubRubric
						id={subRubricId || ''}
						initialRubricId={rubricId || ''}
						name={title || ''}
						toggleModal={toggleModal}
					/>
				</ModalNewProto>
			)}

			<div className={s.container}>
				{title && (
					<div className={s.edit_wrapper}>
						<Heading
							level={5}
							className={cn(archived && s.archived)}
						>
							{title}
						</Heading>

						{editMode && (
							<EditButtons
								id={subRubricId}
								addAction={archived ? undefined : toNewArticlePage}
								editAction={toggleModal}
								type={archivedParent ? undefined : 'sub-rubric'}
								isActive={!archived}
								name={title}
							/>
						)}
					</div>
				)}

				<div className={s.article}>
					{articles.length > 0 ? (
						articles.map(article => (
							<Article
								key={article.id}
								id={article.id}
								name={article.name}
								isPrivate={isPrivate}
								archived={getIsActive(article, true)}
								archivedParent={archivedParent}
								rubricId={rubricId}
								subRubricId={subRubricId}
							/>
						))
					) : (
						<div className={s.empty_list}>{title ? 'В этой подрубрике нет статей.' : 'В этой рубрике нет статей.'}</div>
					)}
				</div>
			</div>
		</>
	);
};
