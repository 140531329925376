import { createColumnHelper } from '@tanstack/react-table';
import { ReactNode } from 'react';
import { Avatar } from '@/shared/ui/Avatar/Avatar';
import s from './ParticipantsModal.module.scss';
import { Heading } from '@/shared/ui/Heading';

export interface ParticipantsData {
	id: string;
	name: {
		name: string;
		position: string;
		avatar: string;
	};
	department: string;
	delete: ReactNode;
}

const columnHelper = createColumnHelper<ParticipantsData>();

export const COLUMNS = [
	columnHelper.accessor('name', {
		header: 'ИМЯ',
		cell: info => (
			<div className={s.user}>
				<Avatar
					name="name"
					avatarId={info.getValue().avatar}
				/>
				<div>
					<Heading
						level={5}
						className={s.name}
					>
						{info.getValue().name}
					</Heading>
					<p className={s.position}>{info.getValue().position}</p>
				</div>
			</div>
		),
	}),
	columnHelper.accessor('department', {
		header: 'ДЕПАРТАМЕНТ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('delete', {
		header: '',
		cell: info => info.getValue(),
	}),
];
