import React, { useEffect, useState } from 'react';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { bookingPageActions } from '@/app/redux/state/office/_states/booking_page_state';
import { useAppDispatch } from '@/app/redux/utils';
import { routerPath } from '@/app/router/paths';
import { FiltersBooking } from '@/pages/_booking/types';
import { BookingFilters } from '../BookingFilters/BookingFilters';
import { BookingRoomsList } from '../BookingRoomsList/BookingRoomsList';
import { MyBookingCard } from '../MyBookingCard/MyBookingCard';
import { OfficeSelectionCard } from '../OfficeSelectionCard/OfficeSelectionCard';
import s from './BookingPage.module.scss';

export const BookingPage: React.FC = () => {
	const [officeId, setOfficeId] = useState<string>('');
	const [filters, setFilters] = useState<FiltersBooking>({});

	const onChangeOfficeId = (officeId: string) => {
		setOfficeId(officeId);
		setFilters({});
	};

	// * Actions
	const dispatch = useAppDispatch();
	const { clearSelectedAddress } = bookingPageActions;

	useEffect(() => {
		return () => {
			if (!window.location.pathname.startsWith(routerPath.booking.page)) {
				dispatch(clearSelectedAddress());
			}
		};
	}, []);

	// * Render
	return (
		<PageDecorator>
			<h1 className={s.title}>Бронирование мест</h1>

			<div className={s.main_block}>
				<OfficeSelectionCard setOfficeId={onChangeOfficeId} />

				<div className={s.second_block}>
					<MyBookingCard />

					<BookingFilters
						officeId={officeId}
						filters={filters}
						setFilters={setFilters}
					/>
				</div>
			</div>

			{officeId && (
				<div className={s.rooms_list}>
					<BookingRoomsList
						officeId={officeId}
						params={filters}
					/>
				</div>
			)}
		</PageDecorator>
	);
};
