import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import React, { useEffect } from 'react';
import { admin_serviceAPI } from '@/app/redux/queries/admin-service/admin_serviceAPI';
import People from '@/shared/assets/img/people.png';
import Download from '@/shared/assets/svg/files/download.svg?react';
import Logo from '@/shared/assets/svg/tercom-logo.svg?react';
import Trapezoid from '@/shared/assets/svg/trapezoid.svg?react';
import { dateToFullRuDataNameString } from '@/shared/lib/date';
import { addSpaceAfterThirdDigitRight } from '@/shared/lib/number';
import { Button } from '@/shared/ui/_buttons/Button';
import { FieldBase, FieldTemplate } from '../../pages/Offer/Offer';
import { CardTitle } from '../CardTitle/CardTitle';
import { ColorSection } from '../ColorSection/ColorSection';
import { DottedList } from '../DottedList/DottedList';
import s from './OfferCard.module.scss';
import { LABELS } from '../../consts/selectOptions';
import cn from 'classnames';

interface OfferCardProps {
	data: FieldTemplate;
	resetOnClick: any;
	goBackOnClick: any;
}

const colors: string[] = ['#E5EFFF', '#FFEAE5', '#E3F3DD', '#F2EFFA', '#F2F3DD', '#F2F4F2', '#E3F1F3', '#E1EEF3', '#FCF4DF', '#FCF4DF', '#DFF1DF', '#F6EAEE'];
const dotColors: string[] = ['#7286F0', '#8EDC69'];

export const OfferCard: React.FC<OfferCardProps> = ({ data, resetOnClick, goBackOnClick }) => {
	const [putActionCounts] = admin_serviceAPI.usePutActionCountsMutation();

	const printRef = React.useRef<HTMLDivElement>(null);

	const invitationSection = (dataArray: FieldBase[]) => {
		const jobTitle = dataArray.find(item => item.label === LABELS.jobTitle);
		const startDate = dataArray.find(item => item.label === LABELS.startDate);
		const registrationType = dataArray.find(item => item.label === LABELS.registrationType);
		const registration = dataArray.find(item => item.label === LABELS.registration);
		const probationPayment = dataArray.find(item => item.label === LABELS.probationPayment);
		const payment = dataArray.find(item => item.label === LABELS.payment);
		const jobFormat = dataArray.find(item => item.label === LABELS.jobFormat);
		const probation = dataArray.find(item => item.label === LABELS.probation);
		const schedule = dataArray.find(item => item.label === LABELS.schedule);
		const mainAddress = dataArray.find(item => item.label === LABELS.mainAddress);

		return (
			<>
				<div className={s.invitation}>
					<div>
						<div className={s.invitation__title}>{jobTitle?.label}:</div>
						<div className={s.invitation__description}>{jobTitle?.value}</div>
					</div>

					<div>
						<div className={s.invitation__title}>{startDate?.label}:</div>
						<div className={s.invitation__description}>{startDate?.value ? dateToFullRuDataNameString(new Date(startDate?.value)) : '-'}</div>
					</div>
				</div>

				<div className={s.invitation}>
					<div>
						<div className={s.invitation__title}>{registration?.label}:</div>
						<div className={s.invitation__description}>{registrationType?.value ? registrationType?.value + '. ' + registration?.value : registration?.value}</div>
					</div>
				</div>

				<div className={s.invitation}>
					<div>
						<div className={s.invitation__title}>{probationPayment?.label}:</div>
						<div className={s.invitation__description}>{addSpaceAfterThirdDigitRight(Number(probationPayment?.value)) + ' руб. / месяц на руки'}</div>
					</div>

					<div>
						<div className={s.invitation__title}>{payment?.label}:</div>
						<div className={s.invitation__description}>{addSpaceAfterThirdDigitRight(Number(payment?.value)) + ' руб. / месяц на руки'}</div>
					</div>
				</div>

				<div className={s.invitation}>
					<div>
						<div className={s.invitation__title}>{jobFormat?.label}:</div>
						<div className={s.invitation__description}>{jobFormat?.value}</div>
					</div>

					<div>
						<div className={s.invitation__title}>{probation?.label}:</div>
						<div className={s.invitation__description}>{probation?.value}</div>
					</div>
				</div>

				<div className={s.invitation}>
					<div>
						<div className={s.invitation__title}>{schedule?.label}:</div>
						<div className={s.invitation__description}>{schedule?.value}</div>
					</div>
				</div>

				<div className={s.invitation}>
					<div>
						<div className={s.invitation__title}>{mainAddress?.label}:</div>
						<div className={s.invitation__description}>{mainAddress?.value}</div>
					</div>
				</div>
			</>
		);
	};

	const dottedSectionContent = (dataArray: FieldBase[], color: string) => {
		return dataArray.map((item, index) => {
			return (
				<DottedList
					color={color}
					text={item.value}
					key={'dotted' + index}
				/>
			);
		});
	};

	const coloredSectionContent = (dataArray: FieldBase[]) => {
		return dataArray.map((item, index) => {
			return (
				<ColorSection
					color={colors[index]}
					text={item.value}
					key={'colored-section-' + index}
				/>
			);
		});
	};

	const handleDownloadPdf = async () => {
		const dataUrl = await htmlToImage.toJpeg(printRef.current!, {
			quality: 1,
			pixelRatio: 2,
		});
		const element = printRef.current!;
		const canvas = await html2canvas(element);
		const pdf = new jsPDF({
			orientation: 'portrait',
			format: [canvas.width / 4, canvas.height / 4],
		});
		const width = pdf.internal.pageSize.getWidth();
		const height = pdf.internal.pageSize.getHeight();
		const widthRatio = width / canvas.width;
		const heightRatio = height / canvas.height;
		const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

		pdf.addImage(dataUrl, 'JPEG', 0, 0, canvas.width * ratio, canvas.height * ratio);
		pdf.save(`${data.formalization[0].value}_offer.pdf`);

		putActionCounts({ actionType: 'OfferCreated' })
			.unwrap()
			.catch(err => console.log(err));
	};

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	return (
		<div className={s.container}>
			<div
				className={s.offerCard}
				ref={printRef}
			>
				<div>
					<div className={s.header}>
						<div className={s.header__text}>
							<Logo className={s.logo} />
							<div className={s.greetings}>
								<div className={s.greetings_name}>{data.formalization[0].value},</div>
								Добрый день!
							</div>
						</div>

						<div className={s.headerImage}>
							<img
								src={People}
								alt="people"
								className={s.image}
							/>
						</div>
						<Trapezoid className={s.offerCard__border} />
					</div>

					<div className={s.section_first}>
						<h2 className={s.invitationTitle}>
							ПРИГЛАШАЕМ СТАТЬ ЧАСТЬЮ КОМАНДЫ ЛАНИТ-ТЕРКОМ <br />И ПРЕДЛАГАЕМ СЛЕДУЮЩИЕ УСЛОВИЯ СОТРУДНИЧЕСТВА:
						</h2>
						{invitationSection(data.formalization)}
					</div>

					{data?.possibility?.length > 0 && (
						<div className={s.section}>
							<CardTitle title="Возможности <br/> в рамках компании:" />
							{dottedSectionContent(data.possibility, dotColors[0])}
							{data?.possibility?.length > 0 && (
								<div>
									<h4 className={s.endOfTrialHeader}>По завершении испытательного срока:</h4>
									{dottedSectionContent(data.endOfTrial, dotColors[1])}
								</div>
							)}
						</div>
					)}

					{!data?.workToDo[0].empty && (
						<div className={s.section}>
							<CardTitle title="Мы рады вам предложить следующие работы по проекту:" />
							{coloredSectionContent(data.workToDo)}
						</div>
					)}

					{data?.contacts[0] && (
						<div className={cn(s.section, s.contacts)}>
							<h4 className={cn(s.contacts__heading)}>{data.contacts[0].label}:</h4>
							<p className={s.contacts__paragraph}>{data.contacts[0].value}</p>
						</div>
					)}

					<div className={s.section}>
						<h2 className={s.blue}>Будем рады плодотворному сотрудничеству.</h2>
						<div className={s.hrTeam}>Команда HR</div>
					</div>
				</div>
			</div>

			<div className={s.buttons}>
				<Button
					onClick={handleDownloadPdf}
					variant="primary"
					wide={true}
					LeftIcon={<Download />}
				>
					Скачать в формате PDF
				</Button>

				<Button
					variant="secondary"
					wide={true}
					onClick={goBackOnClick}
				>
					Вернуться к редактированию
				</Button>

				<Button
					variant="tertiary"
					wide={true}
					onClick={resetOnClick}
				>
					Сбросить и начать заново
				</Button>
			</div>
		</div>
	);
};
