import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { CreateTagsREQ, FindTagsREQ, FindTagsRES, RemoveTagsREQ, TagInfo } from './types';

const NAME = `${ServiceName.OFFICE_SERVICE}/tag`;

const createTags = createAppAsyncThunk(`${NAME}/createTags`, async (arg: CreateTagsREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest<boolean>({
		url: `${NAME}/create`,
		payload,
		thunkAPI,
		action: () => createTags(arg),
	});
});

const findTags = createAppAsyncThunk(`${NAME}/find`, async (arg: FindTagsREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<FindTagsRES>({
		url: `${NAME}/find`,
		params,
		thunkAPI,
		action: () => findTags(arg),
	});
});

const removeTags = createAppAsyncThunk(`${NAME}/remove`, async (arg: RemoveTagsREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.delRequest<boolean>({
		url: `${NAME}/remove`,
		payload,
		thunkAPI,
		action: () => removeTags(arg),
	});
});

export interface State {
	allTags: TagInfo[];
	totalCount: number;
	status: RequestStatus;
}

export const initialState: State = {
	allTags: [],
	totalCount: 0,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		clearTags: state => {
			state.allTags = [];
		},
	},
	extraReducers: builder => {
		builder.addCase(createTags.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createTags.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(createTags.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(findTags.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findTags.fulfilled, (state, action) => {
			state.allTags = action.payload.body;
			state.totalCount = action.payload.totalCount;
			state.status = RequestStatus.still;
		});
		builder.addCase(findTags.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(removeTags.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(removeTags.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(removeTags.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	createTags,
	findTags,
	removeTags,
};
