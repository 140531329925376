import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { survey_serviceAPI } from '@/app/redux/queries/survey-service/survey_serviceAPI';
import { Button } from '@/shared/ui/_buttons/Button';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import s from '../DisplaySurveyPage/DisplaySurveyPage.module.scss';
import { FillingOutForm } from '../../components/FillingOutForm/FillingOutForm';
import { routerPath } from '@/app/router/paths';
import { PageTitle } from '@/entities/PageTitle/PageTitle';

export const TemplatePage: React.FC = () => {
	const navigate = useNavigate();

	// * Route
	const { surveyId } = useParams<'surveyId'>();

	const { page, newSurvey } = routerPath.surveys;
	const createSurveyRoute = `${page}${newSurvey}/${surveyId}`;

	// * API
	const { currentData: surveyIdData, isLoading } = survey_serviceAPI.useGetSurveysSurveyidQuery({
		surveyid: surveyId as string,
		includeCategories: true,
		includeImages: true,
	});

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				className={s.header}
				title="Опросы"
				isBeta
				buttons={[
					<Button
						key="0"
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							navigate(createSurveyRoute);
						}}
					>
						Создать опрос по шаблону
					</Button>,
				]}
			/>

			<FillingOutForm
				id={surveyId}
				surveyInfo={{
					title: surveyIdData?.subject || '',
					description: surveyIdData?.description || '',
					isAnonymous: !!surveyIdData?.isAnonymous,
				}}
				category={surveyIdData?.surveyCategories ? surveyIdData.surveyCategories[0] : null}
				deadline={surveyIdData?.deadline}
				groups={surveyIdData?.groups || []}
				notClickable={true}
				isLoading={isLoading}
				isTemplate
			/>
		</PageDecorator>
	);
};
