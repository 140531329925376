import { useEffect } from 'react';
import { RubricData, PublicRubricData } from '@/app/redux/queries/wiki-service/types/types';
import { wiki_serviceAPI } from '@/app/redux/queries/wiki-service/wiki_serviceAPI';

export const useGetWiki = (isPrivate: boolean, archiveMode: boolean): RubricData[] | PublicRubricData[] | undefined => {
	const [getWiki, { data: wikiData }] = wiki_serviceAPI.useLazyGetWikiQuery();
	const [getPublicWiki, { data: publicWikiData }] = wiki_serviceAPI.useLazyGetPublicWikiQuery();

	useEffect(() => {
		if (isPrivate) {
			getWiki({ includedeactivated: archiveMode });
		} else {
			getPublicWiki();
		}
	}, [archiveMode]);

	return isPrivate ? wikiData : publicWikiData;
};
