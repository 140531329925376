import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLUMNS_PROJECTS, ProjectsTableDataItem } from '@/pages/_projectService/ui/ProjectPage/ui/ProjectsList/consts/COLUMNS_PROJECTS';
import s from './ProjectsList.module.scss';

interface Props {
	projects: ProjectsTableDataItem[];
}

export const ProjectsList: React.FC<Props> = props => {
	const { projects } = props;

	const [data, setData] = useState(projects);

	useEffect(() => {
		setData(projects);
	}, [projects]);

	// * Table
	const table = useReactTable({
		data,
		columns: COLUMNS_PROJECTS,
		getCoreRowModel: getCoreRowModel(),
	});

	// * Navigate
	const navigate = useNavigate();

	return (
		<div className={s.container}>
			<div className={s.table}>
				{table.getHeaderGroups().map(headerGroup => (
					<div
						className={cn(s.table_row, s.header_wrapper)}
						key={headerGroup.id}
					>
						{headerGroup.headers.map(header => (
							<div
								className={s.table_header}
								key={header.id}
							>
								{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
							</div>
						))}
					</div>
				))}

				{table.getRowModel().rows.map(row => (
					<div
						className={cn(s.table_row, s.cell_card)}
						key={row.id}
						onClick={() => navigate(row.original.id)}
					>
						{row.getVisibleCells().map(cell => (
							<div
								className={s.table_cell}
								key={cell.id}
							>
								{flexRender(cell.column.columnDef.cell, cell.getContext())}
							</div>
						))}
					</div>
				))}
			</div>
		</div>
	);
};
