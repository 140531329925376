import React from 'react';
import { filter_serviceAPI } from '@/app/redux/queries/filter-service/filter_serviceAPI';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { useRights } from '@/shared/hooks/useRights';
import { UsersFilters } from '@/pages/UsersPage/ui/UsersFilters/UsersFilters';
import { getContactType } from '@/shared/lib/contact';
import { EmptyData } from '@/shared/ui/EmptyData/EmptyData';
import { GetUsersContextProvider } from '../../context/useGetUsersContext';
import { CardTable } from '../CardTable/CardTable';
import { getColumnsUsers } from './consts/COLUMNS_USERS';
import { createFullName } from '@/shared/lib/string';
import { routerPath } from '@/app/router/paths';

interface Props {
	isActive: boolean;
	isPending: boolean;
}

export const Users: React.FC<Props> = props => {
	const {
		isActive, //
		isPending,
	} = props;

	// * Rights
	const hasEditUsersRights = useRights(1);

	// * API
	const [getUserFilter, { data: usersData, isLoading, isFetching, originalArgs, isSuccess }] = filter_serviceAPI.useLazyGetUsersQuery();

	// * Users
	const users =
		(usersData?.body &&
			usersData?.body.map(item => {
				const { id, avatarId, firstName, lastName, middleName, department, position, role, communicationInfo } = item;
				const fullName = createFullName({
					firstName: firstName || '',
					lastName: lastName || '',
					middleName: middleName || '',
				});

				const contacts =
					communicationInfo?.map(contact => ({
						id: contact.communicationId || '',
						type: getContactType(contact.type || 0),
						isConfirmed: false,
						value: contact.value || '',
					})) || [];

				return {
					id: id || '',
					name: fullName,
					avatarId: avatarId || '',
					department: department?.name || '-',
					departmentId: department?.id || '',
					position: position?.name || '',
					role: role?.name || '-',
					isActive,
					isPending,
					contacts,
				};
			})) ||
		[];

	const onUserClick = (userId: string) => {
		const userUrl = `${routerPath.users.page}/${userId}`;
		window.open(userUrl, '_blank', 'noopener,noreferrer');
	};

	const conditionalRendering = () => {
		if (isLoading || !usersData) {
			return <LoaderCircle />;
		} else if (users.length > 0 && !isLoading) {
			return (
				<CardTable
					columns={getColumnsUsers(!isActive && isPending, hasEditUsersRights)}
					data={users}
					onRowClick={rowId => onUserClick(users[rowId].id)}
					sortingColumnId="name"
				/>
			);
		} else if (usersData && users.length === 0 && !isLoading) {
			return <EmptyData />;
		}
	};

	// * Render
	return (
		<GetUsersContextProvider
			getUserFilter={getUserFilter}
			originalArgs={originalArgs}
		>
			<UsersFilters
				isActive={isActive}
				isPending={isPending}
			/>
			{conditionalRendering()}
		</GetUsersContextProvider>
	);
};
