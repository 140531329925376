import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@/app/api/apiSlice';
import { ServiceName } from '@/app/api/api_types';
import {
	GetRightsListApiArg,
	FindRolesApiArg,
	GetRoleApiArg,
	CreateRoleApiArg,
	EditRoleStatusApiArg,
	EditRoleRightsApiArg,
	CreateRoleLocalizationApiArg,
	EditRoleLocalizationApiArg,
	EditUserRoleApiArg,
} from './types/regTypes';
import {
	GetRightsListApiResponse,
	FindRolesApiResponse,
	GetRoleApiResponse,
	CreateRoleApiResponse,
	EditRoleStatusApiResponse,
	EditRoleRightsApiResponse,
	CreateRoleLocalizationApiResponse,
	EditRoleLocalizationApiResponse,
	EditUserRoleApiResponse,
} from './types/resTypes';

const reducerPath = `${ServiceName.RIGHTS_SERVICE}` as const;

export const rights_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Rights', 'Role', 'RoleLocalization', 'UserRole'] as const,
	endpoints: build => ({
		getRightsList: build.query<GetRightsListApiResponse, GetRightsListApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/rights/get`,
				params: { locale: queryArg.locale },
			}),
			providesTags: ['Rights'],
		}),

		findRoles: build.query<FindRolesApiResponse, FindRolesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/roles/find`,
				params: {
					locale: queryArg.locale,
					includeDeactivated: queryArg.includeDeactivated,
					skipCount: queryArg.skipCount,
					takeCount: queryArg.takeCount,
				},
			}),
			providesTags: ['Role'],
		}),

		getRole: build.query<GetRoleApiResponse, GetRoleApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/roles/get`,
				params: { roleId: queryArg.roleId },
			}),
			providesTags: ['Role'],
		}),

		createRole: build.mutation<CreateRoleApiResponse, CreateRoleApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/roles/create`,
				method: 'POST',
				body: queryArg.createRoleRequest,
			}),
			invalidatesTags: ['Role'],
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),

		editRoleStatus: build.mutation<EditRoleStatusApiResponse, EditRoleStatusApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/roles/editstatus`,
				method: 'PUT',
				params: { roleId: queryArg.roleId, isActive: queryArg.isActive },
			}),
			invalidatesTags: ['Role'],
		}),

		editRoleRights: build.mutation<EditRoleRightsApiResponse, EditRoleRightsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/roles/updaterightsset`,
				method: 'POST',
				body: queryArg.updateRoleRightsRequest,
			}),
			invalidatesTags: ['Role'],
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),

		createRoleLocalization: build.mutation<CreateRoleLocalizationApiResponse, CreateRoleLocalizationApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/rolelocalization/create`,
				method: 'POST',
				body: queryArg.createRoleLocalizationRequest,
			}),
			invalidatesTags: ['RoleLocalization'],
		}),

		editRoleLocalization: build.mutation<EditRoleLocalizationApiResponse, EditRoleLocalizationApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/rolelocalization/edit`,
				method: 'PATCH',
				body: queryArg.editRoleLocalizationRequest,
				params: { roleLocalizationId: queryArg.roleLocalizationId },
			}),
			invalidatesTags: ['Role', 'RoleLocalization'],
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),

		editUserRole: build.mutation<EditUserRoleApiResponse, EditUserRoleApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/user/edit`,
				method: 'PUT',
				body: queryArg.editUserRoleRequest,
			}),
			invalidatesTags: ['UserRole'],
		}),
	}),
});

export const {
	useGetRightsListQuery,
	useFindRolesQuery,
	useGetRoleQuery,
	useCreateRoleMutation,
	useEditRoleStatusMutation,
	useEditRoleRightsMutation,
	useCreateRoleLocalizationMutation,
	useEditRoleLocalizationMutation,
	useEditUserRoleMutation,
} = rights_serviceAPI;
