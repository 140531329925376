import React from 'react';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { OfficesLists } from '../../OfficesLists/OfficesLists';
import { OfficesPageHeader } from '../../OfficesPageHeader/OfficesPageHeader';

export const OfficesPage: React.FC = () => {
	return (
		<PageDecorator>
			<OfficesPageHeader />
			<OfficesLists />
		</PageDecorator>
	);
};
