import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageDecorator } from '@/app/decorators/page_decorators';
import { claim_serviceAPI } from '@/app/redux/queries/claim-service/claim_serviceAPI';
import { useGetDepartmentFindQuery } from '@/app/redux/queries/department-service/department_serviceAPI';
import { routerPath } from '@/app/router/paths';
import { PageTitle } from '@/entities/PageTitle/PageTitle';
import SearchSVG from '@/shared/assets/svg_icons/action/search.svg?react';
import { dateTimeStringToISO, injectTimeToDate } from '@/shared/lib/date';
import { Button } from '@/shared/ui/_buttons/Button';
import { DateRangeInput } from '@/shared/ui/_inputs/date_pickers/DateRangeInput';
import { SelectSingleWithSearch } from '@/shared/ui/_inputs/selects/SelectSingleWithSearch';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { Heading } from '@/shared/ui/Heading';
import { StatisticsPie } from '../StatisticsPie/StatisticsPie';
import { StatisticsTable } from '../StatisticsTable/StatisticsTable';
import s from './ClaimStatisticsPage.module.scss';

interface DataStatistic {
	id: string;
	label: string;
	value: number;
}

const STATISTICS: DataStatistic[] = [
	{
		id: 'completedPercentage',
		label: 'Выполнено в срок',
		value: 0,
	},
	{
		id: 'completedOutdatedPercentage',
		label: 'Выполнено не в срок',
		value: 0,
	},
	{
		id: 'declinedPercentage',
		label: 'Отклонено',
		value: 0,
	},
	{
		id: '',
		label: 'В работе',
		value: 0,
	},
];

export const ClaimStatisticsPage = () => {
	const navigate = useNavigate();
	const [department, setDepartment] = useState({ id: '', name: '' });
	const [category, setCategory] = useState({ id: '', name: '' });
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(null);
	const [fullname, setFullname] = useState('');

	useEffect(() => {
		getClaimStats({
			...(department.id && { departmentIds: [department.id] }),
			...(category.id && { categoryIds: [category.id] }),
			...(startDate && { periodStart: dateTimeStringToISO(startDate, '00:00:00') }),
			...(endDate && { periodEnd: dateTimeStringToISO(endDate, '00:00:00') }),
		});
	}, [department, category, startDate, endDate]);

	// * Api
	const [getClaimStats, { data }] = claim_serviceAPI.useLazyGetClaimsGetStatsQuery();
	const { data: departmentData } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});
	const { currentData: categoriesTreeData } = claim_serviceAPI.useGetCategoriesQuery({
		skipcount: 0,
		takecount: 1000,
	});

	// * Departments
	const departmentAll = (departmentData?.body ?? []).map(department => ({
		id: department.id,
		name: department.shortName,
		fullName: department.name,
	}));

	// * Categories
	const categories =
		categoriesTreeData?.body?.map(category => ({
			id: category.id,
			name: category.name,
		})) || [];

	// * Nav
	const navigateToClaims = () => {
		navigate(routerPath.claim.page);
	};

	// * Update statistic
	const updateStatistic = (arrStats: DataStatistic[], data: any) => {
		return (
			data &&
			arrStats.map((item: DataStatistic) => {
				const newValue = item.id in data ? data[item.id] : 0;

				return {
					...item,
					value: newValue ?? item.value,
				};
			})
		);
	};

	const newStatistic = updateStatistic(STATISTICS, data);

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title="Система заявок"
				isBeta
				buttons={[
					<Button
						key={0}
						onClick={navigateToClaims}
					>
						К заявкам
					</Button>,
				]}
			/>
			<div className={s.wrapper}>
				<div className={s.filter}>
					<SelectSingleWithSearch
						placeholder="Департамент"
						keyNames={{ name: 'name', value: 'id', tooltipText: 'fullName' }}
						selectedOption={department}
						setSelectedOption={value => setDepartment(value)}
						options={departmentAll}
					/>
					<SelectSingleWithSearch
						placeholder="Все категории"
						keyNames={{ name: 'name', value: 'id' }}
						selectedOption={category}
						setSelectedOption={value => setCategory(value)}
						options={categories}
					/>
					<DateRangeInput
						placeholderText="ДД.ММ.ГГГГ"
						startDate={startDate}
						setStartDate={date => setStartDate(date ? injectTimeToDate(date, '00:00') : date)}
						endDate={endDate}
						setEndDate={date => setEndDate(date ? injectTimeToDate(date, '23:59') : date)}
						isClearable={true}
					/>
				</div>
				<div className={s.statistics}>
					<Heading
						level={2}
						className={s.statistics_title}
					>
						{department.name || 'Все заявки'}
					</Heading>
					<div className={s.statistics_info}>
						<div className={s.statistics_columns}>
							<div>
								<p className={s.statistics_text}>Всего заявок:</p>
								<div className={s.statistics_text}>
									<p className={s.statistics_done}>Выполнено:</p>
									<p>В срок</p>
									<p>Не в срок</p>
								</div>
								<p className={s.statistics_text}>Отклонено:</p>
								<p className={s.statistics_text}>В работе:</p>
							</div>
							<div>
								<p className={s.statistics_text}>{data?.totalAmount || '-'}</p>
								<div>
									<p className={s.statistics_done}>{data?.completed || '-'}</p>
									<p>-</p>
									<p className={s.text}>-</p>
								</div>
								<p className={s.statistics_text}>{data?.declined || '-'}</p>
								<p className={s.statistics_text}>-</p>
							</div>
						</div>
						<StatisticsPie data={newStatistic} />
					</div>
				</div>
				<div className={s.filter_users}>
					<SelectSingleWithSearch
						placeholder="Должность"
						keyNames={{ name: 'name', value: 'id' }}
						selectedOption={department}
						setSelectedOption={value => setDepartment(value)}
						options={departmentAll}
					/>
					<TextInput
						value={fullname}
						onChange={e => setFullname(e.target.value)}
						placeholder="Поиск сотрудника по ФИО"
						RightIcon={<SearchSVG />}
					/>
				</div>
				<StatisticsTable />
			</div>
		</PageDecorator>
	);
};
