import React, { useEffect } from 'react';
import { useAppDispatch } from '@/app/redux/utils';
import { WikiSearch } from '../WikiSearch/WikiSearch';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import s from './WikiPage.module.scss';
import { Content } from '../Content/ui/Content/Content';
import { actionsWiki } from '@/app/redux/state/wiki/slice';

export const WikiPage: React.FC = () => {
	const dispatch = useAppDispatch();

	const { setVisited } = actionsWiki;

	useEffect(() => {
		const isVisited = localStorage.getItem('wikiVisited');

		if (!isVisited) {
			localStorage.setItem('wikiVisited', 'visited');
			dispatch(setVisited());
		}
	}, []);

	// * Render
	return (
		<PageDecorator>
			<div className={s.container}>
				<WikiSearch
					className={s.search}
					isPrivate={true}
				/>

				<Content isPrivate={true} />
			</div>
		</PageDecorator>
	);
};
