import React from 'react';
import { ErrorWithLimit } from '../../_shared/ErrorWithLimit/ErrorWithLimit';
import { InputLabel } from '../../_shared/InputLabel/InputLabel';
import { DatePickerInput } from '@/shared/ui/_inputs/date_pickers/DatePickerInput';
import { TimePickerRangeInput } from '../TimePickerRangeInput';
import s from './DateAndTimeRangePickerInput.module.scss';

interface Props {
	selectedDate: Date | null;
	setSelectedDate: (date: Date | null) => void;
	activeStartTime: Date | null;
	setActiveStartDate: (date: Date | null) => void;
	activeEndDate: Date | null;
	setActiveEndDate: (date: Date | null) => void;
	label?: string;
	errorMessage?: string;

	disabledStart?: boolean;
	disabledEnd?: boolean;

	minTime?: Date;
	maxTime?: Date;

	required?: boolean;
	disabled?: boolean;
}

export const DateAndTimeRangePickerInput: React.FC<Props> = props => {
	const {
		label,
		selectedDate,
		setSelectedDate,
		activeStartTime,
		setActiveStartDate,
		activeEndDate,
		setActiveEndDate,
		errorMessage,

		disabledStart,
		disabledEnd,

		minTime,
		maxTime,

		required,
		disabled,
	} = props;

	// * Render
	return (
		<>
			<div className={s.container}>
				<InputLabel
					label={label}
					required={required}
				/>

				<div className={s.inputs_wrapper}>
					<DatePickerInput
						placeholderText="ДД.ММ.ГГГГ"
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
						disabled={disabled}
					/>
					<TimePickerRangeInput
						activeStartTime={activeStartTime}
						setActiveStartDate={setActiveStartDate}
						activeEndDate={activeEndDate}
						setActiveEndDate={setActiveEndDate}
						minTime={minTime}
						maxTime={maxTime}
						disabledStart={disabledStart}
						disabledEnd={disabledEnd}
					/>
				</div>

				{errorMessage && <ErrorWithLimit errorMessage={errorMessage} />}
			</div>
		</>
	);
};
