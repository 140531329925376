import { DateTime } from 'luxon';
import { User } from '../../types';

export const getSortedVacations = (
	users: User[],
	user: {
		id: string;
		name: string;
	},
	monthIndex: number,
) => {
	// * Checking for data existence
	const userVacations = users
		?.find(vacation => vacation.id === user.id)
		?.vacations?.filter(vacation => DateTime.fromFormat(vacation.startDate, 'yyyy-MM-dd').month === monthIndex + 1);

	if (!userVacations?.length) {
		return null;
	}

	const sortedVacations = [...userVacations]
		.sort(a => (a.type === 'Planned' ? -1 : 1)) // Сортировка для того чтобы планируемые отпуска всегда были под обычными. В верстке нижний элемент перекрывает верхний если position: static (по умолчанию).
		.map(vacation => {
			const startDateLuxon = DateTime.fromFormat(vacation.startDate, 'yyyy-MM-dd');
			const endDateLuxon = DateTime.fromFormat(vacation.endDate, 'yyyy-MM-dd');

			return {
				...vacation,
				startDateLuxon,
				endDateLuxon,
				formattedStartDate: startDateLuxon.toFormat('dd.MM.yy'),
				formattedEndDate: endDateLuxon.toFormat('dd.MM.yy'),
			};
		});

	return sortedVacations;
};
