import React from 'react';
import { dateToTimeString, isoTimeStringToDate } from '@/shared/lib/date';
import { Controller, useFormContext } from 'react-hook-form';
import { DateAndTimePickerInput } from '@/shared/ui/_inputs/date_pickers/DateAndTimePickerInput';
import { ReactDatePickerProps } from 'react-datepicker';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
	dateName: string;
	timeName: string;
	label: string;
	required?: boolean;
}

export const DateAndTimeField: React.FC<Props> = props => {
	const {
		dateName, //
		timeName,
		label,
		required,
		...restProps
	} = props;

	const { control, setValue } = useFormContext();

	// * Render
	return (
		<Controller
			name={dateName}
			control={control}
			render={({ field: dateField, fieldState: dateFieldState }) => (
				<Controller
					name={timeName}
					control={control}
					render={({ field: timeField, fieldState: timeFieldState }) => (
						<DateAndTimePickerInput
							{...restProps}
							selectedDate={dateField.value}
							setSelectedDate={date => setValue(dateField.name, date, { shouldValidate: true })}
							activeTime={timeField.value ? isoTimeStringToDate(timeField.value) : null}
							setActiveTime={date => setValue(timeField.name, date ? dateToTimeString(date) : null, { shouldValidate: true })}
							label={label}
							errorMessage={dateFieldState.error?.message || timeFieldState.error?.message}
							required={required}
						/>
					)}
				/>
			)}
		/>
	);
};
