import { FC, useState } from 'react';
import { education_serviceAPI } from '@/app/redux/queries/education-service/education_serviceAPI';
import { EducationInfo } from '@/app/redux/queries/education-service/types/types';
import DeleteSVG from '@/shared/assets/svg_icons/action/delete.svg?react';
import EditSVG from '@/shared/assets/svg_icons/action/edit.svg?react';
import { DropDownMenu } from '@/shared/ui/DropDownMenu/DropDownMenu';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { ConfirmModal } from '../../../../../../../../entities/_modals/ConfirmModal/ConfirmModal';
import { getEducationType } from '../../lib';
import { EducationForm } from '../EducationForm/EducationForm';
import { image_serviceAPI } from '@/app/redux/queries/image-serviceAPI/image_serviceAPI';

interface Props {
	info: EducationInfo;
	isCertificate: boolean;
}

export const EducationDropDownMenu: FC<Props> = props => {
	const { info, isCertificate } = props;

	const imageId = info.imagesIds ? info.imagesIds[0] : null;

	// * API
	const [deleteEducation, { isLoading }] = education_serviceAPI.useDeleteEducationRemoveMutation();

	const { currentData: imageInfo } = image_serviceAPI.useGetImageQuery(
		{
			params: {
				imageId: imageId as string,
				source: 'Education',
			},
		},
		{ skip: !imageId },
	);

	const images = imageInfo?.body ? [imageInfo.body] : [];

	// * Modals
	const [showEditForm, setShowEditForm] = useState(false);
	const toggleEditForm = () => setShowEditForm(prevState => !prevState);

	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const toggleDeleteModal = () => setShowDeleteModal(prevState => !prevState);

	// - Action
	const onDeleteEducation = () => {
		deleteEducation({
			educationId: info.id,
		})
			.unwrap()
			.then(() => toggleDeleteModal)
			.catch(err => console.log(err));
	};

	// * Render
	return (
		<>
			{showEditForm && (
				<ModalNewProto
					isOpen={showEditForm}
					onClose={toggleEditForm}
				>
					<EducationForm
						userId={info.userId}
						educationId={info.id}
						initialValues={{
							institution: info.university,
							specialization: info.qualificationName,
							form: {
								id: info.educationForm.id,
								name: getEducationType(info.educationForm.name),
							},
							startDate: new Date(info.admissionAt).getFullYear() + '',
							endDate: info.issueAt ? new Date(info.issueAt).getFullYear() + '' : '',
							completeness: info.completeness === 'Completed',
							images,
						}}
						isCertificate={isCertificate}
						onCloseModal={toggleEditForm}
					/>
				</ModalNewProto>
			)}

			{showDeleteModal && (
				<ConfirmModal
					isOpen={showDeleteModal}
					onClose={toggleDeleteModal}
					title="Удаление"
					description={`Вы действительно хотите удалить информацию ${isCertificate ? 'о сертификате' : 'об образовании'}?`}
					primaryOnClick={onDeleteEducation}
					isLoading={isLoading}
				/>
			)}

			<DropDownMenu
				items={[
					{
						icon: <EditSVG />,
						title: 'Редактировать',
						action: toggleEditForm,
					},
					{
						icon: <DeleteSVG />,
						title: 'Удалить',
						action: toggleDeleteModal,
					},
				]}
			/>
		</>
	);
};
