import { useState } from 'react';
import { Button } from '@/shared/ui/_buttons/Button';
import { FormContent } from '@/pages/_projectService/ui/ProjectPage/ui/FormContent/FormContent';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';

interface Props {
	isEdit?: boolean;
}

export const FormButton: React.FC<Props> = props => {
	const { isEdit } = props;

	// * Modal
	const [isShowFormModal, setIsShowFormModal] = useState(false);
	const toggleFormModal = () => setIsShowFormModal(prevState => !prevState);

	// * Render
	return (
		<>
			{isShowFormModal && (
				<ModalNewProto
					isOpen={isShowFormModal}
					onClose={toggleFormModal}
				>
					<FormContent
						isEdit={isEdit}
						onCloseModal={toggleFormModal}
					/>
				</ModalNewProto>
			)}

			<Button
				variant={isEdit ? 'tertiary' : 'primary'}
				onClick={toggleFormModal}
			>
				{isEdit ? 'Редактировать' : 'Добавить проект'}
			</Button>
		</>
	);
};
