import React from 'react';
import s from './WikiPagePublic.module.scss';
import { WikiSearch } from '../WikiSearch/WikiSearch';
import { Content } from '../Content/ui/Content/Content';
import { PageDecoratorPublic } from '@/app/decorators/page_decorators/PageDecoratorPublic/PageDecoratorPublic';

export const WikiPagePublic: React.FC = () => {
	return (
		<PageDecoratorPublic>
			<div className={s.container}>
				<WikiSearch
					className={s.search}
					isPrivate={false}
				/>

				<Content isPrivate={false} />
			</div>
		</PageDecoratorPublic>
	);
};
