import { FileRejection } from 'react-dropzone/.';

export const getRejectedFilesErrors = (fileRejections: FileRejection[]) => {
	const errorCodes: string[] = [];

	fileRejections.forEach(item => {
		item.errors.forEach(error => {
			if (!errorCodes.includes(error.code)) {
				errorCodes.push(error.code);
			}
		});
	});

	return errorCodes;
};
