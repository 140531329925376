import React from 'react';
import { BooleanSwitch } from '../_switches/BooleanSwitch/BooleanSwitch';
import { Controller, useFormContext } from 'react-hook-form';

interface Props {
	name: string;
	text: string;
	disabled?: boolean;
	isLeftText?: boolean;
}

export const SwitchField: React.FC<Props> = props => {
	const {
		name, //
		text,
		disabled,
		isLeftText = false,
	} = props;

	const { control } = useFormContext();

	// * Render
	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<BooleanSwitch
					{...field}
					text={text}
					checked={field.value}
					disabled={disabled}
					isLeftText={isLeftText}
				/>
			)}
		/>
	);
};
