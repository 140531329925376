import { useParams } from 'react-router-dom';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { SurveyForm } from '../../components/SurveyForm/SurveyForm';

export const NewSurveyPage = () => {
	// * survey id
	const { surveyId } = useParams<'surveyId'>();

	const isNewSurvey = surveyId === 'new';

	// * Render
	return (
		<PageDecorator>
			<SurveyForm
				isTemplate={false}
				surveyId={!isNewSurvey && surveyId ? surveyId : null}
			/>
		</PageDecorator>
	);
};
