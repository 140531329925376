import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { CreateRoomTypeREQ, EditRoomTypeREQ, FindRoomTypesREQ, FindRoomTypesRES, RoomType } from './types';

const NAME = `${ServiceName.OFFICE_SERVICE}/roomType`;

const createRoomType = createAppAsyncThunk(`${NAME}/createRoomType`, async (arg: CreateRoomTypeREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest<string>({
		url: `${NAME}/create`,
		payload,
		thunkAPI,
		action: () => createRoomType(arg),
	});
});

const findRoomTypes = createAppAsyncThunk(`${NAME}/findRoomTypes`, async (arg: FindRoomTypesREQ, thunkAPI) => {
	const { params } = arg;
	const { signal } = thunkAPI;

	return await apiRequest.getRequest<FindRoomTypesRES>({
		url: `${NAME}/find`,
		params,
		thunkAPI,
		action: () => findRoomTypes(arg),
		signal,
	});
});

const editRoomType = createAppAsyncThunk(`${NAME}/editRoomType`, async (arg: EditRoomTypeREQ, thunkAPI) => {
	const { params, payload } = arg;

	return await apiRequest.patchRequest<string>({
		url: `${NAME}/edit`,
		params,
		payload,
		thunkAPI,
		action: () => editRoomType(arg),
	});
});

export interface State {
	roomTypes: RoomType[];
	totalCount: number;
	status: RequestStatus;
}

export const initialState: State = {
	roomTypes: [],
	totalCount: 0,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(createRoomType.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createRoomType.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(createRoomType.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(findRoomTypes.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findRoomTypes.fulfilled, (state, action) => {
			state.roomTypes = action.payload.body;
			state.totalCount = action.payload.totalCount;
			state.status = RequestStatus.still;
		});
		builder.addCase(findRoomTypes.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(editRoomType.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editRoomType.fulfilled, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editRoomType.rejected, state => {
			state.status = RequestStatus.still;
		});
	},
});

export const asyncActions = {
	createRoomType,
	findRoomTypes,
	editRoomType,
};
