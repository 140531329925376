import s from './StatisticsLegends.module.scss';
import { ColorCircle } from '@/entities/ColorCircle';
import { COLORS } from '../../../../consts/consts';
import { color } from '@/shared/lib/color';

interface StatisticsElements {
	id: string;
	label: string;
	value: number;
}

export const StatisticsLegends = ({ data }: { data: StatisticsElements[] | undefined }) => {
	// * Libs
	const { getColors } = color;
	const colorsArray = data ? getColors(data.length, COLORS) : [];

	return (
		<div className={s.container}>
			{data &&
				data.map((item: StatisticsElements, index: number) => (
					<div
						key={item.id}
						className={s.wrapper}
					>
						<ColorCircle
							size="m"
							customColor={colorsArray[index]}
						/>

						<div className={s.label}>{item.label}</div>
					</div>
				))}
		</div>
	);
};
