import React, { ReactNode, useEffect } from 'react';
import { userServiceActions } from '@/app/redux/state/user/actions';
import { useAppDispatch, useAppSelector } from '@/app/redux/utils';
import { parseJwt } from '@/shared/lib/app/parseJwt/parseJwt';
import { Header } from '@/features/_layout/Header/Header';
import { Nav } from '@/features/_layout/Nav/ui/Nav/Nav';
import s from './PageDecorator.module.scss';

interface Props {
	children: ReactNode;
}

export const PageDecorator: React.FC<Props> = props => {
	const { children } = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { getAppUser } = userServiceActions.user.async;

	// * Selectors
	const userInfo = useAppSelector(state => state.user_service.user.userInfo);

	useEffect(() => {
		const accessToken = localStorage.getItem('access_token');
		const refreshToken = localStorage.getItem('refresh_token');

		if (accessToken && refreshToken && userInfo === null) {
			const parsedToken = parseJwt(accessToken);

			dispatch(
				getAppUser({
					params: {
						userId: parsedToken.UserId,
						includeCurrentAvatar: true,
						includeCommunications: true,
						includeCompany: true,
						includeDepartment: true,
						includeRole: true,
						includeOffice: true,
						includePosition: true,
						locale: 'ru',
					},
				}),
			);
		}
	});

	// * Render
	return (
		<div className={s.container}>
			<Nav
				className={s.menu}
				isPrivate={true}
			/>

			<div>
				<Header isPrivate={true} />
				<div className={s.content}>{children}</div>
			</div>
		</div>
	);
};
