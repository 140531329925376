import cn from 'classnames';
import { ComponentPropsWithoutRef, FC } from 'react';
import CloseSVG from '@/shared/assets/svg_icons/action/close.svg?react';
import DoneSVG from '@/shared/assets/svg_icons/action/done.svg?react';
import { ValidationItem } from './types';
import s from './ValidationRequirements.module.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {
	requirements: ValidationItem[];
}

export const ValidationRequirements: FC<Props> = props => {
	const {
		requirements, //
		className,
		...divProps
	} = props;

	// * Render
	return (
		<div
			className={cn(s.container, className)}
			{...divProps}
		>
			{requirements.map(({ isDone, text }, index) => (
				<div
					key={index}
					className={s.requirement}
				>
					{isDone ? <DoneSVG className={cn(s.icon, s.icon_done)} /> : <CloseSVG className={s.icon} />}
					<p>{text}</p>
				</div>
			))}
		</div>
	);
};
