import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { education_serviceAPI } from '@/app/redux/queries/education-service/education_serviceAPI';
import { RootState } from '@/app/redux/store';
import { useRights } from '@/shared/hooks/useRights';
import { ConditionalRendering } from '@/shared/providers';
import { Heading } from '@/shared/ui/Heading';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { getEducationInfo } from '../../lib';
import { EducationFields } from '../EducationFields/EducationFields';
import { EducationForm } from '../EducationForm/EducationForm';
import s from './Education.module.scss';

interface Props {
	userId: string;
}

export const Education: FC<Props> = props => {
	const { userId } = props;

	// * Rights
	const personalInfo = useSelector((state: RootState) => state.user_service.user.userInfo);
	const isPersonalUserCard = personalInfo?.user.id === userId;
	const hasEditUsersRights = useRights(1);

	// * API
	const { data: educationsData } = education_serviceAPI.useGetUserFindQuery({
		skipcount: 0,
		takecount: 100,
		userid: userId,
	});

	const { certificate, education } = getEducationInfo(educationsData?.body);

	// * Modals
	const [showForm, setShowForm] = useState(false);
	const toggleShowForm = () => setShowForm(prevState => !prevState);

	const [isCertificate, setIsCertificate] = useState(false);
	const toggleShowFormCertificate = () => {
		setIsCertificate(prevState => !prevState);
		toggleShowForm();
	};

	// * Render
	return (
		<>
			{showForm && (
				<ModalNewProto
					isOpen={showForm}
					onClose={toggleShowForm}
				>
					<EducationForm
						userId={userId}
						isCertificate={isCertificate}
						onCloseModal={isCertificate ? toggleShowFormCertificate : toggleShowForm}
					/>
				</ModalNewProto>
			)}

			<ConditionalRendering
				initialLoading={false}
				isSuccessful={certificate.length > 0 || education.length > 0 || isPersonalUserCard || hasEditUsersRights}
				LoadedResult={
					<div className={s.container}>
						<Heading
							level={2}
							size={3}
							marginBottom="m"
						>
							Профессиональная информация
						</Heading>

						<EducationFields
							title="Образование"
							educationInfo={education}
							hasRights={isPersonalUserCard || hasEditUsersRights}
							onClick={toggleShowForm}
						/>

						<EducationFields
							title="Сертификаты"
							isCertificate
							educationInfo={certificate}
							hasRights={isPersonalUserCard || hasEditUsersRights}
							onClick={toggleShowFormCertificate}
						/>
					</div>
				}
			/>
		</>
	);
};
