import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { actionsEventCalendar } from '@/app/redux/state/event-calendar/slice';
import { useAppDispatch } from '@/app/redux/utils';
import { routerPath } from '@/app/router/paths';
import { UserListItem } from '@/features/_card_lists/types';
import CloseSVG from '@/shared/assets/svg_icons/action/close.svg?react';
import { CardsTable } from '@/shared/ui/_tables/CardsTable';
import { Avatar } from '@/shared/ui/Avatar/Avatar';
import { COLUMNS } from './consts';
import s from './UsersListContainer.module.scss';

interface Props {
	users: UserListItem[]; // TODO: Тип берется аж из SingleOfficePage.
}

export const UsersListContainer: FC<Props> = props => {
	const { users } = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { setSelectedUsersInfo } = actionsEventCalendar;

	const onRemoveUser = (userId: string) => {
		dispatch(setSelectedUsersInfo(users.filter(user => user.id !== userId)));
	};

	// * Navigation
	const navigate = useNavigate();
	const navigateToUser = (id: number) => navigate(`${routerPath.users.page}/${tableData[id].id}`);

	const tableData = users.map(user => ({
		id: user.id,
		avatar: (
			<Avatar
				userAvatar={user.avatar}
				name={user.name}
			/>
		),
		user: {
			name: user.name,
			position: user.position ?? '',
		},
		department: user.department ?? '',
		extra: (
			<CloseSVG
				className={s.removeButton}
				onClick={event => {
					event.stopPropagation();
					onRemoveUser(user.id || '');
				}}
			/>
		),
	}));

	// * Render
	return (
		<div className={s.container}>
			{users.length > 0 && (
				<CardsTable
					data={tableData}
					columns={COLUMNS}
					onRowClick={navigateToUser}
					className={s.table}
				/>
			)}
		</div>
	);
};
