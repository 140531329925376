import { useMemo, useState } from 'react';
import { position_serviceAPI } from '@/app/redux/queries/position-service/position_serviceAPI';
import s from '@/pages/AdminPage/style/TabContent.module.scss';
import SearchSVG from '@/shared/assets/svg/action/search.svg?react';
import { ConditionalRendering } from '@/shared/providers';
import { EmptyData } from '@/shared/ui/EmptyData/EmptyData';
import { SelectSingle } from '@/shared/ui/_inputs/selects/SelectSingle';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { STATUS_OPTIONS } from '../../consts/consts';
import { CreateButton } from '../CreateButton/CreateButton';
import { PositionCard } from '../PositionCard/PositionCard';

export const PositionContent = () => {
	// * API
	const { data: positionData, isLoading } = position_serviceAPI.useFindPositionsQuery({
		skipcount: 0,
		takecount: 1000,
		includeDeactivated: true,
	});

	// * Filter
	// - Status
	const [status, setStatus] = useState(STATUS_OPTIONS[0]);

	// - Name
	const [name, setName] = useState('');

	// * Positions
	const positions = useMemo(() => {
		let preparedPositions = positionData?.body
			? positionData.body.map(position => {
					const { id, name, isActive } = position;

					return {
						id,
						name,
						isActive,
					};
				})
			: [];

		if (status.id === 'Active') preparedPositions = preparedPositions.filter(pos => pos.isActive);
		if (status.id === 'Archive') preparedPositions = preparedPositions.filter(pos => !pos.isActive);
		if (name) preparedPositions = preparedPositions?.filter(pos => pos.name.toLocaleLowerCase().includes(name.toLocaleLowerCase().trim()));

		return preparedPositions;
	}, [positionData, status.id, name]);

	// * Render
	return (
		<div className={s.container}>
			<div className={s.filters}>
				<SelectSingle
					keyNames={{
						name: 'name',
						value: 'id',
					}}
					selectedOption={status}
					setSelectedOption={setStatus}
					options={STATUS_OPTIONS}
				/>

				<TextInput
					placeholder="Поиск по названию"
					value={name}
					onChange={event => setName(event.currentTarget.value)}
					RightIcon={<SearchSVG />}
				/>
			</div>

			<CreateButton isRoleContent={false} />

			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={positions.length > 0}
				FailedResult={<EmptyData />}
				LoadedResult={
					<div className={s.list}>
						{positions.map(position => (
							<PositionCard
								key={position.id}
								id={position.id}
								name={position.name}
								isActive={position.isActive}
							/>
						))}
					</div>
				}
			/>
		</div>
	);
};
