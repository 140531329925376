import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '@/app/redux/utils';
import { FileInfo } from '../types';
import { FindFilesREQ, FindFilesRES, RemoveFilesREQ, RemoveFilesRES } from './types';

const NAME = `${ServiceName.WIKI_SERVICE}/file`;

const findFiles = createAppAsyncThunk(`${NAME}/findFiles`, async (arg: FindFilesREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<FindFilesRES>({
		url: `${NAME}/find`,
		params,
		thunkAPI,
		action: () => findFiles(arg),
	});
});

const removeFiles = createAppAsyncThunk(`${NAME}/removeFiles`, async (arg: RemoveFilesREQ, thunkAPI) => {
	const { payload } = arg;

	await apiRequest.delRequest<RemoveFilesRES>({
		url: `${NAME}/remove`,
		payload,
		thunkAPI,
		action: () => removeFiles(arg),
	});

	return payload.filesIds;
});

export interface State {
	activeArticleFiles: FileInfo[];
	status: RequestStatus;
}

export const initialState: State = {
	activeArticleFiles: [],
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(findFiles.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findFiles.fulfilled, (state, action) => {
			state.activeArticleFiles = action.payload.body;
			state.status = RequestStatus.still;
		});
		builder.addCase(findFiles.rejected, state => {
			state.activeArticleFiles = [];
			state.status = RequestStatus.failed;
		});

		builder.addCase(removeFiles.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(removeFiles.fulfilled, (state, action) => {
			state.activeArticleFiles = state.activeArticleFiles.filter(file => !action.payload.includes(file.id));
			state.status = RequestStatus.still;
		});
		builder.addCase(removeFiles.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	findFiles,
	removeFiles,
};
