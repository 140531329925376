import { createApi } from '@reduxjs/toolkit/query/react';
import { GetImageREQ, GetImageRES } from '../../state/image/image/types';
import { ServiceName } from '@/app/api/api_types';
import { baseQueryWithReAuth } from '@/app/api/apiSlice';

const reducerPath = `${ServiceName.IMAGE_SERVICE}` as const;

export const image_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Image'],
	endpoints: build => ({
		getImage: build.query<GetImageRES, GetImageREQ>({
			query: ({ params }) => ({
				url: `${reducerPath}/image/get`,
				params,
			}),
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),
	}),
});
