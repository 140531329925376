import cn from 'classnames';
import React, { ComponentPropsWithoutRef } from 'react';
import { useAppSelector } from '@/app/redux/utils';
import { LeaveEnter } from '@/pages/TimeTrackingPage/ui/LeaveEnter/LeaveEnter';
import { LineTabs } from '@/shared/ui/_tabs/lineTabs';
import { Heading } from '@/shared/ui/Heading';
import { TimeEnter } from '@/widgets/TimeEnter';
import { TABS } from './consts';
import s from './FormsCard.module.scss';
import { useTabs } from './hooks/useTabs';
import { SetURLSearchParams } from 'react-router-dom';

interface Props extends ComponentPropsWithoutRef<'div'> {
	selectedDate: Date;
	setSelectedDate: (date: Date) => void;
	searchParams: URLSearchParams;
	setSearchParams: SetURLSearchParams;
}

export const FormsCard: React.FC<Props> = props => {
	const {
		selectedDate, //
		setSelectedDate,
		searchParams,
		setSearchParams,
		className,
	} = props;

	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);

	const { activeTab, setActiveTab } = useTabs(searchParams, setSearchParams);

	// * Render
	return (
		<div className={cn(s.container, className)}>
			<div>
				<Heading
					level={3}
					marginBottom="m"
				>
					Внести часы
				</Heading>

				{activeTab && (
					<LineTabs
						className={s.lineTabs}
						activeTabId={activeTab}
						tabs={TABS}
						width="full"
						setActiveTabId={(id: string) => setActiveTab(id)}
					/>
				)}

				{userId && activeTab === TABS[0].id && (
					<TimeEnter
						userId={userId}
						initialSelectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
					/>
				)}

				{userId && activeTab === TABS[1].id && <LeaveEnter userId={userId} />}
			</div>
		</div>
	);
};
