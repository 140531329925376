import { ContractTerm } from '@/app/redux/queries/user-service/types/types';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';
import { dateToBackendISO } from '@/shared/lib/date';
import { DefaultValues } from '../types';
import { useAppDispatch } from '@/app/redux/utils';
import { actionsNotifications } from '@/features/notifications/_BLL/slice';
import { position_serviceAPI } from '@/app/redux/queries/position-service/position_serviceAPI';
import { getCompanyId } from '@/shared/lib/app';
import { company_serviceAPI } from '@/app/redux/queries/company-service/company_serviceAPI';

export const useCreateUser = (onCloseModal: () => void) => {
	// * API
	const [createUser, { isLoading: isUserLoading }] = user_serviceAPI.usePostUserCreateMutation();
	const [createPosition, { isLoading: isPositionLoading }] = position_serviceAPI.useCreatePositionMutation();
	const { data: companyData } = company_serviceAPI.useGetCompanyQuery({
		includeoffices: false,
	});

	const isLoading = isUserLoading || isPositionLoading;

	// * Company ID
	const companyId = companyData?.body ? companyData.body.id : getCompanyId();

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	const createNewUser = (data: DefaultValues) => {
		const {
			firstName,
			lastName,
			email,
			isAdmin,
			birthday,
			contract,
			contractByTerm,
			department,
			middleName,
			position,
			dateEndContract,
			dateEndProbation,
			rate,
			role,
			startWorkingDate,
			entitledVacationDays,
		} = data;

		if (!email) return;

		const dateOfBirth = birthday ? dateToBackendISO(birthday) : null;
		const endWorkingAt = dateEndContract ? dateToBackendISO(dateEndContract) : null;
		const probationDate = dateEndProbation ? dateToBackendISO(dateEndProbation) : undefined;
		const startWorkingAt = startWorkingDate ? dateToBackendISO(startWorkingDate) : undefined;

		createUser({
			createUserRequest: {
				firstName,
				lastName,
				middleName,
				communication: {
					type: 'Email',
					value: email,
				},
				dateOfBirth,
				departmentId: department.id || undefined,
				isAdmin,
				positionId: position.id || undefined,
				roleId: role.id || undefined,
				userCompany: {
					companyId: companyId,
					contractSubjectId: contract.id,
					contractTermType: contractByTerm.id as ContractTerm,
					rate: Number(rate.id),
					probation: probationDate,
					endWorkingAt,
					startWorkingAt,
				},
				entitledVacationDays: entitledVacationDays ?? undefined,
			},
		})
			.unwrap()
			.then(() => {
				dispatch(
					addNotification({
						type: 'success',
						message: 'Пользователь успешно добавлен',
					}),
				);
				onCloseModal();
			})
			.catch(err => console.log(err));
	};

	// * Submit
	const onCreateSubmit = (data: DefaultValues) => {
		const { position } = data;

		if (!position.id && !!position.name) {
			createPosition({
				createPositionRequest: {
					name: position.name,
				},
			})
				.unwrap()
				.then(res => {
					const posId = res.body;

					posId &&
						createNewUser({
							...data,
							position: {
								id: posId,
								name: position.name,
							},
						});
				})
				.catch(err => console.log(err));
		} else {
			createNewUser(data);
		}
	};

	return { onCreateSubmit, isCreateLoading: isLoading };
};
