import { createColumnHelper } from '@tanstack/react-table';
import { FeedbackStatusType } from '@/app/redux/queries/gateway-service/types/types';

export const TABS: Array<{ id: Exclude<FeedbackStatusType, 'Archived'>; name: string }> = [
	{
		id: 'New',
		name: 'Новые',
	},
	{
		id: 'InProgress',
		name: 'В работе',
	},
	{
		id: 'Done',
		name: 'Выполненные',
	},
	{
		id: 'Declined',
		name: 'Отклонённые',
	},
];

const columnHelper = createColumnHelper<any>();

export const COLUMNS = [
	columnHelper.accessor('Checkbox', {
		header: '',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '1%',
			},
		},
	}),

	columnHelper.accessor('number', {
		header: '№',
		cell: info => info.getValue(),
	}),

	columnHelper.accessor('senderFullName', {
		header: 'ФИО',
		cell: info => info.getValue(),
	}),

	columnHelper.accessor('type', {
		header: 'КАТЕГОРИЯ',
		cell: info => info.getValue(),
	}),

	columnHelper.accessor('createdAtUtc', {
		header: 'ДАТА И ВРЕМЯ',
		cell: info => info.getValue(),
	}),

	columnHelper.accessor('content', {
		header: 'КОММЕНТАРИЙ',
		cell: info => info.getValue(),
		meta: {
			style: {
				maxWidth: '40rem',
			},
		},
	}),

	columnHelper.accessor('Files', {
		header: '',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '1%',
			},
		},
	}),
];

export const CATEGORIES_RUS = {
	Bug: 'Сломалось',
	Wishes: 'Пожелание',
	Other: 'Другое',
};
