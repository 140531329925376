import React from 'react';
import s from './Category.module.scss';
import { ColorCircle } from '@/entities/ColorCircle/ui/ColorCircle';

export const NoCategory: React.FC = () => {
	return (
		<div className={s.container}>
			<div className={s.name}>
				<ColorCircle />
				<span>Без категории</span>
			</div>
		</div>
	);
};
