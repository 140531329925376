import { education_serviceAPI } from '@/app/redux/queries/education-service/education_serviceAPI';
import { DefaultValues } from '../types';
import { dateToBackendISO, injectYearToDate } from '@/shared/lib/date';
import { CreateEducationRequest } from '@/app/redux/queries/education-service/types/types';

export const useCreateEducation = (
	userId: string, //
	onCloseModal: () => void,
) => {
	// * API
	const [createEducation, { isLoading: isCreateEducationLoading }] = education_serviceAPI.usePostEducationCreateMutation();
	const [createUniversity, { isLoading: isCreateUniversityLoading }] = education_serviceAPI.usePostUniversityCreateMutation();

	const isCreateLoading = isCreateEducationLoading || isCreateUniversityLoading;

	// * Main request
	const onCreateEducationRequest = (args: CreateEducationRequest): void => {
		createEducation({
			createEducationRequest: args,
		})
			.unwrap()
			.then(() => onCloseModal())
			.catch(err => console.log(err));
	};

	// * Submit
	const onCreateSubmit = (data: DefaultValues) => {
		const {
			institution, //
			specialization,
			form,
			startDate,
			endDate,
			images,
			completeness,
		} = data;

		const admissionAt = startDate ? dateToBackendISO(injectYearToDate(new Date(), startDate)) : null;
		const issueAt = endDate ? dateToBackendISO(injectYearToDate(new Date(), endDate)) : undefined;

		const preparedArgs: Omit<CreateEducationRequest, 'universityId'> = {
			userId,
			qualificationName: specialization,
			educationFormId: form.id,
			admissionAt: admissionAt || '',
			issueAt,
			completeness: completeness ? 'Completed' : 'Uncompleted',
			images,
		};

		// - new university
		if (!institution.id) {
			createUniversity({
				createUniversityRequest: {
					name: institution.name,
				},
			})
				.unwrap()
				.then(res => {
					onCreateEducationRequest({
						...preparedArgs,
						universityId: res.body || '',
					});
				})
				.catch(err => console.log(err));

			return;
		}

		// - exist university
		onCreateEducationRequest({
			...preparedArgs,
			universityId: institution.id,
		});
	};

	return { onCreateSubmit, isCreateLoading };
};
