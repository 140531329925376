import { COLORS } from '@/shared/consts/consts';

export const getColors = (count: number, customColors?: string[]): string[] => {
	const colors: string[] = [];
	const palette = customColors || COLORS;

	for (let i = 0; i < Math.ceil(count / 12); i++) {
		colors.push(...palette);
	}

	return colors;
};
