import { createColumnHelper } from '@tanstack/react-table';
import { FileInfo } from '@/app/redux/queries/event-service/types/types';
import DocSVG from '@/shared/assets/svg_icons/file/doc.svg?react';
import FolderSVG from '@/shared/assets/svg_icons/file/folder.svg?react';
import { formatBytes } from '@/shared/lib/string';
import { FileDownload } from '../FileDownload/FileDownload';

export type TableDocItem = Pick<FileInfo, 'id' | 'name' | 'size' | 'extension'>;
const columnHelper = createColumnHelper<FileInfo>();

export const COLUMNS_EVENT_DOCS = [
	columnHelper.accessor('extension', {
		header: '',
		cell: info => (info.getValue() === '.zip' ? <FolderSVG /> : <DocSVG />),
		meta: {
			style: {
				width: '1%',
				fill: 'var(--color-secondary-2)',
			},
		},
	}),

	columnHelper.accessor('name', {
		header: 'НАЗВАНИЕ',
		cell: info => info.getValue(),
	}),

	columnHelper.accessor('size', {
		header: 'РАЗМЕР',
		cell: info => formatBytes(info.getValue()),
		meta: {
			style: {
				color: 'var(--color-secondary-3)',
			},
		},
	}),

	columnHelper.accessor('id', {
		header: '',
		cell: info => (
			<FileDownload
				file={info.row.original}
				fileSourse="Event"
			/>
		),
		meta: {
			style: {
				width: '1%',
			},
		},
	}),
];
