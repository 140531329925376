import { yupResolver } from '@hookform/resolvers/yup';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePatchDepartmentEditMutation, usePostDepartmentCreateMutation } from '@/app/redux/queries/department-service/department_serviceAPI';
import { Button } from '@/shared/ui/_buttons/Button';
import { SelectSingleField } from '@/shared/ui/_fields/SelectSingleField';
import { StringField } from '@/shared/ui/_fields/StringField';
import { TextAreaField } from '@/shared/ui/_fields/TextAreaField';
import { Heading } from '@/shared/ui/Heading';
import * as yup from 'yup';
import { FormValues } from '../../types';
import { NAME_LIMIT, SHORT_NAME_LIMIT, STATUS_OPTIONS } from './consts';
import s from './Form.module.scss';
import { PatchDepartmentEditApiArg } from '@/app/redux/queries/department-service/types/regTypes';

interface Props {
	initialValues: FormValues;
	closeModal: () => void;
}

export const Form: FC<Props> = props => {
	const { initialValues, closeModal } = props;

	const [createDepartment, { isLoading: creatingLoading }] = usePostDepartmentCreateMutation();
	const [editDepartment, { isLoading: editLoading }] = usePatchDepartmentEditMutation();

	const isLoading = creatingLoading || editLoading;

	const editMode = !!initialValues.id;
	const isActiveDepartment = initialValues.isActive.id === 'true';

	const heading = editMode ? 'Редактирование департамента' : 'Создание департамента';

	const schema = yup.object().shape({
		name: yup.string().min(1, 'Название должно быть больше одного символа').max(NAME_LIMIT, `Название должно быть максимум ${NAME_LIMIT} символов`).required('Введите название'),
		shortName: yup
			.string()
			.min(1, 'Название должно быть больше одного символа')
			.max(SHORT_NAME_LIMIT, `Название должно быть максимум ${SHORT_NAME_LIMIT} символов`)
			.required('Введите название'),
	});

	const formMethods = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit } = formMethods;

	const onCreateSubmit = (data: FormValues) => {
		const { name, shortName } = data;

		createDepartment({
			createDepartmentRequest: {
				name,
				shortName,
				users: [],
			},
		})
			.unwrap()
			.then(() => closeModal())
			.catch(e => console.log(e));
	};

	const onEditSubmit = (data: FormValues) => {
		const { id, name, shortName, isActive } = data;

		if (!id) return;

		const args: PatchDepartmentEditApiArg['body'] = [];

		if (initialValues.name !== name) {
			args.push({
				op: 'replace',
				path: '/Name',
				value: name,
			});
		}

		if (initialValues.shortName !== shortName) {
			args.push({
				op: 'replace',
				path: '/ShortName',
				value: shortName,
			});
		}

		if (initialValues.isActive.id !== isActive.id) {
			args.push({
				op: 'replace',
				path: '/IsActive',
				value: isActive.id,
			});
		}

		editDepartment({
			departmentId: id,
			body: args,
		})
			.unwrap()
			.then(() => closeModal())
			.catch(e => console.log(e));
	};

	return (
		<FormProvider {...formMethods}>
			<form
				className={s.container}
				onSubmit={handleSubmit(editMode ? onEditSubmit : onCreateSubmit)}
			>
				<Heading level={2}>{heading}</Heading>

				<TextAreaField
					name="name"
					label="Название"
					placeholder="Введите название департамента"
					characterLimit={NAME_LIMIT}
					disabled={!isActiveDepartment}
				/>

				<div className={s.row}>
					<StringField
						name="shortName"
						label="Сокращенное название"
						placeholder="Введите сокращенное название департамента"
						characterLimit={SHORT_NAME_LIMIT}
						disabled={!isActiveDepartment}
					/>

					{editMode && (
						<SelectSingleField
							name="isActive"
							label="Статус"
							options={STATUS_OPTIONS}
						/>
					)}
				</div>

				<div className={s.buttons}>
					<Button
						type="button"
						variant="tertiary"
						fixedWidth
						isLoading={isLoading}
						onClick={closeModal}
					>
						Отмена
					</Button>

					<Button
						type="submit"
						fixedWidth
						isLoading={isLoading}
					>
						{editMode ? 'Сохранить' : 'Создать'}
					</Button>
				</div>
			</form>
		</FormProvider>
	);
};
