import cn from 'classnames';
import React, { ComponentPropsWithoutRef, useState } from 'react';
import { usePutFeedbackEditstatusMutation } from '@/app/redux/queries/feedback-service/feedback_serviceAPI';
import { FeedbackStatusType } from '@/app/redux/queries/feedback-service/types/types';
import { gateway_serviceAPI } from '@/app/redux/queries/gateway-service/gateway_serviceAPI';
import { useAppDispatch } from '@/app/redux/utils';
import CloseSVG from '@/shared/assets/svg/action/close.svg?react';
import DeleteSVG from '@/shared/assets/svg/action/delete.svg?react';
import { Button } from '@/shared/ui/_buttons/Button';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import { DeleteModal } from '../DeleteModal';
import s from './MultiAssign.module.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {
	selected: string[];
	setSelected: React.Dispatch<React.SetStateAction<string[]>>;
}

export const MultiAssign: React.FC<Props> = props => {
	const {
		selected, //
		setSelected,
		className,
		...restProps
	} = props;

	// * Api
	const dispatch = useAppDispatch();
	const [editFeedback, { isLoading }] = usePutFeedbackEditstatusMutation();

	// * Actions
	const onEditClick = (status: FeedbackStatusType) => {
		editFeedback({
			editFeedbackStatusesRequest: {
				feedbackIds: selected,
				status,
			},
		})
			.unwrap()
			.then(() => {
				setSelected([]);
			})
			.catch(error => console.log('error :>> ', error))
			.finally(() => {
				dispatch(gateway_serviceAPI.util.resetApiState());
			});
	};

	// * Modal
	const [modal, setModal] = useState<boolean>(false);

	const toggleModal = () => setModal(prevState => !prevState);

	// * Render
	return (
		<>
			<DeleteModal
				selectedIds={selected}
				setSelected={setSelected}
				isActive={modal}
				toggleModal={toggleModal}
			/>

			<div
				{...restProps}
				className={cn(s.container, className)}
			>
				<b className={s.count}>Выбрано: {selected.length}</b>

				<div className={s.types}>
					<Button
						variant="secondary"
						onClick={() => onEditClick('InProgress')}
						isLoading={isLoading}
					>
						В работе
					</Button>

					<Button
						variant="secondary"
						onClick={() => onEditClick('Done')}
						isLoading={isLoading}
					>
						Выполнено
					</Button>

					<Button
						variant="secondary"
						onClick={() => onEditClick('Declined')}
						isLoading={isLoading}
					>
						Отклонено
					</Button>
				</div>

				<div className={s.remove}>
					<Button
						LeftIcon={<DeleteSVG />}
						variant="secondary"
						onClick={toggleModal}
						isLoading={isLoading}
					>
						Удалить
					</Button>

					<IconButton
						Icon={<CloseSVG />}
						onClick={() => setSelected([])}
					/>
				</div>
			</div>
		</>
	);
};
