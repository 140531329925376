import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';
import { LoginRequestBody } from '@/app/redux/state/auth/types';
import { Button } from '@/shared/ui/_buttons/Button';
import { StringField } from '@/shared/ui/_fields/StringField';
import * as Yup from 'yup';
import s from './Forgot.module.scss';

export const Forgot = () => {
	// * API
	const [sendEmail, { isLoading }] = user_serviceAPI.useLazyGetPasswordForgotQuery();

	// * User email
	const [email, setEmail] = useState('');

	// * Form
	const schema = Yup.object().shape({
		loginData: Yup.string().required(''),
	});

	const formMethods = useForm({
		defaultValues: {
			loginData: '',
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch, setError, clearErrors, formState } = formMethods;

	const login = watch('loginData');
	const errorMessage = formState.errors?.loginData;

	const onSubmit = (values: Pick<LoginRequestBody, 'loginData'>) => {
		sendEmail({
			userEmail: values.loginData,
		})
			.unwrap()
			.then(res => {
				clearErrors();
				res.body && setEmail(res.body);
			})
			.catch(err => {
				const message = err.data.errors[0];
				message && setError('loginData', { message });
			});
	};

	return email ? (
		<div className={s.email_info}>Ссылка для сброса пароля была отправлена на почту {email}</div>
	) : (
		<form
			className={s.form}
			onSubmit={handleSubmit(onSubmit)}
		>
			<FormProvider {...formMethods}>
				<div className={s.recovery_info}>
					{/* */}
					Введите свой логин или e-mail, и в течение 10 минут мы вышлем вам на почту ссылку для смены пароля.
				</div>

				<StringField
					name="loginData"
					placeholder="Логин или e-mail"
					errorBorder={!!errorMessage}
				/>

				<Button
					type="submit"
					isLoading={isLoading}
					wide
					disabled={!login}
				>
					Сменить пароль
				</Button>
			</FormProvider>
		</form>
	);
};
