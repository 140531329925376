import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { OfficeUsersREQ_BODY } from './types';

const NAME = `${ServiceName.OFFICE_SERVICE}/user`;

const createOfficeUsers = createAppAsyncThunk(`${NAME}/createOfficeUsers`, async (arg: { payload: OfficeUsersREQ_BODY }, thunkAPI) => {
	const { payload } = arg;

	return apiRequest.postRequest({
		url: `${NAME}/create`,
		payload,
		thunkAPI,
		action: () => createOfficeUsers(arg),
	});
});

const removeOfficeUsers = createAppAsyncThunk(`${NAME}/removeOfficeUsers`, async (arg: { payload: OfficeUsersREQ_BODY }, thunkAPI) => {
	const { payload } = arg;

	return apiRequest.delRequest({
		url: `${NAME}/remove`,
		payload,
		thunkAPI,
		action: () => removeOfficeUsers(arg),
	});
});

interface State {
	status: RequestStatus;
}

const initialState: State = {
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(createOfficeUsers.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createOfficeUsers.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(createOfficeUsers.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(removeOfficeUsers.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(removeOfficeUsers.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(removeOfficeUsers.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	createOfficeUsers,
	removeOfficeUsers,
};
