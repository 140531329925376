import React, { InputHTMLAttributes } from 'react';
import s from './Checkbox.module.scss';
import cn from 'classnames';

type DefaultInputPropsType = InputHTMLAttributes<HTMLInputElement>;

type Props = Omit<DefaultInputPropsType, 'type' | 'children'> & {
	label?: string;
	noDisabledStyles?: boolean;
};

export const Checkbox = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
	const {
		label, //
		checked,
		noDisabledStyles,
		className,
		style,
		...restProps
	} = props;

	const disabledStyle = noDisabledStyles ? s.label_another_disabled : s.label_disabled;

	return (
		<label
			className={cn(s.label, restProps.disabled && disabledStyle, className)}
			style={style}
			onClick={event => event.stopPropagation()}
		>
			<input
				ref={ref}
				readOnly={true}
				checked={checked}
				type="checkbox"
				{...restProps}
			/>

			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
			>
				{checked ? (
					<>
						<rect
							className={s.rect_fill}
							width="24"
							height="24"
							rx="4"
						/>
						<path
							className={s.check_mark}
							d="M20.7071 5.78385C21.0976 6.16231 21.0976 6.77593 20.7071 7.15439L9 18.5L3.29289 12.9691C2.90237 12.5907 2.90237 11.977 3.29289 11.5986C3.68342 11.2201 4.31658 11.2201 4.70711 11.5986L9 15.7589L19.2929 5.78385C19.6834 5.40538 20.3166 5.40538 20.7071 5.78385Z"
						/>
					</>
				) : (
					<rect
						className={s.rect_stroke}
						x="0.5"
						y="0.5"
						width="23"
						height="23"
						rx="3.5"
						stroke="#3F98F5"
					/>
				)}
			</svg>

			{label && <div>{label}</div>}
		</label>
	);
});
