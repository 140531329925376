import { FloatingFocusManager } from '@floating-ui/react';
import cn from 'classnames';
import { HTMLAttributes, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useFloatingWrapper } from '@/shared/hooks/useFloatingWrapper';
import _input from '@/shared/ui/_inputs/_styles/_input.module.scss';
import { CollapseArrows } from '../../../CollapseArrows/CollapseArrows';
import { OptionsWindow } from '../../../_option_lists/OptionsWindow/OptionsWindow';
import { ErrorWithLimit } from '../../_shared/ErrorWithLimit/ErrorWithLimit';
import { InputLabel } from '../../_shared/InputLabel/InputLabel';
import _input_with_options from '../../_styles/_input_with_options.module.scss';

interface Props<T, Key> extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
	label?: string;
	placeholder?: string;
	keyNames: {
		name: Key;
		value: Key;
		tooltipText?: string;
	};
	selectedOption: T;
	setSelectedOption: (value: T) => void;
	options: T[];
	sortOptions?: boolean;
	errorMessage?: string;
	isNullable?: boolean;
	disabled?: boolean;
	required?: boolean;
}

export const SelectSingle = <T extends Record<string, any> & { Icon?: ReactNode }, Key extends keyof T>(props: PropsWithChildren<Props<T, Key>>) => {
	const {
		className,
		label,
		keyNames,
		placeholder = 'Выберите из списка',
		selectedOption,
		setSelectedOption,
		options,
		sortOptions,
		errorMessage,
		isNullable = true,
		disabled,
		required,
	} = props;

	const sortedOptions = sortOptions ? [...options].sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())) : options;

	// * User actions
	const [collapsed, setCollapsed] = useState(true);

	const toggleDropDown = () => setCollapsed(!collapsed);

	useEffect(() => {
		setCollapsed(true);
	}, [selectedOption]);

	const emptyValue = {
		[keyNames.name]: null,
		[keyNames.value]: null,
	};

	const onOptionClick = (value: T) => {
		if (value[keyNames.value] === selectedOption[keyNames.value]) {
			isNullable && setSelectedOption(emptyValue as T);
		} else {
			setSelectedOption(value);
		}
	};

	// * Scroll to selected
	const foundOption = !!selectedOption[keyNames.value] && sortedOptions.find(option => option.name.toLowerCase().includes(selectedOption.name.toLowerCase().trim()));
	const optionIndex = foundOption ? sortedOptions.findIndex(option => option[keyNames.value] === foundOption[keyNames.value]) : 0;

	// * Floating
	const { floatingStyles, refs, context, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(!collapsed, (value: any) => !disabled && setCollapsed(!value));

	// * Render
	return (
		<div className={cn(_input.container, className)}>
			<InputLabel
				label={label}
				required={required}
			/>

			<div
				className={`${_input_with_options.container} ${_input.drop_down_container}`}
				onClick={disabled ? () => null : toggleDropDown}
			>
				<div
					className={cn(_input.input_wrapper, disabled && _input.disabled)}
					ref={refs.setReference}
					{...getReferenceProps()}
				>
					<div className={_input.input} />

					<div className={_input.icon_right}>
						<CollapseArrows
							isOpen={!collapsed}
							arrowType="empty"
							style={{ zIndex: 2 }}
						/>
					</div>

					<div className={_input.border} />

					{selectedOption[keyNames.value] ? (
						<span className={cn(_input.value, disabled && _input.placeholder)}>
							{selectedOption.Icon && selectedOption.Icon}
							<span className={_input.text}>{selectedOption[keyNames.name]}</span>
						</span>
					) : (
						<span className={_input.placeholder}>{placeholder}</span>
					)}
				</div>

				{!collapsed && (
					<FloatingFocusManager
						context={context}
						modal={false}
					>
						<div
							className={_input_with_options.options}
							ref={refs.setFloating}
							style={{
								...floatingStyles,
								zIndex: 'var(--z-index-floating)',
							}}
							aria-labelledby={headingId}
							{...getFloatingProps()}
						>
							<OptionsWindow
								keyNames={keyNames}
								options={sortedOptions}
								selectedOptions={[selectedOption]}
								onOptionClick={onOptionClick}
								scrollToItem={optionIndex}
							/>
						</div>
					</FloatingFocusManager>
				)}
			</div>

			{errorMessage && <ErrorWithLimit errorMessage={errorMessage} />}
		</div>
	);
};
