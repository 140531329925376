import { useState } from 'react';
import { LineTabs } from '@/shared/ui/_tabs/lineTabs';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { PageTitle } from '@/entities/PageTitle/PageTitle';
import s from '../../style/TabContent.module.scss';
import { PositionContent } from '../PositionContent/PositionContent';
import { RoleContent } from '../RoleContent/RoleContent';

export const AdminPage = () => {
	const TABS = [
		{
			id: 'role',
			name: 'Роли',
		},
		{
			id: 'position',
			name: 'Должности',
		},
	];

	const [activeTabId, setActiveTabId] = useState<string>(TABS[0].id);

	// * Render
	return (
		<PageDecorator>
			<PageTitle title="Администрирование" />

			<LineTabs
				className={s.lineTabs}
				tabs={TABS}
				activeTabId={activeTabId}
				setActiveTabId={setActiveTabId}
			/>

			{activeTabId === 'role' && <RoleContent />}
			{activeTabId === 'position' && <PositionContent />}
		</PageDecorator>
	);
};
