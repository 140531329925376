import React, { ReactNode, useEffect, useState } from 'react';
import { userServiceActions } from '@/app/redux/state/user/actions';
import { useAppDispatch, useAppSelector } from '@/app/redux/utils';
import { HeaderWithHamburger } from '@/features/_layout/Header/HeaderWithHamburger/HeaderWithHamburger';
import { Nav } from '@/features/_layout/Nav/ui/Nav/Nav';
import { parseJwt } from '@/shared/lib/app/parseJwt/parseJwt';
import s from './PageDecoratorHamburger.module.scss';
import cn from 'classnames';

interface Props {
	children: ReactNode;
}

export const PageDecoratorHamburger: React.FC<Props> = props => {
	const { children } = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { getAppUser } = userServiceActions.user.async;

	// * Selectors
	const userInfo = useAppSelector(state => state.user_service.user.userInfo);

	useEffect(() => {
		const accessToken = localStorage.getItem('access_token');
		const refreshToken = localStorage.getItem('refresh_token');

		if (accessToken && refreshToken && userInfo === null) {
			const parsedToken = parseJwt(accessToken);

			dispatch(
				getAppUser({
					params: {
						userId: parsedToken.UserId,
						includeCurrentAvatar: true,
						includeCommunications: true,
						includeCompany: true,
						includeDepartment: true,
						includeRole: true,
						includeOffice: true,
						includePosition: true,
						locale: 'ru',
					},
				}),
			);
		}
	});

	// * Menu
	const [isOpenMenu, setIsOpenMenu] = useState(false);
	const toggleMenu = () => setIsOpenMenu(prevState => !prevState);

	// * Render
	return (
		<div className={cn(s.container, isOpenMenu && s.container__visible_menu)}>
			{isOpenMenu && (
				<div
					className={s.overlay}
					onClick={toggleMenu}
				/>
			)}
			<Nav
				className={cn(s.menu, isOpenMenu && s.menu_open)}
				isPrivate={true}
				hasAdaptive={false}
				onClose={toggleMenu}
			/>

			<div>
				<HeaderWithHamburger toggleMenu={toggleMenu} />
				<div className={s.content}>{children}</div>
			</div>
		</div>
	);
};
