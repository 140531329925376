import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { DepartmentsTreeInfo, GetDepartmentTreeRES } from './types';

const NAME = `${ServiceName.DEPARTMENT_SERVICE}/departmentsTree`;

const getDepartmentsTree = createAppAsyncThunk(`${NAME}/getDepartmentsTree`, async (arg: void, thunkAPI) => {
	return await apiRequest.getRequest<GetDepartmentTreeRES>({
		url: `${NAME}/get`,
		thunkAPI,
		action: () => getDepartmentsTree(),
	});
});

interface State {
	departments: DepartmentsTreeInfo[];
	status: RequestStatus;
}

export const initialState: State = {
	departments: [],
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getDepartmentsTree.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getDepartmentsTree.fulfilled, (state, action) => {
			state.departments = action.payload.body;
			state.status = RequestStatus.still;
		});
		builder.addCase(getDepartmentsTree.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	getDepartmentsTree,
};
