import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PageDecorator } from '@/app/decorators/page_decorators';
import { useGetFeedbackGetQuery, useLazyGetFilesByFileIdQuery, usePutFeedbackEditstatusMutation } from '@/app/redux/queries/feedback-service/feedback_serviceAPI';
import { FeedbackStatusType, ImageContent } from '@/app/redux/queries/gateway-service/types/types';
import { routerPath } from '@/app/router/paths';
import DeleteSVG from '@/shared/assets/svg/action/delete.svg?react';
import CircleSVG from '@/shared/assets/svg_icons/social/circle.svg?react';
import { backendISOUTCToLocalLuxon, dateToFullRuDateString, dateToTimeString } from '@/shared/lib/date';
import { Heading } from '@/shared/ui/Heading';
import { NavRoute } from '@/shared/ui/NavRoute';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import { SimpleGallery } from '@/shared/ui/_galleries/SimpleGallery';
import { SelectSingle } from '@/shared/ui/_inputs/selects/SelectSingle';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { CATEGORIES_RUS } from '../FeedbackPage/consts';
import { DeleteModal } from '../FeedbackPage/ui/DeleteModal';
import s from './SingleFeedbackPage.module.scss';
import { STATUSES_RUS } from './consts';
import { InfoItem } from './ui/InfoItem';
import { contentTypeToExtension, fileToBase64, getBase64Info } from '@/shared/lib/file';

export const SingleFeedbackPage = () => {
	// * Router
	const { feedbackId } = useParams() as { feedbackId: string };

	// * API
	const {
		data,
		refetch,
		isLoading: InitialLoading,
	} = useGetFeedbackGetQuery({
		feedbackId,
	});

	const feedbackInfo = data?.body;

	const [editFeedback, { isLoading: editIL }] = usePutFeedbackEditstatusMutation();

	const [getFile, { isLoading: fileIL }] = useLazyGetFilesByFileIdQuery();

	// * Files
	const [files, setFiles] = useState<ImageContent[]>([]);

	useEffect(() => {
		if (feedbackInfo && feedbackInfo.feedbackFiles.length > 0) {
			const promises = feedbackInfo.feedbackFiles.map(fileId => {
				return getFile({ fileId });
			});

			Promise.allSettled(promises)
				.then(async res => {
					const videoContents: ImageContent[] = [];
					for (const resItem of res) {
						if ('value' in resItem) {
							const data = resItem.value.data;
							if (data && data.blob) {
								const blob = data.blob;
								const name = data.name;
								const base64 = await fileToBase64(blob);
								if (typeof base64 === 'string') {
									const info = getBase64Info(base64);
									const extension = contentTypeToExtension(info.contentType);

									videoContents.push({
										name: `${name}${extension}`, // TODO: Взять имя файла
										content: info.content,
										extension: contentTypeToExtension(info.contentType),
									});
								}
							}
						}
					}

					if (videoContents.length > 0) {
						setFiles(videoContents);
					}
				})
				.catch(error => console.log(error));
		}
	}, [feedbackInfo?.feedbackFiles?.length]);

	// * User actions
	const onEditClick = (status: Exclude<FeedbackStatusType, 'Archived'>) => {
		editFeedback({
			editFeedbackStatusesRequest: {
				feedbackIds: [feedbackId],
				status,
			},
		}).finally(() => refetch());
	};

	// * Status select
	const selectOptions = Object.entries(STATUSES_RUS).map(([key, value]) => ({
		id: key as Exclude<FeedbackStatusType, 'Archived'>,
		name: value.name,
		Icon: <CircleSVG style={{ fill: value.color }} />,
	}));

	const selectedOption = (() => {
		const feedbackStatus = Object.keys(STATUSES_RUS).find(status => status === feedbackInfo?.feedback?.status);
		if (feedbackStatus) {
			return selectOptions.find(option => option.id === feedbackStatus);
		}
	})();

	// * Modal
	const [modal, setModal] = useState<boolean>(false);

	const toggleModal = () => setModal(prevState => !prevState);

	// * Gallery
	let galleryItems: ImageContent[] = [];
	if (feedbackInfo?.feedbackImages && feedbackInfo?.feedbackImages.length > 0) {
		galleryItems = [...feedbackInfo.feedbackImages];
	}

	if (files.length > 0) {
		galleryItems = [...galleryItems, ...files];
	}

	// * Conditions
	const galleryExists = galleryItems.length > 0;
	const isLoading = editIL || fileIL;

	// * Render
	return (
		<PageDecorator>
			<NavRoute
				className={s.route}
				route={routerPath.feedback.page}
				text="Обратная связь"
			/>

			<DeleteModal
				selectedIds={[feedbackId]}
				isActive={modal}
				toggleModal={toggleModal}
			/>

			{InitialLoading ? (
				<LoaderCircle />
			) : (
				<div className={s.container}>
					<div className={s.header}>
						<div className={s.header__left}>
							<Heading
								className={s.header__heading}
								level={1}
								size={2}
							>
								№ {feedbackInfo?.feedback?.number}
							</Heading>

							{selectedOption && (
								<SelectSingle
									className={s.header__select}
									selectedOption={selectedOption}
									setSelectedOption={option => onEditClick(option.id)}
									keyNames={{
										value: 'id',
										name: 'name',
									}}
									options={selectOptions}
									disabled={isLoading}
								/>
							)}
						</div>

						<IconButton
							Icon={<DeleteSVG />}
							onClick={toggleModal}
							disabled={isLoading}
						/>
					</div>

					{feedbackInfo &&
						feedbackInfo.feedback &&
						(() => {
							const { senderFullName, createdAtUtc, type, content } = feedbackInfo.feedback;
							const createDate = backendISOUTCToLocalLuxon(createdAtUtc).toJSDate();

							return (
								<div className={cn(s.content, galleryExists && s.content_withImages)}>
									<Heading
										level={2}
										size={3}
									>
										{senderFullName}
									</Heading>

									<div className={s.content__row}>
										<InfoItem
											title="Дата и время"
											content={`${dateToFullRuDateString(createDate)} ${dateToTimeString(createDate, true)}`}
										/>

										<InfoItem
											title="Категория запроса"
											content={CATEGORIES_RUS[type]}
										/>
									</div>

									<InfoItem
										title="Комментарий"
										content={content ?? ''}
									/>
								</div>
							);
						})()}

					{galleryExists && <SimpleGallery images={galleryItems} />}
				</div>
			)}
		</PageDecorator>
	);
};
