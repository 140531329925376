import * as yup from 'yup';

export const getSchema = (isEdit: boolean) =>
	yup.object().shape({
		...(isEdit
			? {}
			: {
					email: yup
						.string()
						.nullable()
						.required('Обязательное поле')
						.matches(/^$|^[^@\s]+@[^@\s,]*/, 'Некорректная почта'),
				}),
		firstName: yup.string().nullable().max(45, 'Максимальная длина - 45 символов').required('Обязательное поле'),
		lastName: yup.string().nullable().max(45, 'Максимальная длина - 45 символов').required('Обязательное поле'),
		middleName: yup.string().nullable().max(45, 'Максимальная длина - 45 символов'),
		birthday: yup.date().nullable().notRequired(),
		probation: yup.bool(),
		dateEndProbation: yup
			.date()
			.nullable()
			.when('probation', {
				is: true,
				then: yup.date().nullable().required('Обязательное поле'),
			}),
		isAdmin: yup.bool(),
		contract: yup
			.object()
			.shape({
				id: yup.string().nullable().required('Обязательное поле'),
				name: yup.string().nullable(),
			})
			.nullable(),
		contractByTerm: yup
			.object()
			.shape({
				id: yup.string().nullable().required('Обязательное поле'),
				name: yup.string().nullable(),
			})
			.nullable(),
		dateEndContract: yup.date().nullable(),
		rate: yup
			.object()
			.shape({
				id: yup.string().nullable(),
				name: yup.string().nullable(),
			})
			.nullable(),
		gender: yup
			.object()
			.shape({
				id: yup.string().nullable(),
				name: yup.string().nullable(),
			})
			.nullable(),
		startWorkingDate: yup.date().nullable(),
		department: yup.object().shape({
			id: yup.string().nullable().required('Обязательное поле'),
			name: yup.string().nullable(),
		}),
	});
