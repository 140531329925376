import { VacationType } from '@/app/redux/queries/vacation-service/types/types';
import { routerPath } from '@/app/router/paths';

export const getOnClickCallback = ({
	vacationType,
	departmentId,
	projectId,
	isActiveUserVacation,
	setActiveUserId,
	month,
	year,
	hasExtendedRights,
	isPlanConfirmed,
}: {
	vacationType: VacationType;
	departmentId: string | null;
	projectId: string | null;
	isActiveUserVacation: boolean;
	setActiveUserId: (userId: string | null) => void;
	month: number;
	year: string;
	hasExtendedRights: boolean;
	isPlanConfirmed: boolean;
}) => {
	// - Редактировать планируемый отпуск
	if (vacationType === 'Planned') {
		if ((hasExtendedRights || isActiveUserVacation) && !isPlanConfirmed && departmentId) {
			return (userId: string) => setActiveUserId(userId);
		}
	} else {
		// - Переход в Учет времени у активного пользователя
		if (isActiveUserVacation) {
			return () => window.open(`${routerPath.time.page}/?tab=absence&month=${month - 1}&year=${year}`, '_blank');
		}

		// - Переход в таймлист департамента
		else if (hasExtendedRights && departmentId) {
			return (userId: string) => {
				window.open(`${routerPath.departments.page}${routerPath.departments.timeList}/${departmentId}?month=${month - 1}&year=${year}&userId=${userId}`, '_blank');
			};
		}

		// - Переход в таймлист проекта
		else if (hasExtendedRights && projectId) {
			return (userId: string) => {
				window.open(`${routerPath.projects.page}/${projectId}${routerPath.projects.timelist}?month=${month - 1}&year=${year}&userId=${userId}`, '_blank');
			};
		} else {
			return undefined;
		}
	}
};
