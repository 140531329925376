import React, { useState } from 'react';
import s from './ResultsPage.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { survey_serviceAPI } from '@/app/redux/queries/survey-service/survey_serviceAPI';
import DownloadSVG from '@/shared/assets/svg/files/download.svg?react';
import { Button } from '@/shared/ui/_buttons/Button';
import { LineTabs, SingleTab } from '@/shared/ui/_tabs/lineTabs/LineTabs';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { PageTitle } from '@/entities/PageTitle/PageTitle';
import { SurveyHeader } from '../DisplaySurveyPage/SurveyHeader/SurveyHeader';
import { AnalyticsContent } from './Content/AnalyticsContent/AnalyticsContent';
import { SummaryContent } from './Content/SummaryContent/SummaryContent';
import { UsersContent } from './Content/UsersContent/UsersContent';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';

export const ResultsPage: React.FC = () => {
	// * Navigate
	const navigate = useNavigate();

	// * Router
	const { surveyId } = useParams<'surveyId'>();

	// * API
	const { currentData: surveyIdData, isLoading: surveyIdDataIsLoading } = survey_serviceAPI.useGetSurveysSurveyidQuery(
		{
			surveyid: surveyId as string,
			includeCategories: true,
			includeuseranswers: true,
		},
		{ skip: !surveyId },
	);

	const [trigger, { isLoading: sheetDataIsLoading }] = survey_serviceAPI.useLazyGetSurveyResultsBySurveyidQuery();

	const getSheetData = () => {
		const data = trigger({ surveyid: surveyId as string });
		data.unwrap().then(async res => {
			// TODO: Вынести в отдельную функцию.
			const filename = surveyIdData?.subject || '' + new Date();
			const mediaType = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
			const downloadLink = document.createElement('a');
			downloadLink.href = mediaType + ';base64,' + res;
			downloadLink.download = filename;
			downloadLink.click();
			downloadLink.remove();
		});
	};

	const isLoading = surveyIdDataIsLoading;

	const usersCount = surveyIdData?.numberOfVoters || 0;

	// * Tabs navigation
	const allTabs: SingleTab[] = [
		{
			id: 'summary',
			name: 'Сводка',
		},
		{
			id: 'user',
			name: 'Отдельный пользователь',
		},
		{
			id: 'analytics',
			name: 'Аналитика',
		},
	];

	const tabs = usersCount > 0 ? allTabs : allTabs.filter(tab => tab.id !== 'user');

	const [activeTabId, setActiveTabId] = useState<string>('summary');

	return (
		<PageDecorator>
			<PageTitle
				title="Опросы"
				isBeta
				buttons={[
					<Button
						key="Скачать результаты"
						LeftIcon={<DownloadSVG />}
						variant="secondary"
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							getSheetData();
						}}
						isLoading={sheetDataIsLoading}
					>
						Скачать результаты
					</Button>,
					<Button
						key="Перейти к опросу"
						variant="primary"
						onClick={e => {
							e.preventDefault();
							e.stopPropagation();
							navigate(`/surveys/${surveyId}`);
						}}
					>
						Перейти к опросу
					</Button>,
				]}
			/>

			{isLoading && <LoaderCircle />}

			{!isLoading && (
				<div className={s.container}>
					<SurveyHeader
						title={surveyIdData?.subject || ''}
						description={surveyIdData?.description || ''}
						isAnonymous={!!surveyIdData?.isAnonymous}
						deadline={surveyIdData?.deadline || null}
						category={surveyIdData?.surveyCategories ? surveyIdData.surveyCategories[0] : null}
					/>

					<LineTabs
						tabs={tabs}
						width="fit"
						activeTabId={activeTabId}
						setActiveTabId={setActiveTabId}
					/>

					{activeTabId === 'summary' && (
						<SummaryContent
							isAnonymous={!!surveyIdData?.isAnonymous}
							usersCount={usersCount}
						/>
					)}

					{activeTabId === 'user' && usersCount > 0 && <UsersContent isAnonymous={!!surveyIdData?.isAnonymous} />}

					{activeTabId === 'analytics' && <AnalyticsContent isAnonymous={!!surveyIdData?.isAnonymous} />}
				</div>
			)}
		</PageDecorator>
	);
};
