import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { CommunicationInfo } from '@/app/redux/state/user/user/types';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';
import { Radio } from '@/shared/ui/_inputs/Radio';
import { StringField } from '@/shared/ui/_fields/StringField';
import { ButtonPair } from '@/entities/_buttons/ButtonPair/ButtonPair';
import * as yup from 'yup';
import s from './UserActivation.module.scss';

interface Props {
	userId: string;
	contacts: CommunicationInfo[];
	isPending: boolean;
	onClose: () => void;
	actionAfterRequest?: () => void;
}

export const UserActivation: React.FC<Props> = props => {
	const {
		userId, //
		contacts,
		isPending,
		onClose,
		actionAfterRequest,
	} = props;

	const emails = contacts.filter(contact => ['Email', 'BaseEmail'].includes(contact.type));

	// * Form
	const schema = yup.object().shape({
		contact: yup.string().nullable(),
		email: yup
			.string()
			.nullable()
			.notRequired()
			.when('contact', {
				is: (val: string) => val === 'other',
				then: yup
					.string()
					.nullable()
					.required('Обязательное поле')
					.matches(/^$|^[^@\s]+@[^@\s,]*/, 'Некорректная почта'),
			}),
	});

	const formMethods = useForm({
		defaultValues: {
			email: '',
			contact: emails.length > 0 ? emails[0].id : 'other',
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch, setValue, control } = formMethods;

	const contactValue = watch('contact');

	// * API
	const [createCommunication, { isLoading: isCreateLoading }] = user_serviceAPI.usePostCommunicationCreateMutation();
	const [resendinvitation, { isLoading: isResendLoading }] = user_serviceAPI.useLazyGetPendingResendinvitationQuery();
	const [putUserEditactive, { isLoading: isUnArchiveLoading }] = user_serviceAPI.usePutUserEditactiveMutation();

	// * State
	const onSend = (data: { email: string; contact: string }) => {
		const { contact } = data;

		if (isPending) {
			resendinvitation({
				communicationId: contact,
				userId,
			})
				.unwrap()
				.then(() => {
					actionAfterRequest && actionAfterRequest();
					onClose();
				})
				.catch(err => console.log(err));
		} else {
			putUserEditactive({
				editUserActiveRequest: {
					isActive: true,
					userId,
					communicationId: contact,
				},
			})
				.unwrap()
				.then(() => {
					actionAfterRequest && actionAfterRequest();
					onClose();
				})
				.catch(err => console.log(err));
		}
	};

	const onCreateContact = (data: { email: string; contact: string }) => {
		createCommunication({
			createCommunicationRequest: {
				userId,
				type: 'Email',
				value: data.email,
			},
		})
			.unwrap()
			.then(res => {
				res.body && onSend({ ...data, contact: res.body });
			})
			.catch(err => console.log(err));
	};

	// * Render
	return (
		<form onSubmit={handleSubmit(contactValue === 'other' ? onCreateContact : onSend)}>
			<FormProvider {...formMethods}>
				<div className={s.container}>
					<h2>{isPending ? 'Активация сотрудника' : 'Восстановление сотрудника'}</h2>

					<div className={s.description}>Выберите e-mail сотрудника, на который будет отправлено приглашение, или добавьте новый.</div>

					<div className={s.social}>
						{emails.map(contact => (
							<div
								key={contact.id}
								className={s.field}
							>
								<Controller
									name="contact"
									control={control}
									render={({ field }) => (
										<Radio
											{...field}
											checked={field.value === contact.id}
											onChange={() => setValue('contact', contact.id)}
										/>
									)}
								/>
								{contact.value}
							</div>
						))}

						<div className={s.field}>
							<Controller
								name="contact"
								control={control}
								render={({ field }) => (
									<Radio
										{...field}
										checked={field.value === 'other'}
										onChange={() => setValue('contact', 'other')}
									/>
								)}
							/>

							<StringField
								name="email"
								placeholder="Введите E-mail"
								disabled={contactValue !== 'other'}
							/>
						</div>
					</div>

					<ButtonPair
						primaryText="Отправить"
						primaryIsLoading={isCreateLoading || isResendLoading || isUnArchiveLoading}
						secondaryText="Отмена"
						secondaryOnClick={onClose}
						secondaryIsLoading={isCreateLoading || isResendLoading || isUnArchiveLoading}
					/>
				</div>
			</FormProvider>
		</form>
	);
};
