import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@/app/api/apiSlice';
import { ServiceName } from '@/app/api/api_types';
import {
	DeleteUnsentEmailResendApiArg,
	GetAdminFindApiArg,
	GetFeedbackFilesByFileIdApiArg,
	GetFeedbacksApiArg,
	GetFeedbacksByFeedbackIdApiArg,
	GetGraphicalUserInterfaceGetApiArg,
	GetUnsentEmailFindApiArg,
	PatchGraphicalUserInterfaceEditApiArg,
	PatchModuleSettingEditApiArg,
	PostAdminInstallApiArg,
	PostAuthLoginApiArg,
	PostAuthRefreshApiArg,
	PostFeedbacksApiArg,
	PostFeedbacksByFeedbackIdFilesApiArg,
	PutAdminEditApiArg,
	PutModuleSettingCheckApiArg,
} from './types/reqTypes';
import {
	DeleteUnsentEmailResendApiResponse,
	GetAdminFindApiResponse,
	GetFeedbackFilesByFileIdApiResponse,
	GetFeedbacksApiResponse,
	GetFeedbacksByFeedbackIdApiResponse,
	GetGraphicalUserInterfaceGetApiResponse,
	GetUnsentEmailFindApiResponse,
	PatchGraphicalUserInterfaceEditApiResponse,
	PatchModuleSettingEditApiResponse,
	PostAdminInstallApiResponse,
	PostAuthLoginApiResponse,
	PostAuthRefreshApiResponse,
	PostFeedbacksApiResponse,
	PostFeedbacksByFeedbackIdFilesApiResponse,
	PutAdminEditApiResponse,
	PutModuleSettingCheckApiResponse,
} from './types/resTypes';

const reducerPath = `${ServiceName.GATEWAY_SERVICE}` as const;

export const gateway_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Admin', 'Auth', 'FeedbackFiles', 'Feedbacks', 'GraphicalUserInterface', 'ModuleSetting', 'UnsentEmail'],
	endpoints: build => ({
		getAdminFind: build.query<GetAdminFindApiResponse, GetAdminFindApiArg>({
			query: queryArg => ({ url: `${reducerPath}/Admin/find`, params: { skipcount: queryArg.skipcount, takecount: queryArg.takecount } }),
			providesTags: ['Admin'],
		}),
		putAdminEdit: build.mutation<PutAdminEditApiResponse, PutAdminEditApiArg>({
			query: queryArg => ({ url: `${reducerPath}/Admin/edit`, method: 'PUT', body: queryArg.body }),
			invalidatesTags: ['Admin'],
		}),
		postAdminInstall: build.mutation<PostAdminInstallApiResponse, PostAdminInstallApiArg>({
			query: queryArg => ({ url: `${reducerPath}/Admin/install`, method: 'POST', body: queryArg.installAppRequest }),
			invalidatesTags: ['Admin'],
		}),
		postAuthLogin: build.mutation<PostAuthLoginApiResponse, PostAuthLoginApiArg>({
			query: queryArg => ({ url: `${reducerPath}/Auth/login`, method: 'POST', body: queryArg.loginRequest }),
			invalidatesTags: ['Auth'],
		}),
		postAuthRefresh: build.mutation<PostAuthRefreshApiResponse, PostAuthRefreshApiArg>({
			query: queryArg => ({ url: `${reducerPath}/Auth/refresh`, method: 'POST', body: queryArg.refreshRequest }),
			invalidatesTags: ['Auth'],
		}),
		postFeedbacksByFeedbackIdFiles: build.mutation<PostFeedbacksByFeedbackIdFilesApiResponse, PostFeedbacksByFeedbackIdFilesApiArg>({
			query: queryArg => ({
				headers: { 'Content-Type': undefined }, // ! Добавлен [ген]
				url: `${reducerPath}/feedbacks/${queryArg.feedbackId}/files`,
				method: 'POST',
				body: queryArg.body,
			}),
			invalidatesTags: ['FeedbackFiles'],
		}),
		getFeedbackFilesByFileId: build.query<GetFeedbackFilesByFileIdApiResponse, GetFeedbackFilesByFileIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/feedback-files/${queryArg.fileId}` }),
			providesTags: ['FeedbackFiles'],
		}),
		postFeedbacks: build.mutation<PostFeedbacksApiResponse, PostFeedbacksApiArg>({
			query: queryArg => ({ url: `${reducerPath}/feedbacks`, method: 'POST', body: queryArg.createFeedbackRequest }),
			invalidatesTags: ['Feedbacks'],
		}),
		getFeedbacks: build.query<GetFeedbacksApiResponse, GetFeedbacksApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/feedbacks`,
				params: {
					ownmessagesonly: queryArg.ownmessagesonly,
					feedbackstatus: queryArg.feedbackstatus,
					feedbacktype: queryArg.feedbacktype,
					orderbydescending: queryArg.orderbydescending,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Feedbacks'],
		}),
		getFeedbacksByFeedbackId: build.query<GetFeedbacksByFeedbackIdApiResponse, GetFeedbacksByFeedbackIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/feedbacks/${queryArg.feedbackId}` }),
			providesTags: ['Feedbacks'],
		}),
		getGraphicalUserInterfaceGet: build.query<GetGraphicalUserInterfaceGetApiResponse, GetGraphicalUserInterfaceGetApiArg>({
			query: () => ({ url: `${reducerPath}/GraphicalUserInterface/get` }),
			providesTags: ['GraphicalUserInterface'],
		}),
		patchGraphicalUserInterfaceEdit: build.mutation<PatchGraphicalUserInterfaceEditApiResponse, PatchGraphicalUserInterfaceEditApiArg>({
			query: queryArg => ({ url: `${reducerPath}/GraphicalUserInterface/edit`, method: 'PATCH', body: queryArg.body }),
			invalidatesTags: ['GraphicalUserInterface'],
		}),
		patchModuleSettingEdit: build.mutation<PatchModuleSettingEditApiResponse, PatchModuleSettingEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/ModuleSetting/edit`,
				method: 'PATCH',
				body: queryArg.body,
				params: { moduleSettingId: queryArg.moduleSettingId },
			}),
			invalidatesTags: ['ModuleSetting'],
		}),
		putModuleSettingCheck: build.mutation<PutModuleSettingCheckApiResponse, PutModuleSettingCheckApiArg>({
			query: queryArg => ({ url: `${reducerPath}/ModuleSetting/check`, method: 'PUT', body: queryArg.checkSmtpRequest }),
			invalidatesTags: ['ModuleSetting'],
		}),
		deleteUnsentEmailResend: build.mutation<DeleteUnsentEmailResendApiResponse, DeleteUnsentEmailResendApiArg>({
			query: queryArg => ({ url: `${reducerPath}/UnsentEmail/resend`, method: 'DELETE', params: { unsentEmailId: queryArg.unsentEmailId } }),
			invalidatesTags: ['UnsentEmail'],
		}),
		getUnsentEmailFind: build.query<GetUnsentEmailFindApiResponse, GetUnsentEmailFindApiArg>({
			query: queryArg => ({ url: `${reducerPath}/UnsentEmail/find`, params: { skipcount: queryArg.skipcount, takecount: queryArg.takecount } }),
			providesTags: ['UnsentEmail'],
		}),
	}),
});

export const {
	useGetAdminFindQuery,
	usePutAdminEditMutation,
	usePostAdminInstallMutation,
	usePostAuthLoginMutation,
	usePostAuthRefreshMutation,
	usePostFeedbacksByFeedbackIdFilesMutation,
	useGetFeedbackFilesByFileIdQuery,
	usePostFeedbacksMutation,
	useGetFeedbacksQuery,
	useGetFeedbacksByFeedbackIdQuery,
	useGetGraphicalUserInterfaceGetQuery,
	usePatchGraphicalUserInterfaceEditMutation,
	usePatchModuleSettingEditMutation,
	usePutModuleSettingCheckMutation,
	useDeleteUnsentEmailResendMutation,
	useGetUnsentEmailFindQuery,
} = gateway_serviceAPI;
