import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { ServiceName } from '@/app/api/api_types';
import { baseQueryWithReAuth } from '@/app/api/apiSlice';
import {
	CreateRubricApiArg,
	EditRubricApiArg,
	GetArticleApiArg,
	CreateArticleApiArg,
	EditArticleApiArg,
	FindArticlesByContentApiArg,
	FindFilesApiArg,
	RemoveFilesApiArg,
	GetWikiApiArg,
	GetPublicWikiApiArg,
	FindPublicArticlesByContentApiArg,
	FindPublicFilesApiArg,
	GetPublicArticleApiArg,
	GetFavoritesApiArg,
	PostFavoritesApiArg,
	DeleteFavoritesByArticleIdApiArg,
} from './types/regTypes';
import {
	CreateRubricApiResponse,
	EditRubricApiResponse,
	GetArticleApiResponse,
	CreateArticleApiResponse,
	EditArticleApiResponse,
	FindArticlesByContentApiResponse,
	FindFilesApiResponse,
	RemoveFilesApiResponse,
	GetWikiApiResponse,
	GetPublicWikiApiResponse,
	FindPublicArticlesByContentApiResponse,
	FindPublicFilesApiResponse,
	GetPublicArticleApiResponse,
	GetFavoritesApiResponse,
	PostFavoritesApiResponse,
	DeleteFavoritesByArticleIdApiResponse,
} from './types/resTypes';

export const reducerPath = `${ServiceName.WIKI_SERVICE}` as const;

export const wiki_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	tagTypes: ['Rubric', 'Article', 'File', 'WikiTree', 'publicWikiTree', 'Favorites'],
	reducerPath,
	endpoints: build => ({
		createRubric: build.mutation<CreateRubricApiResponse, CreateRubricApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/rubrics`,
				method: 'POST',
				body: queryArg.createRubricRequest,
			}),
			invalidatesTags: ['WikiTree', 'Rubric'],
		}),
		editRubric: build.mutation<EditRubricApiResponse, EditRubricApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/rubrics/${queryArg.rubricId}`,
				method: 'PATCH',
				body: queryArg.editRubricRequest,
			}),
			invalidatesTags: ['WikiTree', 'Rubric'],
		}),
		getArticle: build.query<GetArticleApiResponse, GetArticleApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/articles/${queryArg.articleId}`,
			}),
			providesTags: ['Article'],
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),
		createArticle: build.mutation<CreateArticleApiResponse, CreateArticleApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/articles`,
				method: 'POST',
				body: queryArg.createArticleRequest,
			}),
			invalidatesTags: ['WikiTree', 'Article'],
		}),
		editArticle: build.mutation<EditArticleApiResponse, EditArticleApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/articles/${queryArg.articleId}`,
				method: 'PATCH',
				body: queryArg.editArticleRequest,
			}),
			invalidatesTags: ['WikiTree', 'Article', 'File'],
		}),
		findArticlesByContent: build.query<FindArticlesByContentApiResponse, FindArticlesByContentApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/articles`,
				params: {
					skipCount: queryArg.skipCount,
					takeCount: queryArg.takeCount,
					contentincludesubstring: queryArg.contentincludesubstring,
					nameincludesubstring: queryArg.nameincludesubstring,
					includedeactivated: queryArg.includedeactivated,
					descendingcreatedatutc: queryArg.descendingcreatedatutc,
					descendingmodifiedatutc: queryArg.descendingmodifiedatutc,
				},
			}),
			providesTags: ['Article'],
		}),
		findFiles: build.query<FindFilesApiResponse, FindFilesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/files`,
				params: { skipCount: queryArg.skipCount, takeCount: queryArg.takeCount, articleid: queryArg.articleid },
			}),
			providesTags: ['File'],
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),
		removeFiles: build.mutation<RemoveFilesApiResponse, RemoveFilesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/files`,
				method: 'DELETE',
				body: queryArg.removeFilesRequest,
			}),
			invalidatesTags: ['File'],
		}),
		getWiki: build.query<GetWikiApiResponse, GetWikiApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/wikitree`,
				params: { includedeactivated: queryArg.includedeactivated },
			}),
			providesTags: ['WikiTree'],
		}),
		getPublicWiki: build.query<GetPublicWikiApiResponse, GetPublicWikiApiArg>({
			query: () => ({
				url: `${reducerPath}/articles/public/wiki`,
			}),
			providesTags: ['publicWikiTree'],
		}),
		findPublicArticlesByContent: build.query<FindPublicArticlesByContentApiResponse, FindPublicArticlesByContentApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/articles/public`,
				params: {
					skipCount: queryArg.skipCount,
					takeCount: queryArg.takeCount,
					contentincludesubstring: queryArg.contentincludesubstring,
					nameincludesubstring: queryArg.nameincludesubstring,
				},
			}),
			providesTags: ['Article'],
		}),
		findPublicFiles: build.query<FindPublicFilesApiResponse, FindPublicFilesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/articles/public/files`,
				params: { skipCount: queryArg.skipCount, takeCount: queryArg.takeCount, articleid: queryArg.articleid },
			}),
			providesTags: ['File'],
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),
		getPublicArticle: build.query<GetPublicArticleApiResponse, GetPublicArticleApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/articles/public/${queryArg.articleId}`,
			}),
			providesTags: ['Article'],
			extraOptions: {
				ignoreErrorNotification: true,
			},
		}),
		postFavorites: build.mutation<PostFavoritesApiResponse, PostFavoritesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/favorites`,
				method: 'POST',
				body: queryArg.createFavoriteArticleRequest,
			}),
			invalidatesTags: ['Favorites', 'Article'],
		}),
		getFavorites: build.query<GetFavoritesApiResponse, GetFavoritesApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/favorites`,
				params: { filter: queryArg.filter },
			}),
			providesTags: ['Favorites', 'Article'],
		}),
		deleteFavoritesByArticleId: build.mutation<DeleteFavoritesByArticleIdApiResponse, DeleteFavoritesByArticleIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/favorites/${queryArg.articleId}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Favorites', 'Article'],
		}),
	}),
});

export const {
	useCreateRubricMutation,
	useEditRubricMutation,
	useGetArticleQuery,
	useCreateArticleMutation,
	useEditArticleMutation,
	useFindArticlesByContentQuery,
	useFindFilesQuery,
	useRemoveFilesMutation,
	useGetWikiQuery,
	useGetPublicWikiQuery,
	useFindPublicArticlesByContentQuery,
	useFindPublicFilesQuery,
	useGetPublicArticleQuery,
} = wiki_serviceAPI;
