import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePatchGroupsByGroupIdSubgroupsAndSubgroupIdMutation } from '@/app/redux/queries/vacation-service/vacation_serviceAPI';
import { SelectStringSearchFilter, SelectValue } from '@/entities/_filters/SelectStringSearchFilter';
import { SelectedCount } from '@/entities/_typography/SelectedCount';
import { AddButton } from '@/shared/ui/_buttons/AddButton';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { Heading } from '@/shared/ui/Heading';
import { SUB_GROUP } from '../../consts/consts';
import { useGetSubGroupUsers } from '../../hooks/useGetSubGroupUsers';
import { getPositionOptions } from '../../lib/getPositionOptions/getPositionOptions';
import { SubGroupType } from '../../types';
import { AddUserContainer } from '../AddUserContainer/AddUserContainer';
import { UsersListContainer } from '../UsersListContainer/UsersListContainer';
import s from './SubGroupUsers.module.scss';
import { UserListItem } from '@/features/_card_lists/types';

interface Props {
	type?: SubGroupType;
	name: string;
	subgroupId: string;
}

export const SubGroupUsers: FC<Props> = props => {
	const {
		type, //
		name,
		subgroupId,
	} = props;

	const { groupId } = useParams() as { groupId: string };

	// * Modal
	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	// * API
	const [editUsers, { isLoading: editLoading }] = usePatchGroupsByGroupIdSubgroupsAndSubgroupIdMutation();

	const { usersInSubgroup, usersNotInSubgroup, isLoadingSubGroup, isLoadingGetUsers } = useGetSubGroupUsers(groupId, subgroupId);

	const onAddUsers = (userIds: string[]) => {
		// ! add не работает, пока не починят на бэке используем replace.
		editUsers({
			groupId,
			subgroupId: subgroupId,
			body: [
				{
					op: 'replace',
					value: [...usersInSubgroup.map(user => user.id), ...userIds],
					path: '/UserIds',
				},
			],
		})
			.unwrap()
			.then(() => toggleModal())
			.catch(e => console.log(e));
	};

	const onDeleteUser = (userId: string) => {
		// ! remove не работает. Удаляет всех пользователей вместо одного, пока не починят на бэке используем replace.
		editUsers({
			groupId,
			subgroupId: subgroupId,
			body: [
				{
					op: 'replace',
					value: usersInSubgroup.filter(user => user.id !== userId).map(user => user.id),
					path: '/UserIds',
				},
			],
		});
	};

	// * Filters
	const [position, setPosition] = useState<SelectValue>({ id: '', name: '' });
	const [searchValue, setSearchValue] = useState('');

	const [filteredUsers, setFilteredUsers] = useState<UserListItem[]>([]);

	// * Filter options
	const positionOptions = getPositionOptions(usersInSubgroup);

	// * Filters apply
	useEffect(() => {
		let newUsers = usersInSubgroup;

		if (name !== '') {
			newUsers = newUsers.filter(user => user?.name.toLowerCase().includes(searchValue.toLowerCase()));
		}

		if (position.name !== '') {
			newUsers = newUsers.filter(user => (position.name === 'Без должности' ? user.position === '' : user.position === position.name));
		}

		setFilteredUsers(newUsers);
	}, [position, searchValue, usersInSubgroup.length]);

	// * Render
	return (
		<>
			{showModal && (
				<AddUserContainer
					showModal={showModal}
					toggleModal={toggleModal}
					editAction={onAddUsers}
					editLoading={editLoading || isLoadingSubGroup || isLoadingGetUsers}
					allUsers={usersNotInSubgroup}
				/>
			)}

			<div className={s.container}>
				<Heading level={2}>
					{type && SUB_GROUP[type]} <span className={s.subgroup_name}>{`“${name}”`}</span>
				</Heading>

				{isLoadingSubGroup || isLoadingGetUsers ? (
					<LoaderCircle />
				) : (
					<>
						<SelectStringSearchFilter
							selectPlaceholder="Должность"
							selectValue={position}
							setSelect={(value: SelectValue) => setPosition(value)}
							selectOptions={positionOptions}
							searchPlaceholder="Введите ФИО сотрудника"
							searchStringValue={searchValue}
							setSearchString={setSearchValue}
						/>

						<div className={s.inner}>
							<SelectedCount
								title="Выбрано сотрудников"
								count={usersInSubgroup.length}
							/>

							<AddButton
								title="Добавить сотрудников"
								onClick={toggleModal}
							/>

							{filteredUsers.length > 0 && (
								<UsersListContainer
									users={filteredUsers}
									onRemoveUser={id => onDeleteUser(id)}
									isLoading={editLoading}
									size="small"
								/>
							)}
						</div>
					</>
				)}
			</div>
		</>
	);
};
