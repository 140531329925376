import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { officeServiceActions } from '@/app/redux/state/office/actions';
import { OfficeRoom } from '@/app/redux/state/office/office/types';
import { useAppDispatch, useAppSelector } from '@/app/redux/utils';
import { SelectStringSearchFilter, SelectValue } from '@/entities/_filters/SelectStringSearchFilter';
import DeleteSVG from '@/shared/assets/svg_icons/action/delete.svg?react';
import EditSVG from '@/shared/assets/svg_icons/action/edit.svg?react';
import { useRights } from '@/shared/hooks/useRights';
import { AddButton } from '@/shared/ui/_buttons/AddButton';
import { DropDownMenu } from '@/shared/ui/DropDownMenu/DropDownMenu';
import { useRoomEdit } from './hooks/useRoomEdit';
import s from './RoomsContent.module.scss';
import { RoomsList } from './RoomsList/RoomsList';

export const RoomsContent: React.FC = () => {
	// * Rights
	const allowedToEditOffices = useRights(8);

	// * Router
	const params = useParams<{ officeId: string }>();
	const officeId: string = params.officeId as string;

	// * Selectors
	const rooms = useAppSelector(state => state.office_service.office.rooms);
	const roomTypes = useAppSelector(state => state.office_service.roomType.roomTypes);

	// * Actions
	const dispatch = useAppDispatch();
	const { findRoomTypes } = officeServiceActions.roomType.async;
	const { clearActiveRoom } = officeServiceActions.room.actions;
	const { getOffice } = officeServiceActions.office.async;
	const { editRoom } = officeServiceActions.room.async;

	// * Initialize
	useEffect(() => {
		officeId &&
			dispatch(
				findRoomTypes({
					params: {
						skipCount: 0,
						takeCount: 1000,
						includeDeactivated: false,
						officeId: officeId,
					},
				}),
			);

		dispatch(clearActiveRoom());
	}, [officeId]);

	// * Create room
	const { openRoomForm } = useRoomEdit({ officeId });

	// * Filter
	const [roomType, setRoomType] = useState<SelectValue>({ id: null, name: '' });
	const [roomName, setRoomName] = useState('');
	const [visibleRooms, setVisibleRooms] = useState<OfficeRoom[]>(rooms);

	useEffect(() => {
		let filteredRooms = rooms;

		if (roomType.id !== null) {
			filteredRooms = filteredRooms.filter(room => room.room.roomType.id === roomType.id);
		}

		if (roomName.trim() !== '') {
			filteredRooms = filteredRooms.filter(room => room.room.name.toLocaleLowerCase().includes(roomName.toLocaleLowerCase()));
		}

		setVisibleRooms(filteredRooms);
	}, [roomType, roomName, rooms.length]);

	// * Conditions
	const roomsExists = rooms.length > 0;

	// * Render
	return (
		<>
			<div className={s.container}>
				{allowedToEditOffices && (
					<AddButton
						title="Добавить помещение"
						onClick={() => openRoomForm(null)}
					/>
				)}

				{roomsExists && roomTypes.length > 0 && (
					<SelectStringSearchFilter
						selectPlaceholder="Тип помещения"
						selectValue={roomType}
						selectOptions={roomTypes}
						setSelect={(value: SelectValue) => setRoomType(value)}
						searchPlaceholder="Поиск помещения"
						searchStringValue={roomName}
						setSearchString={setRoomName}
					/>
				)}

				{roomsExists && (
					<RoomsList
						rooms={visibleRooms}
						getDropDownMenu={(roomId: string) => (
							<DropDownMenu
								items={[
									{
										title: 'Редактировать',
										icon: <EditSVG />,
										action: () => openRoomForm(roomId),
									},
									{
										title: 'Удалить',
										icon: <DeleteSVG />,
										action: () => {
											dispatch(
												editRoom({
													params: {
														roomId,
													},
													payload: [
														{
															op: 'replace',
															path: '/isActive',
															value: false,
														},
													],
												}),
											)
												.unwrap()
												.then(() => {
													dispatch(
														getOffice({
															params: { officeId },
														}),
													);
												});
										},
									},
								]}
							/>
						)}
					/>
				)}
			</div>
		</>
	);
};
