import React, { ChangeEvent, useEffect, useState } from 'react';
import { SelectedCount } from '@/entities/_typography/SelectedCount';
import { Avatar } from '@/shared/ui/Avatar/Avatar';
import { Checkbox } from '@/shared/ui/_inputs/Checkbox';
import { Switch } from '@/shared/ui/_switches/Switch/Switch';
import { CardsTable } from '@/shared/ui/_tables/CardsTable';
import { UserListItem } from '../types';
import s from './AddUserList.module.scss';
import { getColumns } from './consts';
import { ExtraColumn } from './types';

interface Props {
	users: UserListItem[];
	selectedUsersIds: string[];
	setSelectedUserIds: (selectedUsers: string[]) => void;
	extraColumns?: ExtraColumn[];
	hasSelectAll?: boolean;
}

export const AddUserList: React.FC<Props> = props => {
	const {
		users, //
		selectedUsersIds,
		setSelectedUserIds,
		extraColumns = [
			{
				key: 'department',
				header: 'ДЕПАРТАМЕНТ',
			},
		],
		hasSelectAll,
	} = props;

	const addUser = (selectedUserId: string) => {
		setSelectedUserIds([...selectedUsersIds, selectedUserId]);
	};

	const deleteUser = (selectedUserId: string) => {
		setSelectedUserIds(selectedUsersIds.filter(id => id !== selectedUserId));
	};

	// * Select all
	const selectAllUsersChecked = users.length === selectedUsersIds.length;

	const addAllUsersToggle = () => {
		selectAllUsersChecked //
			? setSelectedUserIds([])
			: setSelectedUserIds(users.map(user => user.id));
	};

	// * Switch
	const [isSelected, setIsSelected] = useState<boolean>(false);

	const handleChangeSwitch = (event: ChangeEvent<HTMLInputElement>) => {
		event.stopPropagation();
		setIsSelected(prevState => !prevState);
	};

	useEffect(() => {
		if (selectedUsersIds.length === 0) {
			setIsSelected(false);
		}
	}, [selectedUsersIds.length]);

	// * Table
	const data = users
		.filter(user => {
			if (!isSelected) return user;
			return selectedUsersIds.find(item => item === user.id);
		})
		.map(user => {
			const checked = selectedUsersIds.includes(user.id);

			return {
				checked,
				avatar: (
					<div className={s.user_checkbox}>
						<Checkbox
							checked={selectedUsersIds.includes(user.id)}
							onChange={() => {
								if (!checked) {
									addUser(user.id);
								} else {
									deleteUser(user.id);
								}
							}}
						/>

						<Avatar
							name={user.name}
							userAvatar={user.avatar}
						/>
					</div>
				),
				name: (
					<>
						<div className={s.fullName}>{user.name}</div>
						<div className={s.positionName}>{user.position || ''}</div>
					</>
				),
				department: user.department,
				status: user.status,
			};
		});

	// * Render
	return (
		<>
			<div className={s.selected}>
				<div>
					{hasSelectAll && (
						<Checkbox
							className={s.selected_all}
							label="Выбрать всех"
							checked={selectAllUsersChecked}
							onChange={addAllUsersToggle}
						/>
					)}

					<SelectedCount
						title="Выбрано сотрудников"
						count={selectedUsersIds.length}
					/>
				</div>

				<div className={s.selected_switch}>
					<div>Просмотреть выбранных</div>

					<Switch
						checked={isSelected}
						onChange={handleChangeSwitch}
						disabled={selectedUsersIds.length === 0}
					/>
				</div>
			</div>

			<div className={s.table}>
				<CardsTable
					columns={getColumns(extraColumns)}
					data={data}
					sortingColumnId="name"
				/>
			</div>
		</>
	);
};
