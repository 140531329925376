export const SUB_GROUP = {
	Category: {
		new: 'Новая категория',
		edit: 'Редактирование\nкатегории',
		label: 'Название категории',
		placeholder: 'Введите категорию',
		delete: 'Удаление категории',
		deleteInfo: 'Вы действительно хотите удалить эту категорию?',
	},
	Group: {
		new: 'Новая группа',
		edit: 'Редактирование\nгруппы',
		label: 'Название группы',
		placeholder: 'Введите группу',
		delete: 'Удаление группы',
		deleteInfo: 'Вы действительно хотите удалить эту группу?',
	},
	Team: {
		new: 'Новая команда',
		edit: 'Редактирование\nкоманды',
		label: 'Название команды',
		placeholder: 'Введите команду',
		delete: 'Удаление команды',
		deleteInfo: 'Вы действительно хотите удалить эту команду?',
	},
};

export const NAME_LIMIT = 50;
