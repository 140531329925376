import { routerPath } from '@/app/router/paths';
import KnowledgeBaseSVG from '@/shared/assets/svg/book-mark.svg?react';
import BookingSVG from '@/shared/assets/svg/booking.svg?react';
import FeedbackSVG from '@/shared/assets/svg/bug.svg?react';
import OfficesSVG from '@/shared/assets/svg/building.svg?react';
import ClockSVG from '@/shared/assets/svg/clock.svg?react';
import AdminSVG from '@/shared/assets/svg/gear.svg?react';
import MagnifierSVG from '@/shared/assets/svg/magnifier.svg?react';
import ProjectorScreenSVG from '@/shared/assets/svg/projector-screen.svg?react';
import CompanyStructureSVG from '@/shared/assets/svg/structure.svg?react';
import TeamSVG from '@/shared/assets/svg/team.svg?react';
import UsersSVG from '@/shared/assets/svg/users.svg?react';

const MENU_ITEMS_PRIVATE_PROD = [
	{
		title: 'Учет времени' as const,
		Icon: <ClockSVG />,
		route: routerPath.time.page,
	},
	{
		title: 'Проекты' as const,
		Icon: <ProjectorScreenSVG />,
		route: routerPath.projects.page,
	},
	{
		title: 'Сотрудники' as const,
		Icon: <UsersSVG />,
		route: routerPath.users.page,
	},
	{
		title: 'Департаменты' as const,
		Icon: <TeamSVG />,
		route: routerPath.departments.page,
	},
	{
		title: 'Бронирование' as const,
		Icon: <BookingSVG />,
		route: routerPath.booking.page,
	},
	{
		title: 'Офисы' as const,
		Icon: <OfficesSVG />,
		route: routerPath.offices.page,
	},
	{
		title: 'База знаний' as const,
		Icon: <KnowledgeBaseSVG />,
		route: routerPath.wiki.page,
	},
	{
		title: 'Структура компании' as const,
		Icon: <CompanyStructureSVG />,
		route: routerPath.structure.page,
	},
	// {
	// 	title: 'Опросы' as const,
	// 	Icon: <SurveysSVG />,
	// 	route: routerPath.surveys.page,
	// },
	// {
	// 	title: 'Календарь событий' as const,
	// 	Icon: <EventSVG/>,
	// 	route: routerPath.calendar.page
	// },
	{
		title: 'Администрирование' as const,
		Icon: <AdminSVG />,
		route: routerPath.admin.page + routerPath.admin.dashboard,
	},
	{
		title: 'Обратная связь' as const,
		Icon: <FeedbackSVG />,
		route: routerPath.feedback.page,
	},
	{
		title: 'HR' as const,
		Icon: <MagnifierSVG />,
		route: routerPath.hr.page,
	},
];

const MENU_ITEMS_PUBLIC_PROD = [
	{
		title: 'База знаний',
		Icon: <KnowledgeBaseSVG />,
		route: routerPath.public.page,
	},
];

export { MENU_ITEMS_PRIVATE_PROD, MENU_ITEMS_PUBLIC_PROD };
