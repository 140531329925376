import React from 'react';
import { NotificationProvider } from './NotificationProvider/NotificationProvider';
import { useAppSelector } from '@/app/redux/utils';

export const NotificationController: React.FC = () => {
	// * Selector
	const notifications = useAppSelector(state => state.notifications.notifications);

	// * Render
	return (
		<>
			<NotificationProvider notifications={notifications} />
		</>
	);
};
