import { useEffect, useState } from 'react';
import { useGetDepartmentFindQuery } from '@/app/redux/queries/department-service/department_serviceAPI';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';
import SearchSVG from '@/shared/assets/svg_icons/action/search.svg?react';
import { SelectSingleWithSearch } from '@/shared/ui/_inputs/selects/SelectSingleWithSearch';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { OPTION_PRIORITY, STATUS } from '../../../../consts/consts';
import { useClaimContext } from '../../context/context';
import s from './Filter.module.scss';

export const Filter = ({ userId }: { userId?: string }) => {
	const [topic, setTopic] = useState('');
	const [status, setStatus] = useState({ id: '', name: '' });
	const [priority, setPriority] = useState({ id: '', name: '' });
	const [department, setDepartment] = useState({ id: '', name: '' });
	const [sender, setSender] = useState({ id: '', name: '' });

	const { updatedFilter } = useClaimContext();

	useEffect(() => {
		updatedFilter &&
			updatedFilter({
				topic: topic,
				status: status,
				priority: priority,
				sender: sender,
			});
	}, [topic, status, priority, sender]);

	// * Api
	const { data: departmentData } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});
	const { data: userTreeData } = user_serviceAPI.useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		isactive: true,
	});

	const departments = departmentData?.body ?? [];
	const usersTree = userTreeData?.body ?? [];

	const departmentAll = departments.map(department => ({
		id: department.id,
		name: department.shortName,
		fullName: department.name,
	}));

	const usersAll = usersTree.map(item => ({
		id: item.user.id,
		name: `${item.user.lastName} ${item.user.firstName} ${item.user.middleName || ''}`,
	}));

	// * Render
	return (
		<div className={s.wrapper}>
			<TextInput
				value={topic}
				onChange={e => setTopic(e.target.value)}
				placeholder="Поиск по теме"
				RightIcon={<SearchSVG />}
			/>

			<div className={s.filters}>
				<SelectSingleWithSearch
					placeholder="Статус"
					keyNames={{ name: 'name', value: 'id' }}
					selectedOption={status}
					setSelectedOption={key => setStatus(key)}
					options={STATUS}
				/>

				<SelectSingleWithSearch
					placeholder="Департамент"
					keyNames={{ name: 'name', value: 'id', tooltipText: 'fullName' }}
					selectedOption={department}
					setSelectedOption={value => setDepartment(value)}
					options={departmentAll}
				/>
				<SelectSingleWithSearch
					placeholder="Приоритетность"
					keyNames={{ name: 'name', value: 'id' }}
					selectedOption={priority}
					setSelectedOption={value => setPriority(value)}
					options={OPTION_PRIORITY}
				/>
				{!userId && (
					<SelectSingleWithSearch
						placeholder="Отправитель"
						keyNames={{ name: 'name', value: 'id' }}
						selectedOption={sender}
						setSelectedOption={value => setSender(value)}
						options={usersAll}
					/>
				)}
			</div>
		</div>
	);
};
