import { FC } from 'react';
import s from './ArchiveMC.module.scss';
import { Heading } from '@/shared/ui/Heading';
import { ButtonPair } from '@/entities/_buttons/ButtonPair/ButtonPair';
import { useAppSelector } from '@/app/redux/utils';
import { RequestStatus } from '@/app/api/api_types';

interface Props {
	officeName: string;
	closeModal: () => void;
	onArchiveClick: () => void;
}

export const ArchiveMC: FC<Props> = props => {
	const {
		officeName, //
		closeModal,
		onArchiveClick,
	} = props;

	// * Selectors
	const status = useAppSelector(state => state.office_service.office.status);

	// * Render
	return (
		<div className={s.container}>
			<Heading level={3}>Архивирование</Heading>

			<p>
				Вы действительно хотите архивировать офис <span className={s.name}>{officeName}</span>?
			</p>

			<ButtonPair
				primaryText="Архивировать"
				primaryOnClick={onArchiveClick}
				primaryIsLoading={status === RequestStatus.loading}
				secondaryText="Отмена"
				secondaryOnClick={closeModal}
				secondaryIsLoading={status === RequestStatus.loading}
			/>
		</div>
	);
};
