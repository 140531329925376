import { Editor } from '@tinymce/tinymce-react';
import React, { HTMLAttributes, MutableRefObject } from 'react';
import { ArticleResponse } from '@/app/redux/queries/wiki-service/types/resTypes';
import { StringField } from '@/shared/ui/_fields/StringField';
import s from './ArticleEditor.module.scss';

type Props = HTMLAttributes<HTMLDivElement> & {
	article: ArticleResponse;
	editorRef: MutableRefObject<any>;
};

export const ArticleEditor: React.FC<Props> = props => {
	const {
		className,
		article: { content },
		editorRef,
	} = props;

	// * Render
	return (
		<div className={`${s.container} ${className}`}>
			<div className={s.header}>
				<StringField
					name="name"
					label="Название статьи"
					placeholder="Введите название"
					required
				/>
			</div>

			<Editor
				tinymceScriptSrc="/tinymce/tinymce.min.js"
				onInit={(evt, editor) => (editorRef.current = editor)}
				initialValue={content}
				init={{
					browser_spellcheck: true,
					height: 500,
					menubar: false,
					plugins: [
						'advlist',
						'autolink',
						'lists',
						'link',
						'charmap',
						'emoticons',
						'anchor',
						'searchreplace',
						'visualblocks',
						'code',
						'fullscreen',
						'insertdatetime',
						'media',
						'table',
						'preview',
						'help',
						'wordcount',
					],
					toolbar:
						'undo redo | blocks | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | fontsizeselect | charmap  emoticons | table',
					font_css: '/fontsTinyMCE.css',
					content_css: '/tinyMCE.css',
				}}
			/>
		</div>
	);
};
