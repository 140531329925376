import { createSlice } from '@reduxjs/toolkit';
import { apiFetch } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../utils';
import { LoginRequestBody, RefreshRequestBody, UserData } from './types';

// * API requests
const login = createAppAsyncThunk('auth/login', async (payload: LoginRequestBody, thunkAPI) => {
	const { rejectWithValue } = thunkAPI;

	const response = await apiFetch.postLegacy(`${ServiceName.AUTH_SERVICE}/auth/login`, payload);
	const data: UserData = await response.json();
	if (response.status < 200 || response.status >= 300) {
		return rejectWithValue(data);
	}

	localStorage.setItem('access_token', data.accessToken);
	localStorage.setItem('refresh_token', data.refreshToken);

	return data;
});

const refresh = createAppAsyncThunk('auth/refresh', async (payload: RefreshRequestBody, thunkAPI) => {
	const { rejectWithValue } = thunkAPI;

	const response = await apiFetch.postLegacy(`${ServiceName.AUTH_SERVICE}/auth/refresh`, payload);
	const data: UserData = await response.json();

	if (response.status < 200 || response.status >= 300) {
		return rejectWithValue(data);
	}

	localStorage.setItem('access_token', data.accessToken);
	localStorage.setItem('refresh_token', data.refreshToken);

	return data;
});

// * Reducer
interface State {
	tokens: {
		accessToken: string | null;
		refreshToken: string | null;
	} | null;
	status: RequestStatus;
}

const accessToken = localStorage.getItem('access_token');
const refreshToken = localStorage.getItem('refresh_token');

export const initialState: State = {
	tokens: {
		accessToken: accessToken ? accessToken : null,
		refreshToken: refreshToken ? refreshToken : null,
	},
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: state => {
			state.tokens = {
				accessToken: null,
				refreshToken: null,
			};
			state.status = RequestStatus.still;

			localStorage.removeItem('access_token');
			localStorage.removeItem('refresh_token');
			localStorage.removeItem('userInfo');
		},
		setTokens: (state, action: { payload: { accessToken: string; refreshToken: string } }) => {
			const { accessToken, refreshToken } = action.payload;

			localStorage.setItem('access_token', accessToken);
			localStorage.setItem('refresh_token', refreshToken);

			state.tokens = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(login.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(login.fulfilled, (state, action) => {
			state.tokens = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(login.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(refresh.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(refresh.fulfilled, (state, action) => {
			state.tokens = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(refresh.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActionsAuth = {
	login,
	refresh,
};

export const actionsAuth = slice.actions;
