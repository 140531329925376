import React, { useEffect, useState } from 'react';
import { ArticleTabInner } from '../ArticleTabInner/ArticleTabInner';
import stylesMenu from './_SubRubricTab__menu.module.scss';
import stylesMain from './_SubRubricTab__main.module.scss';
import { CollapseArrows } from '@/shared/ui/CollapseArrows/CollapseArrows';
import { ArticleData, PublicArticleData } from '@/app/redux/queries/wiki-service/types/types';

interface Props extends PublicArticleData {
	styleType: 'main' | 'menu';
	isPrivate: boolean;
	articles?: ArticleData[] | PublicArticleData[];
	isOpen?: boolean;
	activeArticleId?: string;
}

export const SubRubricTab: React.FC<Props> = props => {
	const {
		// id,
		// isActive,
		name,
		// position,
		styleType,
		isPrivate,
		articles,
		isOpen = false,
		activeArticleId,
		...buttonProps
	} = props;

	// * Styles
	const s = styleType === 'main' ? stylesMain : stylesMenu;

	const [showArticles, setShowArticles] = useState<boolean>(isOpen);

	// * Conditions
	const innerArticlesExist = articles && articles.length > 0;

	// * Render
	return (
		<>
			<button
				{...buttonProps}
				onClick={() => setShowArticles(!showArticles)}
				className={s.container}
			>
				<CollapseArrows
					isOpen={showArticles}
					arrowType="empty"
					className={s.icon}
				/>

				<span className={s.name}>{name}</span>
			</button>

			{innerArticlesExist && (
				<>
					{showArticles && (
						<div className={s.articles_wrapper}>
							{articles.map(article => (
								<ArticleTabInner
									{...article}
									key={article.id}
									styleType={styleType}
									active={article.id === activeArticleId}
									isPrivate={isPrivate}
								/>
							))}
						</div>
					)}
				</>
			)}
		</>
	);
};
