import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from '../../../utils';
import { AddNewContactREQ, AddNewContactRES, DeleteContactREQ, DeleteContactRES } from './types';
import { apiRequest } from '@/app/api/api';
import { ServiceName, RequestStatus } from '@/app/api/api_types';

const NAME = `${ServiceName.COMPANY_STRUCTURE_SERVICE}/Contacts`;

const addNewContact = createAppAsyncThunk(`${NAME}/addNewContact`, async (payload: AddNewContactREQ, thunkAPI) => {
	return await apiRequest.getRequest<AddNewContactRES>({
		url: `${NAME}`,
		thunkAPI,
		action: () => addNewContact(payload),
	});
});

const deleteContact = createAppAsyncThunk(`${NAME}/deleteContact`, async (arg: DeleteContactREQ, thunkAPI) => {
	const { params } = arg;

	await apiRequest.delRequest<DeleteContactRES>({
		url: `${NAME}/${params.contactId}`,
		thunkAPI,
		action: () => deleteContact(arg),
	});
});

interface State {
	status: RequestStatus;
}

export const initialState: State = {
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(addNewContact.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(addNewContact.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(addNewContact.rejected, state => {
			state.status = RequestStatus.failed;
		});
		builder.addCase(deleteContact.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(deleteContact.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(deleteContact.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	addNewContact,
	deleteContact,
};
