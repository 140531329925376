import React, { useState } from 'react';
import s from './WeekPickerHeader.module.scss';
import { useFloatingWrapper } from '@/shared/hooks/useFloatingWrapper';
import { FloatingFocusManager } from '@floating-ui/react';
import DatePicker from 'react-datepicker';
import { DateTime } from 'luxon';
import { isAfterThisWeek } from '@/shared/lib/date';

interface Props {
	selectedDate: Date;
	setSelectedDate: (date: Date) => void;
	noPrevious?: boolean; // ! Временная переменная для тестирования возможности смотреть предыдущие брони
}

export const WeekPickerHeader: React.FC<Props> = props => {
	const { selectedDate, setSelectedDate, noPrevious = false } = props;

	// * Const
	const MIN_DATE = new Date();

	const [isOpen, setIsOpen] = useState(false);

	const handleOnChange = (date: Date | null) => {
		setIsOpen(!isOpen);
		date && setSelectedDate(date);
	};

	const getDateString = () => {
		const luxonDate = DateTime.fromJSDate(selectedDate);
		const firstWeekDay = luxonDate.startOf('week').toLocaleString({ day: '2-digit', month: '2-digit' });
		const lastWeekDay = luxonDate.endOf('week').toLocaleString({ day: '2-digit', month: '2-digit' });
		return `${firstWeekDay} - ${lastWeekDay}`;
	};

	const toPreviousWeek = () => {
		const prevWeekStart = DateTime.fromJSDate(selectedDate).minus({ day: 7 }).startOf('week');

		if (noPrevious) {
			if (isAfterThisWeek(selectedDate)) {
				setSelectedDate(prevWeekStart.toJSDate());
			}
		} else {
			setSelectedDate(prevWeekStart.toJSDate());
		}
	};

	const toNextWeek = () => {
		const luxonDate = DateTime.fromJSDate(selectedDate);
		setSelectedDate(luxonDate.plus({ day: 7 }).startOf('week').toJSDate());
	};

	// * Floating
	const { floatingStyles, refs, context, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(isOpen, setIsOpen);

	// * Render
	return (
		<>
			<div className={s.container}>
				<button
					className={s.button}
					onClick={toPreviousWeek}
				>
					&lt;
				</button>

				<div
					className={s.date_string}
					ref={refs.setReference}
					{...getReferenceProps()}
					onClick={event => {
						event.preventDefault();
						setIsOpen(prevState => !prevState);
					}}
				>
					<button className={s.button}>{getDateString()}</button>
				</div>

				<button
					className={s.button}
					onClick={toNextWeek}
				>
					&gt;
				</button>
			</div>

			{isOpen && (
				<FloatingFocusManager
					context={context}
					modal={false}
				>
					<div
						ref={refs.setFloating}
						style={{
							...floatingStyles,
							zIndex: 'var(--z-index-floating)',
						}}
						aria-labelledby={headingId}
						{...getFloatingProps()}
					>
						<DatePicker
							locale={'ru'}
							selected={selectedDate}
							onChange={handleOnChange}
							minDate={noPrevious ? MIN_DATE : undefined}
							inline
						/>
					</div>
				</FloatingFocusManager>
			)}
		</>
	);
};
