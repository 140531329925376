import { useGetWorkTimeMonthLimitFindQuery } from '@/app/redux/queries/time-service/time_serviceAPI';
import { WorkTimeMonthLimitInfo } from '@/app/redux/queries/time-service/types/types';
import { LIST_OF_MONTHS_NAMES } from '@/shared/consts/consts';
import { SelectedYear } from '../../types';

export const useGetSelectedYearInfo = (year: number): SelectedYear | null => {
	const selectedYear: SelectedYear = {
		year,
		months: [],
	};

	const { data: monthLimitData } = useGetWorkTimeMonthLimitFindQuery({
		year,
		takecount: 1000, // ! HARDCODE
		skipcount: 0, // ! HARDCODE
	});

	const monthLimits = monthLimitData?.body;

	let yearInfo: WorkTimeMonthLimitInfo[] = [];

	if (monthLimits) {
		yearInfo = [...monthLimits].sort((a, b) => a.month - b.month);

		selectedYear.months = yearInfo.map((info, index) => ({
			name: LIST_OF_MONTHS_NAMES[index],
			holidays: info.holidays,
		}));

		return selectedYear;
	} else {
		return null;
	}
};
