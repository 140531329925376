import { HTMLAttributes, PropsWithChildren, useState } from 'react';
import { useFloatingWrapper } from '@/shared/hooks/useFloatingWrapper';
import { CollapseArrows } from '../../../CollapseArrows/CollapseArrows';
import { OptionsWindow } from '../../../_option_lists/OptionsWindow/OptionsWindow';
import { ErrorWithLimit } from '../../_shared/ErrorWithLimit/ErrorWithLimit';
import _input_with_options from '../../_styles/_input_with_options.module.scss';
import { TextInput } from '../../text_Inputs/TextInput';

interface Props<T, Key> extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
	label?: string;
	placeholder?: string;
	keyNames: {
		name: Key;
		value: Key;
		tooltipText?: string;
	};
	searchSubstring: string;
	onStringChange: (value: string) => void;
	selectedOption: T;
	setSelectedOption: (value: T) => void;
	options: T[];
	errorMessage?: string;
	isNullable?: boolean;
	disabled?: boolean;
	required?: boolean;
}

export const SelectSingleCustomValue = <T extends { [key: string]: any }, Key extends keyof T>(props: PropsWithChildren<Props<T, Key>>) => {
	const {
		label, //
		placeholder,
		keyNames,
		searchSubstring,
		onStringChange,
		selectedOption,
		setSelectedOption,
		options,
		errorMessage,
		isNullable,
		disabled,
		required,
		...inputProps
	} = props;

	const [scrollItem, setScrollItem] = useState(options.findIndex(option => option.id === selectedOption.id)); // Авто-скрол на выбранный элемент

	// * User actions
	const [collapsed, setCollapsed] = useState(true);

	const emptyValue = {
		[keyNames.name]: null,
		[keyNames.value]: null,
	};

	const onOptionClick = (value: T) => {
		if (value[keyNames.value] === selectedOption[keyNames.value]) {
			isNullable && setSelectedOption(emptyValue as T);
			isNullable && onStringChange('');
		} else {
			setSelectedOption(value);
			const foundOption = options.find(option => option.name.toLowerCase().includes(value.name.toLowerCase().trim()));
			const optionIndex = foundOption ? options.findIndex(option => option[keyNames.value] === foundOption[keyNames.value]) : 0;
			setScrollItem(optionIndex);
		}
		setCollapsed(true);
	};

	// * String change
	const handleStringChange = (value: string) => {
		onStringChange(value);
	};

	// * Floating
	const { floatingStyles, refs, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(!collapsed, (value: any) => !disabled && setCollapsed(!value));

	// * Render
	return (
		<div className={_input_with_options.container}>
			<TextInput
				{...inputProps}
				label={label}
				placeholder={placeholder}
				value={selectedOption[keyNames.value] ? selectedOption[keyNames.name] : searchSubstring}
				onClick={() => collapsed && setCollapsed(false)}
				onChange={event => handleStringChange(event.currentTarget.value)}
				required={required}
				RightIcon={
					<CollapseArrows
						isOpen={!collapsed}
						arrowType="empty"
						onClick={() => setCollapsed(!collapsed)}
					/>
				}
				ref={refs.setReference}
				{...getReferenceProps()}
			/>

			<div
				className={_input_with_options.options}
				ref={refs.setFloating}
				style={{
					...floatingStyles,
					zIndex: 'var(--z-index-floating)',
				}}
				aria-labelledby={headingId}
				{...getFloatingProps()}
			>
				{!collapsed && (
					<OptionsWindow
						keyNames={keyNames}
						options={options}
						selectedOptions={[selectedOption]}
						onOptionClick={onOptionClick}
						scrollToItem={scrollItem}
					/>
				)}
			</div>

			{errorMessage && <ErrorWithLimit errorMessage={errorMessage} />}
		</div>
	);
};
