import { GridOption } from '@/pages/_surveys/pages/NewSurveyPage/_types';

// Получить все строки, без повторений
export const getGridRows = (gridOptions: GridOption[]) => {
	const rows = gridOptions.reduce((acc: GridOption[], option) => {
		const isExistRow = acc.find(opt => opt.rowSignature === option.rowSignature && opt.columnSignature !== option.columnSignature);

		if (isExistRow) return acc;

		return [...acc, { ...option }];
	}, []);

	return rows;
};
