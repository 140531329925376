import { Hours, Minutes, TimeInterval } from '@/shared/lib/date/types';
import { DateTime } from 'luxon';

export const getTimeInterval = (startTime: string, endTime: string): TimeInterval => {
	const startTimeLuxon = DateTime.fromISO(`${startTime}`);
	const endTimeLuxon = DateTime.fromISO(`${endTime}`);

	return {
		start: [startTimeLuxon.hour as Hours, startTimeLuxon.minute as Minutes],
		end: [endTimeLuxon.hour as Hours, endTimeLuxon.minute as Minutes],
	};
};
