import React, { FormEvent, MouseEvent, useRef, useState } from 'react';
import s from './NewEmployee.module.scss';
import { Button } from '@/shared/ui/_buttons/Button';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { TextArea } from '@/shared/ui/_inputs/text_Inputs/TextArea';
import { CroppableAvatar } from '@/shared/ui/CroppableAvatar/CroppableAvatar';
import { NewEmployeeTemplate } from '../../components/NewEmployeeTemplate/NewEmployeeTemplate';
import Download from '@/shared/assets/svg/files/download.svg?react';
import * as htmlToImage from 'html-to-image';
import { admin_serviceAPI } from '@/app/redux/queries/admin-service/admin_serviceAPI';

export interface InputTemplate {
	type: string;
	label: string;
	value?: string;
	placeholder: string;
	inline?: boolean;
	length?: number;
}

const initialInputs: InputTemplate[] = [
	{
		type: 'input',
		label: 'Имя',
		placeholder: 'Введите имя сотрудника',
		inline: true,
		value: '',
	},
	{
		type: 'input',
		label: 'Фамилия',
		placeholder: 'Введите фамилию сотрудника',
		inline: true,
		value: '',
	},
	{
		type: 'input',
		label: 'Должность',
		placeholder: 'Введите должность сотрудника',
		inline: true,
		value: '',
	},
	{
		type: 'input',
		label: 'Проект',
		placeholder: 'Введите проект сотрудника',
		inline: true,
		value: '',
	},
	{
		type: 'textarea',
		label: 'Образование',
		placeholder: 'Введите образование сотрудника',
		length: 280,
		value: '',
	},
	{
		type: 'textarea',
		label: 'Цитата',
		placeholder: 'Введите цитату сотрудника',
		length: 220,
		value: '',
	},
	{
		type: 'textarea',
		label: 'Увлечения',
		placeholder: 'Введите увлечения сотрудника',
		length: 150,
		value: '',
	},
];

export const NewEmployee: React.FC = () => {
	const [newEmployeeValues, setNewEmployeeValues] = useState<InputTemplate[]>(initialInputs);
	const [imageSrc, setImageSrc] = useState<string>('');
	const imageRef = useRef<HTMLDivElement>(null);

	const [putActionCounts] = admin_serviceAPI.usePutActionCountsMutation();

	const onFieldChangeValue = (value: string, index: number) => {
		setNewEmployeeValues(prevState => {
			const prevStateCopy = [...prevState];

			prevStateCopy[index] = { ...prevStateCopy[index], value };

			return prevStateCopy;
		});
	};

	const clearInputs = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setNewEmployeeValues(initialInputs);
		setImageSrc('');
	};

	const renderInputs = () => {
		return newEmployeeValues.map((field, index) => {
			const fieldProps = {
				name: `${field.label}[${index}].name`,
				label: field.label,
				placeholder: field.placeholder,
				value: field.value,
			};

			switch (field.type) {
				case 'input':
					return (
						<div className={s.inputBlock}>
							<TextInput
								{...fieldProps}
								key={`${field.label}[${index}].name`}
								onChange={e => onFieldChangeValue(e.target.value, index)}
							/>
						</div>
					);
				case 'textarea':
					return (
						<div className={s.textAreaBlock}>
							<TextArea
								{...fieldProps}
								size="small"
								characterLimit={field.length}
								key={`${field.label}[${index}].name`}
								onChange={e => onFieldChangeValue((e.target as HTMLTextAreaElement).value, index)}
							/>
						</div>
					);
			}
		});
	};

	const downLoadImageOnSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const dataUrl = await htmlToImage.toJpeg(imageRef.current!, {
			quality: 1,
			pixelRatio: 2,
		});

		const link = document.createElement('a');
		link.href = dataUrl;
		link.setAttribute('download', `${newEmployeeValues[0].value} ${newEmployeeValues[1].value}`);
		document.body.appendChild(link);

		link.click();

		link.parentNode?.removeChild(link);

		putActionCounts({ actionType: 'NewWorkerMentionCreated' })
			.unwrap()
			.catch(err => console.log(err));
	};

	const getImage = (src: string) => setImageSrc(src);

	return (
		<div className={s.newEmployee}>
			<form
				onSubmit={downLoadImageOnSubmit}
				className={s.form}
			>
				<div className={s.avatarWrapper}>
					<CroppableAvatar
						height={228}
						width={228}
						rounded={true}
						getImage={getImage}
					/>
				</div>

				{renderInputs()}

				<div className={s.buttonRow}>
					<Button
						variant="tertiary"
						onClick={clearInputs}
						type="button"
						wide={true}
					>
						Сбросить и начать заново
					</Button>

					<Button
						variant="primary"
						wide={true}
						type="submit"
						LeftIcon={<Download />}
					>
						Скачать в формате JPG
					</Button>
				</div>
			</form>

			<NewEmployeeTemplate
				refData={imageRef}
				image={imageSrc}
				data={newEmployeeValues}
			/>
		</div>
	);
};
