import { createColumnHelper } from '@tanstack/react-table';
import { ProjectInfo } from '@/app/redux/queries/project-service/types/types';
import { ColorCircle } from '@/entities/ColorCircle';

export type TableDataItem = Pick<ProjectInfo, 'id' | 'name' | 'shortName' | 'customer' | 'usersCount' | 'status'>;

const columnHelper = createColumnHelper<TableDataItem>();

export const COLUMNS = [
	columnHelper.accessor('name', {
		header: 'НАЗВАНИЕ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('shortName', {
		header: 'СОКРАЩЕННОЕ НАЗВАНИЕ',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('customer', {
		header: 'ЗАКАЗЧИК',
		cell: info => info.getValue(),
	}),
	columnHelper.accessor('usersCount', {
		header: 'КОЛ-ВО СОТРУДНИКОВ',
		cell: info => `${info.getValue()} чел.`,
	}),
	columnHelper.accessor('status', {
		header: 'СТАТУС',
		cell: info => (
			<ColorCircle
				customColor={(function () {
					switch (info.getValue()) {
						case 'Active':
							return 'var(--success-dark)';
						case 'Suspend':
							return 'var(--warning-dark)';
						case 'Closed':
							return 'var(--error-dark)';
					}
				})()}
			/>
		),
	}),
	// columnHelper.accessor('extra', {
	// 	header: () => null,
	// 	cell: info => info.getValue()
	// })
];
