import cn from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { Tooltip } from '@/shared/ui/_tooltips/Tooltip/Tooltip';
import { CollapseArrows } from '@/shared/ui/CollapseArrows';
import { useVacationPageContext } from '../../../../context/useGroupUsersContext';
import { useGetGroupUserRights } from '../../../../hooks/useGetGroupUserRights';
import { getTableHeight } from '../../../../lib/getTableHeight/getTableHeight';
import { User } from '../../../../types';
import { DIVIDER_WIDTH } from '../../consts';
import { Column, ColumnId, SortBy } from '../../types';
import { VACATION_TOOLTIP_TEXT, VacationTooltipKey } from './consts/consts';
import s from './UsersTable.module.scss';

interface Props {
	users: {
		mainUser: User[] | undefined;
		otherUsers: User[];
	};
	columns: Column[];
	sortBy: { ascending: boolean; by: SortBy } | null;
	setSortBy: (sortBy: { ascending: boolean; by: SortBy } | null) => void;
}

export const UsersTable: FC<Props> = props => {
	const {
		users, //
		columns,
		sortBy,
		setSortBy,
	} = props;

	// * Ref
	const tableRef = useRef<HTMLDivElement>(null);

	const rootFontSize = parseFloat(getComputedStyle(document.documentElement).font);

	// * Rights
	const { hasExtendedRights, activeUserId, isPlanConfirmed, isDepartment } = useGetGroupUserRights();
	const hasRightsToEdit = (userId: string) => (hasExtendedRights || activeUserId === userId) && !isPlanConfirmed && isDepartment;

	// * Context
	const { setPlannedVacationUserId: setActiveUserId, setActiveLeftDaysModal } = useVacationPageContext();

	const onHeaderClick = (columnId: ColumnId) => {
		if (columnId !== 'days') {
			if (sortBy?.by === columnId) {
				setSortBy({ ...sortBy, ascending: !sortBy.ascending });
			} else {
				setSortBy({ by: columnId, ascending: false });
			}
		}
	};

	const isCellOverflown = (column: Column, text: string | null) => {
		if (!text || !tableRef.current) return;

		const columnFont = parseFloat(getComputedStyle(tableRef.current).font);
		const columnWidth = column.width * rootFontSize;
		const textWidth = text.length * columnFont * 0.6;
		return textWidth > columnWidth;
	};

	const [isMount, setIsMount] = useState(false);

	useEffect(() => {
		if (tableRef.current) setIsMount(true);
	}, []);

	// * Render
	return (
		<div
			className={s.container}
			ref={tableRef}
		>
			{columns.map(column => (
				<div
					key={column.id}
					className={cn(s.column, { [s.activeUser]: !!users?.mainUser })}
					style={{ width: column.width + 'rem' }}
				>
					<div className={s.header}>
						<div className={s.row} />
						<div
							className={cn(s.row, s.row__header, column.id !== 'days' && s.row_clickable)}
							onClick={() => onHeaderClick(column.id)}
						>
							{column.name}
							{column.id !== 'days' && (
								<CollapseArrows
									isOpen={sortBy?.by === column.id && !sortBy.ascending ? false : true}
									arrowType="empty"
								/>
							)}
						</div>
					</div>

					{column.id === 'days'
						? Object.values(users).map(userList =>
								userList?.map(user => (
									<div
										key={user.id}
										className={s.row}
									>
										{(Object.keys(VACATION_TOOLTIP_TEXT) as VacationTooltipKey[]).map(dayType => (
											<Tooltip
												key={dayType}
												content={VACATION_TOOLTIP_TEXT[dayType]}
												type="custom"
											>
												<div
													className={cn(s.cell, s.cell__day, { [s.cell_clickable]: dayType === 'left' && hasRightsToEdit(user.id) })}
													onClick={() => dayType === 'left' && hasRightsToEdit(user.id) && setActiveLeftDaysModal(user.id)}
												>
													{user.days[dayType]}
												</div>
											</Tooltip>
										))}
									</div>
								)),
							)
						: Object.values(users).map(userList =>
								userList?.map(user => (
									<div
										key={user.id}
										className={cn(s.cell, {
											[s.cell_clickable]: column.id === 'name' && hasRightsToEdit(user.id),
											[s.cell__user]: column.id === 'name',
										})}
										onClick={() => column.id === 'name' && hasRightsToEdit(user.id) && setActiveUserId(user.id)}
									>
										{(() => {
											const rowValue = user[column.id] as string;
											return isMount && column.id !== 'name' && user[column.id] && isCellOverflown(column, rowValue) ? (
												<Tooltip
													content={rowValue}
													type="custom"
												>
													{rowValue}
												</Tooltip>
											) : (
												rowValue
											);
										})()}
									</div>
								)),
							)}
				</div>
			))}

			<div
				className={s.divider}
				style={{
					height: getTableHeight(users),
					width: DIVIDER_WIDTH + 'rem',
				}}
			/>
		</div>
	);
};
