import cn from 'classnames';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { CategoryInfo, EventInfo, UserBirthdayInfo } from '@/app/redux/queries/event-service/types/types';
import { EMPTY_CATEGORY } from '@/pages/_eventСalendar/consts';
import LeftSVG from '@/shared/assets/svg_icons/arrows/left_1.svg?react';
import RightSVG from '@/shared/assets/svg_icons/arrows/right_1.svg?react';
import { isDateTheSame } from '@/shared/lib/date';
import { isDateInRangeEvent } from '../../../../lib';
import { CalendarEvent } from '../CalendarEvent';
import { ImageListShort } from '../ImageListShort';
import s from './Calendar.module.scss';

interface Props {
	events: EventInfo[];
	birthdays: UserBirthdayInfo[];
	month: number;
	year: number;
	selectedDate: Date;
	onNextMonth: () => void;
	onPreviousMonth: () => void;
	onChangeDate: (date: Date) => void;
}

export const Calendar: React.FC<Props> = props => {
	const {
		events, //
		birthdays,
		month,
		year,
		selectedDate,
		onNextMonth,
		onPreviousMonth,
		onChangeDate,
	} = props;

	// - Month
	const [monthText, setMonthText] = useState(
		new Date()
			.toLocaleString('ru-RU', {
				month: 'long',
				year: 'numeric',
			})
			.replace('г.', ''),
	);

	useEffect(() => {
		setMonthText(
			new Date(`${year}-${month + 1}`)
				.toLocaleString('ru-RU', {
					month: 'long',
					year: 'numeric',
				})
				.replace('г.', ''),
		);
	}, [month]);

	const renderEventsForTheDay = useCallback(
		(events: EventInfo[], currentDate: Date) => {
			const currentDateEvents = events.filter(event => isDateInRangeEvent(currentDate, new Date(event.date), event.endDate ? new Date(event.endDate) : null));
			if (currentDateEvents.length === 0) return null;

			return (
				<div className={s.eventAndBirthday}>
					{currentDateEvents.map((event, index) => {
						if (index <= 3) {
							return (
								<CalendarEvent
									key={event.id}
									category={event.eventsCategories?.length ? event.eventsCategories : ([EMPTY_CATEGORY] as CategoryInfo[])}
								/>
							);
						}
					})}
				</div>
			);
		},
		[events],
	);

	const displayCalendar = (year: number, month: number) => {
		const firstDay = new Date(year, month, 1);
		const firstDayIndex = firstDay.getDay() === 0 ? 6 : firstDay.getDay() - 1;
		const lastDay = new Date(year, month + 1, 0);
		const numberOfDays = lastDay.getDate();
		let dateToRender = 0;

		return Array.from(Array(numberOfDays + firstDayIndex).keys()).map(item => {
			const currentDate = new Date(year, month, item + 1 - firstDayIndex);
			const currentDateString = DateTime.fromJSDate(currentDate).toFormat('dd.MM');
			const isSelectedDate = isDateTheSame(selectedDate, currentDate);
			const isWeekend = currentDate.getDay() === 0 || currentDate.getDay() === 6;
			const isSelectedMonth = currentDate.getMonth() === firstDay.getMonth();

			const usersBirthday = birthdays
				.filter(birthday => birthday.dateOfBirth.startsWith(currentDateString))
				.map(item => ({
					image: item.imageId,
					name: item.lastName + ' ' + item.name,
				}));

			if (
				currentDate.getFullYear() === new Date().getFullYear() && //
				currentDate.getMonth() === new Date().getMonth() &&
				currentDate.getDate() === new Date().getDate() &&
				isSelectedMonth
			) {
				dateToRender += 1;

				return (
					<div
						key={item}
						className={s.day_wrapper}
					>
						<div
							className={cn(s.day, isWeekend && s.weekend, isSelectedDate ? s.selectedDate : s.currentDate)}
							onClick={() => isSelectedMonth && onChangeDate(currentDate)}
						>
							<p className={s.dayNumber}>{dateToRender}</p>
							<div>
								{usersBirthday.length > 0 && <ImageListShort list={usersBirthday} />}
								{events.length > 0 && renderEventsForTheDay(events, currentDate)}
							</div>
						</div>
					</div>
				);
			}

			if (item > firstDayIndex - 1) dateToRender += 1;

			return (
				<div
					key={item}
					className={cn(item > firstDayIndex - 1 && s.day_wrapper)}
				>
					<div
						className={cn(item > firstDayIndex - 1 && s.day, isWeekend && s.weekend, isSelectedMonth && isSelectedDate && s.selectedDate)}
						onClick={() => isSelectedMonth && onChangeDate(currentDate)}
					>
						<p className={s.dayNumber}>{item <= firstDayIndex - 1 ? '' : dateToRender}</p>
						{item > firstDayIndex - 1 && (
							<div>
								{usersBirthday.length > 0 && <ImageListShort list={usersBirthday} />}
								{events.length > 0 && renderEventsForTheDay(events, currentDate)}
							</div>
						)}
					</div>
				</div>
			);
		});
	};

	// * Render
	return (
		<div className={s.container}>
			<div className={s.calendar}>
				<header>
					<div
						className={s.arrow}
						onClick={onPreviousMonth}
					>
						<LeftSVG />
					</div>

					<div className={s.headerDisplay}>
						<p className={s.display}>{monthText}</p>
					</div>

					<div
						className={s.arrow}
						onClick={onNextMonth}
					>
						<RightSVG />
					</div>
				</header>

				<div className={s.week}>
					<div>Пн</div>
					<div>Вт</div>
					<div>Ср</div>
					<div>Чт</div>
					<div>Пт</div>
					<div>Сб</div>
					<div>Вс</div>
				</div>

				<div className={s.days}>{displayCalendar(year, month)}</div>
			</div>
		</div>
	);
};
