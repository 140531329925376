import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageDecorator } from '@/app/decorators/page_decorators';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { VacationPageContextProvider } from '../../context/useGroupUsersContext';
import { useGetGroupUsers } from '../../hooks/useGetGroupUsers';
import { User } from '../../types';
import { LeftVacationDaysMC } from '../_modals/LeftVacationDaysMC/LeftVacationDaysMC';
import { PlannedVacationMC } from '../_modals/PlannedVacationMC';
import { Filters } from '../Filters';
import { Groups } from '../Groups';
import { Header } from '../Header';
import { SubHeader } from '../SubHeader';
import { VacationTable } from '../VacationTable';

export const VacationPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (!searchParams.get('groupId') && !searchParams.get('year')) {
			searchParams.set('groupId', 'null');
			searchParams.set('year', String(new Date().getFullYear().toString()));
			setSearchParams(searchParams);
		}
	}, []);

	const groupId = searchParams.get('groupId');

	const { isLoading, isLoadingSuccess, refetchGroup, users } = useGetGroupUsers(groupId);

	// * Planned vacation modal
	const [plannedVacationUserId, setPlannedVacationUserId] = useState<string | null>(null);
	const [activeLeftDaysModal, setActiveLeftDaysModal] = useState<string | null>(null);

	const closeUserModal = () => setPlannedVacationUserId(null);
	const closeLeftDaysModal = () => setActiveLeftDaysModal(null);

	// * Filtered users
	const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
	const [isFiltersApplied, setIsFiltersApplied] = useState<boolean>(false);

	// * Render
	return (
		<PageDecorator>
			<VacationPageContextProvider
				users={isFiltersApplied ? filteredUsers : users} // ! isFiltersApplied Предотвращает лишний рендер при первой загрузке группы.
				setFilteredUsers={setFilteredUsers}
				isLoading={isLoading}
				isLoadingSuccess={isLoadingSuccess}
				plannedVacationUserId={plannedVacationUserId}
				setPlannedVacationUserId={setPlannedVacationUserId}
				activeLeftDaysModal={activeLeftDaysModal}
				setActiveLeftDaysModal={setActiveLeftDaysModal}
				searchParams={searchParams}
				setSearchParams={setSearchParams}
			>
				{plannedVacationUserId && (
					<ModalNewProto
						isOpen={!!plannedVacationUserId}
						onClose={closeUserModal}
						width="s"
					>
						<PlannedVacationMC
							userId={plannedVacationUserId}
							toggleModal={closeUserModal}
							refetchGroup={refetchGroup}
						/>
					</ModalNewProto>
				)}

				{activeLeftDaysModal && (
					<ModalNewProto
						isOpen={!!activeLeftDaysModal}
						onClose={closeLeftDaysModal}
						width="s"
					>
						<LeftVacationDaysMC
							userId={activeLeftDaysModal}
							toggleModal={closeLeftDaysModal}
							refetchGroup={refetchGroup}
						/>
					</ModalNewProto>
				)}

				<Header />
				<Groups />
				<SubHeader />
				<Filters
					users={users}
					setIsFiltersApplied={setIsFiltersApplied}
				/>
				<VacationTable />
			</VacationPageContextProvider>
		</PageDecorator>
	);
};
