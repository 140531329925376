import { createApi } from '@reduxjs/toolkit/query/react';
import { ServiceName } from '@/app/api/api_types';
import { baseQueryWithReAuth } from '@/app/api/apiSlice';
import {
	DeleteGroupsByGroupIdApiArg,
	DeleteGroupsByGroupIdConfirmationsApiArg,
	DeleteGroupsByGroupIdSubgroupsAndSubgroupIdApiArg,
	DeleteVacationsByVacationIdApiArg,
	GetGroupsApiArg,
	GetGroupsByGroupIdApiArg,
	GetGroupsByGroupIdConfirmationsApiArg,
	GetGroupsByGroupIdSubgroupsAndSubgroupIdApiArg,
	GetGroupsByGroupIdSubgroupsApiArg,
	GetGroupsPersonalApiArg,
	GetUsersApiArg,
	GetVacationsApiArg,
	PatchGroupsByGroupIdApiArg,
	PatchGroupsByGroupIdSubgroupsAndSubgroupIdApiArg,
	PostEmailsApiArg,
	PostGroupsApiArg,
	PostGroupsByGroupIdConfirmationsApiArg,
	PostGroupsByGroupIdSubgroupsApiArg,
	PostImportDepartmentsByDepartmentIdApiArg,
	PostImportProjectsByProjectIdApiArg,
	PostVacationsApiArg,
	PutGroupsByGroupIdApiArg,
	PutGroupsByGroupIdSubgroupsAndSubgroupIdApiArg,
	PutUsersByUserIdApiArg,
	PutUsersByUserIdVacationsApiArg,
	PutVacationsByVacationIdApiArg,
} from './types/reqTypes';
import {
	DeleteGroupsByGroupIdApiResponse,
	DeleteGroupsByGroupIdConfirmationsApiResponse,
	DeleteGroupsByGroupIdSubgroupsAndSubgroupIdApiResponse,
	DeleteVacationsByVacationIdApiResponse,
	GetGroupsApiResponse,
	GetGroupsByGroupIdApiResponse,
	GetGroupsByGroupIdConfirmationsApiResponse,
	GetGroupsByGroupIdSubgroupsAndSubgroupIdApiResponse,
	GetGroupsByGroupIdSubgroupsApiResponse,
	GetGroupsPersonalApiResponse,
	GetUsersApiResponse,
	GetVacationsApiResponse,
	PatchGroupsByGroupIdApiResponse,
	PatchGroupsByGroupIdSubgroupsAndSubgroupIdApiResponse,
	PostEmailsApiResponse,
	PostGroupsApiResponse,
	PostGroupsByGroupIdConfirmationsApiResponse,
	PostGroupsByGroupIdSubgroupsApiResponse,
	PostImportDepartmentsByDepartmentIdApiResponse,
	PostImportProjectsByProjectIdApiResponse,
	PostVacationsApiResponse,
	PutGroupsByGroupIdApiResponse,
	PutGroupsByGroupIdSubgroupsAndSubgroupIdApiResponse,
	PutUsersByUserIdApiResponse,
	PutUsersByUserIdVacationsApiResponse,
	PutVacationsByVacationIdApiResponse,
} from './types/resTypes';

const reducerPath = `${ServiceName.VACATION_SERVICE}` as const;

export const vacation_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Confirmations', 'Emails', 'Groups', 'Import', 'Subgroups', 'Users', 'Vacations'] as const,
	endpoints: build => ({
		postGroupsByGroupIdConfirmations: build.mutation<PostGroupsByGroupIdConfirmationsApiResponse, PostGroupsByGroupIdConfirmationsApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups/${queryArg.groupId}/confirmations`, method: 'POST', params: { year: queryArg.year } }),
			invalidatesTags: ['Confirmations'],
		}),
		deleteGroupsByGroupIdConfirmations: build.mutation<DeleteGroupsByGroupIdConfirmationsApiResponse, DeleteGroupsByGroupIdConfirmationsApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups/${queryArg.groupId}/confirmations`, method: 'DELETE', params: { year: queryArg.year } }),
			invalidatesTags: ['Confirmations'],
		}),
		getGroupsByGroupIdConfirmations: build.query<GetGroupsByGroupIdConfirmationsApiResponse, GetGroupsByGroupIdConfirmationsApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups/${queryArg.groupId}/confirmations` }),
			providesTags: ['Confirmations'],
		}),
		postEmails: build.mutation<PostEmailsApiResponse, PostEmailsApiArg>({
			query: queryArg => ({ url: `${reducerPath}/emails`, method: 'POST', body: queryArg.sendEmailRequest }),
			invalidatesTags: ['Emails'],
		}),
		postGroups: build.mutation<PostGroupsApiResponse, PostGroupsApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups`, method: 'POST', body: queryArg.groupBody }),
			invalidatesTags: ['Groups'],
		}),
		getGroups: build.query<GetGroupsApiResponse, GetGroupsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/groups`,

				params: {
					includeSubgroups: queryArg.includeSubgroups,
					nameIncludeSubstring: queryArg.nameIncludeSubstring,
					shortNameIncludeSubstring: queryArg.shortNameIncludeSubstring,
					isAscendingSortByCreatedAtUtc: queryArg.isAscendingSortByCreatedAtUtc,
					isAscendingSortByModifiedAtUtc: queryArg.isAscendingSortByModifiedAtUtc,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
					departmentId: queryArg.departmentId,
					projectId: queryArg.projectId,
					isCustom: queryArg.isCustom,
					isConfirmed: queryArg.isConfirmed,
				},
			}),
			providesTags: ['Groups'],
		}),
		putGroupsByGroupId: build.mutation<PutGroupsByGroupIdApiResponse, PutGroupsByGroupIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups/${queryArg.groupId}`, method: 'PUT', body: queryArg.groupBody }),
			invalidatesTags: ['Groups'],
		}),
		deleteGroupsByGroupId: build.mutation<DeleteGroupsByGroupIdApiResponse, DeleteGroupsByGroupIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups/${queryArg.groupId}`, method: 'DELETE' }),
			invalidatesTags: ['Groups'],
		}),
		patchGroupsByGroupId: build.mutation<PatchGroupsByGroupIdApiResponse, PatchGroupsByGroupIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups/${queryArg.groupId}`, method: 'PATCH', body: queryArg.body }),
			invalidatesTags: ['Groups'],
		}),
		getGroupsByGroupId: build.query<GetGroupsByGroupIdApiResponse, GetGroupsByGroupIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups/${queryArg.groupId}` }),
			providesTags: ['Groups'],
		}),
		getGroupsPersonal: build.query<GetGroupsPersonalApiResponse, GetGroupsPersonalApiArg>({
			query: () => ({ url: `${reducerPath}/groups/personal` }),
			providesTags: ['Groups'],
		}),
		postImportDepartmentsByDepartmentId: build.mutation<PostImportDepartmentsByDepartmentIdApiResponse, PostImportDepartmentsByDepartmentIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/import/departments/${queryArg.departmentId}`, method: 'POST' }),
			invalidatesTags: ['Import'],
		}),
		postImportProjectsByProjectId: build.mutation<PostImportProjectsByProjectIdApiResponse, PostImportProjectsByProjectIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/import/projects/${queryArg.projectId}`, method: 'POST' }),
			invalidatesTags: ['Import'],
		}),
		postGroupsByGroupIdSubgroups: build.mutation<PostGroupsByGroupIdSubgroupsApiResponse, PostGroupsByGroupIdSubgroupsApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups/${queryArg.groupId}/subgroups`, method: 'POST', body: queryArg.subgroupBody }),
			invalidatesTags: ['Subgroups'],
		}),
		getGroupsByGroupIdSubgroups: build.query<GetGroupsByGroupIdSubgroupsApiResponse, GetGroupsByGroupIdSubgroupsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/groups/${queryArg.groupId}/subgroups`,
				params: { nameIncludeSubstring: queryArg.nameIncludeSubstring, skipcount: queryArg.skipcount, takecount: queryArg.takecount },
			}),
			providesTags: ['Subgroups'],
		}),
		putGroupsByGroupIdSubgroupsAndSubgroupId: build.mutation<PutGroupsByGroupIdSubgroupsAndSubgroupIdApiResponse, PutGroupsByGroupIdSubgroupsAndSubgroupIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups/${queryArg.groupId}/subgroups/${queryArg.subgroupId}`, method: 'PUT', body: queryArg.subgroupBody }),
			invalidatesTags: ['Subgroups'],
		}),
		deleteGroupsByGroupIdSubgroupsAndSubgroupId: build.mutation<DeleteGroupsByGroupIdSubgroupsAndSubgroupIdApiResponse, DeleteGroupsByGroupIdSubgroupsAndSubgroupIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups/${queryArg.groupId}/subgroups/${queryArg.subgroupId}`, method: 'DELETE' }),
			invalidatesTags: ['Subgroups'],
		}),
		patchGroupsByGroupIdSubgroupsAndSubgroupId: build.mutation<PatchGroupsByGroupIdSubgroupsAndSubgroupIdApiResponse, PatchGroupsByGroupIdSubgroupsAndSubgroupIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups/${queryArg.groupId}/subgroups/${queryArg.subgroupId}`, method: 'PATCH', body: queryArg.body }),
			invalidatesTags: ['Subgroups'],
		}),
		getGroupsByGroupIdSubgroupsAndSubgroupId: build.query<GetGroupsByGroupIdSubgroupsAndSubgroupIdApiResponse, GetGroupsByGroupIdSubgroupsAndSubgroupIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/groups/${queryArg.groupId}/subgroups/${queryArg.subgroupId}` }),
			providesTags: ['Subgroups'],
		}),
		putUsersByUserId: build.mutation<PutUsersByUserIdApiResponse, PutUsersByUserIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/users/${queryArg.userId}`, method: 'PUT', body: queryArg.userBody }),
			invalidatesTags: ['Users'],
		}),
		getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
			query: () => ({ url: `${reducerPath}/users` }),
			providesTags: ['Users'],
		}),
		postVacations: build.mutation<PostVacationsApiResponse, PostVacationsApiArg>({
			query: queryArg => ({ url: `${reducerPath}/vacations`, method: 'POST', body: queryArg.vacationRequest }),
			invalidatesTags: ['Vacations'],
		}),
		getVacations: build.query<GetVacationsApiResponse, GetVacationsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/vacations`,

				params: {
					groupId: queryArg.groupId,
					subgroupIds: queryArg.subgroupIds,
					userId: queryArg.userId,
					'vacation-type': queryArg['vacation-type'],
					startTime: queryArg.startTime,
					endTime: queryArg.endTime,
					isAscendingSort: queryArg.isAscendingSort,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Vacations'],
		}),
		putUsersByUserIdVacations: build.mutation<PutUsersByUserIdVacationsApiResponse, PutUsersByUserIdVacationsApiArg>({
			query: queryArg => ({ url: `${reducerPath}/users/${queryArg.userId}/vacations`, method: 'PUT', body: queryArg.updateVacationRequest }),
			invalidatesTags: ['Vacations'],
		}),
		putVacationsByVacationId: build.mutation<PutVacationsByVacationIdApiResponse, PutVacationsByVacationIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/vacations/${queryArg.vacationId}`, method: 'PUT', body: queryArg.vacationBody }),
			invalidatesTags: ['Vacations'],
		}),
		deleteVacationsByVacationId: build.mutation<DeleteVacationsByVacationIdApiResponse, DeleteVacationsByVacationIdApiArg>({
			query: queryArg => ({ url: `${reducerPath}/vacations/${queryArg.vacationId}`, method: 'DELETE' }),
			invalidatesTags: ['Vacations'],
		}),
	}),
});

export const {
	usePostGroupsByGroupIdConfirmationsMutation,
	useDeleteGroupsByGroupIdConfirmationsMutation,
	useGetGroupsByGroupIdConfirmationsQuery,
	usePostEmailsMutation,
	usePostGroupsMutation,
	useGetGroupsQuery,
	usePutGroupsByGroupIdMutation,
	useDeleteGroupsByGroupIdMutation,
	usePatchGroupsByGroupIdMutation,
	useGetGroupsByGroupIdQuery,
	useGetGroupsPersonalQuery,
	usePostImportDepartmentsByDepartmentIdMutation,
	usePostImportProjectsByProjectIdMutation,
	usePostGroupsByGroupIdSubgroupsMutation,
	useGetGroupsByGroupIdSubgroupsQuery,
	usePutGroupsByGroupIdSubgroupsAndSubgroupIdMutation,
	useDeleteGroupsByGroupIdSubgroupsAndSubgroupIdMutation,
	usePatchGroupsByGroupIdSubgroupsAndSubgroupIdMutation,
	useGetGroupsByGroupIdSubgroupsAndSubgroupIdQuery,
	usePutUsersByUserIdMutation,
	useGetUsersQuery,
	usePostVacationsMutation,
	useGetVacationsQuery,
	usePutUsersByUserIdVacationsMutation,
	usePutVacationsByVacationIdMutation,
	useDeleteVacationsByVacationIdMutation,
} = vacation_serviceAPI;
