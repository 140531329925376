import React, { useState } from 'react';
import s from './UnitCard.module.scss';
import { DropDownMenu } from '@/shared/ui/DropDownMenu/DropDownMenu';
import EditSVG from '@/shared/assets/svg_icons/action/edit.svg?react';
import DeleteSVG from '@/shared/assets/svg_icons/action/delete.svg?react';
import DragDropSVG from '@/shared/assets/svg_icons/action/drag.svg?react';
import { CreateNewUnit } from '../CreateNewUnit/CreateNewUnit';
import { RemoveModalContent } from '@/pages/_companyStructure/components/RemoveModalContent/RemoveModalContent';
import { useRights } from '@/shared/hooks/useRights';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';

interface Props {
	edit?: boolean;
	isCustom?: boolean;
	id: string;
	name: string;
	fullname: string;
	description?: string;
	usersCount: number;
	onClick?: () => void;
}

export const UnitCard: React.FC<Props> = props => {
	const { edit, isCustom, id, name, fullname, description, usersCount, onClick } = props;

	// * Rights
	const isAdmin = useRights();

	// * Menu
	const DropDownItems = [
		{
			icon: <EditSVG />,
			title: 'Редактировать',
			action: () => toggleEditModal(),
		},
		{
			icon: <DeleteSVG />,
			title: 'Удалить',
			action: () => toggleRemoveModal(),
		},
	];

	// * Edit Modal
	const [showEditModal, setShowEditModal] = useState(false);
	const toggleEditModal = () => setShowEditModal(prevState => !prevState);

	// * Remove Modal
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const toggleRemoveModal = () => setShowRemoveModal(prevState => !prevState);

	return (
		<div
			onClick={onClick}
			className={`${s.container} ${edit ? '' : s.cursor}`}
		>
			{edit && <DragDropSVG className={s.icon} />}

			<div className={s.unit_wrapper}>
				<div className={s.header}>
					<div className={s.title}>{name}</div>
					{isCustom && isAdmin && (
						<div className={s.menu}>
							<DropDownMenu items={DropDownItems} />
						</div>
					)}
				</div>

				<div className={s.about}>
					<div className={s.position}>{isCustom ? 'Руководитель' : 'Директор'}</div>

					<div className={s.bottom_line}>
						<div className={s.fullname}>{fullname || '-'}</div>

						<div className={s.count}>{usersCount} чел.</div>
					</div>
				</div>
			</div>

			{showEditModal && (
				<ModalNewProto
					isOpen={showEditModal}
					onClose={toggleEditModal}
				>
					<CreateNewUnit
						values={{
							unitId: id,
							name: name,
							description: description || '',
						}}
						toggleModal={toggleEditModal}
					/>
				</ModalNewProto>
			)}

			{showRemoveModal && (
				<ModalNewProto
					isOpen={showRemoveModal}
					onClose={toggleRemoveModal}
				>
					<RemoveModalContent
						unit
						id={id}
						name={name}
						toggleRemoveModal={toggleRemoveModal}
					/>
				</ModalNewProto>
			)}
		</div>
	);
};
