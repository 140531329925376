import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { actionsAuth } from '@/app/redux/state/auth/slice';
import { userServiceActions } from '@/app/redux/state/user/actions';
import { useAppDispatch } from '@/app/redux/utils';
import LogoSVG from '@/shared/assets/svg/logo.svg?react';
import ArrowLeftSVG from '@/shared/assets/svg/navigation/Arrow_left_v1.svg?react';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import { Forgot } from '../Forgot/Forgot';
import { Login } from '../Login/Login';
import { Reactivate } from '../Reactivate/Reactivate';
import { Reset } from '../Reset/Reset';
import { Signup } from '../Signup/Signup';
import s from './AuthPage.module.scss';

type Keys = 'login' | 'signup' | 'forgot' | 'reset' | 'reactivate';

interface Props {
	type: Keys;
}

const authComponents: Record<Keys, ReactNode> = {
	login: <Login />,
	signup: <Signup />,
	forgot: <Forgot />,
	reset: <Reset />,
	reactivate: <Reactivate />,
};

export const AuthPage: React.FC<Props> = props => {
	const { type } = props;

	const navigate = useNavigate();

	// * SHARE - Set data when login happens on another domain.
	const dispatch = useAppDispatch();
	const { setUserInfo } = userServiceActions.user.actions;
	const { logout } = actionsAuth;

	useEffect(() => {
		dispatch(setUserInfo(null));
	}, []);

	useEffect(() => {
		dispatch(logout());
	}, []);

	// * Render
	return (
		<div className={s.AuthPage_container}>
			<div className={s.AuthPage_card}>
				<div className={s.AuthPage_header}>
					{type === 'forgot' && (
						<IconButton
							className={s.AuthPage_navigation}
							Icon={<ArrowLeftSVG />}
							onClick={() => navigate('/auth/login')}
						/>
					)}
					<LogoSVG className={s.AuthPage_logo} />
				</div>

				{authComponents[type]}
			</div>
		</div>
	);
};
