import React, { useState } from 'react';
import { User } from '@/app/redux/state/company-structure/users/types';
import DownArrowSVG from '@/shared/assets/svg_icons/arrows/down_2.svg?react';
import UpArrowSVG from '@/shared/assets/svg_icons/arrows/up_2.svg?react';
import { getRoleOrder } from '../../lib/getRoleOrder/getRoleOrder';
import { CompanyUserCard } from '../CompanyUserCard/CompanyUserCard';
import s from './CompanyUsersList.module.scss';

interface Props {
	users: User[];
	departmentName?: boolean;
	edit?: boolean;
	isCustomUnit?: boolean;
}

export const CompanyUsersList: React.FC<Props> = props => {
	const {
		users, //
		departmentName,
		edit = false,
		isCustomUnit,
	} = props;

	// * Sorting
	const [isAscendingSort, setIsAscendingSort] = useState(true);
	const handleSortChange = () => setIsAscendingSort(prev => !prev);

	const sortedUsers = (isAscendingSort ? [...users] : [...users].reverse()).sort((firstUser, secondUser) => {
		return getRoleOrder(firstUser) - getRoleOrder(secondUser);
	});

	const directorId = [...users].find(user => user.assignment === 'Director')?.id || '';

	// * Render
	return (
		<div>
			{users.length > 0 && (
				<div className={s.header}>
					<span
						onClick={handleSortChange}
						className={s.sort_wrapper}
					>
						ФИО
						{isAscendingSort ? <UpArrowSVG /> : <DownArrowSVG />}
					</span>
					<span className={s.department_name}>
						{departmentName && <span>Структурная единица</span>}
						<span>Контакты</span>
					</span>
				</div>
			)}

			<div className={s.users_list}>
				{sortedUsers.map(user => {
					return (
						<CompanyUserCard
							key={user.id}
							directorId={directorId}
							user={user}
							departmentName={departmentName ? user.departmentName || '-' : ''}
							isCustomUnit={isCustomUnit}
							RightComponent={edit && user.isCustom}
						/>
					);
				})}
			</div>
		</div>
	);
};
