import { useNavigate } from 'react-router-dom';
import { survey_serviceAPI } from '@/app/redux/queries/survey-service/survey_serviceAPI';
import { PatchOptionsByQuestionIdApiArg } from '@/app/redux/queries/survey-service/types';
import { getGridColumns } from '@/pages/_surveys/libs/getGridColumns/getGridColumns';
import { getGridRows } from '@/pages/_surveys/libs/getGridRows/getGridRows';
import { CreateImageArg, DeleteImageArg, EditGroupArg, EditQuestionArg, EditSurvey, EditSurveyPath, EditSurveyProps } from '@/pages/_surveys/libs/types';
import { AllSurveyInfo } from '@/pages/_surveys/pages/NewSurveyPage/_types';
import { dateAndTimeToString, isDateTheSame } from '@/shared/lib/date';

const editList: EditSurvey = {
	title: '/Subject',
	description: '/Description',
	cancelVoice: '/IsRevoteAvailable',
	isAnonymous: '/IsAnonymous',
};

export const useEditSurvey = (prevState: AllSurveyInfo, currentState: AllSurveyInfo, surveyId: string) => {
	// * Navigate
	const navigate = useNavigate();

	// * API
	const [patchSurveysSurveyid, { isLoading: isLoading1 }] = survey_serviceAPI.usePatchSurveysSurveyidMutation();
	const [deleteSurveysCategories, { isLoading: isLoading2 }] = survey_serviceAPI.useDeleteSurveysCategoriesMutation();
	const [postSurveysCategories, { isLoading: isLoading3 }] = survey_serviceAPI.usePostSurveysCategoriesMutation();
	const [patchGroupsGroupid, { isLoading: isLoading4 }] = survey_serviceAPI.usePatchGroupsGroupidMutation();
	const [patchQuestionsByQuestionId, { isLoading: isLoading5 }] = survey_serviceAPI.usePatchQuestionsByQuestionIdMutation();
	const [patchOptionsByQuestionId, { isLoading: isLoading6 }] = survey_serviceAPI.usePatchOptionsByQuestionIdMutation();
	const [postImages, { isLoading: isLoading7 }] = survey_serviceAPI.usePostImagesMutation();
	const [deleteImages, { isLoading: isLoading8 }] = survey_serviceAPI.useDeleteImagesMutation();

	const isEditLoading = isLoading1 || isLoading2 || isLoading3 || isLoading4 || isLoading5 || isLoading6 || isLoading7 || isLoading8;

	const editSurveySubmit = () => {
		const { groups } = prevState;
		const { groups: sections } = currentState;

		const prevDeadline = prevState.dateEnd && dateAndTimeToString(prevState.timeEnd, prevState.dateEnd);
		const curDeadline = currentState.dateEnd && dateAndTimeToString(currentState.timeEnd, currentState.dateEnd);

		// * difference in survey info
		const editSurveyPayload: EditSurveyProps[] = [];

		(Object.keys(editList) as Array<keyof typeof editList>).forEach(key => {
			if (prevState[key] !== currentState[key]) {
				editSurveyPayload.push({
					op: 'replace',
					path: editList[key] as EditSurveyPath,
					value: currentState[key],
				});
			}
		});

		if (prevState.limited !== currentState.limited || prevDeadline !== curDeadline) {
			editSurveyPayload.push({
				op: 'replace',
				path: '/Deadline',
				value: curDeadline,
			});
		}

		if (editSurveyPayload.length > 0) {
			patchSurveysSurveyid({
				surveyId,
				body: editSurveyPayload,
			})
				.unwrap()
				.then(res => console.log('success patch survey: ', res))
				.catch(err => console.log('error patch survey: ', err));
		}

		const createCategory = () => {
			if (currentState.categoryId) {
				postSurveysCategories({
					createSurveyCategoryCommand: {
						surveyId,
						categoriesIds: [currentState.categoryId],
					},
				})
					.unwrap()
					.then(res => console.log('success create category: ', res))
					.catch(err => console.log('error create category: ', err));
			}
		};

		if (prevState.categoryId !== currentState.categoryId) {
			if (prevState.categoryId) {
				deleteSurveysCategories({
					removeSurveyCategoryCommand: {
						surveyId,
						categoriesIds: [prevState.categoryId],
					},
				})
					.unwrap()
					.then(() => {
						createCategory();
					})
					.catch(err => console.log('error delete category: ', err));
			}

			if (!prevState.categoryId) {
				createCategory();
			}
		}

		// * difference in sections info
		const prevGroupContent: { [key: string]: string } = {};
		const curGroupContent: EditGroupArg[] = [];

		const prevQuestionContent: { [key: string]: string } = {};
		const curQuestionContent: EditQuestionArg[] = [];

		const prevImageExist: { [key: string]: string } = {};
		const curDeleteImage: DeleteImageArg[] = [];
		const curCreateImage: CreateImageArg[] = [];

		const prevOptionContent: { [key: string]: string } = {};
		const prevScaleOptionContent: {
			[key: string]: {
				leftSignature: string;
				rightSignature: string;
			};
		} = {};
		const prevGridOptionContent: {
			[key: string]: {
				[key: string]: {
					columnSignature: string;
					rowSignature: string;
				};
			};
		} = {};
		const curOptionContent: PatchOptionsByQuestionIdApiArg[] = [];

		// * prev survey
		groups.forEach(section => {
			const { id, subject, questions } = section;

			Object.assign(prevGroupContent, {
				[id]: subject,
			});

			questions.forEach(question => {
				const { id: questionId, content, options, gridOptions, scaleOptions, images } = question;

				if (images && images.length > 0) {
					Object.assign(prevImageExist, {
						[questionId]: images[0]?.id || '',
					});
				}

				Object.assign(prevQuestionContent, {
					[questionId]: content,
				});

				// options
				options.forEach(opt => {
					const { id: optId, content } = opt;

					Object.assign(prevOptionContent, {
						[optId]: content,
					});
				});

				// scaleOptions
				if (scaleOptions.length > 0) {
					const { leftSignature, rightSignature } = scaleOptions[0];

					Object.assign(prevScaleOptionContent, {
						[questionId]: {
							leftSignature,
							rightSignature,
						},
					});
				}

				// gridOptions
				if (gridOptions.length > 0) {
					const gridOptsContent = {};

					gridOptions.forEach(gridOpt => {
						const { id: gridId, columnSignature, rowSignature } = gridOpt;
						Object.assign(gridOptsContent, {
							[gridId]: {
								columnSignature,
								rowSignature,
							},
						});
					});

					Object.assign(prevGridOptionContent, {
						[questionId]: gridOptsContent,
					});
				}
			});
		});

		// * current survey
		sections.forEach(section => {
			const { id, subject, questions } = section;

			if (prevGroupContent[id] !== subject) {
				curGroupContent.push({
					groupId: id,
					body: [
						{
							op: 'replace',
							value: subject,
							path: '/Subject',
						},
					],
				});
			}

			questions.forEach(question => {
				const { id: questionId, content, options, gridOptions, scaleOptions, images, questionImages } = question;

				const hasPrevImage = !!prevImageExist[questionId];

				if (hasPrevImage && images && images.length === 0) {
					curDeleteImage.push({
						questionId,
						imageId: prevImageExist[questionId],
					});
				}

				if (questionImages) {
					curCreateImage.push({
						questionId,
						body: questionImages,
					});
				}

				if (prevQuestionContent[questionId] !== content) {
					curQuestionContent.push({
						questionId: questionId,
						body: [
							{
								op: 'replace',
								value: content,
								path: '/Content',
							},
						],
					});
				}

				// options
				options.forEach(opt => {
					const { id: optId, content } = opt;

					if (prevOptionContent[optId] === content) return;

					curOptionContent.push({
						questionId: questionId,
						optionsIds: [optId],
						body: [
							{
								op: 'replace',
								value: content,
								path: '/Content',
							},
						],
					});
				});

				// scaleOptions
				if (scaleOptions.length > 0) {
					const { leftSignature, rightSignature } = scaleOptions[0];
					const ids: string[] = [];

					// проверка на изменения
					if (prevScaleOptionContent[questionId].leftSignature === leftSignature && prevScaleOptionContent[questionId].rightSignature === rightSignature) return;

					// добавляем все айдишки шкалы
					scaleOptions.forEach(scaleOpt => {
						const { id: scaleId } = scaleOpt;

						ids.push(scaleId);
					});

					if (prevScaleOptionContent[questionId].leftSignature !== leftSignature) {
						curOptionContent.push({
							questionId: questionId,
							optionsIds: ids,
							body: [
								{
									op: 'replace',
									value: leftSignature,
									path: '/LeftSignature',
								},
							],
						});
					}

					if (prevScaleOptionContent[questionId].rightSignature !== rightSignature) {
						curOptionContent.push({
							questionId: questionId,
							optionsIds: ids,
							body: [
								{
									op: 'replace',
									value: rightSignature,
									path: '/RightSignature',
								},
							],
						});
					}
				}

				// gridOptions
				if (gridOptions.length > 0) {
					const columns = getGridColumns(gridOptions);
					const rows = getGridRows(gridOptions);

					columns.forEach(column => {
						const { id: gridId, columnSignature } = column;
						const prevSignature = prevGridOptionContent[questionId][gridId].columnSignature;

						if (prevSignature === columnSignature) return;

						const columnIds: string[] = [];

						for (const key in prevGridOptionContent[questionId]) {
							if (prevGridOptionContent[questionId][key].columnSignature === prevSignature) {
								columnIds.push(key);
							}
						}

						if (columnIds.length > 0) {
							curOptionContent.push({
								questionId: questionId,
								optionsIds: columnIds,
								body: [
									{
										op: 'replace',
										value: columnSignature,
										path: '/ColumnSignature',
									},
								],
							});
						}
					});

					rows.forEach(row => {
						const { id: gridId, rowSignature } = row;
						const prevSignature = prevGridOptionContent[questionId][gridId].rowSignature;

						if (prevSignature === rowSignature) return;

						const rowIds: string[] = [];

						for (const key in prevGridOptionContent[questionId]) {
							if (prevGridOptionContent[questionId][key].rowSignature === prevSignature) {
								rowIds.push(key);
							}
						}

						if (rowIds.length > 0) {
							curOptionContent.push({
								questionId: questionId,
								optionsIds: rowIds,
								body: [
									{
										op: 'replace',
										value: rowSignature,
										path: '/RowSignature',
									},
								],
							});
						}
					});
				}
			});
		});

		// * edit requests
		const createImageReq = () => {
			if (curCreateImage.length === 0) return;

			curCreateImage.map(item => {
				postImages({
					createImagesCommand: {
						questionId: item.questionId,
						images: item.body,
					},
				});
			});
		};

		if (curDeleteImage.length > 0) {
			curDeleteImage.map(item => {
				deleteImages({
					removeImageCommand: {
						questionId: item.questionId,
						imagesIds: [item.imageId],
					},
				})
					.unwrap()
					.then(() => createImageReq())
					.catch(err => console.log('error delete image: ', err));
			});
		}

		if (curDeleteImage.length === 0) {
			createImageReq();
		}

		if (curGroupContent.length > 0) {
			curGroupContent.forEach(group => {
				patchGroupsGroupid(group)
					.unwrap()
					.then(res => console.log('success edit group: ', res))
					.catch(err => console.log('error edit group: ', group.groupId, '. ', err));
			});
		}

		if (curQuestionContent.length > 0) {
			curQuestionContent.forEach(question => {
				patchQuestionsByQuestionId(question)
					.unwrap()
					.then(res => console.log('success edit question: ', res))
					.catch(err => console.log('error edit question: ', question.questionId, '. ', err));
			});
		}

		if (curOptionContent.length > 0) {
			curOptionContent.forEach(opt => {
				patchOptionsByQuestionId(opt)
					.unwrap()
					.then(res => console.log('success edit option: ', res))
					.catch(err => console.log('error edit option: ', opt.questionId, '. ', err));
			});
		}

		navigate(`/surveys/${surveyId}`);
	};

	return { editSurveySubmit, isEditLoading };
};
