import React, { memo } from 'react';
import { useWindowDimensions } from '@/shared/hooks/useWindowDimensions';
import { HeaderDesktop } from './HeaderDesktop/HeaderDesktop';
import { HeaderMobile } from './HeaderMobile/HeaderMobile';

interface Props {
	isPrivate: boolean;
}

export const Header: React.FC<Props> = props => {
	const { isPrivate } = props;

	// const { width } = useWindowDimensions();

	// * Render
	// return <>{width >= 1024 ? <HeaderDesktop isPrivate={isPrivate} /> : <HeaderMobile isPrivate={isPrivate} />}</>;
	return <HeaderDesktop isPrivate={isPrivate} />;
};
