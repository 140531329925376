import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { asyncActions as wikiTreeAsync } from '../wikiTree/slice';
import { CreateRubricREQ_PAYLOAD, EditRubricREQ_PARAMS, EditRubricREQ_PAYLOAD } from './types';

const NAME = `${ServiceName.WIKI_SERVICE}/rubric`;

const createRubric = createAppAsyncThunk(`${NAME}/createRubric`, async (arg: { payload: CreateRubricREQ_PAYLOAD }, thunkAPI) => {
	const { payload } = arg;
	const { dispatch, getState } = thunkAPI;

	const res = await apiRequest.postRequest({
		url: `${NAME}/create`,
		payload,
		thunkAPI,
		action: () => createRubric(arg),
	});

	// Refresh wiki tree
	dispatch(wikiTreeAsync.getWiki({ params: { includeDeactivated: getState().wiki_service.wikiTree.showArchived } }));
	return res;
});

const editRubric = createAppAsyncThunk('wiki/editRubric', async (arg: { params: EditRubricREQ_PARAMS; payload: EditRubricREQ_PAYLOAD }, thunkAPI) => {
	const { params, payload } = arg;
	const { dispatch, getState } = thunkAPI;

	const res = await apiRequest.patchRequest({
		url: `${NAME}/edit`,
		params,
		payload,
		thunkAPI,
		action: () => editRubric(arg),
	});

	// Refresh wiki tree
	await dispatch(wikiTreeAsync.getWiki({ params: { includeDeactivated: getState().wiki_service.wikiTree.showArchived } }));
	return res;
});

interface State {
	status: RequestStatus;
}

export const initialState: State = {
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setRubricStatusStill: state => {
			if (state.status !== RequestStatus.still) {
				state.status = RequestStatus.still;
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(createRubric.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createRubric.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(createRubric.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(editRubric.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editRubric.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(editRubric.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	createRubric,
	editRubric,
};
