import React from 'react';
import s from './Workspace.module.scss';
import { WorkSpaceInfo } from '@/app/redux/state/office/workspace/types';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import EditSVG from '@/shared/assets/svg/action/edit.svg?react';
import DeleteSVG from '@/shared/assets/svg/action/delete.svg?react';
import { ChipList } from '@/shared/ui/_chips/ChipList/ChipList';

interface Props {
	officeId: string;
	workspace: WorkSpaceInfo;
	editWorkSpace: (workspace: WorkSpaceInfo) => void;
	setWorkspaces: (workspace: WorkSpaceInfo[]) => void;
	currentWorkspaces: WorkSpaceInfo[];
}

export const Workspace: React.FC<Props> = props => {
	const { officeId, workspace, editWorkSpace, setWorkspaces, currentWorkspaces } = props;

	const { id, roomId, name, tags, isActive } = workspace;

	const onDeleteClick = () => {
		const workspacesToKeep = currentWorkspaces.filter(prevWorkspace => prevWorkspace.id !== workspace.id);
		setWorkspaces(workspacesToKeep);
	};

	// * Render
	return (
		<div className={s.container}>
			<h6>{name}</h6>

			<ChipList chips={tags} />

			<div className={s.button_row}>
				<IconButton
					Icon={<EditSVG />}
					onClick={() => editWorkSpace(workspace)}
				/>

				<IconButton
					Icon={<DeleteSVG />}
					onClick={onDeleteClick}
				/>
			</div>
		</div>
	);
};
