import React, { ChangeEvent, useEffect, useState } from 'react';
import s from './TimePickerInput.module.scss';
import { dateToTimeString, getTimeString, injectTimeToDate } from '@/shared/lib/date';
import { Hours, Minutes } from '@/shared/lib/date/types';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { useFloatingWrapper } from '@/shared/hooks/useFloatingWrapper';
import { FloatingFocusManager } from '@floating-ui/react';
import { TextInput } from '../../text_Inputs/TextInput';
import ClockSVG from '@/shared/assets/svg/action/clock.svg?react';
import { DateTime } from 'luxon';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
	selectedDate: Date | null;
	setSelectedDate: (date: Date | null) => void;
	label?: string;
	errorBorderTime?: boolean;
	errorMessage?: string;
	showIcon?: boolean;
	timeIntervals?: number;
	required?: boolean;
}

export const TimePickerInput: React.FC<Props> = props => {
	const {
		label, //
		selectedDate,
		setSelectedDate,
		errorBorderTime,
		errorMessage,
		showIcon = true,
		disabled,
		timeIntervals = 30,
		required,
		...datePickerProps
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	const getDateString = (selectedDate: Date | null) => {
		if (selectedDate) {
			const selectedDateLuxon = DateTime.fromJSDate(selectedDate);
			const hour = getTimeString(selectedDateLuxon.hour as Hours);
			const minute = getTimeString(selectedDateLuxon.minute as Minutes);
			return `${hour}:${minute}`;
		} else {
			return '';
		}
	};

	const [stringValue, setStringValue] = useState(getDateString(selectedDate));

	useEffect(() => {
		setStringValue(getDateString(selectedDate));
	}, [selectedDate]);

	const handleOnChangeField = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value.replace(/([^:0-9]+)/gi, '');
		const prevLength = stringValue.length;
		const valueLength = value.length;
		const isAdding = valueLength > prevLength;

		let time: string;

		if (isAdding) {
			if (valueLength === 2) {
				time = value + ':';
			} else if (valueLength === 3 && !value.includes(':')) {
				time = value.slice(0, 2) + ':' + value.slice(2);
			} else if (valueLength > 5) {
				time = value.slice(0, 5);
			} else {
				time = value;
			}
		} else {
			if (valueLength === 3) {
				time = value.replace(/:/g, '');
			} else {
				time = value;
			}
		}

		const timeSplit = time.split(':');

		const hours = timeSplit[0];
		const minutes = timeSplit[1];

		if (hours && hours.length === 2 && +hours > 23) {
			time = '23' + time.slice(2);
		}

		if (minutes && minutes.length === 2 && +minutes > 59) {
			time = time.slice(0, 3) + '59';
		}

		setStringValue(time);

		if (time.length === 5) {
			// Авто заполнение минимального и максимального времени...
			const minDateTime = datePickerProps.minTime;
			const maxDateTime = datePickerProps.maxTime;

			let finalDateTime: Date = injectTimeToDate(new Date(), time);

			if (minDateTime && finalDateTime.getTime() < minDateTime?.getTime()) {
				finalDateTime = minDateTime;
			} else if (maxDateTime && finalDateTime.getTime() > maxDateTime?.getTime()) {
				finalDateTime = maxDateTime;
			}
			// ...

			const finalTime = dateToTimeString(finalDateTime, true);

			const dateTime = injectTimeToDate(selectedDate ? selectedDate : new Date(), finalTime);
			setSelectedDate(dateTime);
		}
	};

	const handleOnChangePicker = (date: Date | null) => {
		setIsOpen(!isOpen);
		date && setSelectedDate(date);

		setStringValue(getDateString(date));
	};

	const handleOnBlurOut = () => {
		if (stringValue.length < 5) {
			const time = stringValue;
			let autoFilledTime: string | null = null;
			if (time.length === 0) {
				autoFilledTime = null;
			} else if (time.length === 1) {
				autoFilledTime = time.padStart(2, '0') + ':00';
			} else if (time.length === 2) {
				autoFilledTime = time + ':00';
			} else if (time.length === 3) {
				autoFilledTime = time + '00';
			} else if (time.length === 4) {
				autoFilledTime = time.slice(0, 3) + time.slice(3, 4).padStart(2, '0');
			}

			const dateTime = autoFilledTime ? injectTimeToDate(selectedDate ? selectedDate : new Date(), autoFilledTime) : null;
			autoFilledTime && setStringValue(autoFilledTime);
			setSelectedDate(dateTime);
		}
	};

	// * Floating
	const { floatingStyles, refs, context, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(isOpen, setIsOpen);

	// * Render
	return (
		<>
			<div className={s.container}>
				<TextInput
					label={label}
					placeholder="00:00"
					value={stringValue}
					onClick={event => {
						event.preventDefault();
					}}
					onChange={handleOnChangeField}
					onBlur={handleOnBlurOut}
					errorBorder={errorBorderTime}
					errorMessage={errorMessage}
					RightIcon={
						showIcon && (
							<ClockSVG
								ref={refs.setReference}
								{...getReferenceProps()}
								onClick={() => !disabled && setIsOpen(prevState => !prevState)}
							/>
						)
					}
					required={required}
					disabled={disabled}
				/>
			</div>

			{isOpen && !disabled && (
				<FloatingFocusManager
					context={context}
					modal={false}
				>
					<div
						ref={refs.setFloating}
						style={{
							...floatingStyles,
							zIndex: 'var(--z-index-floating)',
						}}
						aria-labelledby={headingId}
						{...getFloatingProps()}
					>
						<DatePicker
							{...datePickerProps}
							locale={'ru'}
							selected={selectedDate}
							onChange={handleOnChangePicker}
							showTimeSelect
							showTimeSelectOnly
							timeIntervals={timeIntervals}
							inline
						/>
					</div>
				</FloatingFocusManager>
			)}
		</>
	);
};
