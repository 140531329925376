import s from './AllComments.module.scss';
import { useState } from 'react';
import { claim_serviceAPI } from '@/app/redux/queries/claim-service/claim_serviceAPI';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';
import { ConfirmModal } from '@/entities/_modals/ConfirmModal/ConfirmModal';
import { Comment } from '../Comment/Comment';

export const AllComments = ({ claimId }: { claimId: string }) => {
	const [isDeleteModal, setIsDeleteModal] = useState(false);

	// * API
	const { data: commentsData } = claim_serviceAPI.useGetClaimsByClaimIdCommentsQuery({ claimId: claimId, skipcount: 0, takecount: 100 });
	const comments = commentsData?.body ?? [];

	const toggleModalForm = () => {
		setIsDeleteModal(!isDeleteModal);
	};

	const removeComment = () => {
		console.log('remove');
	};

	// * Render
	return (
		<>
			{isDeleteModal && (
				<ConfirmModal
					title="Удалить комментарий?"
					description="Вы действительно хотите удалить этот комментарий?"
					primaryText="Удалить"
					isLoading={false}
					isOpen={isDeleteModal}
					onClose={toggleModalForm}
					primaryOnClick={() => removeComment()}
				/>
			)}
			{comments.length > 0 ? (
				comments.map(comment => (
					<Comment
						key={comment.id}
						comment={comment}
					/>
				))
			) : (
				<p className={s.text}>Комментариев пока нет.</p>
			)}
		</>
	);
};
