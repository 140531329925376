import cn from 'classnames';
import { DateTime } from 'luxon';
import React, { ComponentPropsWithoutRef } from 'react';
import { LeaveTimeInfo } from '@/app/redux/queries/time-service/types/types';
import { PieDataItem } from '@/features/TimeChart/_types/types';
import { LEAVE_TIME_OPTIONS } from '@/shared/consts/options';
import { getMonthLeaveHours } from '@/shared/lib/date';
import s from './Tooltip.module.scss';
import { useAppSelector } from '@/app/redux/utils';

interface Props extends ComponentPropsWithoutRef<'div'> {
	tooltipData: PieDataItem;
	hoursLeft: number;
	leaveTimeInfo: LeaveTimeInfo[];
	selectedDateLuxon: DateTime<true> | DateTime<false>;
	holidays: string;
}

export const Tooltip: React.FC<Props> = props => {
	const {
		tooltipData, //
		hoursLeft,
		leaveTimeInfo,
		selectedDateLuxon,
		holidays,
		className,
	} = props;

	// * Selectors
	const rate = useAppSelector(state => state.user_service.user.userInfo?.companyUser?.rate);

	const generateInfo = (tooltipData: PieDataItem) => {
		if (tooltipData.id === 'Запланированные часы') {
			return <div>Осталось часов: {hoursLeft}</div>;
		} else if (tooltipData.id === 'Отсутствия') {
			let leaveTimes: { [key: string]: number } = {};

			leaveTimeInfo.forEach(leaveTime => {
				const finalLeaveTime = leaveTime.managerLeaveTime ? leaveTime.managerLeaveTime : leaveTime;
				leaveTimes = {
					...leaveTimes,
					[finalLeaveTime.leaveType]:
						(leaveTimes[finalLeaveTime.leaveType] ?? 0) +
						getMonthLeaveHours(selectedDateLuxon.month, new Date(finalLeaveTime.startTime), new Date(finalLeaveTime.endTime), holidays, rate),
				};
			});

			return Object.entries(leaveTimes).map(([key, value]) => {
				const leaveTimeName = LEAVE_TIME_OPTIONS.find(option => option.id === key);

				return (
					<div key={key}>
						{leaveTimeName?.name ?? 'отсутствие'}: {value}
					</div>
				);
			});
		} else {
			return <div>Количество часов: {tooltipData.value}</div>;
		}
	};

	// * Render
	return (
		<div className={cn(s.container, className)}>
			<b>{tooltipData.id}</b>
			{generateInfo(tooltipData)}
		</div>
	);
};
