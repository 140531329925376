import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { ContractSubjectInfo, EditContractSubjectREQ_PARAMS, EditContractSubjectRES, FindContractSubjectsREQ_PARAMS, FindContractSubjectsRES } from './types';

const NAME = `${ServiceName.COMPANY_SERVICE}/contractsubject`;

// * Async thunks
const findContractSubjects = createAppAsyncThunk(`${NAME}/findContractSubjects`, async (arg: FindContractSubjectsREQ_PARAMS, thunkAPI) => {
	const { params } = arg;

	const res = await apiRequest.getRequest<FindContractSubjectsRES>({
		url: `${NAME}/find`,
		params,
		thunkAPI,
		action: () => findContractSubjects(arg),
	});

	return res.body;
});

const editContactSubjects = createAppAsyncThunk(`${NAME}/editContactSubjects`, async (arg: EditContractSubjectREQ_PARAMS, thunkAPI) => {
	const { params, payload } = arg;

	await apiRequest.patchRequest<EditContractSubjectRES>({
		url: `${NAME}/edit`,
		params,
		payload,
		action: () => editContactSubjects(arg),
		thunkAPI,
	});
});

// * Slice
export interface State {
	contractSubjectInfo: ContractSubjectInfo[] | null;
	editStatus: RequestStatus;
	status: RequestStatus;
}

export const initialState: State = {
	contractSubjectInfo: null,
	editStatus: RequestStatus.still,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(findContractSubjects.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findContractSubjects.fulfilled, (state, action) => {
			state.contractSubjectInfo = action.payload;
			state.status = RequestStatus.success;
		});
		builder.addCase(findContractSubjects.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(editContactSubjects.pending, state => {
			state.editStatus = RequestStatus.loading;
		});
		builder.addCase(editContactSubjects.fulfilled, state => {
			state.editStatus = RequestStatus.success;
		});
		builder.addCase(editContactSubjects.rejected, state => {
			state.editStatus = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	findContractSubjects,
};
