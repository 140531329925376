import React, { FormEvent } from 'react';
import s from '../../../../../components/SurveyForm/SurveyForm.module.scss';
import CloseSVG from '@/shared/assets/svg/action/close.svg?react';
import { Checkbox } from '@/shared/ui/_inputs/Checkbox';
import { Radio } from '@/shared/ui/_inputs/Radio';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Option } from '../../../_types';
import { DragDropHandle } from '@/shared/ui/DragDropHandle/DragDropHandle';

interface Props {
	option: Option;
	hasMultipleChoice: boolean;
	indexes: {
		indexSection: number;
		indexQuestion: number;
		indexOption: number;
	};
	onOptionContentChange: (event: FormEvent<HTMLInputElement>, indexSection: number, indexQuestion: number, indexOption: number) => void;
	removeOption: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, indexSection: number, indexQuestion: number, indexOption: number) => void;
	showCloseIcon: boolean;
	isEdit?: boolean;
}

export const MultiChoiceOption: React.FC<Props> = props => {
	const {
		option,
		hasMultipleChoice,
		indexes: { indexSection, indexQuestion, indexOption },
		onOptionContentChange,
		removeOption,
		showCloseIcon,
		isEdit,
	} = props;

	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: option.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	// * Render
	return (
		<div
			className={s.option_field}
			style={style}
			ref={setNodeRef}
		>
			{!isEdit && (
				<DragDropHandle
					{...attributes}
					{...listeners}
				/>
			)}

			{hasMultipleChoice && (
				<Checkbox
					disabled
					noDisabledStyles
				/>
			)}

			{!hasMultipleChoice && (
				<Radio
					checked={false}
					disabled
					noDisabledStyles
				/>
			)}

			<TextInput
				placeholder={`Вариант ${indexOption + 1}`}
				value={option.content}
				maxLength={300}
				onChange={e => onOptionContentChange(e, indexSection, indexQuestion, indexOption)}
				onBlur={e => onOptionContentChange(e, indexSection, indexQuestion, indexOption)}
			/>

			{showCloseIcon && !isEdit && (
				<IconButton
					Icon={<CloseSVG />}
					onClick={e => removeOption(e, indexSection, indexQuestion, indexOption)}
				/>
			)}
		</div>
	);
};
