import React from 'react';
import s from './DisplayQuestion.module.scss';
import cn from 'classnames';
import { getIsPastDate } from '@/shared/lib/date';
import { QuestionItem, SectionProps } from '../../NewSurveyPage/_types';
import { ChoiceAnswer } from './variants/ChoiceAnswer/ChoiceAnswer';
import { ScaleAnswer } from './variants/ScaleAnswer/ScaleAnswer';
import { GridAnswer } from './variants/GridAnswer/GridAnswer';
import { QuestionImage } from '../../../components/QuestionImage/QuestionImage';

interface Props {
	indexGroup: number;
	indexQuestion: number;
	question: QuestionItem;

	fillingInfo: Array<SectionProps>;
	changeFillingInfo: (info: Array<SectionProps>) => void;

	errorQuestions?: string[];

	deadline: string | null;
	isCompleted?: boolean;
}

export const DisplayQuestion = (props: Props) => {
	const {
		indexGroup,
		indexQuestion,
		question,

		fillingInfo,
		changeFillingInfo,

		errorQuestions,

		deadline,
		isCompleted,
	} = props;

	const {
		id,
		content,
		hasMultipleChoice,
		isObligatory,
		options,
		gridOptions,
		scaleOptions,
		images, // с бэка
		questionImages, // для превью в создании опроса
		hasCustomOptions,
	} = question;

	const questionImage = images ? images[0] : null;
	const imageId = questionImage?.parentId || null;
	const image = questionImages ? questionImages[0] : null;

	const isEndedSurvey = getIsPastDate(deadline);

	// * Type
	const type =
		options && (options.length > 0 || hasCustomOptions) //
			? 'Base'
			: gridOptions && gridOptions.length > 0
				? 'Grid'
				: scaleOptions && scaleOptions.length > 0
					? 'Scale'
					: '';

	const typeQuestion = () => {
		switch (type) {
			case 'Base':
				return (
					<ChoiceAnswer
						indexGroup={indexGroup}
						indexQuestion={indexQuestion}
						type={hasMultipleChoice ? 'check' : 'radio'}
						options={options || []}
						hasCustomOptions={!!question.hasCustomOptions}
						fillingInfo={fillingInfo}
						changeFillingInfo={changeFillingInfo}
						isEndedSurvey={isEndedSurvey}
						isCompleted={isCompleted}
					/>
				);
			case 'Scale':
				return (
					<ScaleAnswer
						indexGroup={indexGroup}
						indexQuestion={indexQuestion}
						options={scaleOptions || []}
						fillingInfo={fillingInfo}
						changeFillingInfo={changeFillingInfo}
						isEndedSurvey={isEndedSurvey}
						isCompleted={isCompleted}
					/>
				);
			case 'Grid':
				return (
					<GridAnswer
						indexGroup={indexGroup}
						indexQuestion={indexQuestion}
						type={hasMultipleChoice ? 'check' : 'radio'}
						options={gridOptions || []}
						fillingInfo={fillingInfo}
						changeFillingInfo={changeFillingInfo}
						isEndedSurvey={isEndedSurvey}
						isCompleted={isCompleted}
					/>
				);
			default:
				return null;
		}
	};

	// * Error
	const isError = errorQuestions && errorQuestions.includes(id);

	// * Render
	return (
		<div
			id={question.id}
			className={cn(s.container, isError ? s.question_error : '')}
		>
			<div
				className={s.question}
				style={content ? { marginBottom: '1.5rem' } : { marginBottom: 0 }}
			>
				<div className={s.question_content}>{content}</div>

				{isObligatory && <h3>*</h3>}
			</div>

			{(imageId || image) && (
				<QuestionImage
					id={imageId}
					image={image}
				/>
			)}

			<div className={s.question_wrapper}>{typeQuestion()}</div>
		</div>
	);
};
