import cn from 'classnames';
import React, { useState } from 'react';
import { useAppDispatch } from '@/app/redux/utils';
import { actionsNotifications } from '@/features/notifications/_BLL/slice';
import CopySVG from '@/shared/assets/svg/action/copy.svg?react';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import { Heading } from '@/shared/ui/Heading';
import { ParticipantsModal } from '../ParticipantsModal/ParticipantsModal';
import { EventInfoFields } from './consts';
import s from './EventDescription.module.scss';

interface Props {
	start: string;
	end: string;
	participants: number;
	place: string;
	description: string;
	eventId: string;
}

export const EventDescription: React.FC<Props> = props => {
	const {
		description, //
		eventId,
	} = props;

	const [showModal, setShowModal] = useState(false);
	const toggleShowModal = () => setShowModal(prev => !prev);

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	// * Copy
	const urlToClipboard = (value: string) => {
		navigator.clipboard.writeText(value).then(() =>
			dispatch(
				addNotification({
					type: 'success',
					message: 'Место проведения скопировано в буфер обмена',
				}),
			),
		);
	};

	// * Render
	return (
		<>
			{showModal && (
				<ParticipantsModal
					showModal={showModal}
					toggleModal={toggleShowModal}
					eventId={eventId}
				/>
			)}

			<div className={s.container}>
				{EventInfoFields.map(field => {
					const { key, text } = field;

					return (
						<div
							key={field.key}
							className={s.wrapper}
						>
							<span className={s.field}>{text}</span>
							<span
								className={cn(s.value, key === 'participants' && s.clickable)}
								onClick={() => key === 'participants' && toggleShowModal()}
							>
								{props[key as keyof Props]}

								{key === 'place' && (
									<IconButton
										Icon={<CopySVG />}
										onClick={() => urlToClipboard(props[key])}
									/>
								)}
							</span>
						</div>
					);
				})}
			</div>

			{description && (
				<div>
					<Heading
						level={4}
						marginBottom="s"
					>
						Описание
					</Heading>

					<p>{description}</p>
				</div>
			)}
		</>
	);
};
