import ReactDOM from 'react-dom';
import s from './Modal.module.scss';
import React, { HTMLAttributes } from 'react';
import { Backdrop } from '../Backdrop/Backdrop';
import CloseSVG from '@/shared/assets/svg/action/close.svg?react';
import { useWindowDimensions } from '@/shared/hooks/useWindowDimensions';

interface ModalOverlayProps {
	isActive: boolean;
	toggleModal: () => void;
	Content: JSX.Element;
	width: number;
	top: number;
	zIndex: 1 | 2 | 3 | 4 | 5;
	overflowX: 'auto' | 'clip' | 'hidden' | 'scroll' | 'visible';
}

const ModalOverlay: React.FC<ModalOverlayProps> = props => {
	const { isActive, toggleModal, Content, width, top, zIndex, overflowX, ...restProps } = props;

	const windowDimensions = useWindowDimensions();

	const component = (
		<div
			className={s.container}
			style={{
				display: `${isActive ? 'block' : 'none'}`,
				left: `${windowDimensions.width / 2 - width / 2}px`,
				zIndex: `calc(${zIndex} * var(--z-index-modal) + 1)`,
				width: `${width}px`,
				top: `${top}px`,
			}}
			{...restProps}
		>
			<div
				className={s.wrapper}
				id="modal"
				style={{
					overflowX,
				}}
			>
				{Content}
			</div>
			{toggleModal && (
				<div
					className={s.close_button}
					onClick={toggleModal}
				>
					<CloseSVG />
				</div>
			)}
		</div>
	);

	const container = document.getElementById('modal-portal') as HTMLElement;

	return ReactDOM.createPortal(component, container);
};

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
	show: boolean;
	toggleModal: () => void;
	Content: JSX.Element;
	closeOnBackdrop?: boolean;
	width?: number;
	top?: number;
	zIndex?: 1 | 2 | 3 | 4 | 5;
	overflowX?: 'auto' | 'clip' | 'hidden' | 'scroll' | 'visible'; // Для форм
}

export const Modal: React.FC<ModalProps> = props => {
	const {
		show,
		toggleModal,
		Content,
		closeOnBackdrop = true,
		width = 700, // Px
		top = 60,
		zIndex = 1,
		overflowX = 'auto',
		...restProps
	} = props;

	return (
		<>
			<Backdrop
				isActive={show}
				onClick={closeOnBackdrop && toggleModal}
				zIndex={zIndex}
			/>

			<ModalOverlay
				{...restProps}
				isActive={show}
				toggleModal={toggleModal}
				Content={Content}
				width={width}
				top={top}
				zIndex={zIndex}
				overflowX={overflowX}
			/>
		</>
	);
};
