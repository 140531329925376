import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '@/app/api/apiSlice';
import { ServiceName } from '@/app/api/api_types';
import { PostFamilyApiArg, GetFamilyApiArg, PatchFamilyByChildIdApiArg, GetImportApiArg } from './types/regTypes';
import { PostFamilyApiResponse, GetFamilyApiResponse, PatchFamilyByChildIdApiResponse, GetImportApiResponse } from './types/resTypes';

export const reducerPath = `${ServiceName.FAMILY_SERVICE}` as const;

export const family_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Family', 'Import'] as const,
	endpoints: build => ({
		postFamily: build.mutation<PostFamilyApiResponse, PostFamilyApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Family`,
				method: 'POST',
				body: queryArg.createChildRequest,
			}),
			invalidatesTags: ['Family'],
		}),
		getFamily: build.query<GetFamilyApiResponse, GetFamilyApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Family`,
				params: {
					parentuserid: queryArg.parentuserid,
					loweragelimit: queryArg.loweragelimit,
					upperagelimit: queryArg.upperagelimit,
					gender: queryArg.gender,
					departmentid: queryArg.departmentid,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Family'],
		}),
		patchFamilyByChildId: build.mutation<PatchFamilyByChildIdApiResponse, PatchFamilyByChildIdApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Family/${queryArg.childId}`,
				method: 'PATCH',
				body: queryArg.body,
			}),
			invalidatesTags: ['Family'],
		}),
		getImport: build.query<GetImportApiResponse, GetImportApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Import`,
				params: {
					parentuserid: queryArg.parentuserid,
					loweragelimit: queryArg.loweragelimit,
					upperagelimit: queryArg.upperagelimit,
					gender: queryArg.gender,
					departmentid: queryArg.departmentid,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Import'],
		}),
	}),
});

export const { usePostFamilyMutation, useGetFamilyQuery, usePatchFamilyByChildIdMutation, useGetImportQuery } = family_serviceAPI;
