import { useGetGroupsByGroupIdQuery } from '@/app/redux/queries/vacation-service/vacation_serviceAPI';

export const useGetGroupQuery = (groupId: string | null) => {
	return useGetGroupsByGroupIdQuery(
		{
			groupId: groupId ?? '',
		},
		{ skip: groupId === null || groupId === 'null' },
	);
};
