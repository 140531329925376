import { useEffect, useState } from 'react';
import { usePatchEventUserEditMutation, usePostEventUserCreateMutation } from '@/app/redux/queries/event-service/event_serviceAPI';
import { useGetUserFindQuery } from '@/app/redux/queries/user-service/user_serviceAPI';
import { UserListItem } from '@/app/redux/state/event-calendar/types';
import { useAppSelector } from '@/app/redux/utils';
import { SelectedCount } from '@/entities/_typography/SelectedCount';
import { AddUserMCContainer } from '@/pages/_eventСalendar/ui/EventForm/ui/AddUsersToEvent/ui/AddUserMCContainer';
import SearchSVG from '@/shared/assets/svg/action/search.svg?react';
import CloseSVG from '@/shared/assets/svg_icons/action/close.svg?react';
import { createFullName } from '@/shared/lib/string';
import { ConditionalRendering } from '@/shared/providers';
import { AddButton } from '@/shared/ui/_buttons/AddButton';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import { SelectSingle } from '@/shared/ui/_inputs/selects/SelectSingle';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { EmptyData } from '@/shared/ui/EmptyData/EmptyData';
import { Heading } from '@/shared/ui/Heading';
import { ParticipantsData } from './consts';
import s from './ParticipantsModal.module.scss';
import { ParticipantsTable } from './ParticipantsTable/ParticipantsTable';

interface Props {
	showModal: boolean;
	toggleModal: () => void;
	eventId: string;
}

export const ParticipantsModal: React.FC<Props> = props => {
	const {
		showModal, //
		toggleModal,
		eventId,
	} = props;

	const [users, setUsers] = useState<ParticipantsData[]>([]);
	const [filteredUsers, setFilteredUsers] = useState<ParticipantsData[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const [showAddUsersModal, setShowAddUsersModal] = useState(false);
	const toggleAddUsersModal = () => setShowAddUsersModal(prev => !prev);

	// * Filter
	const [position, setPosition] = useState({ name: '', id: '' });
	const [name, setName] = useState('');

	// * Filter apply
	useEffect(() => {
		if (!users) return;
		setIsLoading(true);
		let newUsers = users;

		if (name !== '') {
			newUsers = newUsers.filter(user => user.name.name.toLowerCase().includes(name.toLowerCase()));
		}

		if (position.name && position.name !== '') {
			newUsers = newUsers.filter(user => (position.name === 'Без должности' ? user.name.position === '' : user.name.position === position.name));
		}

		setFilteredUsers(newUsers);
		setIsLoading(false);
	}, [position, name]);

	// * Selectors
	const selectedUsersInfo = useAppSelector(state => state.event_calendar.event_form.selectedUsersInfo).users;

	// * API
	const [editEventUser] = usePatchEventUserEditMutation();
	const [createEventUsers] = usePostEventUserCreateMutation();

	const { data } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includepositions: true,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
		isascendingsort: true,
	});

	const allCompanyUsers = data?.body ?? [];

	const allCompanyUsersList: UserListItem[] = allCompanyUsers.map(item => ({
		id: item.user.id,
		name: createFullName({ firstName: item.user.firstName, lastName: item.user.lastName, middleName: item.user.middleName ?? undefined }),
		position: item.positionUser?.name ?? '',
		department: item.departmentUser?.department.name,
		avatar: item.user.avatar,
	}));

	const onDelete = (id: string) => {
		editEventUser({
			eventUserId: id,
			body: [
				{
					op: 'replace',
					value: 'Discarded',
					path: '/Status',
				},
			],
		})
			.unwrap()
			.catch(e => console.log(e));
	};

	const onCreate = (ids: string[]) => {
		createEventUsers({
			createEventUserRequest: {
				eventId,
				users: ids.map(id => ({
					userId: id,
				})),
			},
		});
	};

	const loadData = async () => {
		setIsLoading(true);
		const usersInfo = [] as any;

		for (const user of selectedUsersInfo) {
			usersInfo.push(
				data && {
					id: user.id,
					name: {
						name: user.name,
						position: user.position,
						avatar: user.avatarId || null,
					},
					department: user.department || '-',
					delete: (
						<IconButton
							Icon={<CloseSVG />}
							onClick={() => onDelete(user.eventUserId as string)}
						/>
					),
				},
			);
		}
		setFilteredUsers(usersInfo);
		setUsers(usersInfo);
		setIsLoading(false);
	};

	useEffect(() => {
		loadData();
	}, [selectedUsersInfo]);

	// * Options
	const positionOptions = users
		? users
				.map(item => ({
					id: item.name.position || 'Без должности',
					name: item.name.position || 'Без должности',
				}))
				.filter((value, index, array) => index === array.findIndex(item => item.id === value.id && item.name === value.name))
		: [{ id: '', name: '' }];

	// * Render
	return (
		<>
			{showAddUsersModal && (
				<ModalNewProto
					isOpen={showAddUsersModal}
					onClose={toggleAddUsersModal}
					width="l"
				>
					<AddUserMCContainer
						toggleModal={toggleAddUsersModal}
						allUsers={allCompanyUsersList}
						createEventUsers={onCreate}
					/>
				</ModalNewProto>
			)}

			<ModalNewProto
				isOpen={showModal}
				onClose={toggleModal}
				width="l"
				className={s.modal}
			>
				<Heading
					level={2}
					className={s.heading}
				>
					Участники
				</Heading>

				<div className={s.wrapper}>
					<SelectSingle
						placeholder="Должность"
						keyNames={{
							name: 'name',
							value: 'id',
						}}
						selectedOption={position}
						setSelectedOption={position => setPosition(position)}
						options={positionOptions}
					/>
					<TextInput
						value={name}
						onChange={e => setName(e.target.value)}
						placeholder="Поиск сотрудника по имени"
						RightIcon={<SearchSVG />}
					/>
				</div>

				{!isLoading && (
					<SelectedCount
						className={s.participants_heading}
						title="Всего участников"
						count={users.length}
					/>
				)}

				<AddButton
					title="Пригласить"
					className={s.button}
					onClick={() => {
						toggleAddUsersModal(), setName(''), setPosition({ name: '', id: '' });
					}}
				/>

				<ConditionalRendering
					initialLoading={isLoading}
					isSuccessful={filteredUsers.length > 0}
					FailedResult={selectedUsersInfo.length > 0 && <EmptyData />}
					LoadedResult={<ParticipantsTable data={filteredUsers} />}
				/>
			</ModalNewProto>
		</>
	);
};
