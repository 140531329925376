import { saveAs as saveFile } from 'file-saver';
import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { file_serviceAPI } from '@/app/redux/queries/file-service/file_serviceAPI';
import { wiki_serviceAPI } from '@/app/redux/queries/wiki-service/wiki_serviceAPI';
import DeleteSVG from '@/shared/assets/svg_icons/action/delete.svg?react';
import DocSVG from '@/shared/assets/svg_icons/file/doc.svg?react';
import DownloadSVG from '@/shared/assets/svg_icons/file/download.svg?react';
import FolderSVG from '@/shared/assets/svg_icons/file/folder.svg?react';
import PhotoSVG from '@/shared/assets/svg_icons/file/photo.svg?react';
import { base64ToBlob } from '@/shared/lib/file';
import { formatBytes } from '@/shared/lib/string';
import { DropZone } from '@/shared/ui/DropZone/DropZone';
import { Heading } from '@/shared/ui/Heading';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import s from './ArticleFiles.module.scss';

interface Props {
	articleId: string;
	isPrivate: boolean;
	existFiles: boolean;
	isEdit?: boolean;
}

export const ArticleFiles: React.FC<Props> = props => {
	const {
		articleId, //
		existFiles,
		isPrivate,
		isEdit = false,
	} = props;

	// * Form
	const { setValue, watch } = useFormContext() || {};

	// * API
	const [getFiles] = file_serviceAPI.useLazyGetFileGetQuery();
	const [getPublicFiles] = file_serviceAPI.useLazyGetPublicGetQuery();
	const { data: privateFiles, isLoading: isPrivateFileLoading } = wiki_serviceAPI.useFindFilesQuery(
		{
			skipCount: 0,
			takeCount: 40,
			articleid: articleId,
		},
		{ skip: !isPrivate || articleId === 'null' || !existFiles },
	);

	const { data: publicFiles, isLoading: isPublicFileLoading } = wiki_serviceAPI.useFindPublicFilesQuery(
		{
			skipCount: 0,
			takeCount: 40,
			articleid: articleId,
		},
		{ skip: isPrivate || articleId === 'null' || !existFiles },
	);

	// * Files
	const files = isPrivate ? privateFiles?.body : publicFiles?.body;

	useEffect(() => {
		if (isEdit) {
			setValue('files', files || []);
			setValue('initialFiles', files || []);
		}
	}, [files]);

	const filesValue = isEdit || articleId === 'null' ? watch('files') : files;
	const isFileServiceLoading = isPrivateFileLoading || isPublicFileLoading;

	const getIcon = (type: string) => {
		switch (type) {
			case '.jpg':
			case '.jpeg':
			case '.png':
				return <PhotoSVG />;
			case '.zip':
				return <FolderSVG />;
			default:
				return <DocSVG />;
		}
	};

	const saveArticleFiles = (newFiles: any) => {
		const lastFiles = watch('files');

		const newArray = [...lastFiles, ...newFiles];
		setValue('files', newArray);
	};

	const deleteFile = (index: number) => {
		const newFiles = [...filesValue];
		newFiles.splice(index, 1);

		setValue('files', [...newFiles]);
	};

	const onDrop = useCallback(
		async (acceptedFiles: any) => {
			saveArticleFiles(acceptedFiles);
		},
		[files],
	);

	// * Download file
	const downloadFile = (file: any) => {
		const downloadFileAction = (downloadedFile: any) => {
			const blob = base64ToBlob(`data:${downloadedFile.contentType};base64,${downloadedFile.fileContents}`);
			saveFile(blob, `${file.name}${file.extension}`);
		};

		isPrivate
			? getFiles({
					filesIds: [file.id],
					fileSource: 'Wiki',
				})
					.unwrap()
					.then(res => {
						const downloadedFile = res[0];
						downloadFileAction(downloadedFile);
					})
					.catch(err => console.log(err))
			: getPublicFiles({
					filesIds: [file.id],
					fileSource: 'Wiki',
				})
					.unwrap()
					.then(res => {
						const resFiles = res.body || [];
						const downloadedFile = resFiles[0];
						downloadFileAction(downloadedFile);
					})
					.catch(err => console.log(err));
	};

	// * Conditions
	const isLoading = isFileServiceLoading;
	const hasFiles = filesValue && filesValue.length > 0;
	const showFiles = hasFiles || isEdit || articleId === 'null';

	// * Render
	return (
		<>
			{showFiles && (
				<div className={s.files}>
					<Heading level={4}>Документы к статье</Heading>

					{isLoading && <LoaderCircle />}

					{(isEdit || articleId == 'null') && (
						<DropZone
							iconType="media"
							onDrop={onDrop}
						/>
					)}

					{hasFiles && (
						<table className={s.table}>
							<thead>
								<tr>
									<th className={s.table_td}></th>
									<th>Название</th>
									<th className={s.table_size}>Размер</th>
									<th className={`${s.table_td} ${s.table_action}`}></th>
								</tr>
							</thead>
							<tbody>
								{filesValue.map((file: any, index: number) => (
									<tr key={index}>
										<td className={s.table_td}>
											<div className={s.table_icon}>{getIcon(file.extension)}</div>
										</td>
										<td>
											{file.name}
											{file.extension}
										</td>
										<td className={s.table_size}>{formatBytes(file.size)}</td>
										{(isEdit || articleId === 'null') && (
											<td
												className={`${s.table_td} ${s.table_action}`}
												onClick={() => deleteFile(index)}
											>
												<div className={s.table_icon}>
													<DeleteSVG />
												</div>
											</td>
										)}
										{!isEdit && articleId !== 'null' && (
											<td
												className={`${s.table_td} ${s.table_action}`}
												onClick={() => downloadFile(file)}
											>
												<div className={s.table_icon}>
													<DownloadSVG />
												</div>
											</td>
										)}
									</tr>
								))}
							</tbody>
						</table>
					)}
				</div>
			)}
		</>
	);
};
