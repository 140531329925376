import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
	GetImportGetApiArg,
	GetLeaveTimeFindApiArg,
	GetStatFindApiArg,
	GetWorkTimeFindApiArg,
	GetWorkTimeMonthLimitFindApiArg,
	PatchLeaveTimeEditApiArg,
	PatchWorkTimeDayJobEditApiArg,
	PatchWorkTimeEditApiArg,
	PatchWorkTimeMonthLimitEditApiArg,
	PostLeaveTimeCreateApiArg,
	PostWorkTimeCreateApiArg,
	PostWorkTimeDayJobCreateApiArg,
} from './types/regTypes';
import {
	GetImportGetApiResponse,
	GetLeaveTimeFindApiResponse,
	GetStatFindApiResponse,
	GetWorkTimeFindApiResponse,
	GetWorkTimeMonthLimitFindApiResponse,
	PatchLeaveTimeEditApiResponse,
	PatchWorkTimeDayJobEditApiResponse,
	PatchWorkTimeEditApiResponse,
	PatchWorkTimeMonthLimitEditApiResponse,
	PostLeaveTimeCreateApiResponse,
	PostWorkTimeCreateApiResponse,
	PostWorkTimeDayJobCreateApiResponse,
} from './types/resTypes';
import { ServiceName } from '@/app/api/api_types';
import { baseQueryWithReAuth } from '@/app/api/apiSlice';

export const reducerPath = `${ServiceName.TIME_SERVICE}` as const;

export const time_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Import', 'LeaveTime', 'Stat', 'WorkTime', 'WorkTimeDayJob', 'WorkTimeMonthLimit'] as const,
	endpoints: build => ({
		getImportGet: build.query<GetImportGetApiResponse, GetImportGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Import/get`,
				params: {
					departmentId: queryArg.departmentId,
					projectId: queryArg.projectId,
					year: queryArg.year,
					month: queryArg.month,
				},
			}),
			providesTags: ['Import'],
		}),
		postLeaveTimeCreate: build.mutation<PostLeaveTimeCreateApiResponse, PostLeaveTimeCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/LeaveTime/create`,
				method: 'POST',
				body: queryArg.createLeaveTimeRequest,
			}),
			invalidatesTags: ['LeaveTime'],
		}),
		getLeaveTimeFind: build.query<GetLeaveTimeFindApiResponse, GetLeaveTimeFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/LeaveTime/find`,
				params: {
					userid: queryArg.userid,
					starttime: queryArg.starttime,
					endtime: queryArg.endtime,
					includedeactivated: queryArg.includedeactivated,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['LeaveTime'],
		}),
		patchLeaveTimeEdit: build.mutation<PatchLeaveTimeEditApiResponse, PatchLeaveTimeEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/LeaveTime/edit`,
				method: 'PATCH',
				body: queryArg.body,
				params: { leaveTimeId: queryArg.leaveTimeId },
			}),
			invalidatesTags: ['LeaveTime'],
		}),
		getStatFind: build.query<GetStatFindApiResponse, GetStatFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Stat/find`,
				params: {
					departmentid: queryArg.departmentid,
					projectid: queryArg.projectid,
					year: queryArg.year,
					month: queryArg.month,
					ascendingsort: queryArg.ascendingsort,
					nameincludesubstring: queryArg.nameincludesubstring,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Stat'],
		}),
		postWorkTimeCreate: build.mutation<PostWorkTimeCreateApiResponse, PostWorkTimeCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/WorkTime/create`,
				method: 'POST',
				body: queryArg.createWorkTimeRequest,
			}),
			invalidatesTags: ['WorkTime'],
		}),
		getWorkTimeFind: build.query<GetWorkTimeFindApiResponse, GetWorkTimeFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/WorkTime/find`,
				params: {
					userid: queryArg.userid,
					projectid: queryArg.projectid,
					month: queryArg.month,
					year: queryArg.year,
					includedeactivated: queryArg.includedeactivated,
					includedayjobs: queryArg.includedayjobs,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['WorkTime'],
		}),
		patchWorkTimeEdit: build.mutation<PatchWorkTimeEditApiResponse, PatchWorkTimeEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/WorkTime/edit`,
				method: 'PATCH',
				body: queryArg.body,
				params: { workTimeId: queryArg.workTimeId },
			}),
			invalidatesTags: ['WorkTime'],
		}),
		postWorkTimeDayJobCreate: build.mutation<PostWorkTimeDayJobCreateApiResponse, PostWorkTimeDayJobCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/WorkTimeDayJob/create`,
				method: 'POST',
				body: queryArg.createWorkTimeDayJobRequest,
			}),
			invalidatesTags: ['WorkTimeDayJob'],
		}),
		patchWorkTimeDayJobEdit: build.mutation<PatchWorkTimeDayJobEditApiResponse, PatchWorkTimeDayJobEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/WorkTimeDayJob/edit`,
				method: 'PATCH',
				body: queryArg.body,
				params: { workTimeDayJobId: queryArg.workTimeDayJobId },
			}),
			invalidatesTags: ['WorkTimeDayJob'],
		}),
		getWorkTimeMonthLimitFind: build.query<GetWorkTimeMonthLimitFindApiResponse, GetWorkTimeMonthLimitFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/WorkTimeMonthLimit/find`,
				params: {
					year: queryArg.year,
					month: queryArg.month,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['WorkTimeMonthLimit'],
		}),
		patchWorkTimeMonthLimitEdit: build.mutation<PatchWorkTimeMonthLimitEditApiResponse, PatchWorkTimeMonthLimitEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/WorkTimeMonthLimit/edit`,
				method: 'PATCH',
				body: queryArg.body,
				params: { workTimeMonthLimitId: queryArg.workTimeMonthLimitId },
			}),
			invalidatesTags: ['WorkTimeMonthLimit'],
		}),
	}),
});

export const {
	useGetImportGetQuery,
	useLazyGetImportGetQuery,
	usePostLeaveTimeCreateMutation,
	useGetLeaveTimeFindQuery,
	usePatchLeaveTimeEditMutation,
	useGetStatFindQuery,
	usePostWorkTimeCreateMutation,
	useGetWorkTimeFindQuery,
	useLazyGetWorkTimeFindQuery,
	usePatchWorkTimeEditMutation,
	usePostWorkTimeDayJobCreateMutation,
	usePatchWorkTimeDayJobEditMutation,
	useGetWorkTimeMonthLimitFindQuery,
	useLazyGetWorkTimeMonthLimitFindQuery,
	usePatchWorkTimeMonthLimitEditMutation,
} = time_serviceAPI;
