import cn from 'classnames';
import { DateTime } from 'luxon';
import { ComponentPropsWithoutRef, FC, useState } from 'react';
import { useAppSelector } from '@/app/redux/utils';
import { LeaveLog } from '@/pages/TimeTrackingPage/ui/LeaveLog/LeaveLog';
import { TimeLog } from '@/pages/TimeTrackingPage/ui/TimeLog/TimeLog';
import { LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO } from '@/shared/consts/consts';
import { getCurrentDayNumber } from '@/shared/lib/date';
import { DatePickerTitleButton } from '@/shared/ui/_inputs/date_pickers/DatePickerTitleButton';
import { LineTabs } from '@/shared/ui/_tabs/lineTabs';
import s from './Log.module.scss';

interface Props extends ComponentPropsWithoutRef<'div'> {
	selectedDate: Date;
	setSelectedDate: (date: Date) => void;
	minDate: Date; // TODO: Убрать константу
	maxDate: Date; // TODO: Убрать константу
}

export const Log: FC<Props> = props => {
	const {
		selectedDate, //
		setSelectedDate,
		minDate,
		maxDate,
		className,
	} = props;

	// * Selectors
	const userId = useAppSelector(state => state.user_service.user.userInfo?.user.id);

	// * Date select
	const selectedDateLuxon = DateTime.fromJSDate(selectedDate);

	const TODAY = new Date();
	const todayDateLuxon = DateTime.fromJSDate(TODAY);

	const currentDayNumber = getCurrentDayNumber(TODAY);
	const allowedToEditPreviousMonth = currentDayNumber <= LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO;
	const thisMonthSelected = selectedDateLuxon.month === todayDateLuxon.month && selectedDateLuxon.year === todayDateLuxon.year;
	const prevMonthSelected = selectedDateLuxon.month === todayDateLuxon.minus({ month: 1 }).month && selectedDateLuxon.year === todayDateLuxon.minus({ month: 1 }).year;
	const nextMonthSelected = selectedDateLuxon.month === todayDateLuxon.plus({ month: 1 }).month && selectedDateLuxon.year === todayDateLuxon.plus({ month: 1 }).year;
	const editingEnabled = (allowedToEditPreviousMonth && prevMonthSelected) || thisMonthSelected || nextMonthSelected;

	// * Render
	return (
		<div className={cn(s.container, className)}>
			<DatePickerTitleButton
				className={s.title}
				selectedDate={selectedDateLuxon.toJSDate()}
				setSelectedDate={setSelectedDate}
				minDate={minDate}
				maxDate={maxDate}
				prefix="Детализация за"
			/>

			{userId && (
				<TimeLog
					userId={userId}
					editingDisabled={!editingEnabled}
				/>
			)}

			{userId && (
				<LeaveLog
					userId={userId}
					editingDisabled={!editingEnabled}
				/>
			)}
		</div>
	);
};
