import { DateTime } from 'luxon';
import { VacationType } from '@/app/redux/queries/vacation-service/types/types';

export const getSeparatedPlannedVacations = (
	vacations: {
		startDate: Date | null;
		endDate: Date | null;
		id?: string;
	}[],
	defaultValues: {
		id: string;
		startDate: Date;
		endDate: Date;
	}[],
) => {
	// * vacations to delete
	const newVacationIds = vacations.map(vacation => vacation.id);
	const vacationsToDelete = defaultValues.filter(({ id }) => !newVacationIds.includes(id));

	// * vacations to add
	const vacationsToAdd = vacations
		.filter(vacation => !vacation.id)
		.map(({ startDate, endDate }) => ({
			startTime: DateTime.fromJSDate(startDate as Date).toFormat('yyyy-MM-dd'),
			endTime: DateTime.fromJSDate(endDate as Date).toFormat('yyyy-MM-dd'),
		}));

	// * vacations to edit
	const vacationsToEdit = vacations
		.filter(({ id, startDate, endDate }) =>
			defaultValues.find(
				vacation => vacation.id === id && (vacation.startDate.toISOString() !== startDate?.toISOString() || vacation?.endDate.toISOString() !== endDate?.toISOString()),
			),
		)
		.map(({ id, startDate, endDate }) => ({
			id,
			vacationBody: {
				type: 'Planned' as VacationType,
				time: {
					startTime: DateTime.fromJSDate(startDate as Date).toFormat('yyyy-MM-dd'),
					endTime: DateTime.fromJSDate(endDate as Date).toFormat('yyyy-MM-dd'),
				},
			},
		}));

	return { vacationsToDelete, vacationsToAdd, vacationsToEdit };
};
