import DocSVG from '@/shared/assets/svg_icons/file/doc.svg?react';
import FolderSVG from '@/shared/assets/svg_icons/file/folder.svg?react';
import PhotoSVG from '@/shared/assets/svg_icons/file/photo.svg?react';

export const getIcon = (type: string) => {
	if (type === '.jpg' || type === '.jpeg' || type === '.png') {
		return <PhotoSVG />;
	} else if (type === '.zip') {
		return <FolderSVG />;
	} else {
		return <DocSVG />;
	}
};
