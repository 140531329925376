import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SelectSingleWithSearch } from '../_inputs/selects/SelectSingleWithSearch';
import { extractNestedError } from '@/shared/lib/fields';

export interface SelectValue {
	id: string;
	name: string;
}

interface Props {
	name: string;
	label?: string;
	placeholder?: string;
	options: SelectValue[];
	isNullable?: boolean;
	required?: boolean;
	sortOptions?: boolean;
}

export const SelectSingleWithFilterField: React.FC<Props> = props => {
	const {
		name, //
		label,
		placeholder,
		options,
		isNullable,
		required,
		sortOptions,
	} = props;

	const { control, setValue } = useFormContext();

	// * Render
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState }) => (
				<SelectSingleWithSearch
					label={label}
					placeholder={placeholder}
					keyNames={{
						name: 'name',
						value: 'id',
					}}
					selectedOption={field.value}
					setSelectedOption={value => setValue(name, value, { shouldValidate: true })}
					options={options}
					sortOptions={sortOptions}
					isNullable={isNullable}
					errorMessage={extractNestedError(fieldState.error)}
					required={required}
				/>
			)}
		/>
	);
};
