import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from '@/app/redux/utils';
import { ButtonPair } from '@/entities/_buttons/ButtonPair/ButtonPair';
import { actionsNotifications } from '@/features/notifications/_BLL/slice';
import { InputTooltip } from '@/pages/AuthPage/ui/InputTooltip/InputTooltip';
import { getPasswordRules } from '@/pages/AuthPage/ui/Reset/utils/validate';
import { Heading } from '@/shared/ui/Heading';
import { PasswordField } from '@/shared/ui/_fields/PasswordField';
import * as yup from 'yup';
import s from './PasswordChange.module.scss';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';

interface Props {
	closeModal: () => void;
}

export const PasswordChange: React.FC<Props> = props => {
	const { closeModal } = props;

	// * API
	const [changePassword, { isLoading }] = user_serviceAPI.usePostPasswordChangeMutation();

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	// - Show tooltip
	const [show, setShow] = useState(false);

	// * Form
	const defaultValues = {
		password: '',
		newPassword: '',
		confirmPassword: '',
	};

	const schema = yup.object().shape({
		password: yup.string().required(''),
		newPassword: yup
			.string()
			.required('')
			.test('test-password', 'Пароль не соответствует требованиям', value => !!value && getPasswordRules(value).filter(rule => !rule.checked).length === 0),
		confirmPassword: yup.string().required(''),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch, setError, formState, clearErrors } = formMethods;

	const oldPassValue = watch('password');
	const newPassValue = watch('newPassword');
	const confirmNewPass = watch('confirmPassword');

	const rules = useMemo(() => getPasswordRules(newPassValue), [newPassValue]);

	useEffect(() => {
		if (newPassValue && confirmNewPass && newPassValue !== confirmNewPass) {
			setError('root', { message: 'Пароли не совпадают' });
		} else {
			clearErrors();
		}
	}, [newPassValue, confirmNewPass]);

	const onSubmit = (data: typeof defaultValues) => {
		changePassword({
			changePasswordRequest: {
				password: data.password,
				newPassword: data.newPassword,
			},
		})
			.unwrap()
			.then(() => {
				dispatch(
					addNotification({
						type: 'success',
						message: 'Пароль успешно изменён',
					}),
				);
				closeModal();
			})
			.catch(err => console.log(err));
	};

	// * Render
	return (
		<FormProvider {...formMethods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={s.form}
			>
				<Heading level={2}>Смена пароля</Heading>

				<div className={s.form__fields}>
					<PasswordField
						name="password"
						label="Старый пароль"
						placeholder="Введите старый пароль"
						maxLength={50}
					/>

					<PasswordField
						name="newPassword"
						label="Новый пароль"
						placeholder="Введите новый пароль"
						maxLength={50}
						autoComplete="new-password"
						onFocus={() => {
							setShow(true);
						}}
						onBlur={() => setShow(false)}
						errorBorder={!!formState.errors?.root}
					/>
					{show && <InputTooltip rules={rules} />}

					<PasswordField
						name="confirmPassword"
						label="Подтверждение нового пароля"
						placeholder="Повторите новый пароль"
						maxLength={50}
						errorBorder={!!formState.errors?.root}
					/>

					{formState.errors?.root && <div className={s.form__error}>{formState.errors.root.message}</div>}
				</div>

				<ButtonPair
					primaryText="Сменить пароль"
					primaryIsLoading={isLoading}
					primaryDisabled={!oldPassValue || !newPassValue || !confirmNewPass || !!formState.errors?.root}
					secondaryText="Отменить"
					secondaryOnClick={closeModal}
					secondaryIsLoading={isLoading}
				/>
			</form>
		</FormProvider>
	);
};
