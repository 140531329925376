import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './app/App';
import { store } from './app/redux/store';
import './index.scss';
import { RELEASE_VERSION } from './shared/consts/consts';

Sentry.init({
	dsn: 'https://722836bf11ec449f9c8833b5377b25e0@monitoring-gitlab.digital-office.dso.lanit-tercom.com/1',
	release: RELEASE_VERSION,
	environment: import.meta.env.NODE_ENV,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<Provider store={store}>
		<App />
	</Provider>,
);
