import { LeaveType } from '@/app/redux/queries/time-service/types/types';

export const PROJECT_STATUS_OPTIONS = [
	{
		id: 'Active',
		name: 'В работе',
	} as const,
	{
		id: 'Suspend',
		name: 'Приостановлен',
	} as const,
	{
		id: 'Closed',
		name: 'Завершен',
	} as const,
];

export interface LeaveTypeOption {
	name: string;
	id: LeaveType | null;
}

export const LEAVE_TIME_OPTIONS: LeaveTypeOption[] = [
	{
		name: 'Отпуск',
		id: 'Vacation',
	},
	{
		name: 'Больничный',
		id: 'SickLeave',
	},
	{
		name: 'Обучение',
		id: 'Training',
	},
	{
		name: 'Отгул',
		id: 'Idle',
	},
	// {
	// 	name: 'Декретный отпуск',
	// 	id: 'Decree',
	// },
];
