import { useEffect, useState } from 'react';
import { useLazyGetClaimsQuery } from '@/app/redux/queries/claim-service/claim_serviceAPI';
import { ClaimInfo, ClaimPriority, ClaimStatus } from '@/app/redux/queries/claim-service/types/types';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';
import { FilterClaim } from '@/pages/_claims/ui/ClaimSystemPage/context/context';

interface UserClaim extends ClaimInfo {
	userName: string;
}
interface Props {
	userId?: string;
	filter?: FilterClaim;
	removeFlag?: Boolean;
}

export const useClaimsFilterQueries = (props: Props) => {
	const {
		userId, //
		filter,
		removeFlag,
	} = props;

	const [claims, setClaims] = useState<UserClaim[]>([]);
	const [isLoaded, setIsLoaded] = useState(false);

	// * Api
	const [getClaims, { isLoading: isClaimsLoading }] = useLazyGetClaimsQuery();
	const [getUsers, { isLoading: isUsersLoading }] = user_serviceAPI.useLazyGetUserGetQuery();
	const isLoading = isClaimsLoading || isUsersLoading;

	useEffect(() => {
		loadData();
	}, [filter, removeFlag]);

	const loadData = async () => {
		setIsLoaded(false);

		const { status, topic, priority, sender } = filter ?? {};
		const newClaims: UserClaim[] = [];

		const claims = await getClaims(
			{
				skipcount: 0,
				takecount: 1000,
				...(topic && { nameIncludeSubstring: topic }),
				...(status?.id && { status: status.id as ClaimStatus }),
				...(priority?.id && { priority: priority.id as ClaimPriority }),
				...(sender?.id && { createdBy: sender.id }),
				...(userId && { createdBy: userId }),
			},
			true,
		);

		const filteredClaims = claims.data?.body || [];

		for (const item of filteredClaims) {
			await getUsers(
				{
					userid: item.createdBy,
				},
				true,
			)
				.unwrap()
				.then(resUser => {
					const userInfo = resUser.body;

					newClaims.push({
						...item,
						userName: userInfo ? `${userInfo.user.lastName} ${userInfo.user.firstName}` : '',
					});
				})
				.catch(error => console.log(error));
		}

		setIsLoaded(true);
		setClaims(newClaims);
	};

	return { isLoaded, claims, isLoading };
};
