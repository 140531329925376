import React from 'react';
import { PercentageUsers } from './PercentageUsers/PercentageUsers';
import s from './AnalyticsContent.module.scss';
import { company_structure_serviceAPI } from '@/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { useParams } from 'react-router-dom';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { EMPTY_ID } from '@/shared/consts/consts';

interface Props {
	isAnonymous: boolean;
}

export const AnalyticsContent: React.FC<Props> = ({ isAnonymous }) => {
	// * Router
	const { surveyId } = useParams<'surveyId'>();

	// * API
	const { data: surveyStatistics, isLoading } = company_structure_serviceAPI.useGetSurveyStatisticsQuery(
		{
			surveyid: surveyId as string,
		},
		{ skip: !surveyId },
	);

	const total = surveyStatistics ? surveyStatistics.reduce((acc, item) => acc + item.numberOfEmployes, 0) : 0;
	const count = surveyStatistics ? surveyStatistics.reduce((acc, item) => acc + item.completedSurveyEmployes, 0) : 0;

	return (
		<div className={s.container}>
			<PercentageUsers
				total={total}
				count={count}
				title="Все сотрудники"
				surveyId={surveyId || ''}
				departmentId={EMPTY_ID}
				isAnonymous={!!isAnonymous}
			/>

			<h5 className={s.subtitle}>Департаменты</h5>

			{isLoading && (
				<div className={s.loading}>
					<LoaderCircle />
				</div>
			)}

			{surveyStatistics &&
				surveyStatistics.map(item => (
					<PercentageUsers
						key={item.departmentId}
						surveyId={surveyId || ''}
						departmentId={item.departmentId}
						total={item.numberOfEmployes}
						count={item.completedSurveyEmployes}
						title={item.departmentName}
						isAnonymous={!!isAnonymous}
					/>
				))}
		</div>
	);
};
