import { GroupInfo, QuestionType, UserInfo } from '@/app/redux/queries/survey-service/types';
import { sortAscending } from '@/pages/_surveys/libs/sortAscending/sortAscending';
import { v4 } from 'uuid';
import { Option, ScaleOption, QuestionTypeInfo, SectionProps } from '../pages/NewSurveyPage/_types';
import { questionCategories } from '../pages/NewSurveyPage/meta/surveyCategory';

// get type question
export const getTypeQuestion = (opt: Option[], scaleOpt: ScaleOption[], hasCustomOptions: boolean): QuestionType => {
	return opt && (opt.length > 0 || hasCustomOptions) ? 'Base' : scaleOpt && scaleOpt.length > 0 ? 'Scale' : 'Grid';
};

export const getCustomTypeQuestion = (hasMulti: boolean, type: QuestionType): QuestionTypeInfo => {
	return questionCategories.find(item => item.hasMultipleChoice === hasMulti && item.type === type) || questionCategories[0];
};

export const getInitialStateSurveyGroups = (surveyId: string | null, surveyIdData?: Array<SectionProps | GroupInfo> | null): SectionProps[] => {
	const initialGroups: SectionProps[] =
		surveyId && surveyIdData
			? surveyIdData.map(group => {
					const { questions } = group;

					const newQs = questions?.map(question => {
						const { options, gridOptions, scaleOptions, hasCustomOptions } = question;

						const opts =
							options?.map(opt => ({
								...opt,
								content: opt.content || '',
							})) || [];

						const sortedOpts = [...opts].sort((opt1, opt2) => sortAscending(opt1.position || '', opt2.position || ''));

						const gridOpts =
							gridOptions?.map(opt => ({
								...opt,
								columnSignature: opt.columnSignature || '',
								rowSignature: opt.rowSignature || '',
							})) || [];

						const sortedGridOpts = [...gridOpts].sort((opt1, opt2) => sortAscending(opt1.position || '', opt2.position || ''));

						const scaleOpts =
							scaleOptions?.map(opt => ({
								...opt,
								content: opt.content || '',
								leftSignature: opt.leftSignature || '',
								rightSignature: opt.rightSignature || '',
							})) || [];

						const sortedScaleOpts = [...scaleOpts].sort((opt1, opt2) => sortAscending(opt1.content, opt2.content));

						const typeQ = getTypeQuestion(opts, scaleOpts, !!hasCustomOptions);
						const customType = getCustomTypeQuestion(question.hasMultipleChoice, typeQ);

						return {
							...question,
							content: question.content || '',
							type: customType,
							options: sortedOpts || [],
							gridOptions: sortedGridOpts || [],
							scaleOptions: sortedScaleOpts || [],
							// questionImages: [],
						};
					});

					return {
						...group,
						questions: newQs ? [...newQs].sort((q1, q2) => sortAscending(q1.position, q2.position)) : [],
					};
				})
			: [
					{
						id: `new_${v4()}`,
						subject: 'Раздел 1',
						position: 1,
						questions: [
							{
								id: `new_${v4()}`,
								content: '',
								type: questionCategories[0],
								isObligatory: false,
								hasMultipleChoice: true,
								position: 1,
								options: [
									{
										id: `new_${v4()}`,
										content: 'Вариант 1',
										isCustom: false,
									},
								],
								gridOptions: [],
								scaleOptions: [],
								questionImages: [],
							},
						],
					},
				];

	return initialGroups;
};

// Получить всех пользователей по ответам
export const getUsers = (isAnonymous: boolean, data?: Array<SectionProps | GroupInfo> | null): UserInfo[] => {
	const groups = getInitialStateSurveyGroups('surveyId', data);

	const users: UserInfo[] = [];

	groups.map(group => {
		group.questions.map(question => {
			const { options, gridOptions, scaleOptions } = question;

			const opts = [];
			if (options && options.length > 0) opts.push(...options);
			if (gridOptions && gridOptions.length > 0) opts.push(...gridOptions);
			if (scaleOptions && scaleOptions.length > 0) opts.push(...scaleOptions);

			opts.map(opt => {
				opt.usersAnswers &&
					opt.usersAnswers.map(answer => {
						answer.user && users.push(answer.user);
					});
			});
		});
	});

	const filteredUsers = users.reduce((acc: UserInfo[], user) => {
		const isExistUser = isAnonymous ? acc.find(item => item.firstName === user.firstName) : acc.find(item => item.id === user.id);

		if (isExistUser) return acc;

		return [...acc, user];
	}, []);

	return filteredUsers;
};

// Все разделы с ответами конкретного пользователя
export const getSortGroupByUser = (name: string, groups?: Array<SectionProps | GroupInfo> | null): Array<SectionProps> => {
	const prepared = getInitialStateSurveyGroups('surveyId', groups);
	const newGroups = prepared.map(group => {
		const { questions } = group;

		const newQs = questions.map(question => {
			const { options, gridOptions, scaleOptions } = question;

			const opts = options.map(opt => {
				const { usersAnswers } = opt;

				return {
					...opt,
					usersAnswers: usersAnswers?.filter(answer => answer.user?.firstName === name),
				};
			});

			const gridOpts = gridOptions.map(opt => {
				const { usersAnswers } = opt;

				return {
					...opt,
					usersAnswers: usersAnswers?.filter(answer => answer.user?.firstName === name),
				};
			});

			const scaleOpts = scaleOptions.map(opt => {
				const { usersAnswers } = opt;

				return {
					...opt,
					usersAnswers: usersAnswers?.filter(answer => answer.user?.firstName === name),
				};
			});

			return {
				...question,
				options: opts,
				gridOptions: gridOpts,
				scaleOptions: scaleOpts,
			};
		});

		return {
			...group,
			questions: newQs,
		};
	});

	return newGroups;
};
