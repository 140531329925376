import cn from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FindSurveysResultInfo } from '@/app/redux/queries/survey-service/types';
import { backendISOUTCToLocalLuxon, getIsPastDate, getTimeString, tupleToIsoString } from '@/shared/lib/date';
import { Hours, Minutes } from '@/shared/lib/date/types';
import { ChipCategory } from '../ChipCategory/ChipCategory';
import { DeleteSurveyModal } from '../DeleteSurveyModal/DeleteSurveyModal';
import s from './SurveyCard.module.scss';
import { DateTime } from 'luxon';

interface Props {
	survey: FindSurveysResultInfo;
	isEdit?: boolean;
	isTemplate?: boolean;
}

export const SurveyCard: React.FC<Props> = props => {
	const { survey, isEdit, isTemplate = false } = props;

	const { id, isCompleted, subject, deadline, description, createdAtUtc, surveyCategories } = survey;

	// * localStorage
	const visitedSurveysJSON = localStorage.getItem('visited_surveys');
	const visitedSurveys = visitedSurveysJSON ? JSON.parse(visitedSurveysJSON) : null;
	const isVisitedSurvey = Array.isArray(visitedSurveys) && !!visitedSurveys.find(surveyId => id === surveyId);

	// * Navigate
	const navigate = useNavigate();

	const toNavigateSurvey = () => {
		const navigateTo = isTemplate ? 'template/' + id : id;
		navigate(navigateTo);
	};

	// * Format date in 'DD.MM в HH:MM'
	const formatSurveyDate = (dateFormatString: string, isBackend?: boolean) => {
		const formatDate = isBackend ? backendISOUTCToLocalLuxon(dateFormatString) : DateTime.fromISO(dateFormatString);
		const preparedTime = tupleToIsoString([formatDate.hour as Hours, formatDate.minute as Minutes]);
		const preparedDate = `${getTimeString(formatDate.day as Hours)}`;
		const preparedMonth = `${getTimeString(formatDate.month as Hours)}`;

		return `${preparedDate}.${preparedMonth} в ${preparedTime}`;
	};

	const createdAt = formatSurveyDate(createdAtUtc, true);
	const expirationDate = deadline ? formatSurveyDate(deadline) : '-';

	const isEndedSurvey = getIsPastDate(deadline);

	// * Category
	const hasCategory = surveyCategories && surveyCategories.length > 0;

	// * Render
	return (
		<>
			<div
				onClick={toNavigateSurvey}
				className={s.container}
			>
				{(isCompleted || !isVisitedSurvey) && !isTemplate && <div className={cn(s.survey_dot, !isVisitedSurvey && s.survey_dot_new, isCompleted && s.survey_dot_complete)} />}

				<div className={s.header}>
					<div className={s.title}>{subject}</div>

					<div className={s.actions}>
						{isEdit && (
							<div className={s.menu}>
								<DeleteSurveyModal
									id={id}
									title={subject}
									isEndedSurvey={isEndedSurvey}
									isTemplate={isTemplate}
								/>
							</div>
						)}
					</div>
				</div>

				<div className={s.description}>{description}</div>

				<div className={s.bottom}>
					<div className={s.period}>
						<div className={s.date}>
							<span>Создан: </span>
							<span className={isEndedSurvey ? s.date_disabled : ''}>{createdAt}</span>
						</div>

						<div className={s.date}>
							<span>Окончание: </span>
							<span className={isEndedSurvey ? s.date_disabled : ''}>{expirationDate}</span>
						</div>
					</div>

					{hasCategory && <ChipCategory category={surveyCategories[0]} />}
				</div>
			</div>
		</>
	);
};
