import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { DateTime } from 'luxon';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePostBookingCreateMutation } from '@/app/redux/queries/office-service/office_serviceAPI';
import { UserInfo } from '@/app/redux/state/user/user/types';
import { Button } from '@/shared/ui/_buttons/Button';
import { DateField } from '@/shared/ui/_fields/DateField';
import { SwitchField } from '@/shared/ui/_fields/SwitchField';
import { TextAreaField } from '@/shared/ui/_fields/TextAreaField';
import * as yup from 'yup';
import { ModalHeader } from '../../../../../../shared/ui/_modals/ModalHeader/ModalHeader';
import { SelectedCellInfoPartial } from '../PartialBookingMC/PartialBookingMC';
import s from './ForbidPartialBookingMC.module.scss';
import { ButtonPair } from '@/entities/_buttons/ButtonPair';

interface Props {
	forbidInfo: SelectedCellInfoPartial;
	userInfo: UserInfo;
	closeModal: () => void;
}

export const ForbidPartialBookingMC: React.FC<Props> = props => {
	const {
		forbidInfo: { selectedDate, workspace, workspaceBookingList },
		userInfo,
		closeModal,
	} = props;

	// * API
	const [createBooking, { isLoading }] = usePostBookingCreateMutation();

	// * Form
	const schema = yup.object().shape({
		comment: yup.string().nullable().required('Заполните комментарий'),
	});

	const formMethods = useForm({
		defaultValues: {
			isEndless: false,
			startDate: selectedDate,
			endDate: selectedDate,
			comment: null,
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch } = formMethods;

	const isEndless = watch('isEndless');

	const onSubmit = (values: any) => {
		const { comment, isEndless, startDate, endDate } = values;

		// !noEndDate &&
		createBooking({
			createBookingRequest: {
				startTime: startDate.toLocaleString('sv'),
				endTime: isEndless ? null : DateTime.fromJSDate(endDate).plus({ day: 1 }).toJSDate().toLocaleString('sv'),
				userId: userInfo.user.id,
				parentId: workspace.id,
				isBan: true,
				comment,
			},
		})
			.unwrap()
			.then(() => closeModal())
			.catch(error => console.log('error', error));
	};

	// * Render
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormProvider {...formMethods}>
				<ModalHeader
					title="Запрет бронирования"
					placeName={workspace.name}
				/>

				<div className={s.inner}>
					<SwitchField
						name="isEndless"
						text="Без даты окончания"
					/>

					<DateField
						label="Дата начала"
						name="startDate"
						minDate={DateTime.fromJSDate(new Date()).plus({ day: 1 }).toJSDate()}
					/>

					<DateField
						label="Дата окончания"
						name="endDate"
						minDate={DateTime.fromJSDate(selectedDate).toJSDate()}
						disabled={isEndless}
					/>

					<TextAreaField
						name="comment"
						label="Комментарий"
						placeholder="Введите комментарий"
						size="tiny"
					/>
				</div>

				<ButtonPair
					primaryText="Подтвердить"
					primaryIsLoading={isLoading}
					secondaryText="Отменить"
					secondaryOnClick={() => closeModal()}
					secondaryIsLoading={isLoading}
				/>
			</FormProvider>
		</form>
	);
};
