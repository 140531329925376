import { DateTime } from 'luxon';
import { dateToTimeString, injectTimeToDate } from '@/shared/lib/date';

export const dateAndTimeToString = (expirationTime: null | Date, expirationDate: Date): string | null => {
	const timeFormatString = expirationTime ? dateToTimeString(expirationTime) : '00:00';

	const deadlineSurvey = injectTimeToDate(expirationDate, timeFormatString);

	return DateTime.fromJSDate(deadlineSurvey).toISO({ includeOffset: false, suppressMilliseconds: true, suppressSeconds: true });
};
