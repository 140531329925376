import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { CreateDepartmentREQ, CreateDepartmentRES, DepartmentInfo, GetAllDepartmentsRES, GetDepartmentRES, ReorderedUnits } from './types';

const NAME = `${ServiceName.COMPANY_STRUCTURE_SERVICE}/Departments`;

const getAllDepartments = createAppAsyncThunk(`${NAME}/getAllDepartments`, async (arg: void, thunkAPI) => {
	return await apiRequest.getRequest<GetAllDepartmentsRES>({
		url: `${NAME}`,
		thunkAPI,
		action: () => getAllDepartments(),
	});
});

const createDepartment = createAppAsyncThunk(`${NAME}/createDepartment`, async (payload: CreateDepartmentREQ, thunkAPI) => {
	const { dispatch } = thunkAPI;

	await apiRequest.postRequest<CreateDepartmentRES>({
		url: `${NAME}`,
		payload,
		thunkAPI,
		action: () => createDepartment(payload),
	});

	dispatch(getAllDepartments());
});

const getDepartment = createAppAsyncThunk(`${NAME}/getDepartment`, async (departmentId: string, thunkAPI) => {
	return await apiRequest.getRequest<GetDepartmentRES>({
		url: `${NAME}/${departmentId}`,
		thunkAPI,
		action: () => getDepartment(departmentId),
	});
});

interface State {
	allDepartments: DepartmentInfo[];
	departmentDetail: GetDepartmentRES | null;
	reorderedUnits: ReorderedUnits;
	status: RequestStatus;
}

export const initialState: State = {
	allDepartments: [],
	reorderedUnits: {},
	departmentDetail: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		addReorderedUnits: (state, action: PayloadAction<ReorderedUnits>) => {
			state.reorderedUnits = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(getAllDepartments.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getAllDepartments.fulfilled, (state, action) => {
			state.allDepartments = action.payload.body;
			state.status = RequestStatus.still;
		});
		builder.addCase(getAllDepartments.rejected, state => {
			state.status = RequestStatus.failed;
		});
		builder.addCase(createDepartment.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createDepartment.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(createDepartment.rejected, state => {
			state.status = RequestStatus.failed;
		});
		builder.addCase(getDepartment.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getDepartment.fulfilled, (state, action) => {
			state.departmentDetail = action.payload;
			state.status = RequestStatus.still;
		});
		builder.addCase(getDepartment.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	getAllDepartments,
	createDepartment,
	getDepartment,
};
