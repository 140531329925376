import { useLazyGetUserGetQuery } from '@/app/redux/queries/user-service/user_serviceAPI';
import { UserResponse } from '@/app/redux/queries/vacation-service/types/resTypes';
import { VacationType } from '@/app/redux/queries/vacation-service/types/types';
import { getDaysSum } from '@/shared/lib/date';
import { createFullName } from '@/shared/lib/string';
import { useEffect, useState } from 'react';
import { SubGroupType } from '../../GroupPage/types';
import { VACATION_MAP, VacationKeys } from '../consts/consts';
import { User } from '../types';
import { useGetGroupQuery } from './useGetGroupQuery';

type UserWithSubgroup = UserResponse & { groups?: { type: SubGroupType; name: string }[] };

export const useGetGroupUsers = (groupId: string | null) => {
	// * API
	const { data: groupData, refetch } = useGetGroupQuery(groupId);

	const group = groupData?.body;

	const [getUser] = useLazyGetUserGetQuery();
	const [usersLoadingState, setUsersLoadingState] = useState<'loading' | 'success' | 'idle'>('idle');

	// * Users
	const [users, setUsers] = useState<User[]>([]);

	useEffect(() => {
		const subGroupUsers: UserWithSubgroup[] = [];

		(group?.subgroups ?? []).forEach(subGroup => {
			subGroup.users?.forEach(user => {
				const existingUserIndex = subGroupUsers.findIndex(u => u.id === user.id);

				if (existingUserIndex !== -1) {
					subGroupUsers[existingUserIndex].groups?.push({
						name: subGroup.name,
						type: subGroup.type,
					});
				} else {
					subGroupUsers.push({
						...user,
						groups: [
							{
								name: subGroup.name,
								type: subGroup.type,
							},
						],
						vacations: group?.users?.find(groupUser => groupUser.id === user.id)?.vacations ?? [],
					});
				}
			});
		});

		const groupUsers: UserWithSubgroup[] = group?.users?.filter(groupUser => !subGroupUsers.find(subGroupUser => subGroupUser.id === groupUser.id)) ?? [];

		const allUsers = [...groupUsers, ...subGroupUsers];

		const promises: ReturnType<typeof getUser>[] = [];

		if (allUsers.length > 0) {
			setUsersLoadingState('loading');
			allUsers.forEach(user => {
				promises.push(
					getUser({
						userid: user.id,
						includeposition: true,
						includecurrentavatar: true,
					}),
				);
			});
		}

		Promise.all(promises)
			.then(res => {
				const tableUsers: User[] = [];

				res.forEach((resItem, index) => {
					const resUser = resItem.data?.body?.user;
					const groupUser = allUsers[index];

					const left = groupUser.body.remainingVacationDays; // Остатки отпуска с прошлых лет.
					const total = (resItem.data?.body?.userAddition?.entitledVacationDays ?? 0) + left; // Сумма отпусков в год. TODO: Возможно лучше показывать ошибку вместо 0
					const used = groupUser.vacations // Использованные дни на фактический отпуск.
						? groupUser.vacations
								.filter(({ vacationBody }) => vacationBody?.type === 'Actual')
								.reduce((acc, value) => {
									const time = value.vacationBody?.time;
									let vacationTime = 0;
									if (time && time.endTime && time.startTime) {
										vacationTime = getDaysSum(new Date(time.startTime), new Date(time.endTime));
									}

									return acc + vacationTime;
								}, 0)
						: 0;

					if (resUser) {
						const user: User = {
							id: resUser.id,
							name: createFullName({
								lastName: resUser.lastName ?? '',
								middleName: resUser.middleName ?? '',
								firstName: resUser.firstName ?? '',
							}),
							position: resItem.data?.body?.position?.name ?? '',
							avatar: resUser.avatar,
							category: groupUser.groups?.find(group => group.type === 'Category')?.name || null,
							group: groupUser.groups?.find(group => group.type === 'Group')?.name || null,
							team: groupUser.groups?.find(group => group.type === 'Team')?.name || null,
							days: {
								total,
								left,
								used,
							},
							vacations: groupUser.vacations?.map(({ id, vacationBody }) => ({
								id: id as string,
								title: VACATION_MAP[vacationBody?.type as VacationKeys].name,
								startDate: vacationBody?.time?.startTime as string,
								endDate: vacationBody?.time?.endTime as string,
								type: vacationBody?.type as VacationType,
							})),
						};

						tableUsers.push(user);
					}
				});

				tableUsers.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

				setUsers(tableUsers);
				setUsersLoadingState('success');
			})
			.catch(error => console.log('error', error));

		return () => {
			setUsers([]);
			setUsersLoadingState('idle');
		};
	}, [group?.id]);

	return {
		refetchGroup: refetch,
		users,
		isLoading: usersLoadingState === 'loading',
		isLoadingSuccess: usersLoadingState === 'success',
	};
};
