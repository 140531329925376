import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { ImageInfo } from '../../image/image/types';
import { CreateImageREQ, GetImagesREQ, GetImagesRES, ImagesEntity, RemoveImagesREQ } from './types';

const NAME = `${ServiceName.OFFICE_SERVICE}/image`;

const createImage = createAppAsyncThunk(`${NAME}/createImage`, async (arg: CreateImageREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest({
		url: `${NAME}/create`,
		payload,
		thunkAPI,
		action: () => createImage(arg),
	});
});

const getImages = createAppAsyncThunk(`${NAME}/getImages`, async (arg: GetImagesREQ, thunkAPI) => {
	const {
		params,
		options: { entity },
	} = arg;

	const res = await apiRequest.getRequest<GetImagesRES>({
		url: `${NAME}/get`,
		params,
		thunkAPI,
		showNotification: false,
		action: () => getImages(arg),
	});

	return {
		res,
		entity,
	};
});

const removeImages = createAppAsyncThunk(`${NAME}/removeImages`, async (arg: RemoveImagesREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.delRequest<string>({
		url: `${NAME}/remove`,
		payload,
		thunkAPI,
		action: () => removeImages(arg),
	});
});

export interface State {
	images: ImageInfo[];
	officeImages: ImageInfo[];
	schemaImages: ImageInfo[];
	roomImages: ImageInfo[];
	status: RequestStatus;
}

export const initialState: State = {
	images: [],
	officeImages: [],
	schemaImages: [],
	roomImages: [],
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		clearImages: (state, action: PayloadAction<ImagesEntity>) => {
			switch (action.payload) {
				case 'office':
					state.officeImages = [];
					return;
				case 'schema':
					state.schemaImages = [];
					return;
				case 'room':
					state.roomImages = [];
					return;
			}
		},
	},
	extraReducers: builder => {
		builder.addCase(createImage.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createImage.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(createImage.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getImages.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getImages.fulfilled, (state, action) => {
			state.status = RequestStatus.still;
			switch (action.payload.entity) {
				case 'office':
					state.officeImages = action.payload.res;
					return;
				case 'schema':
					state.schemaImages = action.payload.res;
					return;
				case 'room':
					state.roomImages = action.payload.res;
					return;
			}
		});
		builder.addCase(getImages.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(removeImages.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(removeImages.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(removeImages.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	createImage,
	getImages,
	removeImages,
};
