import { useNavigate } from 'react-router-dom';
import { usePutFeedbackEditstatusMutation } from '@/app/redux/queries/feedback-service/feedback_serviceAPI';
import { gateway_serviceAPI } from '@/app/redux/queries/gateway-service/gateway_serviceAPI';
import { useAppDispatch } from '@/app/redux/utils';
import { routerPath } from '@/app/router/paths';
import { ButtonPair } from '@/entities/_buttons/ButtonPair';
import { Heading } from '@/shared/ui/Heading';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import s from './DeleteModal.module.scss';

interface Props {
	selectedIds: string[];
	setSelected?: (selectedIds: string[]) => void;
	isActive: boolean;
	toggleModal: () => void;
}

export const DeleteModal: React.FC<Props> = props => {
	const {
		selectedIds, //
		setSelected,
		isActive,
		toggleModal,
	} = props;

	// * Router
	const navigate = useNavigate();

	// * API
	const dispatch = useAppDispatch();
	const [editFeedback, { isLoading }] = usePutFeedbackEditstatusMutation();

	// * User actions
	const onDeleteClick = () => {
		editFeedback({
			editFeedbackStatusesRequest: {
				feedbackIds: selectedIds,
				status: 'Archived',
			},
		})
			.unwrap()
			.then(() => {
				setSelected ? setSelected([]) : navigate(`${routerPath.feedback.page}`);
				toggleModal();
			})
			.catch(error => console.log('error :>> ', error))
			.finally(() => {
				dispatch(gateway_serviceAPI.util.resetApiState());
			});
	};

	// * Render
	return (
		<ModalNewProto
			isOpen={isActive}
			onClose={toggleModal}
		>
			<div className={s.container}>
				<Heading level={3}>Удаление</Heading>
				<p>Вы действительно хотите удалить выбранные обращения?</p>
				<ButtonPair
					primaryText="Удалить"
					secondaryText="Отмена"
					primaryOnClick={onDeleteClick}
					secondaryOnClick={toggleModal}
					primaryIsLoading={isLoading}
					secondaryIsLoading={isLoading}
				/>
			</div>
		</ModalNewProto>
	);
};
