import React, { ChangeEvent } from 'react';
import s from './NewSurveySettings.module.scss';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { TextArea } from '@/shared/ui/_inputs/text_Inputs/TextArea';
import { BooleanSwitch } from '@/shared/ui/_switches/BooleanSwitch/BooleanSwitch';
import { DateAndTimePickerInput } from '@/shared/ui/_inputs/date_pickers/DateAndTimePickerInput';
import { SelectSingle } from '@/shared/ui/_inputs/selects/SelectSingle';
import { CategoryInfo } from '@/app/redux/queries/survey-service/types';
import { Survey } from '../../pages/NewSurveyPage/_types';

interface Props {
	surveyInfo: Survey;
	setSurveyInfo: React.Dispatch<React.SetStateAction<Survey>>;
	isTemplate: boolean;
	prevIsAnonymous: boolean;
	errors: string[];
	getSelectedCategory: () => CategoryInfo | { id: ''; name: '' };
	categories: CategoryInfo[] | null | undefined;
	isEdit?: boolean;
}

export const NewSurveySettings: React.FC<Props> = props => {
	const { surveyInfo, setSurveyInfo, prevIsAnonymous, isTemplate, errors, getSelectedCategory, categories, isEdit } = props;

	// * on change handlers
	const onSurveyTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;

		setSurveyInfo({ ...surveyInfo, title: value });
	};

	const onSurveyDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		const value = event.currentTarget.value;

		setSurveyInfo({ ...surveyInfo, description: value });
	};

	const onOptionsChange = (event: ChangeEvent<HTMLInputElement>, switchName: keyof Survey) => {
		const checked = event.target.checked;

		if (switchName === 'limited') {
			setSurveyInfo({ ...surveyInfo, [switchName]: checked, dateEnd: null, timeEnd: null });
			return;
		}

		setSurveyInfo({ ...surveyInfo, [switchName]: checked });
	};

	const onCategoryChange = (categoryId: string | null) => {
		setSurveyInfo({ ...surveyInfo, categoryId });
	};

	const onDeadlineDateChange = (date: Date | null) => {
		setSurveyInfo({
			...surveyInfo,
			dateEnd: date,
		});
	};

	const onDeadlineTimeChange = (time: Date | null) => {
		setSurveyInfo({
			...surveyInfo,
			timeEnd: time,
		});
	};

	const header = `${isEdit ? 'Редактирование' : 'Новый'} ${isTemplate ? ' шаблон' : 'опрос'}${isEdit ? 'а' : ''}`;

	// * Render
	return (
		<div className={s.common_settings}>
			<div className={s.survey_header}>{header}</div>

			<div className={s.survey_about}>
				<TextInput
					placeholder="Название опроса"
					value={surveyInfo.title}
					maxLength={150}
					onChange={onSurveyTitleChange}
					errorBorder={errors.includes('title')}
				/>

				<TextArea
					size="small"
					placeholder="Описание"
					value={surveyInfo.description}
					maxLength={500}
					onChange={onSurveyDescriptionChange}
				/>
			</div>

			<div className={s.survey_options}>
				<BooleanSwitch
					disabled={isEdit && prevIsAnonymous}
					text="Анонимный опрос"
					checked={surveyInfo.isAnonymous}
					onChange={e => onOptionsChange(e, 'isAnonymous')}
				/>

				<BooleanSwitch
					text="Разрешить отмену голоса"
					checked={surveyInfo.cancelVoice}
					onChange={e => onOptionsChange(e, 'cancelVoice')}
				/>

				<BooleanSwitch
					text="Ограниченное время голосования"
					checked={surveyInfo.limited}
					onChange={e => onOptionsChange(e, 'limited')}
				/>

				<BooleanSwitch
					disabled={isEdit}
					text="Все новые вопросы обязательны"
					checked={surveyInfo.allRequiredFields}
					onChange={e => onOptionsChange(e, 'allRequiredFields')}
				/>
			</div>

			<div className={s.additional}>
				<DateAndTimePickerInput
					label="Дата и время окончания опроса"
					disabled={!surveyInfo.limited}
					selectedDate={surveyInfo.dateEnd}
					setSelectedDate={date => onDeadlineDateChange(date)}
					activeTime={surveyInfo.timeEnd}
					setActiveTime={time => onDeadlineTimeChange(time)}
					errorBorderDate={errors.includes('dateEnd') && !surveyInfo.dateEnd && surveyInfo.limited}
				/>

				{categories && (
					<SelectSingle
						label="Категория"
						placeholder="Категория"
						keyNames={{
							name: 'name',
							value: 'id',
						}}
						selectedOption={getSelectedCategory()}
						setSelectedOption={(option: CategoryInfo | { id: string; name: string }) => {
							onCategoryChange(option.id);
						}}
						options={categories}
					/>
				)}
			</div>
		</div>
	);
};
