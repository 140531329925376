import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { officeServiceActions } from '@/app/redux/state/office/actions';
import { CreateRoomREQ, EditRoomPayloadItem } from '@/app/redux/state/office/room/types';
import { EditRoomTypePayloadItem } from '@/app/redux/state/office/roomType/types';
import { BookingRule } from '@/app/redux/state/office/types';
import { useAppDispatch, useAppSelector } from '@/app/redux/utils';
import { routerPath } from '@/app/router/paths';
import { RoomFormHeader } from '@/pages/_offices/ui/RoomFormHeader/RoomFormHeader';
import { BOOKING_RULE_OPTIONS } from '@/pages/_offices/consts/consts';
import { findMissingItems, findNewItems } from '@/shared/lib/filter';
import { Button } from '@/shared/ui/_buttons/Button';
import { StringField } from '@/shared/ui/_fields/StringField';
import { TimeRangeField } from '@/shared/ui/_fields/TimeRangeField';
import { SelectMultiWithSearch } from '@/shared/ui/_inputs/selects/SelectMultiWithSearch';
import { SelectSingle } from '@/shared/ui/_inputs/selects/SelectSingle';
import { SelectSingleCustomValue } from '@/shared/ui/_inputs/selects/SelectSingleCustomValue';
import { TextArea } from '@/shared/ui/_inputs/text_Inputs/TextArea';
import * as yup from 'yup';
import s from './First.module.scss';

interface Props {
	officeId: string;
	roomId: string;
	isNewRoom: boolean;
}

export const First: React.FC<Props> = props => {
	const { officeId, roomId, isNewRoom } = props;

	// * Router
	const navigate = useNavigate();

	// * Navigation
	const goBack = () => {
		navigate(`${routerPath.offices.page}${routerPath.offices.singleOffice}/${officeId}`);
	};

	const goToNextPage = (roomId: string) => {
		dispatch(
			getRoom({
				params: { roomId },
			}),
		)
			.unwrap()
			.then(() => navigate(`${routerPath.offices.page}${routerPath.offices.roomForm}/${officeId}/${roomId}/2`))
			.catch(error => console.log(error));
	};

	// * Selectors
	const office = useAppSelector(state => state.office_service.office.office);
	const activeRoom = useAppSelector(state => state.office_service.room.activeRoom);
	const roomTypes = useAppSelector(state => state.office_service.roomType.roomTypes);
	const allTags = useAppSelector(state => state.office_service.tag.allTags);

	// * Actions
	const dispatch = useAppDispatch();
	const { createRoom, editRoom, getRoom } = officeServiceActions.room.async;
	const { createRoomType, editRoomType } = officeServiceActions.roomType.async;
	const { findTags, createTags, removeTags } = officeServiceActions.tag.async;

	const { clearTags } = officeServiceActions.tag.actions;

	// * Form
	const defaultValues = {
		officeId,
		name: activeRoom ? activeRoom.name : '',
		description: activeRoom ? activeRoom.description : '',
		roomTypeId: activeRoom ? activeRoom.roomType.id : null,
		roomType: {
			officeId,
			name: activeRoom ? activeRoom.roomType.name : '',
			description: activeRoom ? activeRoom.roomType.description : '',
			startTime: activeRoom ? activeRoom.roomType.startTime : null,
			endTime: activeRoom ? activeRoom.roomType.endTime : null,
			bookingRule: activeRoom ? activeRoom?.roomType.bookingRule : null,
			isActive: activeRoom?.roomType.isActive,
		},
		tags: activeRoom ? activeRoom.tags : [],
	};

	const schema = yup.object().shape({
		name: yup.string().trim().required('Введите название помещения').max(50, 'Максимальная длина названия помещение не может быть больше 50 букв.').nullable(),
		// tags: yup.array().min(1, 'Добавьте минимум одну характеристику'),
		roomType: yup.object().shape({
			name: yup.string().trim().required('Тип помещения является обязательным'),
			bookingRule: yup.string().trim().required('Выберете правило бронирования').nullable(),
			startTime: yup.string().trim().required('Выберете часы открытия помещения').nullable(),
			endTime: yup.string().trim().required('Выберете часы закрытия помещения').nullable(),
		}),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, control, setValue, reset, watch } = formMethods;

	const roomTypeId = watch('roomTypeId');
	const roomType = watch('roomType');

	useEffect(() => {
		reset(defaultValues);
	}, [office, activeRoom]);

	const onSubmit = (values: typeof defaultValues) => {
		const promises: any[] = [];
		let newRoomId: string | null = null;

		if (isNewRoom) {
			// * Create Room
			const getCreateRoomPayload = (): CreateRoomREQ => {
				if (values.roomTypeId) {
					return {
						// Existing roomType
						payload: {
							officeId,
							name: values.name,
							description: values.description,
							roomTypeId: values.roomTypeId,
						},
					};
				} else {
					return {
						// New roomType
						payload: {
							officeId,
							name: values.name,
							description: values.description,
							roomType: {
								officeId,
								name: values.roomType.name,
								description: values.roomType.description,
								startTime: values.roomType.startTime as string, // ! Dangerous
								endTime: values.roomType.endTime as string, // ! Dangerous
								bookingRule: values.roomType.bookingRule as BookingRule, // ! Dangerous
							},
						},
					};
				}
			};

			promises.push(
				dispatch(createRoom(getCreateRoomPayload()))
					.unwrap()
					.then(createdRoomId => {
						const newTags = values.tags;

						if (newTags.length > 0) {
							promises.push(
								dispatch(
									createTags({
										payload: {
											entityId: createdRoomId,
											tags: newTags.map(tag => tag.name),
										},
									}),
								),
							);
						}

						newRoomId = createdRoomId;
					})
					.catch(error => console.log(error)),
			);
		} else {
			// * Edit room
			const payload: EditRoomPayloadItem[] = [];

			if (defaultValues.name !== values.name) {
				payload.push({
					op: 'replace',
					path: '/name',
					value: values.name,
				});
			}

			if (defaultValues.description !== values.description) {
				payload.push({
					op: 'replace',
					path: '/description',
					value: values.description,
				});
			}

			// * RoomType
			if (values.roomTypeId) {
				// RoomTypeId exists but changed. Assign new room type id to the room
				payload.push({
					op: 'replace',
					path: '/roomTypeId',
					value: values.roomTypeId,
				});

				const existingRoomType = roomTypes.find(roomType => roomType.name === values.roomType.name);
				if (existingRoomType) {
					// Edit existing room type properties.
					promises.push(
						dispatch(
							editRoomType({
								params: {
									roomTypeId: existingRoomType.id,
								},
								payload: Object.entries(values.roomType)
									.filter(([key]) => key !== 'officeId' && key !== 'name')
									.map(([key, value]) => ({
										op: 'replace',
										path: `/${key}`,
										value,
									})) as EditRoomTypePayloadItem[],
							}),
						).unwrap(),
					);
				}
			} else {
				// Create new roomType
				promises.push(
					dispatch(
						createRoomType({
							payload: {
								officeId,
								name: values.roomType.name,
								description: values.roomType.description,
								startTime: values.roomType.startTime as string, // ! Dangerous
								endTime: values.roomType.endTime as string, // ! Dangerous
								bookingRule: values.roomType.bookingRule as BookingRule, // ! Dangerous
							},
						}),
					)
						.unwrap()
						.then(roomTypeId => {
							promises.push(
								dispatch(
									editRoom({
										params: { roomId },
										payload: [
											{
												op: 'replace',
												path: '/roomTypeId',
												value: roomTypeId,
											},
										],
									}),
								).unwrap(),
							);
						})
						.catch(error => console.log(error)),
				);
			}

			if (payload.length > 0) {
				promises.push(
					dispatch(
						editRoom({
							params: { roomId },
							payload,
						}),
					)
						.unwrap()
						.then(() => navigate(`${routerPath.offices.page}${routerPath.offices.roomForm}/${officeId}/${roomId}/2`))
						.catch(error => console.log(error)),
				);
			}

			// * tags
			const previousTags = defaultValues.tags;
			const currentTags = values.tags;

			const newTags = findNewItems(previousTags, currentTags);
			const tagsToDelete = findMissingItems(previousTags, currentTags);

			if (newTags.length > 0) {
				promises.push(
					dispatch(
						createTags({
							payload: {
								entityId: roomId,
								tags: newTags.map(tag => tag.name),
							},
						}),
					).unwrap(),
				);
			}

			if (tagsToDelete.length > 0) {
				promises.push(
					dispatch(
						removeTags({
							payload: {
								entityId: roomId,
								tagsIds: tagsToDelete.map(tag => tag.id),
							},
						}),
					).unwrap(),
				);
			}
		}

		Promise.all(promises)
			.then(() => goToNextPage(newRoomId ? newRoomId : roomId))
			.catch(error => console.log('error', error));
	};

	// * Booking rule
	const getSelectedBookingRule = (fieldValue: BookingRule | null) => {
		const selectedBookingRule = BOOKING_RULE_OPTIONS.find(option => option.value === fieldValue);
		return selectedBookingRule ? selectedBookingRule : { name: null, value: null };
	};

	const onRuleSelect = (fieldValue: { name: string | null; value: BookingRule | null }) => {
		setValue('roomType.bookingRule', fieldValue.value, { shouldValidate: true });
	};

	// * Tags
	const [tagSearchString, setTagSearchString] = useState('');

	useEffect(() => {
		if (tagSearchString) {
			dispatch(
				findTags({
					params: {
						nameIncludeSubstring: tagSearchString,
						takeCount: 200,
						skipCount: 0,
					},
				}),
			);
		} else {
			dispatch(clearTags);
		}
	}, [tagSearchString]);

	// * Render
	return (
		<div className={s.container}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				onKeyDown={event => {
					if (event.keyCode === 13) {
						event.preventDefault();
					}
				}}
			>
				<FormProvider {...formMethods}>
					<div className={s.header}>
						{office && (
							<RoomFormHeader
								officeName={office.name}
								isNewRoom={isNewRoom}
							/>
						)}

						<div className={s.button_wrapper}>
							<Button
								variant="tertiary"
								onClick={goBack}
							>
								Отменить
							</Button>

							<Button type="submit">{`${isNewRoom ? 'Создать' : 'Редактировать'} помещение`}</Button>
						</div>
					</div>

					<div className={s.card}>
						<h3>Основная информация</h3>

						<StringField
							name="name"
							label="Название помещения"
							placeholder="Введите название помещения"
						/>

						<div className={s.input_row}>
							<Controller
								name="roomType.name"
								control={control}
								render={({ field, fieldState }) => (
									<SelectSingleCustomValue
										{...field}
										label="Тип помещения"
										placeholder="Введите или выбирите из списка"
										keyNames={{
											name: 'name',
											value: 'id',
										}}
										searchSubstring={field.value}
										onStringChange={value => {
											setValue('roomType.name', value, { shouldValidate: true });
											setValue('roomTypeId', null, { shouldValidate: true });
										}}
										options={roomTypes}
										selectedOption={{
											id: roomTypeId as string,
											name: roomType.name,
											description: roomType.description,
											bookingRule: roomType.bookingRule as BookingRule,
											startTime: roomType.startTime,
											endTime: roomType.endTime,
											isActive: roomType.isActive,
										}}
										setSelectedOption={value => {
											setValue('roomType.name', value.name);
											setValue('roomType.description', value.description);
											setValue('roomType.bookingRule', value.bookingRule);
											setValue('roomType.startTime', value.startTime);
											setValue('roomType.endTime', value.endTime);
											setValue('roomTypeId', value.id);
										}}
										errorMessage={fieldState.error?.message}
									/>
								)}
							/>

							<Controller
								name="roomType.bookingRule"
								control={control}
								render={({ field, fieldState }) => (
									<SelectSingle
										{...field}
										keyNames={{
											name: 'name',
											value: 'value',
										}}
										selectedOption={getSelectedBookingRule(field.value)}
										setSelectedOption={onRuleSelect}
										options={BOOKING_RULE_OPTIONS}
										label="Правило бронирования"
										placeholder="Выберите из списка"
										errorMessage={fieldState.error?.message}
									/>
								)}
							/>

							<TimeRangeField
								firstName="roomType.startTime"
								secondName="roomType.endTime"
								labels={['Часы работы']}
							/>
						</div>

						<Controller
							name="tags"
							control={control}
							render={({ field, fieldState }) => (
								<SelectMultiWithSearch
									label="Характеристики"
									placeholder="Введите или выбирите из списка"
									keyNames={{
										name: 'name',
										value: 'id',
									}}
									searchSubstring={tagSearchString}
									onStringChange={setTagSearchString}
									selectedOptions={field.value}
									setSelectedOptions={value => field.onChange(value)}
									options={allTags}
									errorMessage={fieldState.error?.message}
								/>
							)}
						/>

						<Controller
							name="description"
							control={control}
							render={({ field, fieldState }) => (
								<TextArea
									{...field}
									label="Описание"
									placeholder="Введите описание помещения"
									errorMessage={fieldState.error?.message}
									characterLimit={300}
								/>
							)}
						/>
					</div>
				</FormProvider>
			</form>
		</div>
	);
};
