import React, { ChangeEvent, FormEvent, useState } from 'react';
import s from '../../../components/SurveyForm/SurveyForm.module.scss';
import cn from 'classnames';

import CopySVG from '@/shared/assets/svg/action/copy.svg?react';
import ImageSVG from '@/shared/assets/svg/files/image.svg?react';
import DeleteSVG from '@/shared/assets/svg/action/delete.svg?react';

import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { SelectSingle } from '@/shared/ui/_inputs/selects/SelectSingle';
import { questionCategories } from '../meta/surveyCategory';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import { Switch } from '@/shared/ui/_switches/Switch/Switch';
import { GridOption, Option, QuestionItem, QuestionTypeInfo, ScaleOption, SectionProps } from '../_types';
import { v4 } from 'uuid';
import { MultiChoice } from './variants/MultiChoice/MultiChoice';
import { LinearScale } from './variants/LinearScale/LinearScale';
import { ChoiceGrid } from './variants/ChoiceGrid/ChoiceGrid';

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragDropHandle } from '@/shared/ui/DragDropHandle/DragDropHandle';
import { Modal } from '@/shared/ui/_modals/Modal/ui/Modal/Modal';
import { ImageModal } from './ImageModal/ImageModal';
import { ImageContent } from '@/app/redux/queries/survey-service/types';
import { QuestionImage } from '../../../components/QuestionImage/QuestionImage';

interface Props {
	indexQuestion: number;
	question: QuestionItem;

	indexSection: number;
	sections: SectionProps[];
	setSections: (sections: SectionProps[]) => void;

	onlyQuestionInSection: boolean;
	isEdit?: boolean;
}

export const Question: React.FC<Props> = props => {
	const {
		indexQuestion,
		question,

		indexSection,
		sections,
		setSections,

		onlyQuestionInSection,
		isEdit,
	} = props;

	const questionImage = question.questionImages ? question.questionImages[0] : null; // для превью из редактирования
	const questionImageRes = question.images ? question.images[0] : null; // с бэка приходит
	const imageId = questionImageRes?.parentId || null;

	// * Options
	const defaultBaseOption: Option = {
		id: `new_${v4()}`,
		content: 'Вариант 1',
		isCustom: false,
	};

	const defaultScaleOption: ScaleOption[] = [
		{
			id: `new_${v4()}`,
			content: '1',
			rightSignature: '',
			leftSignature: '',
		},
		{
			id: `new_${v4()}`,
			content: '2',
			rightSignature: '',
			leftSignature: '',
		},
	];

	const defaultGridOption: GridOption = {
		id: `new_${v4()}`,
		columnSignature: 'Столбец 1',
		rowSignature: 'Строка 1',
	};

	const onQuestionContentChange = (event: FormEvent<HTMLInputElement>, indexSection: number, indexQuestion: number) => {
		const isTypeBlur = event.type === 'blur';
		const value = event.currentTarget.value;
		const newValue = isTypeBlur ? value.trim() : value;

		const changedSection = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					return {
						...question,
						content: isTypeBlur && !newValue ? `Вопрос ${indexQuestion + 1}` : newValue,
					};
				}),
			};
		});

		setSections([...changedSection]);
	};

	const onTypeQuestionChange = (selectedOption: QuestionTypeInfo, indexSection: number, indexQuestion: number) => {
		const { type, hasMultipleChoice } = selectedOption;

		const changedSection = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;
					const { type: prevType, options, gridOptions } = question;
					const { type: prev } = prevType;

					// Стираем options, если тип вопроса поменялся.
					// Добавляем дефолтные значения options выбранного типа.
					// Оставляем предыдущие options, если поменялся только hasMultipleChoice
					const cleanedBaseOptions = type === 'Base' ? (prev === 'Base' ? [...options] : [defaultBaseOption]) : [];
					const cleanedGridOptions = type === 'Grid' ? (prev === 'Grid' ? [...gridOptions] : [defaultGridOption]) : [];

					return {
						...question,
						type: selectedOption,
						hasMultipleChoice: hasMultipleChoice,
						options: [...cleanedBaseOptions],
						scaleOptions: type === 'Scale' ? [...defaultScaleOption] : [],
						gridOptions: [...cleanedGridOptions],
					};
				}),
			};
		});

		setSections([...changedSection]);
	};

	const onIsObligatoryChange = (event: ChangeEvent<HTMLInputElement>, indexSection: number, indexQuestion: number) => {
		const checked = event.target.checked;

		const changedSection = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					return {
						...question,
						isObligatory: checked,
					};
				}),
			};
		});

		setSections([...changedSection]);
	};

	const removeQuestion = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, indexSection: number, indexQuestion: number) => {
		event.preventDefault();
		event.stopPropagation();

		const preparedSections = sections.map((section, i) => {
			if (i !== indexSection) return section;

			const { questions } = section;

			return {
				...section,
				questions: questions.filter((_q, ind) => ind !== indexQuestion),
			};
		});

		setSections([...preparedSections]);
	};

	const copyQuestion = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, indexSection: number, indexQuestion: number) => {
		const preparedSections = sections.map((section, i) => {
			if (i !== indexSection) return section;

			const { questions } = section;
			const copiedQuestion = questions.find((_q, indQ) => indQ === indexQuestion);

			if (!copiedQuestion) return section;

			const { options } = copiedQuestion;
			const preparedOptions = Array.isArray(options) ? options.map(opt => ({ ...opt, id: `new_${v4()}` })) : options;

			const newQuestions = [...questions];
			newQuestions.splice(indexQuestion, 0, {
				...copiedQuestion,
				id: `new_${v4()}`,
				options: preparedOptions,
			});

			return {
				...section,
				questions: newQuestions,
			};
		});

		setSections([...preparedSections]);
	};

	const typeQuestion = () => {
		const type = question.type.type;

		switch (type) {
			case 'Base':
				return (
					<MultiChoice
						defaultOption={defaultBaseOption}
						{...props}
					/>
				);
			case 'Scale':
				return <LinearScale {...props} />;
			case 'Grid':
				return <ChoiceGrid {...props} />;
		}
	};

	// * DnD
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: question.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	// * Image upload
	const [showImageModal, setShowImageModal] = useState(false);
	const toggleImageModal = () => {
		setShowImageModal(prevState => !prevState);
	};

	const setQuestionImage = (image: ImageContent | null) => {
		setSections(
			sections.map((section, index) => {
				if (index === indexSection) {
					return {
						...section,
						questions: section.questions.map((question, index) => {
							if (index === indexQuestion) {
								return {
									...question,
									questionImages: image ? [image] : null,
									images: [],
								};
							} else {
								return question;
							}
						}),
					};
				} else {
					return section;
				}
			}),
		);
	};

	// * Render
	return (
		<>
			{showImageModal && (
				<Modal
					show={showImageModal}
					toggleModal={toggleImageModal}
					Content={
						<ImageModal
							toggleModal={toggleImageModal}
							questionImage={questionImage}
							setQuestionImage={setQuestionImage}
							id={imageId}
						/>
					}
				/>
			)}

			<div
				className={s.questions}
				style={style}
				ref={setNodeRef}
			>
				{!onlyQuestionInSection && (
					<div className={s.drag_drop_icon}>
						{!isEdit && (
							<DragDropHandle
								variant="horizontal"
								{...attributes}
								{...listeners}
							/>
						)}
					</div>
				)}

				<div className={s.question_header}>
					<TextInput
						placeholder="Вопрос"
						value={question.content}
						maxLength={300}
						onChange={e => onQuestionContentChange(e, indexSection, indexQuestion)}
						onBlur={e => onQuestionContentChange(e, indexSection, indexQuestion)}
					/>

					<SelectSingle
						placeholder="Категория"
						disabled={isEdit}
						keyNames={{
							name: 'name',
							value: 'id',
						}}
						selectedOption={question.type}
						setSelectedOption={option => {
							if (!option.name) return;

							onTypeQuestionChange(option, indexSection, indexQuestion);
						}}
						options={questionCategories}
					/>

					<IconButton
						Icon={<ImageSVG />}
						onClick={toggleImageModal}
					/>
				</div>

				<QuestionImage
					image={questionImage}
					id={imageId}
				/>

				{typeQuestion()}

				<hr />

				<div className={s.footer}>
					<div className={cn(s.switch_obligation, isEdit && s.switch_obligation_disabled)}>
						Обязательный вопрос
						<Switch
							disabled={isEdit}
							checked={question.isObligatory}
							onChange={(e: ChangeEvent<HTMLInputElement>) => onIsObligatoryChange(e, indexSection, indexQuestion)}
						/>
					</div>

					<IconButton
						Icon={<CopySVG />}
						disabled={isEdit}
						onClick={e => copyQuestion(e, indexSection, indexQuestion)}
					/>

					<IconButton
						Icon={<DeleteSVG />}
						disabled={isEdit}
						onClick={e => removeQuestion(e, indexSection, indexQuestion)}
					/>
				</div>
			</div>
		</>
	);
};
