import React, { useEffect, useState } from 'react';
import { companyServiceActions } from '@/app/redux/state/company/actions';
import { useAppDispatch } from '@/app/redux/utils';
import { useRights } from '@/shared/hooks/useRights';
import { Button } from '@/shared/ui/_buttons/Button';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { OfficeFormMC } from '../OfficeFormMC/OfficeFormMC';
import s from './OfficesPageHeader.module.scss';

export const OfficesPageHeader: React.FC = () => {
	// * Rights
	const isAdmin = useRights();

	// * Actions
	const dispatch = useAppDispatch();
	const { getCompany } = companyServiceActions.company.async;

	// * Modal
	const [showModal, setShowModal] = useState(false);

	const toggleModal = () => {
		setShowModal(prevState => !prevState);
	};

	// * Initialise
	useEffect(() => {
		dispatch(
			getCompany({
				params: {
					includeOffices: false,
				},
			}),
		);
	}, []);

	// * Render
	return (
		<div className={s.container}>
			{showModal && (
				<ModalNewProto
					isOpen={showModal}
					onClose={toggleModal}
					width="m"
				>
					<OfficeFormMC toggleModal={toggleModal} />
				</ModalNewProto>
			)}

			<h1>Офисы</h1>

			{isAdmin && <Button onClick={toggleModal}>Создать офис</Button>}
		</div>
	);
};
