import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import { LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO } from '@/shared/consts/consts';

const PARAMS = {
	month: 'month',
	year: 'year',
};

export const useSelectedDate = (searchParams: URLSearchParams, setSearchParams: SetURLSearchParams) => {
	const month = searchParams.get(PARAMS.month);
	const year = searchParams.get(PARAMS.year);

	const now = DateTime.fromJSDate(new Date());
	const initialDate = now
		.minus({ months: now.day <= LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO ? 1 : 0 })
		.startOf('month')
		.startOf('day')
		.toJSDate();

	useEffect(() => {
		!month && searchParams.set(PARAMS.month, String(initialDate.getMonth()));
		!year && searchParams.set(PARAMS.year, String(initialDate.getFullYear()));

		setSearchParams(searchParams);
	}, []);

	const setSelectedDate = (date: Date) => {
		searchParams.set(PARAMS.month, String(date.getMonth()));
		searchParams.set(PARAMS.year, String(date.getFullYear()));
		setSearchParams(searchParams);
	};

	const selectedDate = month && year ? DateTime.fromObject({ month: Number(month) + 1, year: Number(year) }).toJSDate() : initialDate;

	return { selectedDate, setSelectedDate };
};
