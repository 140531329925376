import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { event_serviceAPI } from '@/app/redux/queries/event-service/event_serviceAPI';
import { routerPath } from '@/app/router/paths';
import { ConfirmModal } from '@/entities/_modals/ConfirmModal/ConfirmModal';
import EditSVG from '@/shared/assets/svg_icons/action/edit.svg?react';
import ArchiveSVG from '@/shared/assets/svg_icons/file/archive.svg?react';
import UnArchiveSVG from '@/shared/assets/svg_icons/file/unarchive.svg?react';
import { DropDownMenu } from '@/shared/ui/DropDownMenu/DropDownMenu';

interface Props {
	eventId: string;
	isActive: boolean;
	isPast: boolean;
}

export const EventDropDownMenu: FC<Props> = props => {
	const {
		eventId, //
		isActive,
		isPast,
	} = props;

	// * Routing
	const navigate = useNavigate();

	const [confirmModal, setConfirmModal] = useState(false);
	const toggleConfirmModal = () => setConfirmModal(prevState => !prevState);

	// * API
	const [editEvent, { isLoading }] = event_serviceAPI.usePatchEventEditMutation();

	// * Actions
	const navigateToEditEvent = () => navigate(`${routerPath.calendar.page}${routerPath.calendar.editEvent}/${eventId}`); //TODO: переводить на страницу редактирования

	const archiveEvent = (activeValue: boolean) => {
		editEvent({
			eventId,
			body: [
				{
					op: 'replace',
					path: '/IsActive',
					value: !activeValue,
				},
			],
		})
			.unwrap()
			.then(() => {
				toggleConfirmModal();
				navigate('/event-calendar');
			})
			.catch(err => console.log(err));
	};

	// - Items
	const dropDownItems = [
		{
			isVisible: isActive && !isPast,
			icon: <EditSVG />,
			title: 'Редактировать',
			action: navigateToEditEvent,
		},
		{
			isVisible: isActive,
			icon: <ArchiveSVG />,
			title: 'Архивировать',
			action: toggleConfirmModal,
		},
		{
			isVisible: !isActive,
			icon: <UnArchiveSVG />,
			title: 'Восстановить',
			action: navigateToEditEvent,
		},
	].filter(item => item.isVisible);

	// * Render
	return (
		<>
			<ConfirmModal
				isOpen={confirmModal}
				onClose={toggleConfirmModal}
				title={isActive ? 'Архивация события' : 'Восстановление события'}
				description={isActive ? 'Вы действительно хотите архивировать это событие?' : 'Вы действительно хотите восстановить из архива это событие?'}
				primaryText={isActive ? 'Архивировать' : 'Восстановить'}
				primaryOnClick={() => archiveEvent(isActive)}
				isLoading={isLoading}
			/>

			<DropDownMenu items={dropDownItems} />
		</>
	);
};
