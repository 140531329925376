import { FC } from 'react';
import { CardWrapper } from '../CardWrapper/CardWrapper';
import { Heading } from '@/shared/ui/Heading';
import s from './Wiki.module.scss';
import { wiki_serviceAPI } from '@/app/redux/queries/wiki-service/wiki_serviceAPI';
import { WikiFavoriteItem } from '../WikiFavoriteItem/WikiFavoriteItem';
import { useNavigate } from 'react-router-dom';

export const Wiki: FC = () => {
	// * Routing
	const navigate = useNavigate();

	// * API
	const { data: favorites } = wiki_serviceAPI.useGetFavoritesQuery({ filter: {} });
	const ids = favorites?.body || [];

	const { data: articlesData } = wiki_serviceAPI.useFindArticlesByContentQuery({
		skipCount: 0,
		takeCount: 4,
		descendingcreatedatutc: true,
		descendingmodifiedatutc: true,
	});
	const articles = articlesData?.body || [];

	// * Render
	return (
		<CardWrapper
			title="База знаний"
			path="/wiki"
			fullHeight
		>
			<Heading
				level={3}
				marginBottom="s"
			>
				Избранные статьи
			</Heading>

			<div className={s.list}>
				{ids.map(id => (
					<WikiFavoriteItem
						key={id}
						id={id}
					/>
				))}
			</div>

			<Heading
				level={3}
				marginBottom="s"
			>
				Новые и обновлённые статьи
			</Heading>

			<div className={s.list}>
				{articles.map(article => (
					<p
						key={article.id}
						onClick={() => navigate(`/wiki/${article.id}`)}
					>
						{article.name || ''}
					</p>
				))}
			</div>
		</CardWrapper>
	);
};
