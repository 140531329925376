import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from '@/app/redux/utils';
import { AdminPage } from '@/pages/AdminPage';
import { AuthPage } from '@/pages/AuthPage/ui/AuthPage/AuthPage';
import { TimeTrackingPage } from '@/pages/TimeTrackingPage';
import { UserCardPage } from '@/pages/UserCardPage/index';
import { UsersPage } from '@/pages/UsersPage';
import { BookingPage, BookingRoomPage } from '@/pages/_booking';
import { CompanyDetailsPage } from '@/pages/_companyStructure/pages/CompanyDetails/CompanyDetailsPage';
import { CompanyStructurePage } from '@/pages/_companyStructure/pages/CompanyStructurePage';
import { DepartmentPage, DepartmentsPage, TimeListDDPage } from '@/pages/_departments';
import { NewEventPage } from '@/pages/_eventСalendar';
import { EventCalendarPage } from '@/pages/_eventСalendar';
import { EventDetailsPage } from '@/pages/_eventСalendar/ui/EventDetailsPage';
import { FeedbackPage } from '@/pages/_feedback';
import { SingleFeedbackPage } from '@/pages/_feedback/SingleFeedbackPage/SingleFeedbackPage';
import { NotFoundPage } from '@/pages/NotFoundPage/NotFoundPage';
import { OfferMainPage } from '@/pages/_offer/pages/OfferMainPage';
import { OfficesPage, RoomFormPage, SingleOfficePage } from '@/pages/_offices';
import { ProjectDetailPage, ProjectPage, TimeListPage } from '@/pages/_projectService';
import { DisplaySurveyPage } from '@/pages/_surveys/pages/DisplaySurveyPage/DisplaySurveyPage';
import { EditSurveyPage } from '@/pages/_surveys/pages/EditSurvey/EditSurveyPage';
import { NewSurveyPage } from '@/pages/_surveys/pages/NewSurveyPage/NewSurveyPage';
import { NewTemplatePage } from '@/pages/_surveys/pages/NewTemplatePage/NewTemplatePage';
import { ResultsPage } from '@/pages/_surveys/pages/ResultsPage/ResultsPage';
import { SurveyPage } from '@/pages/_surveys/pages/SurveyPage/SurveyPage';
import { TemplatePage } from '@/pages/_surveys/pages/TemplatePage/TemplatePage';
import { CreateArticlePage, ArticlePage, ArticlePagePublic, WikiPage, WikiPagePublic } from '@/pages/_wiki';
import { MainPage } from '@/pages/MainPage';
import { useRights } from '@/shared/hooks/useRights';
import { isDevEnv } from '@/shared/lib/app';
import { routerPath } from './paths';
import { createPrivateRoute } from './lib/createBasicRoute';
import { createPublicRoute } from './lib/createPublicRoute';
import { VacationPage } from '@/pages/_vacation';
import { GroupPage } from '@/pages/_vacation/ui/GroupPage';
import { ClaimFormPage, ClaimPage, ClaimSystemPage, ClaimStatisticsPage } from '@/pages/_claims';

export const AppRouter = () => {
	const tokens = useAppSelector(state => state.auth_service.tokens);
	const hasTokens = !!(tokens && tokens.accessToken && tokens.refreshToken);

	const isAdmin = useRights();
	const allowedHR = useRights(18);
	const allowedToEditOffices = useRights(8);

	const startingPage = routerPath.main.page;

	return (
		<BrowserRouter
			future={{
				v7_startTransition: true,
				v7_relativeSplatPath: true,
			}}
		>
			<Routes>
				{/* Redirect unauthorized */}
				<Route
					path="/"
					element={
						hasTokens ? (
							<Navigate
								to={startingPage}
								replace
							/>
						) : (
							<Navigate
								to={routerPath.auth.login}
								replace
							/>
						)
					}
				/>

				{/* Redirect authorized */}
				<Route
					path={routerPath.auth.login}
					element={
						hasTokens ? (
							<Navigate
								to={startingPage}
								replace
							/>
						) : (
							<AuthPage type="login" />
						)
					}
				/>

				<Route
					path={routerPath.auth.signup}
					element={
						hasTokens ? (
							<Navigate
								to={startingPage}
								replace
							/>
						) : (
							<AuthPage type="signup" />
						)
					}
				/>

				<Route
					path={routerPath.auth.forgot}
					element={
						hasTokens ? (
							<Navigate
								to={startingPage}
								replace
							/>
						) : (
							<AuthPage type="forgot" />
						)
					}
				/>

				<Route
					path={routerPath.auth.reset}
					element={
						hasTokens ? (
							<Navigate
								to={startingPage}
								replace
							/>
						) : (
							<AuthPage type="reset" />
						)
					}
				/>

				<Route
					path={routerPath.auth.reactivate}
					element={
						hasTokens ? (
							<Navigate
								to={startingPage}
								replace
							/>
						) : (
							<AuthPage type="reactivate" />
						)
					}
				/>

				{/* * Time */}
				{createPrivateRoute({
					path: routerPath.main.page,
					PageComponent: <MainPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Time */}
				{createPrivateRoute({
					path: routerPath.time.page,
					PageComponent: <TimeTrackingPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Projects */}
				{createPrivateRoute({
					path: routerPath.projects.page,
					PageComponent: <ProjectPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.projects.page + '/:projectId',
					PageComponent: <ProjectDetailPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.projects.page + '/:projectId' + routerPath.projects.timelist,
					PageComponent: <TimeListPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Users */}
				{createPrivateRoute({
					path: routerPath.users.page,
					PageComponent: <UsersPage />,
					rights: [],
					hasTokens,
				})}

				{/* * User card */}
				{createPrivateRoute({
					path: routerPath.users.page + '/:userId',
					PageComponent: <UserCardPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Wiki - Private */}
				{createPrivateRoute({
					path: routerPath.wiki.page,
					PageComponent: <WikiPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.wiki.page + '/:articleId',
					PageComponent: <ArticlePage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.wiki.page + routerPath.wiki.newArticles + '/:rubricId/:subRubricId/:articleId',
					PageComponent: <CreateArticlePage />,
					rights: [],
					hasTokens,
				})}

				{/* Vacation */}
				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.vacation.page,
						PageComponent: <VacationPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.vacation.page + routerPath.vacation.group + '/:groupId',
						PageComponent: <GroupPage />,
						rights: [],
						hasTokens,
					})}

				<Route
					path={routerPath.wiki.page + routerPath.wiki.editArticles + '/:rubricId/:subRubricId/:articleId'}
					element={
						hasTokens ? (
							<CreateArticlePage />
						) : (
							<Navigate
								to="/"
								replace
							/>
						)
					}
				/>

				{/* * Wiki - Public */}
				<Route
					path={routerPath.public.page}
					element={<WikiPagePublic />}
				/>

				<Route
					path={routerPath.public.page + '/:articleId'}
					element={<ArticlePagePublic />}
				/>

				{/* * Offices */}
				{createPrivateRoute({
					path: routerPath.offices.page,
					PageComponent: <OfficesPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.offices.page + routerPath.offices.singleOffice + '/:officeId',
					PageComponent: <SingleOfficePage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.offices.page + routerPath.offices.roomForm + '/:officeId/:roomId/:formStep',
					PageComponent: <RoomFormPage />,
					rights: [allowedToEditOffices],
					hasTokens,
				})}

				{/* * Departments */}
				{createPrivateRoute({
					path: routerPath.departments.page,
					PageComponent: <DepartmentsPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.departments.page + routerPath.departments.singleDepartment + '/:departmentId' + '/:tab',
					PageComponent: <DepartmentPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.departments.page + routerPath.departments.timeList + '/:departmentId',
					PageComponent: <TimeListDDPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.departments.timeList + '/:departmentId',
					PageComponent: <TimeListDDPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Booking */}
				{createPrivateRoute({
					path: routerPath.booking.page,
					PageComponent: <BookingPage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.booking.page + '/:officeId' + '/:roomId',
					PageComponent: <BookingRoomPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Company structure */}
				{createPrivateRoute({
					path: routerPath.structure.page,
					PageComponent: <CompanyStructurePage />,
					rights: [],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.structure.details + '/:departmentId',
					PageComponent: <CompanyDetailsPage />,
					rights: [],
					hasTokens,
				})}

				{/* * Event Calendar */}
				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.calendar.page,
						PageComponent: <EventCalendarPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.calendar.page + routerPath.calendar.newEvent,
						PageComponent: <NewEventPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.calendar.page + routerPath.calendar.editEvent + '/:eventId',
						PageComponent: <NewEventPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.calendar.page + routerPath.calendar.event + '/:eventId',
						PageComponent: <EventDetailsPage />,
						rights: [],
						hasTokens,
					})}

				{/* * HR */}
				{createPrivateRoute({
					path: routerPath.hr.page,
					PageComponent: <OfferMainPage />,
					rights: [allowedHR],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.hr.page + routerPath.hr.offer,
					PageComponent: <OfferMainPage />,
					rights: [allowedHR],
					hasTokens,
				})}

				{/* * Surveys */}
				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page,
						PageComponent: <SurveyPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page + routerPath.surveys.newSurvey + '/:surveyId',
						PageComponent: <NewSurveyPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page + routerPath.surveys.edit + '/:surveyId',
						PageComponent: <EditSurveyPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page + routerPath.surveys.newTemplate + '/:surveyId',
						PageComponent: <NewTemplatePage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page + '/:surveyId',
						PageComponent: <DisplaySurveyPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page + routerPath.surveys.template + '/:surveyId',
						PageComponent: <TemplatePage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.surveys.page + '/:surveyId' + '/results',
						PageComponent: <ResultsPage />,
						rights: [],
						hasTokens,
					})}

				{/* Admin-service */}
				{createPrivateRoute({
					path: routerPath.admin.page + routerPath.admin.dashboard,
					PageComponent: <AdminPage />,
					rights: [],
					hasTokens,
				})}

				{/* Feedback */}
				{createPrivateRoute({
					path: routerPath.feedback.page,
					PageComponent: <FeedbackPage />,
					rights: [isAdmin],
					hasTokens,
				})}

				{createPrivateRoute({
					path: routerPath.feedback.page + '/:feedbackId',
					PageComponent: <SingleFeedbackPage />,
					rights: [isAdmin],
					hasTokens,
				})}

				{/* Claim-service */}
				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.claim.page,
						PageComponent: <ClaimSystemPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.claim.page + routerPath.claim.addClaim,
						PageComponent: <ClaimFormPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.claim.page + '/:claimId',
						PageComponent: <ClaimPage />,
						rights: [],
						hasTokens,
					})}

				{isDevEnv &&
					createPrivateRoute({
						path: routerPath.claim.page + routerPath.claim.statistics,
						PageComponent: <ClaimStatisticsPage />,
						rights: [],
						hasTokens,
					})}

				{/* Wiki - Public */}
				{createPublicRoute({
					path: routerPath.public.page,
					PageComponent: <WikiPagePublic />,
				})}

				{createPublicRoute({
					path: routerPath.public.page + '/:articleId',
					PageComponent: <ArticlePagePublic />,
				})}

				{/* ... */}
				{createPublicRoute({
					path: '*',
					PageComponent: <NotFoundPage />,
				})}
			</Routes>
		</BrowserRouter>
	);
};
