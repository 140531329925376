import { yupResolver } from '@hookform/resolvers/yup';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { RubricData, RubricPatchDocument } from '@/app/redux/queries/wiki-service/types/types';
import { wiki_serviceAPI } from '@/app/redux/queries/wiki-service/wiki_serviceAPI';
import { ButtonPair } from '@/entities/_buttons/ButtonPair';
import { SelectSingleField } from '@/shared/ui/_fields/SelectSingleField';
import { StringField } from '@/shared/ui/_fields/StringField';
import * as Yup from 'yup';
import s from './ModalEditSubRubric.module.scss';

interface DefaultProps {
	name: string;
	rubric: RubricData;
}

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	id: string;
	initialRubricId: string;
	name: string;
	toggleModal: () => void;
};

export const ModalEditSubRubric: React.FC<Props> = props => {
	const { id, initialRubricId, name, toggleModal } = props;

	// * API
	const { data: wikiData } = wiki_serviceAPI.useGetWikiQuery({
		includedeactivated: false,
	});

	const wikiTree = wikiData || [];

	const [editSubRubric, { isLoading }] = wiki_serviceAPI.useEditRubricMutation();

	// * Form
	const schema = Yup.object().shape({
		name: Yup.string().required('Введите название').max(100, 'Название не может быть больше 100'),
		files: Yup.array(),
	});

	const defaultValues: DefaultProps = {
		name: name,
		rubric: wikiTree.find(item => item.id === initialRubricId) || wikiTree[0],
	};

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch, reset } = formMethods;

	const rubricName = watch('name');

	// * Create
	const onSaveEdit = (data: typeof defaultValues) => {
		const { name, rubric } = data;

		const args: RubricPatchDocument[] = [];

		if (name !== defaultValues.name) {
			args.push({
				op: 'replace',
				path: '/name',
				value: name,
			});
		}

		if (rubric.id !== defaultValues.rubric.id) {
			args.push({
				op: 'replace',
				path: '/parentId',
				value: rubric.id,
			});
		}

		editSubRubric({
			rubricId: id,
			editRubricRequest: args,
		})
			.unwrap()
			.then(() => toggleModal())
			.catch(err => console.log(err));
	};

	// * Render
	return (
		<FormProvider {...formMethods}>
			<form onSubmit={handleSubmit(onSaveEdit)}>
				<h3 className={s.title}>Редактирование подрубрики</h3>

				<div className={s.inner}>
					<SelectSingleField
						name="rubric"
						label="Рубрика"
						options={[...wikiTree]}
					/>

					<StringField
						name="name"
						label="Название"
						placeholder="Введите название подрубрики"
					/>
				</div>

				<ButtonPair
					primaryIsLoading={isLoading}
					primaryText="Сохранить"
					primaryDisabled={!rubricName}
					secondaryIsLoading={isLoading}
					secondaryText="Отменить"
					secondaryOnClick={toggleModal}
				/>
			</form>
		</FormProvider>
	);
};
