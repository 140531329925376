import React, { useState } from 'react';
import { company_structure_serviceAPI } from '@/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { UserWithSurveyInfo } from '@/app/redux/state/company-structure/surveysStatistics/types';
import { CardsList } from '@/entities/CardsList/CardsList';
import { SelectStringSearchFilter, SelectValue } from '@/entities/_filters/SelectStringSearchFilter';
import { SelectedCount } from '@/entities/_typography/SelectedCount';
import { Avatar } from '@/shared/ui/Avatar/Avatar';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import s from './Users.module.scss';

interface Props {
	count: number;
	surveyId: string;
	department: string;
	departmentId: string;
}

const options = [
	{
		id: '1',
		name: 'Прошли опрос',
	},
	{
		id: '2',
		name: 'Не прошли опрос',
	},
];

export const Users: React.FC<Props> = props => {
	const { count, surveyId, departmentId } = props;

	// * API
	const { data: usersInfo, isLoading } = company_structure_serviceAPI.useGetUsersWithSurveyInfoQuery(
		{
			surveyid: surveyId,
			departmentid: departmentId,
		},
		{ skip: !surveyId || !departmentId },
	);

	// * Filter
	const [option, setOption] = useState<SelectValue>(count ? options[0] : options[1]);
	const [userName, setUserName] = useState('');

	const filteredUsers =
		usersInfo &&
		usersInfo.filter(user => (user.isCompletedSurvey && option.id === '1') || (!user.isCompletedSurvey && option.id === '2')).sort((a, b) => a.fullName.localeCompare(b.fullName));

	const getShortName = (fullname: string): string => {
		const avatarName = fullname.split(' ');

		return `${avatarName[0]} ${avatarName[1]}`;
	};

	// * Render
	return (
		<div>
			<div className={s.title}>
				<h2>Участники опроса</h2>
			</div>

			{/* filters */}
			<div className={s.filters}>
				<SelectStringSearchFilter
					selectPlaceholder=""
					selectValue={option}
					setSelect={option => setOption(option)}
					selectOptions={options}
					searchPlaceholder="Поиск сотрудника по имени"
					searchStringValue={userName}
					setSearchString={setUserName}
				/>
			</div>

			<SelectedCount
				className={s.count_text}
				title="Всего участников"
				count={filteredUsers?.length ?? 0}
			/>

			{isLoading && (
				<div className={s.loading}>
					<LoaderCircle />
				</div>
			)}

			{filteredUsers && (
				<CardsList
					columns={[
						{
							title: 'ФИО',
							width: 10,
						},
						{
							title: 'ДЕПАРТАМЕНТ',
							width: 20,
						},
					]}
					isWideCard
					cards={filteredUsers.map((user: UserWithSurveyInfo) => ({
						id: user.userId,
						columns: [
							{
								Value: (
									<span className={s.position}>
										{user.fullName}
										<span>{user.position}</span>
									</span>
								),
								width: 10,
							},
							{
								Value: <span className={s.department}>{user.departmentName}</span>,
								width: 20,
							},
						],
						leftComponent: {
							width: 4,
							Component: (
								<>
									<Avatar
										name={getShortName(user.fullName)}
										avatarId={user.avatarId}
									/>
								</>
							),
						},
					}))}
				/>
			)}
		</div>
	);
};
