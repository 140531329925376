import { BookingInfo } from '@/app/redux/queries/office-service/types/types';
import { isLessThenMax } from '@/shared/lib/date';

export const getMaxDateBooking = (workspaceBookingList: BookingInfo[], selectedDate: Date): Date | null => {
	const lessSelected = workspaceBookingList
		.filter(item => {
			// Отсеиваем даты меньше выбранной
			const isLess = isLessThenMax(selectedDate, new Date(item.startTime));
			return isLess;
		})
		.map(item => item.startTime)
		// Сортируем, чтобы найти ближайшую дату окончания к выбранной дате
		.sort((a, b) => {
			return isLessThenMax(new Date(a), new Date(b)) ? -1 : 1;
		});

	// Если броней после выбранной даты нет, возвращаем null
	if (lessSelected.length > 0) {
		return new Date(lessSelected[0]);
	} else {
		return null;
	}
};
