import { useLazyGetWorkTimeMonthLimitFindQuery, useLazyGetWorkTimeFindQuery } from '@/app/redux/queries/time-service/time_serviceAPI';
import { useEffect, useState } from 'react';
import { getAllSettledValues } from '@/shared/lib/fetch';
import { WorkTimeMonthLimitInfo } from '@/app/redux/queries/time-service/types/types';
import { getCurrentMonthNumber, getCurrentYearNumber } from '@/shared/lib/date';
import { DateTime } from 'luxon';
import { WorkTimeResponse } from '@/app/redux/queries/time-service/types/resTypes';

export const useFindWorkTimeMonthLimits = (selectedDate: Date, userId: string, skip: boolean = false) => {
	const [getWorkTime, { isLoading: workTimesLoading, isFetching: workTimesRetching }] = useLazyGetWorkTimeFindQuery();
	const [findWorkTimeMonthLimits, { isLoading: monthLimitsLoading, isFetching: monthLimitsFetching }] = useLazyGetWorkTimeMonthLimitFindQuery();

	const isLoading = workTimesLoading || monthLimitsLoading;
	const isFetching = workTimesRetching || monthLimitsFetching;

	const [workTimes, setWorkTimes] = useState<WorkTimeResponse[]>([]); // ![текущий месяц, предыдущий месяц, следующий месяц]
	const [workTimeMonthLimits, setWorkTimeMonthLimits] = useState<WorkTimeMonthLimitInfo[]>([]); // ![текущий месяц, предыдущий месяц, следующий месяц]

	useEffect(() => {
		const workTimeRequests = [];
		const limitsRequests = [];

		const dates = [selectedDate];

		const prevMonth = DateTime.fromJSDate(selectedDate).startOf('month').minus({ month: 1 }).toJSDate();
		dates.push(prevMonth);

		const nextMonth = DateTime.fromJSDate(selectedDate).startOf('month').plus({ month: 1 }).toJSDate();
		dates.push(nextMonth);

		for (const date of dates) {
			const year = getCurrentYearNumber(date);
			const month = getCurrentMonthNumber(date);

			if (!skip) {
				const workTimeRequest = getWorkTime(
					{
						userid: userId,
						year,
						month,
						takecount: 1000, // ! HARDCODE
						skipcount: 0, // ! HARDCODE
					},
					true, // Prefer cached values
				).unwrap();

				workTimeRequests.push(workTimeRequest);
			}

			const limitsRequest = findWorkTimeMonthLimits(
				{
					year,
					month,
					takecount: 1000, // ! HARDCODE
					skipcount: 0, // ! HARDCODE
				},
				true, // Prefer cached values
			).unwrap();

			limitsRequests.push(limitsRequest);
		}

		Promise.allSettled(workTimeRequests).then(res => {
			const values = getAllSettledValues(res);

			const workTimeMonthLimits = values
				.filter(value => 'body' in value)
				.map(value => value.body as WorkTimeResponse[])
				.flat();

			setWorkTimes(workTimeMonthLimits);
		});

		Promise.allSettled(limitsRequests).then(res => {
			const values = getAllSettledValues(res);

			const workTimeMonthLimits = values
				.filter(value => 'body' in value)
				.map(value => value.body as WorkTimeMonthLimitInfo[])
				.flat();

			setWorkTimeMonthLimits(workTimeMonthLimits);
		});
	}, []);

	return { workTimes, workTimeMonthLimits, isLoading, isFetching };
};
