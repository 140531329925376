import { SingleTab } from '@/shared/ui/_tabs/lineTabs/LineTabs';
import { type Option } from '../types';

export const OPTION_PRIORITY: Option[] = [
	{ id: 'Minor', name: 'Низкая' },
	{ id: 'Normal', name: 'Нормальная' },
	{ id: 'Major', name: 'Высокая' },
];

export const TABS: SingleTab[] = [
	{
		id: 'Заявки департамента',
		name: 'Заявки департамента',
	},
	{
		id: 'Все заявки',
		name: 'Все заявки',
	},
	{
		id: 'Мои заявки',
		name: 'Мои заявки',
	},
	{
		id: 'Маршруты и категории',
		name: 'Маршруты и категории',
	},
];

export const STATUS: Option[] = [
	{ id: 'Completed', name: 'Завершено' },
	{ id: 'Declined', name: 'Отклонено' },
	{ id: 'ResponsibleChanged', name: 'Ответственный изменен' },
	{ id: 'Pending', name: 'В ожидании' },
	{ id: 'InWork', name: 'В работе' },
	{ id: 'InWorkSupervisor', name: 'В работе руководителя' },
	{ id: 'Coordinating', name: 'Координируется' },
	{ id: 'RequiresRework', name: 'Необходима переработка' },
];

export const EDIT_CLAIM = {
	name: '/Name',
	content: '/Content',
	category: '/CategoryId',
	department: '/DepartmentId',
	priority: '/Priority',
	status: '/Status',
	deadline: '/Deadline',
	responsibleUser: '/ResponsibleUserId',
	manager: '/ManagerUserId',
} as const;

export const COLORS = ['var(--color-1)', 'var(--color-2)', 'var(--color-4)', 'var(--color-10)'];
