import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CommunicationType } from '@/app/redux/queries/user-service/types/types';
import { ButtonPair } from '@/entities/_buttons/ButtonPair/ButtonPair';
import { Button } from '@/shared/ui/_buttons/Button';
import { PhoneStringField } from '@/shared/ui/_fields/PhoneStringField';
import { SelectSingleField } from '@/shared/ui/_fields/SelectSingleField';
import { StringField } from '@/shared/ui/_fields/StringField';
import { Heading } from '@/shared/ui/Heading';
import { ContactsTypes } from '../../const/contacts';
import { contactSchema } from '../../const/schema';
import { useCreateContact } from '../../hooks/useCreateContact';
import { useEditContact } from '../../hooks/useEditContact';
import { ContactsDefaultValues } from '../../types';
import s from './ContactForm.module.scss';

interface Props {
	onClose: () => void;

	contactId?: string;
	type?: CommunicationType;
	value?: string;
}

export const ContactForm: FC<Props> = props => {
	const {
		onClose, //

		contactId,
		type,
		value,
	} = props;

	// * Routing
	const { userId } = useParams() as { userId: string };

	// - Если создается/редактируется email, то показываем модалку с инфой о подтверждении почты
	const [showConfirmationInfo, setShowConfirmationInfo] = useState(false);

	// * Form
	const defaultValues: ContactsDefaultValues = {
		type: ContactsTypes.find(contact => contact.id === type) || {
			id: 'Email',
			name: 'Электронная почта',
			placeholder: 'Введите электронную почту',
		},
		value: value || '',
	};

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(contactSchema),
	});

	const { watch, handleSubmit } = formMethods;
	const contactType = watch('type');
	const contactValue = watch('value');

	// * Submit
	const { onCreateSubmit, isCreateLoading } = useCreateContact(
		userId, //
		contactType.id === 'Email' ? () => setShowConfirmationInfo(true) : onClose,
	);

	const { onEditSubmit, isEditLoading } = useEditContact(
		defaultValues.value, //
		contactType.id === 'Email' ? () => setShowConfirmationInfo(true) : onClose,
		contactId,
	);

	// * Render
	return showConfirmationInfo ? (
		<div className={cn(s.form, s.content)}>
			<Heading level={2}>Подтверждение почты</Heading>

			<div className={s.info}>
				<div>На адрес {contactValue} выслана ссылка для подтверждения почты.</div>
				<div>Она будет действительна в течение 30 минут.</div>
			</div>

			<Button
				className={s.button}
				fixedWidth
				onClick={onClose}
			>
				Ок
			</Button>
		</div>
	) : (
		<form
			className={s.form}
			onSubmit={handleSubmit(contactId ? onEditSubmit : onCreateSubmit)}
		>
			<FormProvider {...formMethods}>
				<Heading
					level={2}
					marginBottom="l"
					className={s.header}
				>
					{contactId ? 'Редактирование контакта' : 'Новый контакт'}
				</Heading>

				<div className={s.fields}>
					<SelectSingleField
						name="type"
						options={ContactsTypes.filter(contact => contact.id !== 'BaseEmail')}
						label="Тип контакта"
						disabled={!!contactId}
					/>

					{contactType.id === 'Phone' ? (
						<PhoneStringField
							name="value"
							label={contactType.name}
							placeholder="+7(___)___-__-__"
						/>
					) : (
						<StringField
							name="value"
							label={contactType.name}
							placeholder={contactType.placeholder}
							LeftIcon={contactType.id === 'Telegram' && <>@</>}
						/>
					)}
				</div>

				<ButtonPair
					primaryText={contactId ? 'Сохранить' : 'Добавить'}
					primaryIsLoading={isCreateLoading || isEditLoading}
					secondaryText="Отменить"
					secondaryOnClick={onClose}
					secondaryIsLoading={isCreateLoading || isEditLoading}
				/>
			</FormProvider>
		</form>
	);
};
