import React, { DetailedHTMLProps, HTMLAttributes, useState } from 'react';
import s from './CompanyUserCard.module.scss';
import { Avatar } from '@/shared/ui/Avatar/Avatar';
import { User } from '@/app/redux/state/company-structure/users/types';
import { ContactItem } from './ContactItem/ContactItem';
import { DropDownMenu } from '@/shared/ui/DropDownMenu/DropDownMenu';
import EditSVG from '@/shared/assets/svg_icons/action/edit.svg?react';
import DeleteSVG from '@/shared/assets/svg_icons/action/delete.svg?react';
import StarSVG from '@/shared/assets/svg_icons/action/star_stroke.svg?react';
import CancelSVG from '@/shared/assets/svg_icons/action/close.svg?react';
import { Modal } from '@/shared/ui/_modals/Modal/ui/Modal/Modal';
import { CreateNewUser } from '../AddUserModal/CreateNewUser/CreateNewUser';
import { company_structure_serviceAPI } from '@/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { image_serviceAPI } from '@/app/redux/queries/image-serviceAPI/image_serviceAPI';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	user: User;
	departmentName?: string | null;
	directorId: string;
	LeftComponent?: React.ReactNode;
	RightComponent?: React.ReactNode;
	isCustomUnit?: boolean;
}

export const CompanyUserCard: React.FC<Props> = props => {
	const { className, user, departmentName, directorId, LeftComponent, RightComponent, isCustomUnit, ...divProps } = props;

	const { isCustom, fullName, position, role, contacts, assignment, id, avatar, avatarId } = user;

	// * API
	const [editUser] = company_structure_serviceAPI.useEditUserMutation();

	// * Queries
	const { currentData: avatarData } = company_structure_serviceAPI.useGetAvatarQuery({ params: { userId: id } }, { skip: !isCustom });

	const { currentData: avatarInfo } = image_serviceAPI.useGetImageQuery(
		{
			params: {
				imageId: avatarId as string,
				source: 'User',
			},
		},
		{ skip: !avatarId || isCustom },
	);

	const userAvatar = isCustom ? avatarData : avatarInfo?.body;

	// * Name
	const fullNameArray = fullName?.split(' ');
	const lastName = fullNameArray[0];
	const firstName = fullNameArray[1];
	const middleName = fullNameArray[2] || '';
	const lastNameAndFirstName = `${lastName} ${firstName}`;

	// * Position
	const getRole = () => {
		if (assignment === 'Director') {
			if (isCustomUnit) return 'Руководитель';

			return 'Директор';
		}

		if (position) return position;

		return 'Сотрудник';
	};

	// * Contacts
	const contactsMap = contacts.map(contact => [contact.name.toLowerCase(), contact.value]);
	const contactsValues = Object.fromEntries(contactsMap);
	const contactsIdsMap = contacts.map(contact => [contact.name.toLowerCase(), contact.id]);
	const contactsIds = Object.fromEntries(contactsIdsMap);

	// * Menu
	const MainDropDownItems = [
		{
			icon: <EditSVG />,
			title: 'Редактировать',
			action: () => toggleEditModal(),
		},
		{
			icon: <DeleteSVG />,
			title: 'Удалить',
			action: () => id && deleteUser(id),
		},
	];

	departmentName !== '-' &&
		MainDropDownItems.unshift({
			icon: <CancelSVG />,
			title: 'Открепить',
			action: () => id && cancelUser(id),
		});

	assignment === 'Employee' &&
		!departmentName &&
		MainDropDownItems.unshift({
			icon: <StarSVG />,
			title: 'Назначить руководителем',
			action: () => id && appointByManager(id),
		});

	// * Edit
	const [showEditModal, setShowEditModal] = useState(false);
	const toggleEditModal = () => setShowEditModal(prevState => !prevState);

	// * Открепить сотрудника
	const cancelUser = (id: string) => {
		editUser({
			userId: id,
			payload: [
				{
					op: 'replace',
					path: '/departmentId',
					value: null,
				},
			],
		})
			.unwrap()
			.then(() => {
				if (id === directorId) {
					editUser({
						userId: id,
						payload: [
							{
								op: 'replace',
								path: '/assignment',
								value: 'Employee',
							},
						],
					});
				}
			})
			.catch(err => console.log(err));
	};

	// * delete user
	const deleteUser = (id: string) => {
		editUser({
			userId: id,
			payload: [
				{
					op: 'replace',
					path: '/isActive',
					value: false,
				},
			],
		});
	};

	// * Change assignment
	const appointByManager = (id: string) => {
		editUser({
			userId: id,
			payload: [
				{
					op: 'replace',
					path: '/assignment',
					value: 'Director',
				},
			],
		})
			.unwrap()
			.then(() => {
				if (directorId) {
					editUser({
						userId: directorId,
						payload: [
							{
								op: 'replace',
								path: '/assignment',
								value: 'Employee',
							},
						],
					});
				}
			})
			.catch(err => console.log(err));
	};

	// * Render
	return (
		<div
			{...divProps}
			className={`${s.container} ${className}`}
		>
			<div className={s.element_wrapper__left}>
				{LeftComponent}
				<Avatar
					userAvatar={userAvatar}
					name={lastNameAndFirstName}
				/>
			</div>

			<div className={s.info_wrapper}>
				<div className={s.about}>
					<div className={s.fullname_star}>
						<h5>{fullName ? fullName : ''}</h5>
						{assignment === 'Director' && <StarSVG />}
					</div>
					<div className={s.role}>{getRole()}</div>
				</div>

				<div className={`${departmentName ? s.units_and_contacts : ''}`}>
					{departmentName && <div className={s.department_name}>{departmentName}</div>}
					<div className={s.contacts}>
						{contacts.length > 0 &&
							contacts.map(contact => (
								<ContactItem
									key={contact.id}
									name={contact.name}
									value={contact.value}
								/>
							))}
						{contacts.length < 1 && '-'}
					</div>
				</div>
			</div>

			<div className={s.element_wrapper__right}>
				{RightComponent && (
					<DropDownMenu
						items={MainDropDownItems}
						value={id}
					/>
				)}
			</div>

			<Modal
				show={showEditModal}
				toggleModal={toggleEditModal}
				width={800}
				overflowX="auto"
				zIndex={2}
				Content={
					<CreateNewUser
						userId={id}
						values={{
							avatar: userAvatar,
							lastName: lastName,
							firstName: firstName,
							middleName: middleName,
							position: position || '',
							positionId: '',
							contacts: {
								email: contactsValues?.email || '',
								skype: contactsValues?.skype || '',
								telegram: contactsValues?.telegram || '',
								phone: contactsValues?.phone || '',
							},
						}}
						contactsIds={contactsIds}
						toggleCreateModal={toggleEditModal}
					/>
				}
			/>
		</div>
	);
};
