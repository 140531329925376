export const isDateOverlap = (
	vacations: {
		startDate: Date | null;
		endDate: Date | null;
	}[],
) => {
	const overlaps = [];
	const validVacations = vacations.filter(({ startDate, endDate }) => startDate && endDate);

	for (let i = 0; i < validVacations.length; i++) {
		for (let j = i + 1; j < validVacations.length; j++) {
			if ((validVacations[i].startDate as Date) <= (validVacations[j].endDate as Date) && (validVacations[i].endDate as Date) >= (validVacations[j].startDate as Date)) {
				overlaps.push({
					interval1: i + 1,
					interval2: j + 1,
				});
			}
		}
	}

	let message;
	if (overlaps.length > 0) message = `Интервалы ${overlaps.map(({ interval1, interval2 }) => ` ${interval1} и ${interval2}`)} пересекаются.`;

	return message;
};
