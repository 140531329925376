import cn from 'classnames';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CommentInfo } from '@/app/redux/queries/event-service/types/types';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';
import { imageServiceActions } from '@/app/redux/state/image/actions';
import ReplySVG from '@/shared/assets/svg_icons/arrows/reply.svg?react';
import { backendISOUTCToLocalLuxon } from '@/shared/lib/date';
import { createFullName } from '@/shared/lib/string';
import { Avatar } from '@/shared/ui/Avatar/Avatar';
import { Heading } from '@/shared/ui/Heading';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import { Gallery } from '@/shared/ui/_galleries/Gallery';
import { UserInfoForReply } from '../../types';
import { CommentCreate } from '../CommentCreate/CommentCreate';
import { CommentDropDownMenu } from '../CommentDropDownMenu/CommentDropDownMenu';
import { CommentList } from '../CommentList/CommentList';
import s from './Comment.module.scss';

interface Props {
	comment: CommentInfo;
	nestedCreateAction?: (user: UserInfoForReply | null) => void;
}

export const Comment: FC<Props> = props => {
	const {
		comment, //
		nestedCreateAction,
	} = props;

	const { comments: replies } = comment;

	// * Routing
	const navigate = useNavigate();
	const navigateToUserCard = () => navigate(`/users/${id}`);

	// * Actions
	const { getImage } = imageServiceActions.image.async;

	// * API
	// - Кем был создан комментарий
	const { data: createdByUserData } = user_serviceAPI.useGetUserGetQuery({
		userid: comment.createdBy,
		includeavatars: true,
	});

	// - В ответ кому был создан комментарий
	const { data: userData } = user_serviceAPI.useGetUserGetQuery(
		{
			userid: comment.userId,
			includeavatars: true,
		},
		{ skip: !comment.userId },
	);

	// - Для какого user создан комментарий-ответ
	const [userForReply, setUserForReply] = useState<UserInfoForReply | null>(null);

	const onChangeUserForReply = (user: UserInfoForReply | null) => {
		setUserForReply(user);
	};

	const replyAction = () => {
		const prepared = {
			id: createdByUserId,
			userName: `${createdByUserFirstName} ${createdByUserMiddleName || ''}`.trim(),
		};

		return nestedCreateAction ? nestedCreateAction(prepared) : onChangeUserForReply(prepared);
	};

	// - Режим редактирования
	const [isEditMode, setIsEditMode] = useState(false);
	const toggleEditMode = () => setIsEditMode(prevState => !prevState);

	// * Users info: values
	const {
		id: createdByUserId = '', //
		avatar: createdByUserAvatar = null,
		firstName: createdByUserFirstName = '',
		lastName: createdByUserLastName = '',
		middleName: createdByUserMiddleName = '',
	} = createdByUserData?.body?.user || {};

	const {
		id = '', //
		firstName = '',
		middleName = '',
	} = userData?.body?.user || {};

	const createdByUserFullName = createFullName({
		firstName: createdByUserFirstName,
		lastName: createdByUserLastName,
		middleName: createdByUserMiddleName || '',
	});

	// * Render
	return (
		<>
			{!isEditMode && (
				<div className={cn(s.container, comment.parentId && s.reply_container)}>
					<Avatar
						userAvatar={createdByUserAvatar}
						name={`${createdByUserLastName} ${createdByUserFirstName}`}
						size="large"
					/>

					<div className={s.content}>
						<div className={s.header}>
							<div className={s.header__inner}>
								<Heading level={4}>{createdByUserFullName}</Heading>

								<span>{backendISOUTCToLocalLuxon(comment.createdAtUtc).toFormat('dd.LL.yyyy HH:mm')}</span>

								<IconButton
									Icon={<ReplySVG />}
									onClick={replyAction}
								/>
							</div>

							<CommentDropDownMenu
								commentId={comment.id}
								editAction={toggleEditMode}
							/>
						</div>

						<p>
							{firstName && (
								<>
									<span
										className={s.reply_user}
										onClick={navigateToUserCard}
									>
										{`${firstName} ${middleName || ''}`.trim()}
									</span>
									<span>, </span>
								</>
							)}

							{comment.content}
						</p>
						{comment.commentImages && (
							<div className={s.gallery}>
								<Gallery
									images={comment.commentImages}
									allowedToEdit={false}
									size="small"
									fetchImage={(imageId: string) =>
										getImage({
											params: {
												imageId,
												source: 'Event',
											},
										})
									}
								/>
							</div>
						)}
					</div>
				</div>
			)}

			{isEditMode && (
				<CommentCreate
					user={{
						id: createdByUserId,
						avatar: createdByUserAvatar,
						firstName: createdByUserFirstName,
						lastName: createdByUserLastName,
					}}
					parentId={comment.id}
					userForReply={
						comment.userId
							? {
									id: id,
									userName: `${firstName} ${middleName || ''}`.trim(),
								}
							: null
					}
					commentContent={comment.content}
					commentImages={comment.commentImages || []}
					onClose={toggleEditMode}
				/>
			)}

			{replies && (
				<CommentList
					comments={replies}
					nestedCreateAction={onChangeUserForReply}
				/>
			)}

			{userForReply && (
				<CommentCreate
					parentId={comment.id}
					userForReply={userForReply}
					onClose={() => setUserForReply(null)}
				/>
			)}
		</>
	);
};
