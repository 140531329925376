import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { FindRolesREQ_PARAMS, FindRolesRES, RoleInfo, RoleLocalizationInfo } from './types';

const NAME = `${ServiceName.RIGHTS_SERVICE}/roles`;

// * Async thunks
const findRoles = createAppAsyncThunk(`${NAME}/findRoles`, async (arg: FindRolesREQ_PARAMS, thunkAPI) => {
	const { params } = arg;

	const res = await apiRequest.getRequest<FindRolesRES>({
		url: `${NAME}/find`,
		params,
		thunkAPI,
		action: () => findRoles(arg),
	});

	return res.body;
});

// * Slice
export interface State {
	rolesInfo: RoleInfo[] | null;
	localizationsInfo: RoleLocalizationInfo[] | []; // roles name
	status: RequestStatus;
}

export const initialState: State = {
	rolesInfo: null,
	localizationsInfo: [],
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setLocalizationsInfo: (state, action) => {
			state.localizationsInfo = [...state.localizationsInfo, action.payload];
		},
	},
	extraReducers: builder => {
		builder.addCase(findRoles.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findRoles.fulfilled, (state, action) => {
			state.rolesInfo = action.payload;
			state.status = RequestStatus.success;
		});
		builder.addCase(findRoles.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	findRoles,
};
