import { CategoryInfo } from '@/app/redux/queries/event-service/types/types';
import { COLORS } from '@/shared/consts/consts';

export const getCategoryInfo = (category: CategoryInfo[]) => {
	if (!category[0]) return {};

	const color = COLORS[Number(category[0].color ? parseInt(category[0].color.slice(-2)) - 1 : 0)];
	const name = category[0].name;

	return {
		color,
		name,
	};
};
