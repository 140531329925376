import React from 'react';
import s from './ChoiceGrid.module.scss';
import CloseSVG from '@/shared/assets/svg/action/close.svg?react';
import { OptionInput } from './OptionInput/OptionInput';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import { GridOption } from '../../../_types';
import { Checkbox } from '@/shared/ui/_inputs/Checkbox';
import { Radio } from '@/shared/ui/_inputs/Radio';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragDropHandle } from '@/shared/ui/DragDropHandle/DragDropHandle';

interface Props {
	option: GridOption;
	allColumns: GridOption[];
	indexes: {
		indexSection: number;
		indexQuestion: number;
		indexColumn: number;
	};

	onColumnSignatureChange: (value: string, prevValue: string, indexSection: number, indexQuestion: number) => void;
	removeColumn: (indexSection: number, indexQuestion: number, columnValue: string) => void;

	hasMultipleChoice: boolean;
	isEdit?: boolean;
}

export const ChoiceGridOptionColumn: React.FC<Props> = props => {
	const {
		option,
		allColumns,
		indexes: { indexSection, indexQuestion, indexColumn },
		onColumnSignatureChange,
		removeColumn,

		hasMultipleChoice,
		isEdit,
	} = props;

	// * DnD
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: option.id });

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	// * Render
	return (
		<div
			className={s.column_field}
			style={style}
			ref={setNodeRef}
		>
			{!isEdit && (
				<DragDropHandle
					{...attributes}
					{...listeners}
				/>
			)}

			{hasMultipleChoice && (
				<Checkbox
					disabled
					noDisabledStyles
				/>
			)}

			{!hasMultipleChoice && (
				<Radio
					checked={false}
					disabled
					noDisabledStyles
				/>
			)}

			<OptionInput
				id={option.id}
				placeholder={`Столбец ${indexColumn + 1}`}
				value={option.columnSignature}
				existValues={allColumns.map(col => col.columnSignature).filter(col => col !== option.columnSignature)}
				onSignatureChange={value => onColumnSignatureChange(value, option.columnSignature, indexSection, indexQuestion)}
			/>

			{allColumns.length > 1 && !isEdit && (
				<IconButton
					Icon={<CloseSVG />}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						removeColumn(indexSection, indexQuestion, option.columnSignature);
					}}
				/>
			)}
		</div>
	);
};
