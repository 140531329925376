import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { project_serviceAPI } from '@/app/redux/queries/project-service/project_serviceAPI';
import { ColorCircle } from '@/entities/ColorCircle';
import { ConditionalRendering } from '@/shared/providers';
import { Heading } from '@/shared/ui/Heading';
import s from './Projects.module.scss';
import { STATUS_COLORS } from './const';

export const Projects: FC = () => {
	// * Routing
	const { userId } = useParams() as { userId: string };
	const navigate = useNavigate();

	// * API
	const { data: projectsData, isLoading } = project_serviceAPI.useFindProjectsQuery(
		{
			skipCount: 0,
			takeCount: 1000,
			userid: userId,
		},
		{ skip: !userId },
	);

	// * Projects
	const projects = projectsData?.body
		? projectsData?.body.map(project => {
				const { id, name, shortName, status } = project;

				return { id, name, shortName, status };
			})
		: [];

	const allDataLoaded = projectsData?.body && projectsData.body.length > 0 && !isLoading;

	// * Render
	return (
		<div className={s.container}>
			<Heading
				level={2}
				size={3}
				marginBottom="m"
			>
				Проекты
			</Heading>

			<ConditionalRendering
				initialLoading={false}
				isSuccessful={!!allDataLoaded}
				FailedResult={<span>Нет активных проектов.</span>}
				LoadedResult={
					<div className={s.list}>
						{projects.map(project => (
							<div
								key={project.id}
								className={s.card}
								onClick={() => navigate(`/projects/${project.id}`)}
							>
								<div className={s.title}>
									<Heading level={4}>{project.shortName}</Heading>

									<ColorCircle
										size="xs"
										customColor={STATUS_COLORS[project.status]}
									/>
								</div>

								<span>{project.name}</span>
							</div>
						))}
					</div>
				}
			/>
		</div>
	);
};
