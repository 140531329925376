import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { GetFilesREQ } from '../file/types';

const NAME = `${ServiceName.FILE_SERVICE}/public`;

const getFiles = createAppAsyncThunk(`${NAME}/getFiles`, async (arg: GetFilesREQ, thunkAPI) => {
	const { params } = arg;

	const res = await apiRequest.getPublicRequest<any>({
		url: `${NAME}/get`,
		params,
		thunkAPI,
	});

	return res.body;
});

// * Reducer
export interface State {
	status: RequestStatus;
}

export const initialState: State = {
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getFiles.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getFiles.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(getFiles.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	getFiles,
};
