import { FC } from 'react';
import { UserResponse } from '@/app/redux/queries/user-service/types/resTypes';
import { Heading } from '@/shared/ui/Heading';
import { GeneralHeader } from '../GeneralHeader/GeneralHeader';
import s from './General.module.scss';
import { getInfo } from '../../lib/getInfo';
import InfoSVG from '@/shared/assets/svg/alerts/info.svg?react';
import { Tooltip } from '@/shared/ui/_tooltips/Tooltip/Tooltip';

interface Props {
	userInfo: UserResponse;
	hasRights: boolean;
}

export const General: FC<Props> = props => {
	const { userInfo, hasRights } = props;

	// * Render
	return (
		<div className={s.container}>
			<GeneralHeader userInfo={userInfo} />

			{getInfo(userInfo, hasRights).map(item => (
				<div
					key={item.title}
					className={s.info_wrapper}
				>
					<Heading
						level={3}
						size={4}
						marginBottom="s"
					>
						{item.title}
					</Heading>

					{item.fields.map(field => (
						<div
							key={field.name}
							className={s.info_fields}
						>
							<span>{field.name}</span>
							<span className={s.info_tooltip}>
								{field.value}
								{field.isHidden && (
									<Tooltip
										content="Дата рождения скрыта от других пользователей"
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										<InfoSVG />
									</Tooltip>
								)}
							</span>
						</div>
					))}
				</div>
			))}
		</div>
	);
};
