import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import SearchSVG from '@/shared/assets/svg/action/search.svg?react';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { useGenerateTimeListUser } from '@/shared/api/useGenerateTimeListUsers';
import { EmptyData } from '@/shared/ui/EmptyData/EmptyData';
import { TimeListTable } from '@/widgets/TimeListTable';
import s from './TimeList.module.scss';

interface Props {
	projectId: string;
	luxonDate: DateTime;
}

export const TimeList: React.FC<Props> = props => {
	const { projectId, luxonDate } = props;

	// * API
	const { users, isLoading, refetch } = useGenerateTimeListUser(luxonDate, { projectId });

	// * Filters
	// - Search name
	const [searchValue, setSearchValue] = useState('');

	// - Result
	const [filteredUsers, setFilteredUsers] = useState(users);

	useEffect(() => {
		let newUsers = users;

		if (searchValue !== '') {
			newUsers = newUsers.filter(user => user.fullName.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
		}

		setFilteredUsers(newUsers);
	}, [users, searchValue]);

	// * Render
	return (
		<div className={s.container}>
			<TextInput
				placeholder="Поиск по ФИО"
				value={searchValue}
				onChange={event => setSearchValue(event.target.value)}
				RightIcon={<SearchSVG />}
			/>

			{isLoading && <LoaderCircle />}

			{filteredUsers.length > 0 && (
				<TimeListTable
					users={filteredUsers}
					refetch={refetch}
				/>
			)}

			{users.length === 0 && !isLoading && <EmptyData />}
		</div>
	);
};
