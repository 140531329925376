import React from 'react';
import s from '@/features/CustomLegends/CustomLegends.module.scss';
import { ColorCircle } from '@/entities/ColorCircle/ui/ColorCircle';

interface DataItem {
	id: string;
	value: number;
	color: string;
}

interface Props {
	legends: DataItem[];
}

export const CustomLegends: React.FC<Props> = props => {
	const { legends } = props;

	// * Render
	return (
		<div className={s.container}>
			{legends.map(data => (
				<div
					key={data.id}
					className={s.legend}
				>
					<ColorCircle
						key={data.id}
						size="xs"
						customColor={data.color}
					/>

					<span>{data.id}</span>
				</div>
			))}
		</div>
	);
};
