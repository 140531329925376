import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '@/app/api/apiSlice';
import { ServiceName } from '@/app/api/api_types';
import {
	DeleteEventCategoryRemoveApiArg,
	DeleteEventCommentRemoveApiArg,
	DeleteFileRemoveApiArg,
	DeleteImageRemoveApiArg,
	GetCategoryFindApiArg,
	GetEventFindApiArg,
	GetEventGetApiArg,
	GetEventUserFindApiArg,
	GetFileFindApiArg,
	GetUserBirthdayFindApiArg,
	PatchCategoryEditApiArg,
	PatchEventCommentEditApiArg,
	PatchEventEditApiArg,
	PatchEventUserEditApiArg,
	PostCategoryCreateApiArg,
	PostEventCategoryCreateApiArg,
	PostEventCommentCreateApiArg,
	PostEventCreateApiArg,
	PostEventUserCreateApiArg,
	PostImageCreateApiArg,
} from './types/reqTypes';
import {
	DeleteEventCategoryRemoveApiResponse,
	DeleteEventCommentRemoveApiResponse,
	DeleteFileRemoveApiResponse,
	DeleteImageRemoveApiResponse,
	GetCategoryFindApiResponse,
	GetEventFindApiResponse,
	GetEventGetApiResponse,
	GetEventUserFindApiResponse,
	GetFileFindApiResponse,
	GetUserBirthdayFindApiResponse,
	PatchCategoryEditApiResponse,
	PatchEventCommentEditApiResponse,
	PatchEventEditApiResponse,
	PatchEventUserEditApiResponse,
	PostCategoryCreateApiResponse,
	PostEventCategoryCreateApiResponse,
	PostEventCommentCreateApiResponse,
	PostEventCreateApiResponse,
	PostEventUserCreateApiResponse,
	PostImageCreateApiResponse,
} from './types/resTypes';

export const reducerPath = `${ServiceName.EVENT_SERVICE}` as const;

export const event_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Category', 'Event', 'EventCategory', 'EventComment', 'EventUser', 'File', 'Image', 'UserBirthday'] as const,
	endpoints: build => ({
		postCategoryCreate: build.mutation<PostCategoryCreateApiResponse, PostCategoryCreateApiArg>({
			query: queryArg => ({ url: `${reducerPath}/Category/create`, method: 'POST', body: queryArg.createCategoryRequest }),
			invalidatesTags: ['Category'],
		}),
		patchCategoryEdit: build.mutation<PatchCategoryEditApiResponse, PatchCategoryEditApiArg>({
			query: queryArg => ({ url: `${reducerPath}/Category/edit`, method: 'PATCH', body: queryArg.body, params: { categoryId: queryArg.categoryId } }),
			invalidatesTags: ['Category'],
		}),
		getCategoryFind: build.query<GetCategoryFindApiResponse, GetCategoryFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Category/find`,
				params: {
					nameIncludeSubstring: queryArg.nameIncludeSubstring,
					color: queryArg.color,
					isAscendingSort: queryArg.isAscendingSort,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Category'],
		}),
		postEventCreate: build.mutation<PostEventCreateApiResponse, PostEventCreateApiArg>({
			query: queryArg => ({ url: `${reducerPath}/Event/create`, method: 'POST', body: queryArg.createEventRequest }),
			invalidatesTags: ['Event'],
		}),
		getEventFind: build.query<GetEventFindApiResponse, GetEventFindApiArg>({
			query: queryArg => ({
				url: queryArg.categoriesIds ? `${reducerPath}/Event/find?categoriesIds=${queryArg.categoriesIds.join('&categoriesIds=')}` : `${reducerPath}/Event/find`,
				params: {
					userId: queryArg.userId,
					nameIncludeSubstring: queryArg.nameIncludeSubstring,
					categoryNameIncludeSubstring: queryArg.categoryNameIncludeSubstring,
					includeDeactivated: queryArg.includeDeactivated,
					startTime: queryArg.startTime,
					endTime: queryArg.endTime,
					access: queryArg.access,
					color: queryArg.color,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Event'],
		}),
		getEventGet: build.query<GetEventGetApiResponse, GetEventGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Event/get`,
				params: {
					eventId: queryArg.eventId,
					includeUsers: queryArg.includeUsers,
					includeCategories: queryArg.includeCategories,
					includeImages: queryArg.includeImages,
					includeFiles: queryArg.includeFiles,
					includeComments: queryArg.includeComments,
				},
			}),
			providesTags: ['Event'],
		}),
		patchEventEdit: build.mutation<PatchEventEditApiResponse, PatchEventEditApiArg>({
			query: queryArg => ({ url: `${reducerPath}/Event/edit`, method: 'PATCH', body: queryArg.body, params: { eventId: queryArg.eventId } }),
			invalidatesTags: ['Event'],
		}),
		postEventCategoryCreate: build.mutation<PostEventCategoryCreateApiResponse, PostEventCategoryCreateApiArg>({
			query: queryArg => ({ url: `${reducerPath}/EventCategory/create`, method: 'POST', body: queryArg.createEventCategoryRequest }),
			invalidatesTags: ['EventCategory'],
		}),
		deleteEventCategoryRemove: build.mutation<DeleteEventCategoryRemoveApiResponse, DeleteEventCategoryRemoveApiArg>({
			query: queryArg => ({ url: `${reducerPath}/EventCategory/remove`, method: 'DELETE', body: queryArg.removeEventCategoryRequest }),
			invalidatesTags: ['EventCategory'],
		}),
		postEventCommentCreate: build.mutation<PostEventCommentCreateApiResponse, PostEventCommentCreateApiArg>({
			query: queryArg => ({ url: `${reducerPath}/EventComment/create`, method: 'POST', body: queryArg.createEventCommentRequest }),
			invalidatesTags: ['Event', 'EventComment'],
		}),
		patchEventCommentEdit: build.mutation<PatchEventCommentEditApiResponse, PatchEventCommentEditApiArg>({
			query: queryArg => ({ url: `${reducerPath}/EventComment/edit`, method: 'PATCH', body: queryArg.body, params: { commentId: queryArg.commentId } }),
			invalidatesTags: ['Event', 'EventComment'],
		}),
		deleteEventCommentRemove: build.mutation<DeleteEventCommentRemoveApiResponse, DeleteEventCommentRemoveApiArg>({
			query: queryArg => ({ url: `${reducerPath}/EventComment/remove`, method: 'DELETE', params: { commentId: queryArg.commentId } }),
			invalidatesTags: ['Event', 'EventComment'],
		}),
		postEventUserCreate: build.mutation<PostEventUserCreateApiResponse, PostEventUserCreateApiArg>({
			query: queryArg => ({ url: `${reducerPath}/EventUser/create`, method: 'POST', body: queryArg.createEventUserRequest }),
			invalidatesTags: ['EventUser'],
		}),
		getEventUserFind: build.query<GetEventUserFindApiResponse, GetEventUserFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/EventUser/find`,
				params: {
					eventId: queryArg.eventId,
					userFullNameIncludeSubstring: queryArg.userFullNameIncludeSubstring,
					status: queryArg.status,
					isAscendingSort: queryArg.isAscendingSort,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['EventUser'],
		}),
		patchEventUserEdit: build.mutation<PatchEventUserEditApiResponse, PatchEventUserEditApiArg>({
			query: queryArg => ({ url: `${reducerPath}/EventUser/edit`, method: 'PATCH', body: queryArg.body, params: { eventUserId: queryArg.eventUserId } }),
			invalidatesTags: ['EventUser'],
		}),
		getFileFind: build.query<GetFileFindApiResponse, GetFileFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/File/find`,
				params: { entityId: queryArg.entityId, skipcount: queryArg.skipcount, takecount: queryArg.takecount },
			}),
			providesTags: ['File'],
		}),
		deleteFileRemove: build.mutation<DeleteFileRemoveApiResponse, DeleteFileRemoveApiArg>({
			query: queryArg => ({ url: `${reducerPath}/File/remove`, method: 'DELETE', body: queryArg.removeFilesRequest }),
			invalidatesTags: ['File'],
		}),
		postImageCreate: build.mutation<PostImageCreateApiResponse, PostImageCreateApiArg>({
			query: queryArg => ({ url: `${reducerPath}/Image/create`, method: 'POST', body: queryArg.createImagesRequest }),
			invalidatesTags: ['Image'],
		}),
		deleteImageRemove: build.mutation<DeleteImageRemoveApiResponse, DeleteImageRemoveApiArg>({
			query: queryArg => ({ url: `${reducerPath}/Image/remove`, method: 'DELETE', body: queryArg.removeImageRequest }),
			invalidatesTags: ['Image'],
		}),
		getUserBirthdayFind: build.query<GetUserBirthdayFindApiResponse, GetUserBirthdayFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/UserBirthday/find`,
				params: { StartTime: queryArg.startTime, EndTime: queryArg.endTime, skipcount: queryArg.skipcount, takecount: queryArg.takecount },
			}),
			providesTags: ['UserBirthday'],
		}),
	}),
});

export const {
	usePostCategoryCreateMutation,
	usePatchCategoryEditMutation,
	useGetCategoryFindQuery,
	usePostEventCreateMutation,
	useGetEventFindQuery,
	useLazyGetEventFindQuery,
	useGetEventGetQuery,
	usePatchEventEditMutation,
	usePostEventCategoryCreateMutation,
	useDeleteEventCategoryRemoveMutation,
	usePostEventCommentCreateMutation,
	usePatchEventCommentEditMutation,
	useDeleteEventCommentRemoveMutation,
	usePostEventUserCreateMutation,
	useGetEventUserFindQuery,
	usePatchEventUserEditMutation,
	useGetFileFindQuery,
	useLazyGetFileFindQuery,
	useDeleteFileRemoveMutation,
	usePostImageCreateMutation,
	useDeleteImageRemoveMutation,
	useGetUserBirthdayFindQuery,
} = event_serviceAPI;
