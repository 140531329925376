import { useNavigate } from 'react-router-dom';
import { survey_serviceAPI } from '@/app/redux/queries/survey-service/survey_serviceAPI';
import { QuestionType } from '@/app/redux/queries/survey-service/types';
import { getGridColumns } from '@/pages/_surveys/libs/getGridColumns/getGridColumns';
import { getGridRows } from '@/pages/_surveys/libs/getGridRows/getGridRows';
import { GridOption, Option, ScaleOption, SectionProps, Survey } from '@/pages/_surveys/pages/NewSurveyPage/_types';
import { dateAndTimeToString } from '@/shared/lib/date';

export const useCreateSurvey = (surveyInfo: Survey, sections: SectionProps[], isTemplate: boolean) => {
	// * Navigate
	const navigate = useNavigate();

	// * API
	const [postSurveys, { isLoading: isCreateLoading }] = survey_serviceAPI.usePostSurveysMutation();

	const createSurveySubmit = () => {
		const preparedInfo = sections.map(section => {
			const { subject, questions } = section;

			const preparedQuestions = questions.map(question => {
				const { options, gridOptions, scaleOptions } = question;

				// options
				const preparedOptions: Omit<Option, 'id'>[] = [];
				options.length > 0 &&
					options.map(option =>
						preparedOptions.push({
							content: option.content,
							isCustom: false,
						}),
					);

				// grid options
				const preparedGridOptions: Omit<GridOption, 'id'>[] = [];
				gridOptions.length > 0 &&
					gridOptions.map(option => {
						preparedGridOptions.push({
							columnSignature: option.columnSignature,
							rowSignature: option.rowSignature,
						});
					});

				const columnsCount = getGridColumns(gridOptions).length;
				const rowsCount = getGridRows(gridOptions).length;

				// scale options
				const preparedScaleOptions: Omit<ScaleOption, 'id'>[] = [];
				scaleOptions.length > 0 &&
					scaleOptions.map(option => {
						preparedScaleOptions.push({
							content: option.content,
							leftSignature: option.leftSignature,
							rightSignature: option.rightSignature,
						});
					});

				return {
					content: question.content,
					hasMultipleChoice: question.hasMultipleChoice,
					questionType: question.type.type as QuestionType,
					hasCustomOptions: !!question.hasCustomOptions,
					isObligatory: question.isObligatory,
					numberOfRows: rowsCount,
					numberOfColumns: columnsCount,
					options: [...preparedOptions],
					gridOptions: [...preparedGridOptions],
					scaleOptions: [...preparedScaleOptions],
					questionImages: question.questionImages && question.questionImages.length > 0 ? question.questionImages : null,
				};
			});

			return {
				subject: subject,
				questions: [...preparedQuestions],
			};
		});

		const preparedRequest = {
			subject: surveyInfo.title,
			description: surveyInfo.description,
			hasRealTimeResult: true,
			isAnonymous: surveyInfo.isAnonymous,
			isRevoteAvailable: surveyInfo.cancelVoice,
			isTemplate: isTemplate,
			groups: [...preparedInfo],
		};

		surveyInfo.categoryId &&
			Object.assign(preparedRequest, {
				categoriesIds: [surveyInfo.categoryId],
			});

		if (surveyInfo.dateEnd) {
			const deadlineSurvey = dateAndTimeToString(surveyInfo.timeEnd, surveyInfo.dateEnd);

			Object.assign(preparedRequest, {
				deadline: deadlineSurvey,
			});
		}

		postSurveys({
			createSurveyCommand: preparedRequest,
		})
			.unwrap()
			.then(() => navigate('/surveys'))
			.catch(err => console.log(err));
	};

	return { createSurveySubmit, isCreateLoading };
};
