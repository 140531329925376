import { useEffect } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import { TABS } from '../consts';

export const useTabs = (searchParams: URLSearchParams, setSearchParams: SetURLSearchParams) => {
	const activeTab = searchParams.get('tab');

	const setActiveTab = (tab: (typeof TABS)[number]['id']) => {
		searchParams.set('tab', tab);
		setSearchParams(searchParams);
	};

	useEffect(() => {
		if (!searchParams.get('tab')) {
			searchParams.set('tab', TABS[0].id);
			setSearchParams(searchParams);
		}
	}, []);

	return {
		activeTab,
		setActiveTab,
	};
};
