import BalanceSVG from '@/shared/assets/svg/social/balance.svg?react';
import OfficeSVG from '@/shared/assets/svg/social/office.svg?react';
import SupervisorSVG from '@/shared/assets/svg/social/supervisor.svg?react';
import LoyaltySVG from '@/shared/assets/svg/social/loyalty.svg?react';
import ClockSVG from '@/shared/assets/svg/action/clock.svg?react';
import AccessAlarmsSVG from '@/shared/assets/svg/social/access-alarms.svg?react';
import HistorySVG from '@/shared/assets/svg/social/history.svg?react';

export const ProjectInfoFields = [
	{
		key: 'department',
		Icon: BalanceSVG,
		text: 'Департамент',
	},
	{
		key: 'customer',
		Icon: OfficeSVG,
		text: 'Заказчик',
	},
	{
		key: 'startDateUtc',
		Icon: ClockSVG,
		text: 'Дата запуска',
	},
	{
		key: 'usersCount',
		Icon: SupervisorSVG,
		text: 'Сотрудники',
	},

	{
		key: 'endDateUtc',
		Icon: AccessAlarmsSVG,
		text: 'Дата завершения',
	},
	{
		key: 'status',
		Icon: LoyaltySVG,
		text: 'Статус',
	},
	{
		key: 'duration',
		Icon: HistorySVG,
		text: 'Продолжительность',
	},
];
