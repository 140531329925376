import React, { useState } from 'react';
import s from './DatePickerTitleButton.module.scss';
import { dateToMonthYearLocaleString, dateToYearLocaleString } from '@/shared/lib/date';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { FloatingFocusManager } from '@floating-ui/react';
import { useFloatingWrapper } from '@/shared/hooks/useFloatingWrapper';
import cn from 'classnames';
import { CollapseArrows } from '@/shared/ui/CollapseArrows/CollapseArrows';

interface Props extends Omit<ReactDatePickerProps, 'onChange' | 'showMonthYearPicker' | 'showFullMonthYearPicker'> {
	selectedDate: Date;
	setSelectedDate: (date: Date) => void;
	prefix?: string;
	format?: 'monthYear' | 'year';
	size?: 'medium' | 'large';
}

export const DatePickerTitleButton: React.FC<Props> = props => {
	const {
		selectedDate, //
		setSelectedDate,
		prefix,
		format = 'monthYear',
		size = 'medium',
		className,
		...restProps
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	const onChange = (date: Date | null) => {
		setIsOpen(!isOpen);
		date && setSelectedDate(date);
	};

	// * Floating
	const { floatingStyles, refs, context, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(isOpen, setIsOpen);

	let dateString = '';
	let pickerProps = null;

	switch (format) {
		case 'monthYear':
			dateString = dateToMonthYearLocaleString(selectedDate);
			pickerProps = {
				showMonthYearPicker: true,
				showFullMonthYearPicker: true,
			};
			break;
		case 'year':
			dateString = dateToYearLocaleString(selectedDate);
			pickerProps = {
				showYearPicker: true,
			};
			break;
	}

	let Title = null;

	const TitleChildren = (
		<>
			{prefix}
			<span
				className={s.picker_button}
				ref={refs.setReference}
				{...getReferenceProps()}
			>
				{dateString}
				<CollapseArrows
					isOpen={isOpen}
					arrowType="empty"
					color="primary"
				/>
			</span>
		</>
	);

	switch (size) {
		case 'medium':
			Title = <h3 className={s.title}>{TitleChildren}</h3>;
			break;
		case 'large':
			Title = <h2 className={s.title}>{TitleChildren}</h2>;
			break;
	}

	// * Render
	return (
		<div className={cn(s.container, className)}>
			{/* <h3 className={s.title}>
				{prefix}
				<span
					className={s.picker_button}
					ref={refs.setReference}
					{...getReferenceProps()}
				>
					{dateString}
					<CollapseArrows
						isOpen={isOpen}
						arrowType="empty"
						color="primary"
					/>
				</span>
			</h3> */}
			{Title}

			{isOpen && (
				<FloatingFocusManager
					context={context}
					modal={false}
				>
					<div
						ref={refs.setFloating}
						style={{
							...floatingStyles,
							zIndex: 'var(--z-index-floating)',
						}}
						aria-labelledby={headingId}
						{...getFloatingProps()}
					>
						<DatePicker
							{...restProps}
							locale={'ru'}
							selected={selectedDate}
							onChange={onChange}
							{...pickerProps}
							inline
						/>
					</div>
				</FloatingFocusManager>
			)}
		</div>
	);
};
