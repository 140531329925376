import React, { DetailedHTMLProps, InputHTMLAttributes, useState } from 'react';
import VisibilitySVG from '@/shared/assets/svg/action/visibility.svg?react';
import VisibilityOffSVG from '@/shared/assets/svg/action/visibility_off.svg?react';
import _input from '@/shared/ui/_inputs/_styles/_input.module.scss';
import { ErrorWithLimit } from '../../_shared/ErrorWithLimit/ErrorWithLimit';
import { InputLabel } from '../../_shared/InputLabel/InputLabel';
import cn from 'classnames';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	label?: string;
	placeholder?: string | undefined;
	value: string;
	errorBorder?: boolean;
	errorMessage?: string;
}

export const PasswordInput: React.FC<Props> = props => {
	const { className, label, placeholder, value, errorBorder, errorMessage, onChange, required, ...restInputProps } = props;

	const [type, setType] = useState<'text' | 'password'>('password');

	return (
		<div className={_input.container}>
			<InputLabel
				label={label}
				required={required}
			/>

			<div className={`${_input.input_wrapper} ${className}`}>
				<input
					{...restInputProps}
					className={_input.input}
					type={type}
					value={value}
					onChange={onChange}
				/>
				{type === 'text' ? (
					<VisibilitySVG
						className={`${_input.icon_right} ${_input.icon_right__clickable}`}
						onClick={() => setType('password')}
					/>
				) : (
					<VisibilityOffSVG
						className={`${_input.icon_right} ${_input.icon_right__clickable}`}
						onClick={() => setType('text')}
					/>
				)}
				<div className={cn(_input.border, errorBorder && _input.border_error)} />
				{!value && <span className={_input.placeholder}>{placeholder}</span>}
			</div>

			{errorMessage && (
				<ErrorWithLimit
					errorMessage={errorMessage}
					className={_input.error_message}
				/>
			)}
		</div>
	);
};
