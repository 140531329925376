import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { useGetEventGetQuery, useGetEventUserFindQuery } from '@/app/redux/queries/event-service/event_serviceAPI';
import { AccessType, FileInfo, ImageInfo } from '@/app/redux/queries/event-service/types/types';
import { actionsEventCalendar } from '@/app/redux/state/event-calendar/slice';
import { useAppDispatch } from '@/app/redux/utils';
import { PageTitle } from '@/entities/PageTitle/PageTitle';
import { AddUsersToEvent } from '@/pages/_eventСalendar/ui/EventForm/ui/AddUsersToEvent';
import { StepCounter } from '@/pages/_eventСalendar/ui/EventForm/ui/StepCounter';
import { extractTimeFromISO } from '@/shared/lib/date';
import { Button } from '@/shared/ui/_buttons/Button';
import { FORM_NAMES, MAX_STEPS } from '../../consts/consts';
import { AddFilesToEvent } from '../AddFilesToEvent';
import { EventMainInfo } from '../EventMainInfo';
import { Form, InitialFormValues } from '../EventMainInfo/types';
import s from './EventForm.module.scss';
import { getSubmitButtonText } from './lib/getSubmitButtonText/getSubmitButtonText';

export const EventForm = () => {
	const { eventId } = useParams() as { eventId?: string };

	const [files, setFiles] = useState<File[]>([]);
	const [sendLoading, setSendLoading] = useState(false);
	const [initialFilesImages, setInitialFilesImages] = useState<{ images: ImageInfo[]; files: FileInfo[] }>({ images: [], files: [] });

	// * Navigation
	const navigate = useNavigate();

	const closeForm = () => {
		navigate(-1);
	};

	// * Actions
	const dispatch = useAppDispatch();
	const { resetEventForm } = actionsEventCalendar;

	// * API
	const {
		data,
		isLoading: getEventLoading,
		refetch,
	} = useGetEventGetQuery(
		{
			eventId: eventId as string,
			includeFiles: true,
			includeImages: true,
			includeCategories: true,
		},
		{
			skip: !eventId,
		},
	);

	const { data: eventUsersData, isLoading: getUsersLoading } = useGetEventUserFindQuery(
		{
			skipcount: 0,
			takecount: 1000,
			eventId: eventId as string,
			status: 'Participant',
		},
		{
			skip: !eventId,
		},
	);

	useEffect(() => {
		dispatch(resetEventForm());
		if (eventId) {
			refetch();
		}
	}, [eventId, refetch]);

	const eventData = data?.body;
	const isLoading = getEventLoading || sendLoading || getUsersLoading;

	// * Form navigation
	const [currentStep, setCurrentStep] = useState<number>(1);

	const goToNextStep = () => {
		setCurrentStep(prevState => prevState + 1);
	};

	const goToPrevStep = () => {
		if (currentStep > 1) {
			setCurrentStep(prevState => prevState - 1);
		}
	};

	// * Info step
	const noCategory = {
		id: '',
		name: '',
	};

	const initialValues: InitialFormValues = {
		name: '',
		address: '',
		category: noCategory,
		startTime: '',
		endTime: '',
		access: 'Opened',
	};

	const category = eventData?.eventCategories ? eventData.eventCategories[0] : noCategory;

	const eventValues = eventData && {
		name: eventData.name,
		address: eventData.address,
		category: category,
		startDate: new Date(eventData.date),
		startTime: eventData.date ? extractTimeFromISO(eventData.date) : initialValues.startTime,
		endDate: eventData.endDate && new Date(eventData.endDate),
		endTime: eventData.endDate ? extractTimeFromISO(eventData.endDate) : initialValues.endTime,
		description: eventData.description,
		access: (+eventData.access === 0 ? 'Opened' : 'Closed') as AccessType,
		isActive: eventData.isActive,
		eventImages: eventData.eventImages || [],
	};

	const defaultValues = eventId && eventValues ? eventValues : initialValues;

	// * Images & Files step
	useEffect(() => {
		setInitialFilesImages({
			images: eventData?.eventImages || [],
			files: eventData?.eventFiles || [],
		});
	}, [eventData]);

	// * Users step
	const initialUsersIds = (eventUsersData?.body ?? []).map(user => ({ id: user.userInfo.userId, userEventId: user.id })); // Нужно только при редактировании

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title={eventId ? 'Редактирование события' : 'Новое событие'}
				isBeta
				buttons={[
					<Button
						variant="tertiary"
						onClick={closeForm}
						key="cancel"
					>
						Отмена
					</Button>,
				]}
			/>

			<div className={s.stepContainer}>
				<div className={s.stepContainer__wrapper}>
					<Button
						style={currentStep > 1 ? undefined : { visibility: 'hidden' }}
						variant="tertiary"
						onClick={goToPrevStep}
					>
						Назад
					</Button>

					<StepCounter
						currentStep={currentStep}
						maxSteps={MAX_STEPS}
					/>

					<Button
						type="submit"
						variant="primary"
						form={FORM_NAMES[currentStep - 1]}
						isLoading={isLoading}
					>
						{getSubmitButtonText(currentStep, eventId)}
					</Button>
				</div>
			</div>

			<div className={s.container}>
				<div
					style={{
						display: currentStep === 1 ? 'block' : 'none',
					}}
				>
					{(!eventId || (eventId && eventData && !!defaultValues.name)) && (
						<EventMainInfo
							formName={FORM_NAMES[0]}
							onSubmitAfterValidation={goToNextStep}
							defaultValues={defaultValues}
							key={JSON.stringify(defaultValues)}
						/>
					)}
				</div>

				<div
					style={{
						display: currentStep === 2 ? 'block' : 'none',
					}}
				>
					<AddFilesToEvent
						formName={FORM_NAMES[1]}
						onSubmitAfterValidation={goToNextStep}
						setFiles={setFiles}
						files={files}
						initialData={initialFilesImages}
					/>
				</div>

				<div
					style={{
						display: currentStep === 3 ? 'block' : 'none',
					}}
				>
					<AddUsersToEvent
						formName={FORM_NAMES[2]}
						initialUsersIds={initialUsersIds}
						initialValues={defaultValues as Form}
						eventId={eventId}
						files={files}
						initialFilesImages={initialFilesImages}
						setLoading={setSendLoading}
						setInitialFilesImages={setInitialFilesImages}
					/>
				</div>
			</div>
		</PageDecorator>
	);
};
