import React, { MouseEvent, useRef } from 'react';
import { Crop, PercentCrop, PixelCrop, ReactCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useDebounceEffect } from '@/pages/_projectService/hooks/useDebounceEffect';
import { contentTypeToExtension, getBase64Info } from '@/shared/lib/file';
import { Button } from '@/shared/ui/_buttons/Button';
import s from './CroppableAvatarModal.module.scss';

interface CroppableAvatarModalProps {
	onSetCrop: (percent: PercentCrop) => void;
	imageSrc: string;
	crop: Crop | undefined;
	onImageLoad: (e: React.SyntheticEvent<HTMLImageElement>) => void;
	onComplete: (pixel: PixelCrop) => void;
	imageSize: {
		height: number;
		width: number;
	};
	completedCrop: PixelCrop | undefined;
	updateImage: (content: string, type: string, url: string) => void;
	closeModal: () => void;
	deleteImage: () => void;
}

export const CroppableAvatarModal: React.FC<CroppableAvatarModalProps> = props => {
	const { onSetCrop, imageSrc, crop, onImageLoad, onComplete, imageSize, completedCrop, updateImage, closeModal, deleteImage } = props;

	const previewCanvasRef = useRef<HTMLCanvasElement>(null);
	const blobUrlRef = useRef('');
	const imgRef = useRef(null);

	const canvasPreview = async (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop, scale = 1, rotate = 0) => {
		const ctx = canvas.getContext('2d');

		if (!ctx) {
			throw new Error('No 2d context');
		}

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		const pixelRatio = window.devicePixelRatio || 1;

		canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
		canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

		ctx.scale(pixelRatio, pixelRatio);
		ctx.imageSmoothingQuality = 'high';

		const cropX = crop.x * scaleX;
		const cropY = crop.y * scaleY;

		const rotateRads = (rotate * Math.PI) / 180;
		const centerX = image.naturalWidth / 2;
		const centerY = image.naturalHeight / 2;

		ctx.save();
		ctx.translate(-cropX, -cropY);
		ctx.translate(centerX, centerY);
		ctx.rotate(rotateRads);
		ctx.scale(scale, scale);
		ctx.translate(-centerX, -centerY);
		ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

		ctx.restore();
	};

	useDebounceEffect(
		async () => {
			if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
				await canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
			}
		},
		100,
		[completedCrop],
	);

	const onDownloadCropClick = () => {
		if (!previewCanvasRef.current) {
			throw new Error('Канвас не был создан');
		}

		previewCanvasRef.current.toBlob(blob => {
			if (!blob) {
				throw new Error('Ошибка при создании blob');
			}
			if (blobUrlRef.current) {
				URL.revokeObjectURL(blobUrlRef.current);
			}
			blobUrlRef.current = URL.createObjectURL(blob);

			// update image
			const reader = new FileReader();

			reader.onload = async () => {
				const result = reader.result;

				const { content, contentType } = getBase64Info(result ? result.toString() : ''); // content & contentType
				const type = contentTypeToExtension(contentType);

				const src = await new Response(blobUrlRef.current).text();

				updateImage(content, type, src);
			};

			reader.readAsDataURL(blob);
		});
	};

	const deleteImageSrc = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		deleteImage();
		closeModal();
	};

	return (
		<div className={s.content}>
			<h2 className={s.title}>Редактирование фотографии</h2>
			<div
				className={s.image}
				style={{
					width: imageSize.width,
				}}
			>
				<ReactCrop
					onChange={(crop, percentCrop) => onSetCrop(percentCrop)}
					crop={crop}
					aspect={1}
					onComplete={c => onComplete(c)}
					circularCrop={true}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<img
						src={imageSrc}
						alt=""
						ref={imgRef}
						onLoad={onImageLoad}
						style={{
							height: 'auto',
							width: '100%',
						}}
					/>
				</ReactCrop>
				<div>
					<canvas
						ref={previewCanvasRef}
						style={{
							display: 'none',
						}}
					/>
				</div>
			</div>
			<div className={s.buttonRow}>
				<Button
					variant="tertiary"
					onClick={e => deleteImageSrc(e)}
					fixedWidth
					type="button"
				>
					Удалить
				</Button>
				<Button
					variant="primary"
					type="button"
					fixedWidth
					onClick={onDownloadCropClick}
				>
					Сохранить
				</Button>
			</div>
		</div>
	);
};
