import React from 'react';
import s from './ContactItem.module.scss';
import EmailSVG from '@/shared/assets/svg/social/email.svg?react';
import PhoneSVG from '@/shared/assets/svg/social/phone.svg?react';
import SkypeSVG from '@/shared/assets/svg/social/skype.svg?react';
import TelegramSVG from '@/shared/assets/svg/social/telegram.svg?react';
import { UserContact } from '@/entities/UserContact/UserContact';

interface Props {
	name: string;
	value: string;
}

export const ContactItem: React.FC<Props> = props => {
	const { name, value } = props;

	const getIcon = () => {
		switch (name) {
			case 'Email':
				return <EmailSVG />;
			case 'Telegram':
				return <TelegramSVG />;
			case 'Phone':
				return <PhoneSVG />;
			case 'Skype':
				return <SkypeSVG />;
			default:
				return <EmailSVG />;
		}
	};

	return (
		<div className={s.container}>
			<div className={s.icon}>{getIcon()}</div>

			<UserContact
				type={name}
				value={value}
				font="body2"
			/>
		</div>
	);
};
