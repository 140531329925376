import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@/app/api/apiSlice';
import { ServiceName } from '@/app/api/api_types';
import {
	DeleteUserRemoveApiArg,
	GetCategoryFindApiArg,
	GetDepartmentFindApiArg,
	GetDepartmentGetApiArg,
	GetDepartmentsTreeGetApiArg,
	GetUserFindApiArg,
	PatchDepartmentEditApiArg,
	PostCategoryCreateApiArg,
	PostDepartmentCreateApiArg,
	PostUserCreateApiArg,
	PutUserEditAssignmentApiArg,
	PutUserEditRoleApiArg,
} from './types/regTypes';
import {
	DeleteUserRemoveApiResponse,
	GetCategoryFindApiResponse,
	GetDepartmentFindApiResponse,
	GetDepartmentGetApiResponse,
	GetDepartmentsTreeGetApiResponse,
	GetUserFindApiResponse,
	PatchDepartmentEditApiResponse,
	PostCategoryCreateApiResponse,
	PostDepartmentCreateApiResponse,
	PostUserCreateApiResponse,
	PutUserEditAssignmentApiResponse,
	PutUserEditRoleApiResponse,
} from './types/resTypes';

const reducerPath = `${ServiceName.DEPARTMENT_SERVICE}` as const;

export const department_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Category', 'Department', 'DepartmentsTree', 'User'],
	endpoints: build => ({
		postCategoryCreate: build.mutation<PostCategoryCreateApiResponse, PostCategoryCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Category/create`,
				method: 'POST',
				body: queryArg.createCategoryRequest,
			}),
			invalidatesTags: ['Category'],
		}),
		getCategoryFind: build.query<GetCategoryFindApiResponse, GetCategoryFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Category/find`,
				params: {
					nameincludesubstring: queryArg.nameincludesubstring,
					isAscendingSort: queryArg.isAscendingSort,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Category'],
		}),
		postDepartmentCreate: build.mutation<PostDepartmentCreateApiResponse, PostDepartmentCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Department/create`,
				method: 'POST',
				body: queryArg.createDepartmentRequest,
			}),
			invalidatesTags: ['Department'],
		}),
		getDepartmentGet: build.query<GetDepartmentGetApiResponse, GetDepartmentGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Department/get`,
				params: {
					departmentid: queryArg.departmentid,
					includeusers: queryArg.includeusers,
					includecategory: queryArg.includecategory,
				},
			}),
			providesTags: ['Department'],
		}),
		getDepartmentFind: build.query<GetDepartmentFindApiResponse, GetDepartmentFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Department/find`,
				params: {
					isAscendingSort: queryArg.isAscendingSort,
					isAscendingSortByShortName: queryArg.isAscendingSortByShortName,
					isActive: queryArg.isActive,
					nameIncludeSubstring: queryArg.nameIncludeSubstring,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['Department'],
		}),
		patchDepartmentEdit: build.mutation<PatchDepartmentEditApiResponse, PatchDepartmentEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Department/edit`,
				method: 'PATCH',
				body: queryArg.body,
				params: { departmentId: queryArg.departmentId },
			}),
			invalidatesTags: ['Department'],
		}),
		getDepartmentsTreeGet: build.query<GetDepartmentsTreeGetApiResponse, GetDepartmentsTreeGetApiArg>({
			query: () => ({
				url: `${reducerPath}/DepartmentsTree/get`,
			}),
			providesTags: ['DepartmentsTree'],
		}),
		postUserCreate: build.mutation<PostUserCreateApiResponse, PostUserCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/create`,
				method: 'POST',
				body: queryArg.createDepartmentUsersRequest,
			}),
			invalidatesTags: ['User'],
		}),
		deleteUserRemove: build.mutation<DeleteUserRemoveApiResponse, DeleteUserRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/remove`,
				method: 'DELETE',
				body: queryArg.body,
				params: { departmentId: queryArg.departmentId },
			}),
			invalidatesTags: ['User'],
		}),
		getUserFind: build.query<GetUserFindApiResponse, GetUserFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/find`,
				params: {
					isActive: queryArg.isActive,
					isAscendingSort: queryArg.isAscendingSort,
					fullnameincludesubstring: queryArg.fullnameincludesubstring,
					departmentUserRoleAscendingSort: queryArg.departmentUserRoleAscendingSort,
					includePositions: queryArg.includePositions,
					byPositionId: queryArg.byPositionId,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
					departmentId: queryArg.departmentId,
				},
			}),
			providesTags: ['User'],
		}),
		putUserEditRole: build.mutation<PutUserEditRoleApiResponse, PutUserEditRoleApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/editRole`,
				method: 'PUT',
				body: queryArg.editDepartmentUsersRoleRequest,
				params: { departmentId: queryArg.departmentId },
			}),
			invalidatesTags: ['User'],
		}),
		putUserEditAssignment: build.mutation<PutUserEditAssignmentApiResponse, PutUserEditAssignmentApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/editAssignment`,
				method: 'PUT',
				body: queryArg.editDepartmentUserAssignmentRequest,
				params: { departmentId: queryArg.departmentId },
			}),
			invalidatesTags: ['User'],
		}),
	}),
});

export const {
	usePostCategoryCreateMutation,
	useGetCategoryFindQuery,
	usePostDepartmentCreateMutation,
	useGetDepartmentGetQuery,
	useLazyGetDepartmentGetQuery,
	useGetDepartmentFindQuery,
	usePatchDepartmentEditMutation,
	useGetDepartmentsTreeGetQuery,
	usePostUserCreateMutation,
	useDeleteUserRemoveMutation,
	useGetUserFindQuery,
	usePutUserEditRoleMutation,
	usePutUserEditAssignmentMutation,
} = department_serviceAPI;
