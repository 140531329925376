import { createColumnHelper } from '@tanstack/react-table';
import { WorkTimeTableDataItem } from '../_types';
import { useNavigate } from 'react-router-dom';
import { EMPTY_ID } from '@/shared/consts/consts';
import s from '../TimeLog.module.scss';

const columnHelper = createColumnHelper<WorkTimeTableDataItem>();

export const COLUMNS_WORK_TIME = [
	columnHelper.accessor('name', {
		header: 'НАЗВАНИЕ ПРОЕКТА',
		cell: ({ getValue, row }) => {
			const navigate = useNavigate();
			const isProject = EMPTY_ID !== row.original.id;

			return (
				<div
					className={isProject ? s.link_wrapper : s.project_title}
					onClick={() => isProject && navigate(`/projects/${row.original.id}`)}
				>
					{getValue()}
				</div>
			);
		},
		meta: {
			style: {
				width: '30%',
			},
		},
	}),
	columnHelper.accessor('userHours', {
		header: 'ВНЕСЕННЫЕ ЧАСЫ',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '15%',
			},
		},
	}),
	columnHelper.accessor('date', {
		header: 'ДАТА ВНЕСЕНИЯ',
		cell: info => info.getValue() ?? '-',
		meta: {
			style: {
				width: '15%',
			},
		},
	}),
	columnHelper.accessor('description', {
		header: 'КОММЕНТАРИЙ',
		cell: info => {
			const value = info.getValue();
			return value && value.toString().length > 0 ? info.getValue() : '-';
		},
		meta: {
			style: {
				width: '30%',
			},
		},
	}),
	columnHelper.accessor('extra', {
		header: '',
		cell: info => info.getValue(),
		meta: {
			style: {
				width: '1%',
			},
		},
	}),
];
