import React, { useState } from 'react';
import s from './AdminstrationCard.module.scss';
import { DropDownMenu } from '@/shared/ui/DropDownMenu/DropDownMenu';
import EditSVG from '@/shared/assets/svg_icons/action/edit.svg?react';
import DeleteSVG from '@/shared/assets/svg_icons/action/delete.svg?react';
import DragDropSVG from '@/shared/assets/svg_icons/action/drag.svg?react';
import { DepartmentUserInfo } from '@/app/redux/state/company-structure/departments/types';
import { Modal } from '@/shared/ui/_modals/Modal/ui/Modal/Modal';
import { CreateNewAdministrator } from '../CreateNewAdministrator/CreateNewAdministrator';
import { RemoveModalContent } from '../../../../components/RemoveModalContent/RemoveModalContent';
import { createGalleyFileFromImageInfo } from '@/shared/ui/_galleries/Gallery/_utils';
import { image_serviceAPI } from '@/app/redux/queries/image-serviceAPI/image_serviceAPI';
import { company_structure_serviceAPI } from '@/app/redux/queries/company-structure-service/company_structure_serviceAPI';

interface Props {
	admin: DepartmentUserInfo;
	edit?: boolean;
}

export const AdminstrationCard: React.FC<Props> = props => {
	const { admin, edit } = props;
	const { avatarId } = admin;

	// * Queries
	const { currentData: avatarData } = company_structure_serviceAPI.useGetAvatarQuery({ params: { userId: admin.id as string } }, { skip: !avatarId });
	const { data: imageData } = image_serviceAPI.useGetImageQuery(
		{
			params: {
				imageId: avatarData?.parentId as string,
				source: 'CompanyStructure',
			},
		},
		{ skip: !avatarData },
	);

	const image = imageData?.body
		? createGalleyFileFromImageInfo({
				...imageData?.body,
				name: imageData?.body.name || 'avatar.jpg',
			}).preview
		: '';

	const { fullName, description, position } = admin;

	// * Menu
	const DropDownItems = [
		{
			icon: <EditSVG />,
			title: 'Редактировать',
			action: () => toggleEditModal(),
		},
		{
			icon: <DeleteSVG />,
			title: 'Удалить',
			action: () => toggleRemoveModal(),
		},
	];

	// * Edit modal
	const [showEditModal, setShowEditModal] = useState(false);
	const toggleEditModal = () => setShowEditModal(prevState => !prevState);

	// * Remove modal
	const [showRemoveModal, setShowRemoveModal] = useState(false);
	const toggleRemoveModal = () => setShowRemoveModal(prevState => !prevState);

	return (
		<div className={s.container}>
			<div className={s.first_wrapper}>
				{edit && <DragDropSVG className={s.icon} />}

				<div className={s.second_wrapper}>
					<div className={s.personal_photo}>
						<img
							src={image}
							alt="avatar"
						/>
					</div>

					<div className={s.about}>
						<h3 className={s.fullname}>{fullName}</h3>
						<div className={s.position}>{position || '-'}</div>
						<div className={s.description}>{description}</div>
					</div>
				</div>
			</div>
			{edit && (
				<div className={s.menu}>
					<DropDownMenu items={DropDownItems} />
				</div>
			)}

			<Modal
				show={showEditModal}
				toggleModal={toggleEditModal}
				width={800}
				Content={
					<CreateNewAdministrator
						userId={admin.id}
						values={{
							avatar: imageData?.body,
							fullname: admin.fullName || '',
							position: admin.position || '',
							description: admin.description || '',
						}}
						toggleModal={toggleEditModal}
					/>
				}
			/>

			<Modal
				show={showRemoveModal}
				width={476}
				top={200}
				toggleModal={toggleRemoveModal}
				overflowX="visible"
				Content={
					<RemoveModalContent
						id={admin.id}
						name={admin.fullName || ''}
						toggleRemoveModal={toggleRemoveModal}
					/>
				}
			/>
		</div>
	);
};
