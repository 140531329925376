import { AsyncThunkAction } from '@reduxjs/toolkit';
import { saveAs as saveFile } from 'file-saver';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ImageInfo } from '@/app/redux/state/image/image/types';
import { useAppDispatch } from '@/app/redux/utils';
import CloseSVG from '@/shared/assets/svg/action/close.svg?react';
import DownloadSVG from '@/shared/assets/svg/files/download.svg?react';
import PrevSVG from '@/shared/assets/svg/navigation/Arrow_left_v1.svg?react';
import NextSVG from '@/shared/assets/svg/navigation/Arrow_right_v1.svg?react';
import { isVideoContent } from '@/shared/lib/file';
import { IconSquareButton } from '../../_buttons/IconSquareButton';
import { GalleyFile } from '../../_galleries/Gallery/_types';
import { createGalleyFileFromImageInfo } from '../../_galleries/Gallery/_utils';
import { Backdrop } from '../Modal/ui/Backdrop/Backdrop';
import s from './MediaModal.module.scss';

interface ModalOverlayProps {
	files: GalleyFile[];
	imageToShow: number;
	setImageToShow: (imageIndex: number | null) => void;
	fetchImage?: (imageId: string) => AsyncThunkAction<any, any, any>;
	zIndex: 1 | 2 | 3 | 4 | 5;
}

const ModalOverlay: React.FC<ModalOverlayProps> = props => {
	const {
		files, //
		imageToShow,
		setImageToShow,
		fetchImage,
		zIndex,
		...restProps
	} = props;

	const downloadFile = (activeImage: GalleyFile) => {
		const downloadedFile = activeImage;
		saveFile(downloadedFile, `${downloadedFile.name}`);
	};

	// * Actions
	const dispatch = useAppDispatch();

	const [activeImage, setActiveImage] = useState<GalleyFile | null>(null);

	console.log('files :>> ', files);

	useEffect(() => {
		const imageFile = files[imageToShow];
		const parentId = imageFile.parentId;

		if (fetchImage && parentId) {
			dispatch(fetchImage(parentId))
				.unwrap()
				.then((res: { body: ImageInfo }) => {
					setActiveImage(createGalleyFileFromImageInfo(res.body));
				})
				.catch(error => console.log(error));
		} else {
			setActiveImage(imageFile);
		}
	}, [imageToShow]);

	const onImageClick = (img: string) => {
		activeImage !== null && window.open(img, '_blank');
	};

	const renderMedia = (galleryFile: GalleyFile) => (
		<>
			{isVideoContent(galleryFile.type) ? (
				<video
					className={s.modal_image}
					controls
				>
					<source src={galleryFile.preview} />
				</video>
			) : (
				<img
					className={s.modal_image}
					src={galleryFile.preview}
					alt={galleryFile.name}
					onClick={() => onImageClick(galleryFile.preview)}
				/>
			)}
		</>
	);

	const component = (
		<div
			className={s.container}
			style={{ zIndex: `calc(${zIndex} * var(--z-index-modal))` }}
			{...restProps}
		>
			{fetchImage ? ( //
				<>{activeImage !== null && renderMedia(activeImage)}</>
			) : (
				<>{imageToShow !== null && renderMedia(files[imageToShow])}</>
			)}

			<IconSquareButton
				size="large"
				Icon={<CloseSVG />}
				onClick={() => setImageToShow(null)}
				className={s.close}
			/>

			{imageToShow !== files.length - 1 && (
				<IconSquareButton
					size="large"
					Icon={<NextSVG />}
					onClick={() => setImageToShow(imageToShow + 1)}
					className={s.next}
				/>
			)}

			{imageToShow !== 0 && (
				<IconSquareButton
					size="large"
					Icon={<PrevSVG />}
					onClick={() => setImageToShow(imageToShow - 1)}
					className={s.previous}
				/>
			)}

			{activeImage !== null && (
				<IconSquareButton
					size="large"
					Icon={<DownloadSVG />}
					onClick={() => downloadFile(activeImage)}
					className={s.download}
				/>
			)}
		</div>
	);

	const container = document.getElementById('modal-portal') as HTMLElement;
	return ReactDOM.createPortal(component, container);
};

interface ModalProps extends HTMLAttributes<HTMLDivElement> {
	// show: boolean;
	toggleModal?: () => void;
	files: GalleyFile[];
	imageToShow: number;
	setImageToShow: React.Dispatch<React.SetStateAction<number | null>>;
	fetchImage?: (imageId: string) => AsyncThunkAction<any, any, any>;
	closeOnBackdrop?: boolean;
	zIndex?: 1 | 2 | 3 | 4 | 5;
}

export const MediaModal: React.FC<ModalProps> = props => {
	const {
		toggleModal, //
		files,
		imageToShow,
		setImageToShow,
		fetchImage,
		closeOnBackdrop = true,
		zIndex = 1,
		...restProps
	} = props;

	// * Render
	return (
		<>
			<Backdrop
				isActive={imageToShow !== null}
				onClick={closeOnBackdrop && toggleModal}
				zIndex={zIndex}
			/>

			<ModalOverlay
				{...restProps}
				files={files}
				imageToShow={imageToShow}
				setImageToShow={setImageToShow}
				fetchImage={fetchImage}
				zIndex={zIndex}
			/>
		</>
	);
};
