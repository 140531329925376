import { base64InfoToString, base64ToBlob, contentTypeToExtension, extensionToContentType } from '@/shared/lib/file';
import { getBase64Info } from '@/shared/lib/file/getBase64Info/getBase64Info';
import { ImageInfo } from '@/app/redux/state/image/image/types';
import { GalleyFile } from './_types';

// TODO: Почти дубликат imageContentToGalleyFile
// TODO: Используется вне Gallery
export const createGalleyFileFromImageInfo = (image: ImageInfo): GalleyFile => {
	const blob = base64ToBlob(base64InfoToString(extensionToContentType(image.extension), image.content));
	const contentType = extensionToContentType(image.extension);
	const file = new File([blob], image.name, { type: contentType });

	return Object.assign(file, {
		id: image.id,
		parentId: image.parentId,
		preview: URL.createObjectURL(blob),
	});
};

export const createImageInfoFromFile = (values: any): ImageInfo[] => {
	return values.map((value: any) => {
		const base64Info = getBase64Info(value.base64);

		const image = {
			id: value.id,
			name: value.name,
			content: base64Info.content,
			extension: contentTypeToExtension(base64Info.contentType),
		};

		if (value.isSchemaImage) {
			Object.assign(image, {
				isSchemaImage: value.isSchemaImage,
			});
		}

		return image;
	});
};
