import { position_serviceAPI } from '@/app/redux/queries/position-service/position_serviceAPI';

export const useEditPosition = (positionId: string, defaultName: string, onCloseModal: () => void) => {
	const [editPosition, { isLoading: isEditLoading }] = position_serviceAPI.useEditPositionMutation();

	const onEditSubmit = (data: { name: string }) => {
		const { name } = data;

		if (name === defaultName) {
			return onCloseModal();
		}

		editPosition({
			positionId,
			editPositionRequest: [
				{
					op: 'replace',
					path: '/name',
					value: name,
				},
			],
		})
			.unwrap()
			.then(() => onCloseModal())
			.catch(err => console.log('Error: ', err));
	};

	return { onEditSubmit, isEditLoading };
};
