import React from 'react';
import s from './OfficeFormMC.module.scss';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { TextArea } from '@/shared/ui/_inputs/text_Inputs/TextArea';
import { Button } from '@/shared/ui/_buttons/Button';
import { useAppDispatch, useAppSelector } from '@/app/redux/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { EditOfficeREQ_BODY } from '@/app/redux/state/office/office/types';
import { Office } from '@/app/redux/state/office/types';
import { officeServiceActions } from '@/app/redux/state/office/actions';
import { RequestStatus } from '@/app/api/api_types';
import { TimeRangeField } from '@/shared/ui/_fields/TimeRangeField';
import { StringField } from '@/shared/ui/_fields/StringField';
import { NumberField } from '@/shared/ui/_fields/NumberField';

interface Props {
	defaultValues?: Office;
	toggleModal: () => void;
}

export const OfficeFormMC: React.FC<Props> = props => {
	const { defaultValues, toggleModal } = props;

	// * Selectors
	const companyId = useAppSelector(state => state.company_service.company.company?.id);
	const status = useAppSelector(state => state.company_service.company.status);

	// * Actions
	const dispatch = useAppDispatch();
	const { createOffice, editOffice, getOffice } = officeServiceActions.office.async;

	// * Conditions
	const isEditForm = !!defaultValues; // There are default values only when editing office.

	// * Form
	const TIME_ERROR = 'Введите время работы офиса';

	const schema = yup.object().shape({
		name: yup.string().required('Введите название офиса').nullable(),
		city: yup.string().required('Введите город').nullable(),
		address: yup.string().required('Введите адрес').nullable(),
		startTime: yup.string().required(TIME_ERROR).nullable(),
		endTime: yup.string().required(TIME_ERROR).nullable(),
	});

	const formMethods = useForm<Office>({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, control } = formMethods;

	// * User actions
	const onSubmit = (values: Office) => {
		if (isEditForm) {
			const payload: EditOfficeREQ_BODY[] = [];
			Object.entries(values).forEach(([key, value]) => {
				if (key !== 'id' && key !== 'companyId' && key !== 'images') {
					// @ts-ignore
					if (value !== defaultValues[key]) {
						payload.push({
							op: 'replace',
							// @ts-ignore
							path: `/${key}`,
							value,
						});
					}
				}
			});

			companyId &&
				dispatch(
					editOffice({
						params: {
							officeId: defaultValues?.id,
						},
						payload,
					}),
				)
					.unwrap()
					.then(() => {
						dispatch(
							getOffice({
								params: { officeId: defaultValues?.id },
							}),
						);
						toggleModal();
					})
					.catch(error => console.log(error));
		} else {
			companyId &&
				dispatch(
					createOffice({
						...values,
						companyId,
					}),
				)
					.unwrap()
					.then(() => toggleModal())
					.catch(error => console.log(error));
		}
	};

	// * Render
	return (
		<div>
			<h2 className={s.title}>{isEditForm ? 'Редактирование' : 'Создание'} офиса</h2>

			<form
				className={s.form}
				onSubmit={handleSubmit(onSubmit)}
			>
				<FormProvider {...formMethods}>
					<div className={s.row}>
						<StringField
							name="name"
							label="Название офиса"
							placeholder="Введите название офиса"
						/>

						<TimeRangeField
							firstName="startTime"
							secondName="endTime"
							labels={['Часы работы']}
						/>
					</div>

					<StringField
						name="city"
						label="Город"
						placeholder="Введите город"
					/>

					<StringField
						name="address"
						label="Адрес"
						placeholder="Введите адрес"
					/>

					<div className={s.row}>
						<NumberField
							name="longitude"
							label="Долгота"
							placeholder="Введите долготу координат офиса"
							step="0.000001"
						/>

						<NumberField
							name="latitude"
							label="Широта"
							placeholder="Введите широту координат офиса"
							step="0.000001"
						/>
					</div>

					<Controller
						name="description"
						control={control}
						render={({ field, fieldState }) => (
							<TextArea
								{...field}
								value={field.value ? field.value : undefined}
								label="Описание"
								placeholder="Введите описание офиса"
								errorMessage={fieldState.error?.message}
								characterLimit={300}
							/>
						)}
					/>

					<div className={s.buttons}>
						<Button
							variant="tertiary"
							fixedWidth
							onClick={() => toggleModal()}
							isLoading={status === RequestStatus.loading}
						>
							Отмена
						</Button>

						<Button
							fixedWidth
							isLoading={status === RequestStatus.loading}
						>
							{isEditForm ? 'Редактировать' : 'Создать'}
						</Button>
					</div>
				</FormProvider>
			</form>
		</div>
	);
};
