import React from 'react';
import { ImageContent } from '@/app/redux/queries/survey-service/types';
import s from './Image.module.scss';
import { base64InfoToString, base64ToBlob, isValidBase64DataString, isVideoExtension } from '@/shared/lib/file';

interface Props extends ImageContent {
	onClick: () => void;
}

// TODO Переименовать
export const Image: React.FC<Props> = props => {
	const {
		content, //
		extension,
		name,
		onClick,
	} = props;

	const isVideo = isVideoExtension(extension);

	const preview = URL.createObjectURL(base64ToBlob(isValidBase64DataString(content) ? content : base64InfoToString(extension, content)));

	// * Render
	return (
		<>
			{isVideo ? (
				<video
					className={s.container}
					onClick={onClick}
				>
					<source src={preview} />
				</video>
			) : (
				<img
					className={s.container}
					onClick={onClick}
					src={preview}
					alt={name ?? ''}
				/>
			)}
		</>
	);
};
