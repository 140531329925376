import React, { useEffect } from 'react';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { First } from './form_steps/First/First';
import { useParams } from 'react-router-dom';
import { Second } from './form_steps/Second/Second';
import { officeServiceActions } from '@/app/redux/state/office/actions';
import { useAppDispatch } from '@/app/redux/utils';

export const RoomFormPage: React.FC = () => {
	const params = useParams<{ officeId: string; roomId: string; formStep: string }>();
	const officeId: string = params.officeId as string;
	const roomId: string = params.roomId as string;
	const formStep: string = params.formStep as string;

	// * Action
	const dispatch = useAppDispatch();
	const { getOffice } = officeServiceActions.office.async;
	const { getRoom } = officeServiceActions.room.async;
	const { findRoomTypes } = officeServiceActions.roomType.async;

	// * Initialize
	useEffect(() => {
		!isNewRoom &&
			dispatch(
				getRoom({
					params: { roomId },
				}),
			);
	}, [roomId]);

	useEffect(() => {
		dispatch(
			getOffice({
				params: { officeId },
			}),
		);

		dispatch(
			findRoomTypes({
				params: {
					skipCount: 0,
					takeCount: 1000,
					includeDeactivated: false,
					officeId: officeId,
				},
			}),
		);
	}, [officeId]);

	// * Conditions
	const isNewRoom = roomId === 'null';

	const pageProps = {
		officeId,
		roomId,
		isNewRoom,
	};

	const renderFormStep: any = {
		1: <First {...pageProps} />,
		2: <Second {...pageProps} />,
	};

	// * Render
	return <PageDecorator>{renderFormStep[formStep.toString()]}</PageDecorator>;
};
