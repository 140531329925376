import React from 'react';
import s from './QuestionImage.module.scss';

import { useGetImage } from '@/pages/_surveys/libs/useGetImage/useGetImage';
import { ImageContent } from '@/app/redux/queries/survey-service/types';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';

interface Props {
	image?: ImageContent | null;
	id?: string | null;
}

export const QuestionImage: React.FC<Props> = props => {
	const { id, image } = props;

	const { imageInfo, isLoading } = useGetImage(id, image);

	// * Render
	return (
		<>
			{isLoading && (
				<div className={s.container}>
					<LoaderCircle />
				</div>
			)}

			{imageInfo && (
				<div className={s.container}>
					<img
						src={imageInfo.preview}
						alt={imageInfo.name}
					/>
				</div>
			)}
		</>
	);
};
