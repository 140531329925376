import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { routerPath } from '@/app/router/paths';
import { LineTabs, SingleTab } from '@/shared/ui/_tabs/lineTabs/LineTabs';
import { NewEmployee } from './NewEmployee/NewEmployee';
import { Offer } from './Offer/Offer';
import s from './OfferMainPage.module.scss';

interface navigationDepsType {
	[key: string]: string;
}

export const OfferMainPage: React.FC = () => {
	const tabs: SingleTab[] = [
		{
			id: 'new-employee',
			name: 'Новый сотрудник',
		},
		{
			id: 'offer',
			name: 'Оффер',
		},
	];

	const navigationDeps: navigationDepsType = {
		offer: routerPath.hr.page + routerPath.hr.offer,
		'new-employee': routerPath.hr.page,
	};

	const [activeTabId, setActiveTabId] = useState<string>('new-employee');

	const navigate = useNavigate();

	useEffect(() => {
		Object.keys(navigationDeps).map(key => {
			if (activeTabId === key) {
				navigate(navigationDeps[key]);
			}
		});
	}, [activeTabId]);

	return (
		<PageDecorator>
			<div className={s.container}>
				<div className={s.title}>
					<h1>HR</h1>
				</div>

				<LineTabs
					tabs={tabs}
					tabWidth={20}
					activeTabId={activeTabId}
					setActiveTabId={setActiveTabId}
				/>

				<div className={s.container_body}>
					{activeTabId === 'new-employee' && <NewEmployee />}

					{activeTabId === 'offer' && <Offer />}
				</div>
			</div>
		</PageDecorator>
	);
};
