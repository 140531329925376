import s from '.././RoutesAndCategories/RoutesAndCategories.module.scss';
import { CategoryCatalog } from '../СategoryCatalog/CategoryCatalog';
import { RouteTemplate } from '../RouteTemplate/RouteTemplate';
import { AddButton } from '@/shared/ui/_buttons/AddButton';
import { useState } from 'react';

export const RoutesAndCategories = () => {
	// * Render
	return (
		<div className={s.wrapper}>
			<CategoryCatalog />
			<div>
				<RouteTemplate />
				<div>Списки заявок</div>
			</div>
		</div>
	);
};
