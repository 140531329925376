import React, { FormEvent } from 'react';
import { SelectSingle } from '@/shared/ui/_inputs/selects/SelectSingle';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import s from './LinearScale.module.scss';
import { QuestionItem, ScaleOption, SectionProps } from '../../../_types';
import { InputLabel } from '@/shared/ui/_inputs/_shared/InputLabel/InputLabel';
import { v4 } from 'uuid';

interface Props {
	indexQuestion: number;
	question: QuestionItem;

	sections: SectionProps[];
	setSections: (sections: SectionProps[]) => void;
	indexSection: number;

	isEdit?: boolean;
}

export const LinearScale: React.FC<Props> = props => {
	const {
		indexQuestion,
		question,

		sections,
		setSections,
		indexSection,
		isEdit,
	} = props;

	const { scaleOptions: optionsScale } = question;

	const { leftSignature, rightSignature } = optionsScale[0];

	const scaleStart = +optionsScale[0].content;
	const scaleEnd = +optionsScale[optionsScale.length - 1].content;

	const onScaleOptionChange = (event: FormEvent<HTMLInputElement>, indexSection: number, indexQuestion: number, isLeftSignature?: boolean) => {
		const value = event.currentTarget.value;

		const changedSection = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					return {
						...question,
						scaleOptions: question.scaleOptions.map(option => {
							const { leftSignature, rightSignature } = option;

							return {
								...option,
								rightSignature: isLeftSignature ? rightSignature : value,
								leftSignature: isLeftSignature ? value : leftSignature,
							};
						}),
					};
				}),
			};
		});

		setSections([...changedSection]);
	};

	const onScaleChange = (indexSection: number, indexQuestion: number, scale: string, isStart?: boolean) => {
		const changedSection = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					const newOptions = [];
					const countStart = isStart ? +scale : scaleStart;
					const countEnd = isStart ? scaleEnd : +scale;
					for (let i = countStart; i <= countEnd; i++) {
						newOptions.push({
							id: v4(),
							content: `${i}`,
							leftSignature: question.scaleOptions[0].leftSignature,
							rightSignature: question.scaleOptions[0].rightSignature,
						});
					}

					return {
						...question,
						scaleOptions: [...newOptions],
					};
				}),
			};
		});

		setSections([...changedSection]);
	};

	const N = 11;
	const scaleNumbers = Array.from(Array(N), (_, index) => index);
	const scaleOptions = scaleNumbers.map(scale => ({
		id: `${scale}`,
		name: `${scale}`,
	}));

	// * Render
	return (
		<div className={s.container}>
			<div>
				<InputLabel label="От" />
				<div className={s.fields}>
					<SelectSingle
						keyNames={{
							name: 'name',
							value: 'id',
						}}
						selectedOption={scaleOptions[scaleStart]}
						setSelectedOption={option => onScaleChange(indexSection, indexQuestion, option.name, true)}
						options={scaleOptions.filter(scale => +scale.id < 2)}
						disabled={isEdit}
					/>
					<TextInput
						placeholder="Подпись"
						value={leftSignature}
						maxLength={50}
						onChange={e => onScaleOptionChange(e, indexSection, indexQuestion, true)}
					/>
				</div>
			</div>

			<div>
				<InputLabel label="До" />
				<div className={s.fields}>
					<SelectSingle
						keyNames={{
							name: 'name',
							value: 'id',
						}}
						selectedOption={scaleOptions[scaleEnd]}
						setSelectedOption={option => onScaleChange(indexSection, indexQuestion, option.name)}
						options={scaleOptions.filter(scale => +scale.id > 1).reverse()}
						disabled={isEdit}
					/>
					<TextInput
						placeholder="Подпись"
						value={rightSignature}
						maxLength={50}
						onChange={e => onScaleOptionChange(e, indexSection, indexQuestion)}
					/>
				</div>
			</div>
		</div>
	);
};
