import { User } from '../../types';
import { CELL_GAP, CELL_SIZE } from '../../ui/VacationTable/consts';

export const getTableHeight = (users: { mainUser: User[] | undefined; otherUsers: User[] }) => {
	const otherUsersLength = users.otherUsers.length;
	const usersCount = users.mainUser ? otherUsersLength + 1 : otherUsersLength;
	const tableHeight = usersCount * CELL_SIZE + (usersCount - 1) * CELL_GAP + (users.mainUser ? 0.7 : 0) + 'rem';

	return tableHeight;
};
