import { FC, ReactNode } from 'react';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';

interface Props {
	initialLoading: boolean;
	isSuccessful: boolean;
	LoadedResult: ReactNode;
	FailedResult?: ReactNode;
}

export const ConditionalRendering: FC<Props> = props => {
	const { initialLoading, isSuccessful, LoadedResult, FailedResult } = props;

	const conditionalRendering = () => {
		if (initialLoading) {
			return <LoaderCircle />;
		} else if (!isSuccessful) {
			return FailedResult ? <>{FailedResult}</> : null;
		} else {
			return <>{LoadedResult}</>;
		}
	};

	return <>{conditionalRendering()}</>;
};
