import { HTMLAttributes, PropsWithChildren, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { education_serviceAPI } from '@/app/redux/queries/education-service/education_serviceAPI';
import { useDebounceEffect } from '@/pages/_projectService/hooks/useDebounceEffect';
import { useFloatingWrapper } from '@/shared/hooks/useFloatingWrapper';
import { ConditionalRendering } from '@/shared/providers';
import { CollapseArrows } from '@/shared/ui/CollapseArrows/CollapseArrows';
import { ErrorWithLimit } from '@/shared/ui/_inputs/_shared/ErrorWithLimit/ErrorWithLimit';
import _input_with_options from '@/shared/ui/_inputs/_styles/_input_with_options.module.scss';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { OptionsWindow } from '@/shared/ui/_option_lists/OptionsWindow/OptionsWindow';

interface Props<T, Key> extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
	name: string;
}

export const UniversityInput = <T extends { [key: string]: any }, Key extends keyof T>(props: PropsWithChildren<Props<T, Key>>) => {
	const {
		name, //
	} = props;

	// * Form
	const { control, watch, setValue } = useFormContext();
	const selectedOption = watch(name);

	// * API
	const [findUniversity, { currentData: universityData, isLoading }] = education_serviceAPI.useLazyGetUniversityFindQuery();

	const options = universityData?.body || [{ id: '', name: '' }];

	// * Initial
	useEffect(() => {
		findUniversity({
			skipcount: 0,
			takecount: 15,
			nameIncludeSubstring: searchSubstring,
		});
	}, []);

	// * User actions
	const [collapsed, setCollapsed] = useState(true);

	const onOptionClick = (value: T) => {
		setValue(name, value);
		setSearchSubstring(value.name);
		setCollapsed(true);
	};

	// * Filter substring
	const [searchSubstring, setSearchSubstring] = useState<string>(selectedOption ? selectedOption.name : '');

	const handleStringChange = (value: string) => {
		const university = options.find(item => item.name === value);
		setValue(name, university ? university : { name: value, id: '' });

		collapsed && setCollapsed(false);

		setSearchSubstring(value);
	};

	useDebounceEffect(
		() => {
			findUniversity({
				skipcount: 0,
				takecount: 15,
				nameIncludeSubstring: searchSubstring,
			});
		},
		500,
		[searchSubstring],
	);

	// * Floating
	const { floatingStyles, refs, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(!collapsed, (value: any) => {
		setCollapsed(!value);
		setSearchSubstring(selectedOption?.id ? selectedOption.name : searchSubstring);
	});

	// * Render
	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState }) => (
				<div className={_input_with_options.container}>
					<div
						ref={refs.setReference}
						{...getReferenceProps()}
					>
						<TextInput
							{...field}
							label="Учебное заведение"
							placeholder="Выберите из списка"
							value={searchSubstring}
							required
							onClick={() => collapsed && setCollapsed(false)}
							onChange={event => handleStringChange(event.currentTarget.value)}
							RightIcon={
								<CollapseArrows
									isOpen={!collapsed}
									arrowType="empty"
								/>
							}
							onRightIconClick={() => setCollapsed(!collapsed)}
						/>
					</div>

					<div
						className={_input_with_options.options}
						ref={refs.setFloating}
						style={{
							...floatingStyles,
							zIndex: 'var(--z-index-floating)',
						}}
						aria-labelledby={headingId}
						{...getFloatingProps()}
					>
						{!collapsed && (
							<ConditionalRendering
								initialLoading={isLoading}
								isSuccessful={!!universityData?.body}
								LoadedResult={
									<OptionsWindow
										keyNames={{
											name: 'name',
											value: 'id',
										}}
										options={options as Array<any>} // ! [NST]
										selectedOptions={[selectedOption]}
										onOptionClick={onOptionClick}
									/>
								}
							/>
						)}
					</div>

					{fieldState.error?.message && <ErrorWithLimit errorMessage={fieldState.error?.message} />}
				</div>
			)}
		/>
	);
};
