import React from 'react';
import { company_structure_serviceAPI } from '@/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { image_serviceAPI } from '@/app/redux/queries/image-serviceAPI/image_serviceAPI';
import { Avatar } from '@/shared/ui/Avatar/Avatar';

interface Props {
	name: string;
	userId: string;
	isCustom: boolean;
	avatarId?: string;
}

// TODO: Использовать также в CompanyUserCard
export const AvatarCustom: React.FC<Props> = props => {
	const { name, userId, avatarId, isCustom } = props;

	// * Queries
	const { currentData: avatarData } = company_structure_serviceAPI.useGetAvatarQuery({ params: { userId } }, { skip: !isCustom });

	const { currentData: avatarInfo } = image_serviceAPI.useGetImageQuery(
		{
			params: {
				imageId: avatarId as string,
				source: 'User',
			},
		},
		{ skip: !avatarId || isCustom },
	);

	const userAvatar = isCustom ? avatarData : avatarInfo?.body;

	// * Render
	return (
		<Avatar
			userAvatar={userAvatar}
			name={name}
		/>
	);
};
