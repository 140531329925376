import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { wiki_serviceAPI } from '@/app/redux/queries/wiki-service/wiki_serviceAPI';

interface Props {
	id: string;
}

export const WikiFavoriteItem: FC<Props> = props => {
	const { id } = props;

	// * Routing
	const navigate = useNavigate();

	// * API
	const { data: article } = wiki_serviceAPI.useGetArticleQuery({ articleId: id });

	// * Render
	return <p onClick={() => navigate(`/wiki/${id}`)}>{article?.name || ''}</p>;
};
