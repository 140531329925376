import cn from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import LeftArrowSVG from '@/shared/assets/svg_icons/arrows/left_1.svg?react';
import RightArrowSVG from '@/shared/assets/svg_icons/arrows/right_1.svg?react';
import { LIST_OF_MONTHS_NAMES } from '@/shared/consts/consts';
import { useWindowDimensions } from '@/shared/hooks/useWindowDimensions';
import { CELL_GAP, CELL_SIZE, MONTH_GAP } from '../../consts';
import { SelectedYear } from '../../types';
import s from './MonthSelector.module.scss';

const isOverflown = (element: HTMLDivElement): boolean => {
	return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};

const calculateScrollValue = (monthIndex: number, selectedYear: SelectedYear, rootFontSize: number) => {
	let moveValue = 0;

	for (let i = 0; i < monthIndex; i++) {
		const daysInMonth = selectedYear.months[i].holidays.length;

		moveValue += (daysInMonth * CELL_SIZE + (daysInMonth - 1) * CELL_GAP + MONTH_GAP) * rootFontSize;
	}

	return moveValue;
};

interface Props {
	width: string;
	selectedYear: SelectedYear;
	tableRef: React.RefObject<HTMLDivElement>;
}

export const MonthSelector: FC<Props> = props => {
	const {
		width, //
		selectedYear,
		tableRef,
	} = props;

	const ref = useRef<HTMLDivElement>(null);

	const { width: screenWidth } = useWindowDimensions();
	const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

	const [overflown, setOverflown] = useState<boolean>(false);
	const [scrollFinished, setScrollFinished] = useState<'left' | 'right' | null>(null);

	useEffect(() => {
		if (ref.current) {
			const currentOverflow = isOverflown(ref.current);
			if (currentOverflow !== overflown) {
				setOverflown(currentOverflow);
			}
		}
	}, [screenWidth]);

	// When initialized, set the scroll to the current month
	useEffect(() => {
		if (tableRef?.current) {
			const currentMonthIndex = new Date().getMonth(); // Get the current month index (0 - January, 11 - December)

			tableRef.current.scrollLeft = calculateScrollValue(currentMonthIndex, selectedYear, rootFontSize);
		}
	}, [tableRef, selectedYear]);

	const onArrowClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, toLeft?: boolean) => {
		event.stopPropagation();

		const containerElement = ref.current;

		if (!containerElement) return;

		// Determine how much to scroll
		const scrollDelta = 60;
		containerElement.scrollLeft += toLeft ? -scrollDelta : scrollDelta;

		// Check if scrolling is complete
		const isAtLeftEdge = containerElement.scrollLeft === 0;
		const isAtRightEdge = containerElement.offsetWidth + Math.ceil(containerElement.scrollLeft) >= containerElement.scrollWidth;

		// Update the state depending on the scroll position
		setScrollFinished(isAtLeftEdge ? 'left' : isAtRightEdge ? 'right' : null);
	};

	const onMonthClick = (monthIndex: number) => {
		if (tableRef?.current && selectedYear?.months) {
			tableRef.current.scrollLeft = monthIndex === 0 ? 0 : calculateScrollValue(monthIndex, selectedYear, rootFontSize);
		}
	};

	// * Render
	return (
		<div
			className={s.container}
			style={{ width: width }}
			ref={ref}
		>
			{overflown && (
				<LeftArrowSVG
					className={cn(s.arrow, s.arrow__left, scrollFinished === 'left' && s.arrow_disabled)}
					onClick={event => onArrowClick(event, true)}
				/>
			)}

			<div className={s.months}>
				{LIST_OF_MONTHS_NAMES.map((month, index) => (
					<span
						key={month}
						onClick={() => onMonthClick(index)}
					>
						{month}
					</span>
				))}
			</div>

			{overflown && (
				<RightArrowSVG
					className={cn(s.arrow, s.arrow__right, scrollFinished === 'right' && s.arrow_disabled)}
					onClick={event => onArrowClick(event)}
				/>
			)}
		</div>
	);
};
