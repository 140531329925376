import { autoUpdate, flip, FloatingPortal, offset, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole } from '@floating-ui/react';
import cn from 'classnames';
import React, { ComponentPropsWithoutRef, ReactNode, useState } from 'react';
import s from './Tooltip.module.scss';

interface Props extends Omit<ComponentPropsWithoutRef<'div'>, 'content'> {
	content: string | ReactNode;
	type?: 'basic' | 'custom';
}

export const Tooltip: React.FC<Props> = props => {
	const {
		content, //
		type = 'basic',
		children,
		...divProps
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	const { x, y, refs, strategy, context } = useFloating({
		open: isOpen,
		onOpenChange: setIsOpen,
		placement: 'top',
		// Make sure the tooltip stays on the screen
		whileElementsMounted: autoUpdate,
		middleware: [
			offset(5),
			flip({
				fallbackAxisSideDirection: 'start',
			}),
			shift(),
		],
	});

	// Event listeners to change the open state
	const hover = useHover(context, { move: false });
	const focus = useFocus(context);
	const dismiss = useDismiss(context);
	// Role props for screen readers
	const role = useRole(context, { role: 'tooltip' });

	// Merge all the interactions into prop getters
	const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

	return (
		<>
			<div
				{...divProps}
				className={cn(s[`container_${type}`])}
				ref={refs.setReference}
				{...getReferenceProps()}
			>
				{children}
			</div>

			<FloatingPortal>
				{isOpen && (
					<div
						className={cn(s[`tooltip_${type}`])}
						ref={refs.setFloating}
						style={{
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
							zIndex: 'var(--z-index-tooltip)',
						}}
						{...getFloatingProps()}
					>
						{content}
					</div>
				)}
			</FloatingPortal>
		</>
	);
};
