import { DateTime } from 'luxon';
import { time_serviceAPI } from '@/app/redux/queries/time-service/time_serviceAPI';
import { Button } from '@/shared/ui/_buttons/Button';
import { document } from '@/shared/lib/document';

interface Props {
	date: Date;
	projectId?: string;
	departmentId?: string;
}

export const TimeListDownload: React.FC<Props> = props => {
	const { date, projectId, departmentId } = props;

	const { download } = document;

	// * API
	const [getImport, { isLoading }] = time_serviceAPI.useLazyGetImportGetQuery();

	// * Upload
	const uploadFile = () => {
		const luxonDate = DateTime.fromJSDate(date);

		const data = getImport({
			departmentId,
			projectId,
			month: luxonDate.month,
			year: luxonDate.year,
		});

		data
			.unwrap()
			.then(async res => {
				if (!res.body) return;

				const filename = `Statistic_${luxonDate.year}_${luxonDate.month}.xlsx`;
				download(filename, res.body);
			})
			.catch(err => console.log('Error: ', err)); //TODO: показывать юзеру, что нет файла за этот период
	};

	// * Render
	return (
		<Button
			variant="primary"
			isLoading={isLoading}
			onClick={uploadFile}
		>
			Выгрузить часы
		</Button>
	);
};
