import { useCallback, useMemo } from 'react';
import { CategoryInfo, EventInfo, UserBirthdayInfo } from '@/app/redux/queries/event-service/types/types';
import { createFullName } from '@/shared/lib/string';
import { Heading } from '@/shared/ui/Heading';
import { DAY_OF_THE_WEEK, EMPTY_CATEGORY, LIST_OF_MONTHS_GENITIVE } from '../../../../../../consts';
import { EventInfoItem } from '../EventInfoItem';
import { UserInfo } from '../UserInfo';
import s from './DayEventsInfoList.module.scss';

interface Props {
	events: EventInfo[];
	birthdays: UserBirthdayInfo[];
	date: Date;
}

export const DayEventsInfoList: React.FC<Props> = props => {
	const {
		events, //
		birthdays,
		date,
	} = props;

	// Чтобы при переходе на другие месяца не пропадала информация о выбранном дне
	const birthdayList = useMemo(() => birthdays, [date]);
	const eventList = useMemo(() => events, [date]);

	// - header
	const dateInfo = {
		day: date.getDate(),
		dayName: DAY_OF_THE_WEEK[date.getDay() === 0 ? 6 : date.getDay() - 1],
		month: date.getMonth(),
	};

	const renderEvents = useCallback(
		(eventList: EventInfo[]) => {
			return eventList.map(event => {
				const eventDate = new Date(event.date);

				const isToday = eventDate.getDate() === date.getDate();
				const hours = eventDate.getHours().toString();
				const minutes = eventDate.getMinutes().toString();

				return (
					<EventInfoItem
						key={event.id}
						eventId={event.id}
						eventName={event.name}
						time={isToday ? `${hours}:${minutes === '0' ? '00' : minutes}` : ''}
						category={event.eventsCategories?.length ? event.eventsCategories : ([EMPTY_CATEGORY] as CategoryInfo[])}
					/>
				);
			});
		},
		[eventList],
	);

	// * Render
	return (
		<div className={s.container}>
			<Heading level={3}>
				{dateInfo.dayName}, {dateInfo.day} {LIST_OF_MONTHS_GENITIVE[Number(dateInfo.month)]}
			</Heading>

			{birthdayList.length > 0 && (
				<div className={s.section}>
					<h4>Дни рождения</h4>

					{birthdayList.map(user => (
						<UserInfo
							key={user.userId}
							userId={user.userId}
							name={createFullName({
								firstName: user.name,
								lastName: user.lastName,
								middleName: user.middleName || '',
							})}
							job={user.positionName}
							imageId={user.imageId}
						/>
					))}
				</div>
			)}

			{eventList && eventList.length > 0 && (
				<div className={s.section}>
					<h4>События</h4>
					{renderEvents(eventList)}
				</div>
			)}
		</div>
	);
};
