import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { ButtonPair } from '@/entities/_buttons/ButtonPair';
import { StringField } from '@/shared/ui/_fields/StringField';
import { Heading } from '@/shared/ui/Heading';
import * as yup from 'yup';
import { SubGroupType } from '../../types';
import s from './AddEditSubGroupMC.module.scss';
import { NAME_LIMIT, SUB_GROUP } from './consts';

interface Props {
	name?: string;
	type: SubGroupType;
	closeModal: () => void;
	onSubmitAction: (data: { name: string }) => void;
	isLoading: boolean;
}

export const AddSubGroupMC: React.FC<Props> = props => {
	const {
		name, //
		type,
		closeModal,
		onSubmitAction,
		isLoading,
	} = props;

	const isEdit = !!name;

	//* Form
	const defaultValues = {
		name: name ?? '',
	};

	const schema = yup.object().shape({
		name: yup.string().required('Обязательное поле').max(NAME_LIMIT, `Максимум ${NAME_LIMIT} символов`),
	});

	const formMethods = useForm({
		defaultValues: defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch } = formMethods;

	const value = watch('name');

	const onSubmit = (data: typeof defaultValues) => onSubmitAction(data);

	// * Render
	return (
		<FormProvider {...formMethods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={s.container}
			>
				<Heading
					level={2}
					className={s.header}
				>
					{isEdit ? SUB_GROUP[type].edit : SUB_GROUP[type].new}
				</Heading>

				<StringField
					name="name"
					label={SUB_GROUP[type].label}
					placeholder={SUB_GROUP[type].placeholder}
				/>

				<ButtonPair
					primaryText={isEdit ? 'Сохранить' : 'Создать'}
					primaryIsLoading={isLoading}
					primaryDisabled={!value}
					secondaryText="Отмена"
					secondaryOnClick={closeModal}
					secondaryIsLoading={isLoading}
				/>
			</form>
		</FormProvider>
	);
};
