import { PageDecorator } from '@/app/decorators/page_decorators';
import s from './ClaimSystemPage.module.scss';
import { useNavigate } from 'react-router-dom';
import { routerPath } from '@/app/router/paths';
import { Button } from '@/shared/ui/_buttons/Button';
import { PageTitle } from '@/entities/PageTitle/PageTitle';
import { LineTabs } from '@/shared/ui/_tabs/lineTabs';
import { TABS } from '../../../../consts/consts';
import { RoutesAndCategories } from '../RoutesAndCategories/ui/RoutesAndCategories/RoutesAndCategories';
import { AllClaims } from '../AllClaims/AllClaims';
import { useState } from 'react';
import { useRights } from '@/shared/hooks/useRights';
import { useSelector } from 'react-redux';
import { RootState } from '@/app/redux/store';

export const ClaimSystemPage = () => {
	const navigate = useNavigate();
	const isAdmin = useRights();
	const [activeTabId, setActiveTabId] = useState<string>(isAdmin ? TABS[0].id : TABS[2].id);
	const personalInfo = useSelector((state: RootState) => state.user_service.user.userInfo)?.user;

	const navigateToApplicationForm = () => {
		navigate(routerPath.claim.page + routerPath.claim.addClaim);
	};

	const navigateToStatistics = () => {
		navigate(routerPath.claim.page + routerPath.claim.statistics);
	};
	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title="Система заявок"
				isBeta
				buttons={[
					<Button
						key={0}
						onClick={navigateToApplicationForm}
					>
						Создать заявку
					</Button>,
					isAdmin && (
						<Button
							key={1}
							onClick={navigateToStatistics}
						>
							Статистика
						</Button>
					),
				]}
			/>
			<LineTabs
				className={s.tabs}
				tabs={isAdmin ? TABS : [TABS[2]]}
				activeTabId={activeTabId}
				setActiveTabId={setActiveTabId}
			/>
			{activeTabId === 'Маршруты и категории' && <RoutesAndCategories />}
			{activeTabId === 'Все заявки' && <AllClaims />}
			{activeTabId === 'Мои заявки' && <AllClaims userId={personalInfo?.id} />}
			{activeTabId === 'Заявки департамента'}
		</PageDecorator>
	);
};
