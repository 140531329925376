import React, { useEffect, useState } from 'react';
import { RequestStatus } from '@/app/api/api_types';
import { useGetDepartmentFindQuery } from '@/app/redux/queries/department-service/department_serviceAPI';
import { userServiceActions } from '@/app/redux/state/user/actions';
import { useAppDispatch, useAppSelector } from '@/app/redux/utils';
import { SelectStringSearchFilter, SelectValue } from '@/entities/_filters/SelectStringSearchFilter';
import { useRights } from '@/shared/hooks/useRights';
import { matchItems } from '@/shared/lib/filter';
import { createFullName } from '@/shared/lib/string';
import { AddButton } from '@/shared/ui/_buttons/AddButton';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { AddUserMCContainer } from './AddUserMC/ui/AddUserMCContainer/AddUserMCContainer';
import s from './UsersContent.module.scss';
import { UsersListContainer } from './UsersListContainer';

export const UsersContent: React.FC = () => {
	// * Rights
	const allowedToEditOffices = useRights(8);

	// * API
	const { data: departmentData } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});

	// * Selectors
	const userIds = useAppSelector(state => state.office_service.office.userIds);
	const companyUsers = useAppSelector(state => state.user_service.user.companyUsers);
	const status = useAppSelector(state => state.user_service.user.status);

	// * Actions
	const dispatch = useAppDispatch();
	const { getCompanyUsers } = userServiceActions.user.async;
	const { clearCompanyUsers } = userServiceActions.user.actions;

	// * Initialize
	useEffect(() => {
		userIds && userIds?.length > 0
			? dispatch(
					getCompanyUsers({
						usersParams: userIds.map(userId => ({
							userId,
							includeRole: false,
							includeDepartment: true,
							includeAvatars: true,
							includePosition: true,
						})),
					}),
				)
			: dispatch(clearCompanyUsers());
	}, [userIds, userIds?.length]);

	// * Modal
	const [showModal, setShowModal] = useState(false);
	const toggleModal = () => setShowModal(prevState => !prevState);

	// * All company users
	const allUsers =
		companyUsers?.map(companyUser => {
			const { body } = companyUser;
			const { user, departmentUser } = body;
			return {
				id: user.id,
				name: createFullName({ lastName: user.lastName, firstName: user.firstName, middleName: user.middleName || '' }),
				department: departmentUser?.department.name,
				position: body.position?.name,
				avatar: user.avatar,
			};
		}) || [];

	// * Filter
	const [department, setDepartment] = useState<SelectValue>({ id: null, name: '', tooltipText: '' });
	const [userName, setUserName] = useState('');

	const usersFilter = allUsers.filter(item => {
		if (department.tooltipText) {
			return item.department === department.tooltipText;
		}
		return item;
	});

	const filteredUsers = matchItems(usersFilter, userName).sort((a, b) => a.name.localeCompare(b.name));

	const departments = departmentData?.body ?? [];

	const departmentAll = departments.map(department => ({
		id: department.id,
		name: department.shortName,
		tooltipText: department.name,
	}));
	// * Conditions
	const isLoading = status === RequestStatus.loading;

	// * Render
	return (
		<>
			{showModal && ( // ! Для очистки выбранных пользователей. Можно воспользоваться useImperativeHandle для доступа к setTempSelectedUsersIds.
				<ModalNewProto
					isOpen={showModal}
					onClose={toggleModal}
					width="l"
				>
					<AddUserMCContainer toggleModal={toggleModal} />
				</ModalNewProto>
			)}

			<div className={s.container}>
				{allowedToEditOffices && (
					<AddButton
						title="Добавить сотрудника"
						onClick={toggleModal}
					/>
				)}

				{departmentAll && companyUsers.length > 0 && (
					<SelectStringSearchFilter
						selectPlaceholder="Департамент"
						selectValue={department}
						setSelect={(value: SelectValue) => setDepartment(value)}
						selectOptions={departmentAll}
						keyNames={{
							name: 'name',
							value: 'id',
							tooltipText: 'tooltipText',
						}}
						searchPlaceholder="Поиск сотрудников"
						searchStringValue={userName}
						setSearchString={setUserName}
					/>
				)}

				{isLoading ? <LoaderCircle /> : <>{filteredUsers.length > 0 && <UsersListContainer users={filteredUsers} />}</>}
			</div>
		</>
	);
};
