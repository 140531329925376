import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageDecoratorPublic } from '@/app/decorators/page_decorators/PageDecoratorPublic/PageDecoratorPublic';
import { wiki_serviceAPI } from '@/app/redux/queries/wiki-service/wiki_serviceAPI';
import { useAppDispatch } from '@/app/redux/utils';
import { ArticleFiles } from '../../../../ArticleFiles/ArticleFiles';
import { Menu } from '../Menu/ui/Menu/Menu';
import s from './ArticlePagePublic.module.scss';
import { actionsNotifications } from '@/features/notifications/_BLL/slice';
import { ArticleText } from '../ArticleText';

export const ArticlePagePublic: React.FC = () => {
	// * Router
	const params: any = useParams();
	const articleId: string = params.articleId;
	const navigate = useNavigate();

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	// * API
	const { data: wikiData } = wiki_serviceAPI.useGetPublicWikiQuery();
	const { data: activeArticleData, isError, error } = wiki_serviceAPI.useGetPublicArticleQuery({ articleId }, { skip: !articleId });

	const wikiTree = wikiData || [];

	// * Archived article or not founded
	useEffect(() => {
		if (isError) {
			navigate('/wiki_public');
			dispatch(addNotification({ type: 'error', message: 'Статья не найдена.' }));
		}
	}, [isError]);

	useEffect(() => {
		if (activeArticleData && !activeArticleData.isActive) {
			navigate('/wiki_public');
			dispatch(addNotification({ type: 'error', message: 'Данная статья находится в архиве.' }));
		}
	}, [activeArticleData]);

	// * Render
	return (
		<PageDecoratorPublic>
			{activeArticleData && (
				<div className={s.container}>
					{activeArticleData.rubricId && wikiTree && (
						<Menu
							className={s.menu}
							tree={wikiTree}
							isPrivate={false}
							activeRubricId={activeArticleData.rubricId}
							activeArticleId={activeArticleData.id}
						/>
					)}

					<div className={s.content}>
						<ArticleText
							article={activeArticleData}
							isPrivate={false}
						/>

						<ArticleFiles
							existFiles={!!activeArticleData.files && activeArticleData.files.length > 0}
							articleId={articleId}
							isPrivate={false}
						/>
					</div>
				</div>
			)}
		</PageDecoratorPublic>
	);
};
