import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { company_structure_serviceAPI } from '@/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { EditUserPayloadItem } from '@/app/redux/state/company-structure/users/types';
import { imageServiceActions } from '@/app/redux/state/image/actions';
import { UserAvatar } from '@/app/redux/state/user/user/types';
import { useRights } from '@/shared/hooks/useRights';
import { AvatarField } from '@/shared/ui/AvatarField';
import { Button } from '@/shared/ui/_buttons/Button';
import { StringField } from '@/shared/ui/_fields/StringField';
import { TextAreaField } from '@/shared/ui/_fields/TextAreaField';
import * as yup from 'yup';
import s from './CreateNewAdministrator.module.scss';
import { ErrorWithLimit } from '@/shared/ui/_inputs/_shared/ErrorWithLimit/ErrorWithLimit';
import { useAppDispatch } from '@/app/redux/utils';
import { actionsNotifications } from '@/features/notifications/_BLL/slice';

interface DefaultValuesProps {
	avatar?: UserAvatar | null;
	fullname: string;
	position: string;
	description: string;
}

interface Props {
	userId?: string;
	values?: DefaultValuesProps;
	isNewAdministrator?: boolean;
	toggleModal: () => void;
}

export const CreateNewAdministrator: React.FC<Props> = props => {
	const { values, userId, isNewAdministrator, toggleModal } = props;

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;
	const { getImage } = imageServiceActions.image.async;

	// * Queries
	const { data: customPositions } = company_structure_serviceAPI.useGetPositionsQuery({
		params: {
			skipcount: 0,
			takecount: 1000,
		},
	});

	// * ?
	const [createUser, { isLoading: isCreateLoading }] = company_structure_serviceAPI.useCreateUserMutation();
	const [createPosition, { isLoading: isCreatePositionLoading }] = company_structure_serviceAPI.useCreatePositionMutation();
	const [editUser, { isLoading: isEditLoading }] = company_structure_serviceAPI.useEditUserMutation();
	const [deletePosition, { isLoading: isDelPositionLoading }] = company_structure_serviceAPI.useDeletePositionMutation();
	const [editPosition] = company_structure_serviceAPI.useEditPositionMutation();
	const [createAvatar, { isLoading: isAvatarLoading }] = company_structure_serviceAPI.useCreateAvatarMutation();

	const isLoading = isCreateLoading || isCreatePositionLoading || isEditLoading || isDelPositionLoading || isAvatarLoading;

	// * Form
	const defaultValues: DefaultValuesProps = {
		avatar: values?.avatar,
		fullname: values?.fullname || '',
		position: values?.position || '',
		description: values?.description || '',
	};

	const schema = yup.object().shape({
		avatar: yup.object().required('Добавьте фотографию').nullable(),
		fullname: yup.string().required('').nullable(),
		position: yup.string().required('').nullable(),
		description: yup.string().required('').nullable(),
	});

	const formMethods = useForm({
		defaultValues,
		resolver: yupResolver(schema),
	});

	const { handleSubmit, control, setValue, setError, formState } = formMethods;
	const positionError = formState.errors?.position;
	const fullnameError = formState.errors?.fullname;

	const isAdmin = useRights();

	const onSubmit = (data: DefaultValuesProps) => {
		if (isNewAdministrator) {
			createPosition({
				params: {
					name: data.position,
				},
			})
				.unwrap()
				.then(posId => {
					createUser({
						params: {
							user: {
								fullName: data.fullname,
								positionId: posId,
								description: data.description,
								assignment: 'Director',
								contacts: [],
							},
						},
					})
						.unwrap()
						.then(res => {
							const id = res;
							createAvatar({
								params: {
									userId: id,
								},
								payload: {
									name: data?.avatar?.name || '',
									content: data?.avatar?.content || '',
									extension: data?.avatar?.extension || '',
								},
							})
								.unwrap()
								.then(() => toggleModal())
								.catch(err => console.log(err));
						})
						.catch(err => console.log(err));
				})
				.catch(err => {
					const errorMessage = err?.data?.Message;
					if (errorMessage.includes('Position')) {
						dispatch(
							addNotification({
								type: 'error',
								message: 'Пользователь с такой должностью уже существует',
							}),
						);
						setError('position', {
							message: '',
						});
					}
				});
			return;
		}

		const preparedPayload: EditUserPayloadItem[] = [];
		const fullNameChanged = values?.fullname !== data.fullname;
		const descriptionChanged = values?.description !== data.description;
		const positionChanged = values?.position !== data.position;
		const imageChanged = values?.avatar?.id !== data.avatar?.id;

		if (fullNameChanged) {
			preparedPayload.push({
				op: 'replace',
				path: '/fullname',
				value: data.fullname,
			});
		}

		if (descriptionChanged) {
			preparedPayload.push({
				op: 'replace',
				path: '/description',
				value: data.description,
			});
		}

		if (positionChanged && userId) {
			const positions = customPositions?.body || [];
			const positionId = positions.filter(pos => pos.name === values?.position)[0]?.id;

			if (imageChanged) {
				createAvatar({
					params: {
						userId: userId,
					},
					payload: {
						name: data?.avatar?.name || '',
						content: data?.avatar?.content || '',
						extension: data?.avatar?.extension || '',
					},
				});
			}

			createPosition({
				params: {
					name: data.position,
				},
			})
				.unwrap()
				.then(posId => {
					editUser({
						userId: userId,
						payload: [
							...preparedPayload,
							{
								op: 'replace',
								path: '/positionId',
								value: posId,
							},
						],
					})
						.unwrap()
						.then(() => {
							values?.position &&
								deletePosition({
									positionId: positionId,
								});
						})
						.catch(error => console.log('error: ', error));
				})
				.then(() => toggleModal())
				.catch(err => console.log(err?.data?.Message));

			return;
		}

		if (imageChanged && userId && preparedPayload.length === 0) {
			createAvatar({
				params: {
					userId: userId,
				},
				payload: {
					name: data?.avatar?.name || '',
					content: data?.avatar?.content || '',
					extension: data?.avatar?.extension || '',
				},
			})
				.unwrap()
				.then(() => toggleModal())
				.catch(err => console.log(err));

			return;
		}

		editUser({
			userId: userId || '',
			payload: preparedPayload,
		})
			.unwrap()
			.then(() => {
				if (imageChanged && userId) {
					createAvatar({
						params: {
							userId: userId,
						},
						payload: {
							name: data?.avatar?.name || '',
							content: data?.avatar?.content || '',
							extension: data?.avatar?.extension || '',
						},
					});
				}
				toggleModal();
			})
			.catch(error => console.log('error: ', error?.data?.Message));
	};

	return (
		<div className={s.container}>
			<h2 className={s.title}>{isNewAdministrator ? 'Создание администратора' : 'Редактирование администратора'}</h2>

			<form
				className={s.form}
				onSubmit={handleSubmit(onSubmit)}
			>
				<FormProvider {...formMethods}>
					<div className={s.form_components}>
						<div className={s.wrapper}>
							<div className={s.left}>
								<Controller
									name="avatar"
									control={control}
									render={({ field, fieldState }) => (
										<>
											<AvatarField
												createImages={newFile => setValue('avatar', newFile[0])}
												deleteImages={() => setValue('avatar', null)}
												images={field.value ? [{ ...field.value, name: field.value.name || '' }] : []}
												maxFiles={1}
												allowedToEdit={isAdmin}
												fetchImage={(imageId: string) =>
													getImage({
														params: {
															imageId,
															source: 'CompanyStructure',
														},
													})
												}
											/>

											{fieldState?.error?.message && <ErrorWithLimit errorMessage={fieldState?.error?.message} />}
										</>
									)}
								/>
							</div>

							<div className={s.right}>
								<StringField
									name="fullname"
									placeholder="ФИО"
									errorBorder={!!fullnameError}
								/>

								<StringField
									name="position"
									placeholder="Должность"
									errorBorder={!!positionError}
								/>
							</div>
						</div>

						<TextAreaField
							name="description"
							placeholder="Описание"
						/>
					</div>
					<div className={s.buttons}>
						<Button
							wide
							variant="tertiary"
							onClick={event => {
								event.preventDefault();
								event.stopPropagation();
								toggleModal();
							}}
							isLoading={isLoading}
						>
							Отменить
						</Button>

						<Button
							type="submit"
							wide
							variant="primary"
							isLoading={isLoading}
						>
							{isNewAdministrator ? 'Создать' : 'Сохранить'}
						</Button>
					</div>
				</FormProvider>
			</form>
		</div>
	);
};
