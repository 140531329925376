import { VacationType } from '@/app/redux/queries/vacation-service/types/types';

export const PATHS = {
	name: '/Name',
	shortName: '/ShortName',
} as const;

export type PathKeys = keyof typeof PATHS;
export type PathValues = (typeof PATHS)[PathKeys];

export const VACATION_MAP: { [key in Exclude<VacationType, 'Prolonged' | 'Decree'>]: { name: string; color: string } } = {
	Planned: {
		name: 'Планируемый отпуск',
		color: '#C4D593',
	},
	SickLeave: {
		name: 'Больничный',
		color: '#F4D775',
	},
	Actual: {
		name: 'Фактический отпуск',
		color: '#73c171',
	},
	Idle: {
		name: 'Отгул',
		color: '#D6A4DE',
	},
	Training: {
		name: 'Обучение',
		color: '#97CED2',
	},
};

export type VacationKeys = keyof typeof VACATION_MAP;

export const FILTER_PARAMS_NAMES = {
	name: 'name',
	category: 'category',
	group: 'group',
	team: 'team',
	status: 'status',
};
