import { createContext, FC, ReactNode, useContext } from 'react';
import { User } from '../types';
import { SetURLSearchParams } from 'react-router-dom';

interface Context {
	users: User[];
	setFilteredUsers: (users: User[]) => void;
	isLoading: boolean;
	isLoadingSuccess: boolean;
	plannedVacationUserId: string | null;
	setPlannedVacationUserId: (userId: string | null) => void;
	activeLeftDaysModal: string | null;
	setActiveLeftDaysModal: (userId: string | null) => void;
	searchParams: URLSearchParams;
	setSearchParams: SetURLSearchParams;
}

interface Props extends Context {
	children: ReactNode;
}

const VacationPageContext = createContext<Context | null>(null);

export const VacationPageContextProvider: FC<Props> = props => {
	const {
		users,
		setFilteredUsers,
		isLoading,
		isLoadingSuccess,
		plannedVacationUserId,
		setPlannedVacationUserId,
		activeLeftDaysModal,
		setActiveLeftDaysModal,
		searchParams,
		setSearchParams,
		children,
	} = props;

	// * Render
	return (
		<VacationPageContext.Provider
			value={{
				users,
				setFilteredUsers,
				isLoading,
				isLoadingSuccess,
				plannedVacationUserId,
				setPlannedVacationUserId,
				activeLeftDaysModal,
				setActiveLeftDaysModal,
				searchParams,
				setSearchParams,
			}}
		>
			{children}
		</VacationPageContext.Provider>
	);
};

export const useVacationPageContext = () => {
	const context = useContext(VacationPageContext);

	if (!context) {
		throw new Error('useVacationPageContext must be used within WorkTimesInfoContext');
	}

	return context;
};
