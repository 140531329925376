import React, { FC } from 'react';
import { Heading } from '@/shared/ui/Heading';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import s from './BaseEmailChangeModal.module.scss';
import { ButtonPair } from '@/entities/_buttons/ButtonPair/ButtonPair';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';

interface Props {
	id: string;
	email: string;
	isOpen: boolean;
	onClose: () => void;
}

export const BaseEmailChangeModal: FC<Props> = props => {
	const { id, email, isOpen, onClose } = props;

	const [changeBaseEmail] = user_serviceAPI.usePutCommunicationEditMutation();

	const onChangeBaseEmail = () => {
		changeBaseEmail({
			communicationId: id,
			editCommunicationRequest: { type: 'BaseEmail' },
		})
			.unwrap()
			.then(() => onClose())
			.catch(err => console.log(err));
	};

	// * Render
	return (
		<ModalNewProto
			isOpen={isOpen}
			onClose={onClose}
			className={s.modal}
		>
			<div className={s.container}>
				<Heading
					level={2}
					marginBottom="l"
					className={s.header}
				>
					Сделать почту основной
				</Heading>

				<div className={s.description}>{'Основная электронная почта используется для уведомлений от системы,\nа также для восстановления доступа и смены пароля.'}</div>
				<div className={s.description}>
					Сделать <span className={s.email}>{email}</span> основной почтой?
				</div>

				<ButtonPair
					primaryText="Сделать основной"
					primaryIsLoading={false}
					primaryOnClick={onChangeBaseEmail}
					secondaryText="Отмена"
					secondaryOnClick={onClose}
					secondaryIsLoading={false}
				/>
			</div>
		</ModalNewProto>
	);
};
