import { GridOption } from '@/pages/_surveys/pages/NewSurveyPage/_types';

// Получить все столбцы, без повторений
export const getGridColumns = (gridOptions: GridOption[]) => {
	const columns = gridOptions.reduce((acc: GridOption[], option) => {
		const isExistRow = acc.find(opt => opt.columnSignature === option.columnSignature && opt.rowSignature !== option.rowSignature);

		if (isExistRow) return acc;

		return [...acc, { ...option }];
	}, []);

	return columns;
};
