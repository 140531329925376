import s from './ClaimPage.module.scss';
import { PageDecorator } from '@/app/decorators/page_decorators';
import { PageTitle } from '@/entities/PageTitle/PageTitle';
import { useParams } from 'react-router-dom';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';
import { claim_serviceAPI } from '@/app/redux/queries/claim-service/claim_serviceAPI';
import { createFullName } from '@/shared/lib/string';
import { ClaimsRoute } from '../ClaimsRoute/ClaimsRoute';
import { file_serviceAPI } from '@/app/redux/queries/file-service/file_serviceAPI';
import { ClaimsComments } from '../ClaimsComments/ClaimsComments/ClaimsComments';
import { ClaimInfo } from '../ClaimInfo/ClaimInfo';
import { useRights } from '@/shared/hooks/useRights';
import { ClaimsHistory } from '../ClaimsHistory/ClaimsHistory/ClaimsHistory';

export interface ClaimId {
	claimId: string;
}

export const ClaimPage = () => {
	const { claimId } = useParams() as unknown as ClaimId;
	const isAdmin = useRights();

	// * API
	const { data: claimData } = claim_serviceAPI.useGetClaimsByClaimIdQuery({
		claimId: claimId,
	});
	const { data: usersData } = user_serviceAPI.useGetUserGetQuery(
		{
			userid: claimData?.createdBy,
			includecurrentavatar: true,
		},
		{ skip: !claimData?.createdBy },
	);

	// const { data: getFiles, isLoading: isFilesLoading } = claim_serviceAPI.useGetFilesQuery({ entityid: claimId, skipcount: 0, takecount: 100 });

	const user = usersData?.body?.user;

	const userName = createFullName({
		firstName: user?.firstName || '',
		lastName: user?.lastName || '',
		middleName: user?.middleName || '',
	});

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title="Система заявок"
				isBeta
			/>
			{claimData && (
				<div className={s.container}>
					<div className={s.container_left}>
						<ClaimInfo
							userName={userName}
							claimData={claimData}
							isAdmin={isAdmin}
						/>
						<ClaimsComments claimId={claimId} />
						<ClaimsHistory
							userName={`${user?.lastName} ${user?.firstName}`}
							avatar={user?.avatar}
							data={claimData.createdAtUtc}
						/>
					</div>
					<ClaimsRoute />
				</div>
			)}
		</PageDecorator>
	);
};

//![Tatiana]
//? ClaimsComments пока не проверяла, пока не будем его трогать
