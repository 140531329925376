import { FC } from 'react';
import { CommentInfo } from '@/app/redux/queries/event-service/types/types';
import { UserInfoForReply } from '../../types';
import { Comment } from '../Comment/Comment';

interface Props {
	comments: CommentInfo[];
	nestedCreateAction?: (user: UserInfoForReply | null) => void; // - replyAction из компонента Comment
}

export const CommentList: FC<Props> = props => {
	const { comments, nestedCreateAction } = props;

	// * Render
	return (
		<>
			{comments.map(comment => (
				<Comment
					key={comment.id}
					comment={comment}
					nestedCreateAction={nestedCreateAction}
				/>
			))}
		</>
	);
};
