import React, { useEffect, useMemo, useState } from 'react';
import { position_serviceAPI } from '@/app/redux/queries/position-service/position_serviceAPI';
import { project_serviceAPI } from '@/app/redux/queries/project-service/project_serviceAPI';
import { TeamUserAdd } from '@/pages/_projectService/ui/ProjectDetailPage/ui/TeamUserAdd/TeamUserAdd';
import SearchSVG from '@/shared/assets/svg/action/search.svg?react';
import { useRights } from '@/shared/hooks/useRights';
import { Avatar } from '@/shared/ui/Avatar/Avatar';
import { EmptyData } from '@/shared/ui/EmptyData/EmptyData';
import { SelectSingle } from '@/shared/ui/_inputs/selects/SelectSingle';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { CardsTable } from '@/shared/ui/_tables/CardsTable';
import { UserDropDown } from '../UserDropDown/UserDropDown';
import { COLUMNS } from './consts/COLUMNS';
import s from './Team.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { RoleStar } from '@/shared/ui/RoleStar/RoleStar';
import { createFullName } from '@/shared/lib/string';

interface Props {
	projectId: string;
	hasRights: boolean;
}

export const Team: React.FC<Props> = props => {
	const { projectId, hasRights } = props;

	// * Navigation
	const navigate = useNavigate();

	// * Rights
	const projectsRightsOrAdmin = useRights(2);

	// * Sorting
	const [isAscendingSort, setIsAscendingSort] = useState(true);
	const handleSortChange = () => setIsAscendingSort(prev => !prev);

	// * API
	const { data: usersData, isLoading } = project_serviceAPI.useFindUsersQuery({
		skipCount: 0,
		takeCount: 1000,
		projectId: projectId,
		includePositions: true,
		isAscendingSort,
		isActive: true,
	});

	const { data: positionData } = position_serviceAPI.useFindPositionsQuery({
		skipcount: 0,
		takecount: 1000,
		includeDeactivated: false,
	});

	// - users
	const usersList = useMemo(() => {
		const newUsersData = usersData?.body
			? [...usersData.body]
					.sort((user1, user2) => {
						const a = user1.role === 'Manager' ? -1 : 0;
						const b = user2.role === 'Manager' ? -1 : 0;

						return a - b;
					})
					.map(user => {
						const { id, firstName, lastName, middleName, role, position, imageId } = user;

						const fullName = createFullName({ firstName, lastName, middleName });
						const positionName = position?.name;

						return {
							id,
							avatar: (
								<Link
									className={s.user_link}
									to={`/users/${id}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									<Avatar
										className={s.avatar}
										name={`${lastName} ${firstName}`}
										avatarId={imageId}
									/>
								</Link>
							),
							fullName_position: (
								<>
									<Link
										className={s.fullName}
										to={`/users/${id}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{fullName}
										<RoleStar role={role} />
									</Link>

									<div className={s.positionName}>{positionName}</div>
								</>
							),
							name: fullName,
							role,
							positionId: position?.id || '',
							position: role === 'Manager' ? 'Руководитель проекта' : position?.name || '-',
							imageId: imageId || '',
							extra: (
								<>
									{projectsRightsOrAdmin && (
										<UserDropDown
											projectId={projectId}
											id={id}
											role={role}
										/>
									)}
								</>
							),
						};
					})
			: [];

		return newUsersData;
	}, [usersData]);

	const [users, setUsers] = useState(usersList);

	// * Filters
	// - Position
	const positions = positionData?.body?.map(item => ({
		id: item.id,
		name: item.name,
	})) || [{ id: '', name: '' }];

	const [positionOption, setPositionOption] = useState({ id: '', name: '' });

	// - Name
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => {
		let newUsersList = usersList;

		if (searchValue !== '') {
			newUsersList = newUsersList.filter(user => user.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()));
		}

		if (positionOption.id) {
			newUsersList = newUsersList.filter(user => user.positionId === positionOption.id);
		}

		setUsers(newUsersList);
	}, [usersList, searchValue, positionOption]);

	// * Render
	return (
		<div className={s.container}>
			<h3 className={s.title}>Команда</h3>

			<div className={s.filters}>
				<SelectSingle
					placeholder="Должность"
					keyNames={{
						name: 'name',
						value: 'id',
					}}
					selectedOption={positionOption}
					setSelectedOption={value => setPositionOption(value)}
					options={positions}
				/>

				<TextInput
					placeholder="Поиск по ФИО"
					value={searchValue}
					onChange={event => setSearchValue(event.target.value)}
					RightIcon={<SearchSVG />}
				/>
			</div>

			<div className={s.users}>
				{hasRights && (
					<TeamUserAdd
						projectId={projectId}
						userIds={users.map(user => user.id)}
					/>
				)}

				{isLoading && <LoaderCircle />}
				{users.length === 0 && !isLoading && <EmptyData />}

				{/* {users.length > 0 && (
					<TeamUsersList
						projectId={projectId}
						users={users}
					/>
				)} */}

				{users && (
					<>
						{users.length > 0 && (
							<CardsTable
								data={users}
								columns={COLUMNS}
								sortingColumnId="name"
								isAscendingSort={isAscendingSort}
								handleSortChange={handleSortChange}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
};
