import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { EditUserREQ_PARAMS, EditUserRES, FindUsersREQ, FindUsersRES, GetUserREQ_PARAMS, GetUserRES, UserInfo } from './types';

const NAME = `${ServiceName.USER_SERVICE}/user`;

// * Async thunks
const getAppUser = createAppAsyncThunk(`${NAME}/getAppUser`, async (arg: { params: GetUserREQ_PARAMS }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<GetUserRES>({
		url: `${NAME}/get`,
		params,
		thunkAPI,
		action: () => getAppUser(arg),
	});
});

const getUser = createAppAsyncThunk(`${NAME}/getUser`, async (arg: { params: GetUserREQ_PARAMS }, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<GetUserRES>({
		url: `${NAME}/get`,
		params,
		thunkAPI,
		action: () => getAppUser(arg),
	});
});

const findUsers = createAppAsyncThunk(`${NAME}/findUser`, async (arg: FindUsersREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<FindUsersRES>({
		url: `${NAME}/find`,
		params,
		thunkAPI,
		action: () => findUsers(arg),
	});
});

// * Custom
const getCompanyUsers = createAppAsyncThunk(`${NAME}/getCompanyUsers`, async (arg: { usersParams: GetUserREQ_PARAMS[] }, thunkAPI) => {
	const { usersParams } = arg;

	return await Promise.all(
		usersParams.map(params =>
			apiRequest.getRequest<GetUserRES>({
				url: `${NAME}/get`,
				params,
				thunkAPI,
				action: () => getCompanyUsers(arg),
			}),
		),
	);
});

const editUser = createAppAsyncThunk(`${NAME}/editUser`, async (arg: EditUserREQ_PARAMS, thunkAPI) => {
	const { params, payload } = arg;
	// const {dispatch} = thunkAPI;
	// const userId: any = useParams();
	// console.log(userId);

	await apiRequest.patchRequest<EditUserRES>({
		url: `${NAME}/edit`,
		params,
		payload,
		action: () => editUser(arg),
		thunkAPI,
	});

	// dispatch(actionsNotifications.addNotification({
	// 	type: 'success',
	// 	message: 'Данные изменены',
	// }));

	// dispatch(getUser({
	// 	params: {
	// 		userId: userId,
	// 		includeCurrentAvatar: true,
	// 		includeCommunications: true,
	// 		includeCompany: true,
	// 		includeDepartment: true,
	// 		includeRole: true,
	// 		locale: 'ru',
	// 	}
	// }));
});

// * Slice
export interface State {
	userInfo: UserInfo | null;
	users: FindUsersRES | null;
	companyUsers: GetUserRES[];
	status: RequestStatus;
}

const userInfo = localStorage.getItem('userInfo');

export const initialState: State = {
	userInfo: userInfo ? JSON.parse(userInfo) : null,
	users: null,
	companyUsers: [],
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setUserInfo: (state, action) => {
			state.userInfo = action.payload;
		},
		clearCompanyUsers: state => {
			state.companyUsers = [];
		},
	},
	extraReducers: builder => {
		builder.addCase(getAppUser.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getAppUser.fulfilled, (state, action) => {
			state.userInfo = action.payload.body;
			state.status = RequestStatus.success;
			localStorage.setItem('userInfo', JSON.stringify(action.payload.body));
		});
		builder.addCase(getAppUser.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getUser.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getUser.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(getUser.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(findUsers.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findUsers.fulfilled, (state, action) => {
			state.users = action.payload;
			state.status = RequestStatus.success;
		});
		builder.addCase(findUsers.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getCompanyUsers.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getCompanyUsers.fulfilled, (state, action) => {
			state.companyUsers = action.payload;
			state.status = RequestStatus.success;
		});
		builder.addCase(getCompanyUsers.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(editUser.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editUser.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(editUser.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	getAppUser,
	getUser,
	findUsers,
	getCompanyUsers,
	editUser,
};
