import React from 'react';
import s from './OfficeCard.module.scss';
import { getTimeRangeString } from '@/shared/lib/date';
import { Office } from '@/app/redux/state/office/types';
import { Chip } from '@/shared/ui/_chips/Chip/Chip';
import { useNavigate } from 'react-router-dom';
import { routerPath } from '@/app/router/paths';
import { OfficeInnerMenu } from '../OfficeInnerMenu';

export const OfficeCard: React.FC<Office> = props => {
	const { id, name, address, city, isActive, startTime, endTime } = props;

	// * Router
	const navigate = useNavigate();

	// * Interactions
	const onOfficeClick = () => {
		isActive && navigate(routerPath.offices.page + routerPath.offices.singleOffice + `/${id}`);
	};

	// * Render
	return (
		<div
			className={s.container}
			onClick={onOfficeClick}
			aria-disabled={!isActive}
		>
			<div className={s.header}>
				<div className={s.header_left}>
					<Chip variant={isActive ? 'green' : 'red'} />
					<h5>{name ? name : 'Безымянный офис'}</h5>
				</div>

				{!isActive && <OfficeInnerMenu office={props} />}
			</div>

			<div>
				<div className={s.caption}>адрес</div>
				<div>{address}</div>
			</div>

			<div className={s.footer}>
				<Chip text={city} />

				{startTime && endTime && <div className={s.time}>{getTimeRangeString(startTime, endTime)}</div>}
			</div>
		</div>
	);
};
