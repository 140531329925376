import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { useGetDepartmentGetQuery } from '@/app/redux/queries/department-service/department_serviceAPI';
import { useGetGroupsQuery, usePostImportDepartmentsByDepartmentIdMutation } from '@/app/redux/queries/vacation-service/vacation_serviceAPI';
import { useAppSelector } from '@/app/redux/utils';
import { routerPath } from '@/app/router/paths';
import { PageTitle } from '@/entities/PageTitle/PageTitle';
import { useRights } from '@/shared/hooks/useRights';
import { ConditionalRendering } from '@/shared/providers';
import { Button } from '@/shared/ui/_buttons/Button';
import { LineTabs } from '@/shared/ui/_tabs/lineTabs';
import { AddEditDepartmentButton } from '../AddEditDepartmentButton/ui/AddEditDepartmentButton/AddEditDepartmentButton';
import { Projects } from '../Projects/Projects';
import { Team } from '../Team';
import s from './DepartmentPage.module.scss';

const CURRENT_YEAR = new Date().getFullYear();

export const DepartmentPage = () => {
	// * GroupId - to vacation page
	const [groupId, setGroupId] = useState<string | null>(null);

	// * Routing
	const { departmentId, tab } = useParams() as { departmentId: string; tab: 'projects' | 'team' };
	const navigate = useNavigate();

	const toTimeList = () => navigate(`${routerPath.departments.page}${routerPath.departments.timeList}/${departmentId}`);
	const toVacation = () => {
		const groupUrl = `${routerPath.vacation.page}?groupId=${groupId}&year=${CURRENT_YEAR}&fromDepartment=true`;
		window.open(groupUrl, '_blank', 'noopener, noreferrer');
	};

	// * Selectors
	const departmentUser = useAppSelector(state => state.user_service.user.userInfo?.departmentUser);

	// * Rights
	const isAdmin = useRights();
	const departmentRightsOrAdmin = useRights(4);
	const departmentManager = departmentUser?.role === 'Manager' && departmentUser?.department.id === departmentId;

	// * API
	const { data: departmentData, isLoading: isDepartmentLoading } = useGetDepartmentGetQuery({
		departmentid: departmentId,
	});

	const { data: vacationGroupData, isLoading: isVacationGroupsLoading } = useGetGroupsQuery(
		{
			skipcount: 0,
			takecount: 1000,
			departmentId,
		},
		{ skip: !isAdmin },
	);

	const department = departmentData?.body;
	const group = vacationGroupData?.body;

	const [importDepartment, { isLoading: isImportDepartmentLoading }] = usePostImportDepartmentsByDepartmentIdMutation();
	const isLoading = isDepartmentLoading || isVacationGroupsLoading || isImportDepartmentLoading;

	useEffect(() => {
		if (!isAdmin || !group) return;

		if (group.length === 0) {
			importDepartment({ departmentId })
				.then((groupRes: any) => setGroupId(groupRes?.data?.body?.id))
				.catch(e => console.log(e));
		} else {
			setGroupId(group[0].id);
		}
	}, [group]);

	// * Tabs
	const TABS = [
		{
			id: 'team',
			name: 'Команда',
		},
		{
			id: 'projects',
			name: 'Проекты',
		},
	];

	const setActiveTab = (tab: string) => {
		navigate(`${routerPath.departments.page}${routerPath.departments.singleDepartment}/${departmentId}/${tab}`);
	};

	// * Title buttons
	const buttons = [];

	const TimeListButton = (
		<Button
			key="1"
			onClick={toTimeList}
		>
			Таймлист
		</Button>
	);

	const VacationButton = (
		<Button
			key="2"
			variant="secondary"
			onClick={toVacation}
		>
			Календарь отпусков
		</Button>
	);

	if (departmentRightsOrAdmin && department) {
		buttons.push([
			<AddEditDepartmentButton
				key="0"
				department={{
					...department,
					isActive: department.isActive
						? {
								id: 'true',
								name: 'Активный',
							}
						: {
								id: 'false',
								name: 'Архивированный',
							},
				}}
			/>,
		]);

		if (isAdmin) buttons.push(VacationButton);
		if (department.isActive) buttons.push(TimeListButton);
	} else if (departmentManager && department?.isActive) {
		buttons.push(TimeListButton);
	}

	// * Render
	return (
		<PageDecorator>
			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={!!department}
				LoadedResult={
					<>
						{department && (
							<PageTitle
								title={department.name ?? ''}
								goBackRoute={{
									text: 'Департаменты',
									route: routerPath.departments.page,
								}}
								buttons={buttons}
							/>
						)}

						<LineTabs
							className={s.tabs}
							tabs={TABS}
							activeTabId={tab}
							setActiveTabId={setActiveTab}
						/>

						{tab === 'team' ? (
							<Team
								departmentId={departmentId}
								isActive={!!department?.isActive}
							/>
						) : (
							<Projects departmentId={departmentId} />
						)}
					</>
				}
			/>
		</PageDecorator>
	);
};
