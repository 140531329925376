import React, { useMemo, useState } from 'react';
import { CommunicationInfo } from '@/app/redux/state/user/user/types';
import { UserActivation } from '@/pages/UsersPage/ui/UserActivation/UserActivation';
import ArchiveSVG from '@/shared/assets/svg_icons/file/archive.svg?react';
import CLoseSVG from '@/shared/assets/svg_icons/action/close.svg?react';
import UnArchiveSVG from '@/shared/assets/svg_icons/file/unarchive.svg?react';
import EmailSVG from '@/shared/assets/svg_icons/social/mail.svg?react';
import { DropDownMenu } from '@/shared/ui/DropDownMenu/DropDownMenu';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { useGetUsersContext } from '../../context/useGetUsersContext';
import { ArchiveModal } from '../ArchiveModal/ArchiveModal';

interface Props {
	id: string;
	isActive: boolean;
	isPending: boolean;
	contacts: CommunicationInfo[];
}

export const UserDropDown: React.FC<Props> = props => {
	const { id, isActive, isPending, contacts } = props;

	// * Context
	const { getUserFilter, originalArgs } = useGetUsersContext();

	// * Modals
	const [isVisible, setIsVisible] = useState(false);
	const toggleVisible = () => setIsVisible(prevState => !prevState);

	const [isArchiveModal, setIsArchiveModal] = useState(false);
	const toggleArchiveModal = () => setIsArchiveModal(prevState => !prevState);

	// * Action after request
	const toUpdateUsers = () => {
		if (!originalArgs) return null;

		getUserFilter(originalArgs, true);
	};

	// * Menu
	const DropDownItems = useMemo(
		() =>
			[
				{
					icon: <ArchiveSVG />,
					isVisible: isActive && !isPending,
					title: 'Архивировать',
					action: () => toggleArchiveModal(),
				},
				{
					icon: <EmailSVG />,
					isVisible: !isActive && isPending,
					title: 'Отправить приглашение',
					action: () => {
						toggleVisible();
					},
				},
				{
					icon: <CLoseSVG />,
					isVisible: !isActive && isPending,
					title: 'Отменить приглашение',
					action: () => toggleArchiveModal(),
				},
				{
					icon: <UnArchiveSVG />,
					isVisible: !isActive && !isPending,
					title: 'Восстановить',
					action: () => {
						toggleVisible();
					},
				},
			].filter(item => item.isVisible),
		[id, isActive, isPending],
	);

	// * Render
	return (
		<>
			<DropDownMenu
				items={DropDownItems}
				value={id}
			/>

			{isVisible && (
				<ModalNewProto
					isOpen={isVisible}
					onClose={toggleVisible}
				>
					<UserActivation
						userId={id}
						isPending={isPending}
						contacts={contacts}
						onClose={toggleVisible}
						actionAfterRequest={toUpdateUsers}
					/>
				</ModalNewProto>
			)}

			{isArchiveModal && (
				<ArchiveModal
					id={id}
					isActive={isActive && !isPending}
					isVisible={isArchiveModal}
					onClose={toggleArchiveModal}
					actionAfterRequest={toUpdateUsers}
				/>
			)}
		</>
	);
};
