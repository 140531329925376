import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { DepartmentUserInfo, ReorderedUnits } from '../departments/types';
import { CreateUserREQ, CreateUserRES, GetUsersREQ, GetUsersRES } from './types';

const NAME = `${ServiceName.COMPANY_STRUCTURE_SERVICE}/Users`;

const createUser = createAppAsyncThunk(`${NAME}/createUser`, async (arg: CreateUserREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.postRequest<CreateUserRES>({
		url: `${NAME}`,
		thunkAPI,
		params,
		action: () => createUser(arg),
	});
});

const getUsers = createAppAsyncThunk(`${NAME}/getUsers`, async (arg: GetUsersREQ, thunkAPI) => {
	return await apiRequest.getRequest<GetUsersRES>({
		url: `${NAME}`,
		thunkAPI,
		action: () => getUsers(arg),
	});
});

interface State {
	users: DepartmentUserInfo[];
	reorderedAdministrators: ReorderedUnits;
	status: RequestStatus;
}

export const initialState: State = {
	users: [],
	reorderedAdministrators: {},
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		addReorderedAdministrators: (state, action: PayloadAction<ReorderedUnits>) => {
			state.reorderedAdministrators = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(createUser.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createUser.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(createUser.rejected, state => {
			state.status = RequestStatus.failed;
		});
		builder.addCase(getUsers.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getUsers.fulfilled, (state, action) => {
			state.users = action.payload.body;
			state.status = RequestStatus.still;
		});
		builder.addCase(getUsers.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	createUser,
	getUsers,
};
