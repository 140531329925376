import cn from 'classnames';
import { FC } from 'react';
import { CommunicationType } from '@/app/redux/queries/user-service/types/types';
import s from './UserContact.module.scss';

interface Props {
	type: CommunicationType | number | string;
	value: string;
	font?: 'body1' | 'body2';
}

export const UserContact: FC<Props> = props => {
	const { type, value, font = 'body1' } = props;

	const getHref = () => {
		switch (type) {
			case 'Email':
			case 0:
				return `mailto:${value}`;

			case 'Telegram':
			case 1:
				return `https://t.me/${value.startsWith('@') ? value.substring(1) : value.substring(0)}`;

			case 'Skype':
			case 3:
				return `Skype:${value}?chat`;

			case 'BaseEmail':
			case 5:
				return `mailto:${value}`;

			default:
				return '';
		}
	};

	const getNumberFormat = () => {
		const maskNumber = new RegExp(/^(\+7|7|8)?[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/);

		if (value.match(maskNumber)) {
			return value.replace(maskNumber, '+7($2)$3-$4-$5');
		}

		return value;
	};

	// * Render
	return (
		<>
			{type === 'Phone' ? (
				<span className={s[font]}>{getNumberFormat()}</span>
			) : (
				<a
					className={cn(s[font], s.link)}
					href={getHref()}
					target="_blank"
					rel="noreferrer"
				>
					{value}
				</a>
			)}
		</>
	);
};

/*
	с бэка может приходить не только строка с типом контакта, но и число
	enum:
		0 -  Email,
  	1 - Telegram,
  	2 - Phone,
  	3 - Skype,
  	4 - Twitter,
  	5 - BaseEmail
*/
