import { VacationInfoFormattedDate } from '../../ui/VacationTable/ui/Table/types';

export const getVacationOverlaps = (vacation: VacationInfoFormattedDate, vacations: VacationInfoFormattedDate[]) => {
	return vacations.filter(v => {
		const vStart = v.startDateLuxon;
		const vEnd = v.endDateLuxon;
		const vacationStart = vacation.startDateLuxon;
		const vacationEnd = vacation.endDateLuxon;

		return vacationStart <= vEnd && vacationEnd >= vStart;
	});
};
