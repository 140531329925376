import { GalleyFile } from '@/shared/ui/_galleries/Gallery/_types';

export const galleyFileToBase64 = (file: GalleyFile) =>
	new Promise<{ id: string; name: string; base64: string }>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () =>
			resolve({
				id: file.id,
				name: file.name,
				base64: reader.result as string,
			});
		reader.onerror = error => reject(error);
	});
