import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { WorkSpaceInfo } from '../workspace/types';
import { CreateRoomREQ, EditRoomREQ, FindRoomsREQ, FindRoomsRES, GetRoomREQ, GetRoomRES, Room } from './types';

const NAME = `${ServiceName.OFFICE_SERVICE}/room`;

const createRoom = createAppAsyncThunk(`${NAME}/createRoom`, async (arg: CreateRoomREQ, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postRequest<string>({
		url: `${NAME}/create`,
		payload,
		thunkAPI,
		action: () => createRoom(arg),
	});
});

const findRooms = createAppAsyncThunk(`${NAME}/findRooms`, async (arg: FindRoomsREQ, thunkAPI) => {
	const { params } = arg;
	const { signal } = thunkAPI;

	return await apiRequest.getRequest<FindRoomsRES>({
		url: `${NAME}/find`,
		params,
		thunkAPI,
		action: () => findRooms(arg),
		signal,
	});
});

const getRoom = createAppAsyncThunk(`${NAME}/getRoom`, async (arg: GetRoomREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<GetRoomRES>({
		url: `${NAME}/get`,
		params,
		thunkAPI,
		action: () => getRoom(arg),
	});
});

const editRoom = createAppAsyncThunk(`${NAME}/editRoom`, async (arg: EditRoomREQ, thunkAPI) => {
	const { params, payload } = arg;

	return await apiRequest.patchRequest<string>({
		url: `${NAME}/edit`,
		params,
		payload,
		thunkAPI,
		action: () => editRoom(arg),
	});
});

// * Reducer
export interface State {
	rooms: Room[];
	activeRoom: Room | null;
	activeRoomWorkSpaces: WorkSpaceInfo[];
	totalCount: number;
	status: RequestStatus;
}

export const initialState: State = {
	rooms: [],
	activeRoom: null,
	activeRoomWorkSpaces: [],
	totalCount: 0,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setStatus: (state, action: PayloadAction<RequestStatus>) => {
			state.status = action.payload;
		},
		clearActiveRoom: state => {
			state.activeRoom = null;
		},
	},
	extraReducers: builder => {
		builder.addCase(createRoom.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createRoom.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(createRoom.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(findRooms.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findRooms.fulfilled, (state, action) => {
			state.rooms = action.payload.body;
			state.totalCount = action.payload.totalCount;
			state.status = RequestStatus.success;
		});
		builder.addCase(findRooms.rejected, state => {
			state.status = RequestStatus.still;
		});

		builder.addCase(getRoom.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getRoom.fulfilled, (state, action) => {
			state.activeRoom = action.payload.room;
			state.activeRoomWorkSpaces = action.payload.workspaces;
			state.status = RequestStatus.success;
		});
		builder.addCase(getRoom.rejected, state => {
			state.status = RequestStatus.still;
		});

		builder.addCase(editRoom.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editRoom.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(editRoom.rejected, state => {
			state.status = RequestStatus.still;
		});
	},
});

export const asyncActions = {
	createRoom,
	findRooms,
	getRoom,
	editRoom,
};
