import cn from 'classnames';
import React, { useState } from 'react';
import { CategoryInfo } from '@/app/redux/queries/survey-service/types';
import { ColorCircle } from '@/entities/ColorCircle/ui/ColorCircle';
import { ModalContent } from '@/pages/_surveys/components/ModalContent/ModalContent';
import { FormValues, NewCategoryMC } from '@/pages/_surveys/pages/SurveyPage/Content/TemplateContent/NewCategoryMC/NewCategoryMC';
import DeleteSVG from '@/shared/assets/svg/action/delete.svg?react';
import EditSVG from '@/shared/assets/svg/action/edit.svg?react';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import s from './Category.module.scss';

interface Props {
	categories: CategoryInfo[];
	category: CategoryInfo;

	getCategoryInfo: (value: FormValues) => void;
	getCategoryId: (id: string) => void;

	isLoading: boolean;
	setCategoriesIds: React.Dispatch<React.SetStateAction<string[]>> | undefined;
}

export const Category: React.FC<Props> = props => {
	const {
		categories,
		category,

		getCategoryInfo,
		getCategoryId,

		isLoading,
		setCategoriesIds,
	} = props;

	const { color, name } = category;

	const isFilter = !!setCategoriesIds;
	const [isClicked, setIsClicked] = useState(false);
	const handleClicked = () => setIsClicked(prev => !prev);

	// * User Actions
	const [newCategoryModal, setNewCategoryModal] = useState(false);
	const toggleModal = () => setNewCategoryModal(prevState => !prevState);

	const onEdit = (values: FormValues) => {
		toggleModal();
		getCategoryInfo(values);
	};

	// * Delete
	const subtitle = `Вы действительно хотите удалить категорию\n "${category.name}"?`;

	const [deleteModal, setDeleteModal] = useState(false);
	const toggleDeleteModal = () => setDeleteModal(prevState => !prevState);

	const onDelete = () => {
		getCategoryId(category.id);
	};

	// * Click for filter
	const handleClick = (id: string) => {
		if (isFilter) {
			setCategoriesIds(prevState => {
				if (prevState.includes(id)) return prevState.filter(prevId => prevId !== id);
				return [...prevState, id];
			});
			handleClicked();
		}
	};

	// * Render
	return (
		<>
			<ModalNewProto
				isOpen={newCategoryModal}
				onClose={toggleModal}
				width="s"
			>
				<NewCategoryMC
					toggleModal={toggleModal}
					title="Редактирование категории"
					saveButtonTitle="Сохранить"
					onSave={onEdit}
					categories={categories}
					isLoading={isLoading}
					category={category}
				/>
			</ModalNewProto>

			<ModalNewProto
				isOpen={deleteModal}
				onClose={toggleDeleteModal}
				width="s"
			>
				<ModalContent
					title="Удалить категорию"
					subtitle={subtitle}
					buttonText="Удалить"
					isLoading={isLoading}
					action={onDelete}
					onClose={toggleDeleteModal}
				/>
			</ModalNewProto>

			<div
				className={cn(s.container, { [s.clicked]: isClicked })}
				onClick={() => isFilter && handleClick(category.id)}
			>
				<div className={s.name}>
					<ColorCircle color={color} />
					<span>{name}</span>
				</div>

				<div className={cn(s.buttons, { [s.clicked_buttons]: isClicked })}>
					<IconButton
						Icon={<EditSVG />}
						onClick={toggleModal}
					/>

					<IconButton
						Icon={<DeleteSVG />}
						onClick={toggleDeleteModal}
					/>
				</div>
			</div>
		</>
	);
};
