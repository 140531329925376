import { FC } from 'react';
import UnCheckedSVG from '@/shared/assets/svg/action/close.svg?react';
import CheckedSVG from '@/shared/assets/svg/checked.svg?react';
import s from './InputTooltip.module.scss';

interface Props {
	rules: Array<{
		name: string;
		checked: boolean;
	}>;
}

export const InputTooltip: FC<Props> = props => {
	const { rules } = props;

	return (
		<div className={s.container}>
			{rules.map(item => (
				<div
					key={item.name}
					className={s.rule}
				>
					{item.checked ? <CheckedSVG className={s.checked} /> : <UnCheckedSVG className={s.unchecked} />}
					<span>{item.name}</span>
				</div>
			))}
		</div>
	);
};
