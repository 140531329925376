import { FC } from 'react';
import AddSVG from '@/shared/assets/svg_icons/action/add.svg?react';
import EditSVG from '@/shared/assets/svg_icons/action/edit.svg?react';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import { ArchiveButton } from '../ArchiveButton/ArchiveButton';
import { RubricEditButton } from '../RubricEditButton/RubricEditButton';
import s from './EditButtons.module.scss';

type Type = 'article' | 'sub-rubric' | 'rubric';

interface Props {
	id?: string; // for archive action
	addAction?: () => void;
	editAction?: () => void;
	hasEditAction?: boolean;
	isPublic?: boolean;

	name?: string;
	isActive?: boolean;
	type?: Type;
}

export const EditButtons: FC<Props> = props => {
	const {
		id, //
		addAction,
		editAction,
		hasEditAction,
		isPublic,

		name = '',
		isActive,
		type,
	} = props;

	const access = isPublic ? 1 : 0;

	// * Render
	return (
		<div className={s.edit_actions}>
			{addAction && (
				<IconButton
					Icon={<AddSVG />}
					onClick={addAction}
				/>
			)}

			{id && hasEditAction && (
				<RubricEditButton
					id={id}
					name={name}
					access={access}
				/>
			)}

			{isActive && editAction && (
				<IconButton
					Icon={<EditSVG />}
					onClick={editAction}
				/>
			)}

			{id && type && (
				<ArchiveButton
					id={id}
					name={name}
					isActive={!!isActive}
					type={type}
				/>
			)}
		</div>
	);
};
