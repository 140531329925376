import { DepartmentUserAssignment, DepartmentUserRole } from '@/app/redux/queries/department-service/types/types';

export const departmentRoleToRussian = (role: DepartmentUserRole, assignment: DepartmentUserAssignment) => {
	if (assignment === 'Director') {
		return 'Директор департамента';
	} else if (role === 'Manager') {
		return 'Менеджер департамента';
	} else {
		return 'Сотрудник департамента';
	}
};
