import { CommunicationType } from '@/app/redux/queries/user-service/types/types';

export const getContactType = (type: number): CommunicationType => {
	switch (type) {
		case 0:
			return 'Email';
		case 1:
			return 'Telegram';
		case 2:
			return 'Phone';
		case 3:
			return 'Skype';
		case 4:
			return 'Twitter';
		case 5:
			return 'BaseEmail';
		default:
			return 'Email';
	}
};

/*
	с бэка может приходить не только строка с типом контакта, но и число
	enum:
		0 -  Email,
  	1 - Telegram,
  	2 - Phone,
  	3 - Skype,
  	4 - Twitter,
  	5 - BaseEmail
*/
