import cn from 'classnames';
import { routerPath } from '@/app/router/paths';
import CloseSVG from '@/shared/assets/svg_icons/action/close.svg?react';
import { Avatar } from '@/shared/ui/Avatar/Avatar';
import { LoaderCircle } from '@/shared/ui/_loaders/LoaderCircle/LoaderCircle';
import { CardsTable } from '@/shared/ui/_tables/CardsTable';
import s from './UsersList.module.scss';
import { COLUMNS } from './consts/consts';
import { UserListItem } from '@/features/_card_lists/types';
import { ComponentPropsWithoutRef } from 'react';

interface Props extends ComponentPropsWithoutRef<'div'> {
	users: UserListItem[];
	onRemoveUser: (userId: string) => void;
	isLoading?: boolean;
}

export const UsersList: React.FC<Props> = props => {
	const {
		users, //
		onRemoveUser,
		isLoading,
		className,
		...restProps
	} = props;

	const tableData = users.map(user => ({
		id: user.id,
		avatar: (
			<Avatar
				userAvatar={user.avatar}
				name={user.name}
			/>
		),
		user: {
			name: user.name,
			position: user.position ?? '',
		},
		status: user.status ?? '',
		extra: (
			<>
				{isLoading ? (
					<LoaderCircle />
				) : (
					<CloseSVG
						className={s.removeButton}
						onClick={event => {
							event.stopPropagation();
							onRemoveUser(user.id || '');
						}}
					/>
				)}
			</>
		),
	}));

	const onUserClick = (userId: string) => {
		const userUrl = `${routerPath.users.page}/${userId}`;
		window.open(userUrl, '_blank', 'noopener,noreferrer');
	};

	return (
		<CardsTable
			{...restProps}
			className={cn(s.table, className)}
			data={tableData}
			columns={COLUMNS}
			onRowClick={rowId => onUserClick(tableData[rowId].id)}
			sortingColumnId="user"
		/>
	);
};
