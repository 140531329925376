import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import React from 'react';
import { getGridColumns } from '@/pages/_surveys/libs/getGridColumns/getGridColumns';
import { getGridRows } from '@/pages/_surveys/libs/getGridRows/getGridRows';
import { v4 } from 'uuid';
import { Button } from '@/shared/ui/_buttons/Button';
import { QuestionItem, SectionProps } from '../../../_types';
import { DndChoiceGrid } from '../../lib/dNd/DndChoiceGrid';
import s from './ChoiceGrid.module.scss';
import { ChoiceGridOptionColumn } from './ChoiceGridOptionColumn';
import { ChoiceGridOptionRow } from './ChoiceGridOptionRow';

interface Props {
	question: QuestionItem;
	indexQuestion: number;

	sections: SectionProps[];
	setSections: (sections: SectionProps[]) => void;
	indexSection: number;

	isEdit?: boolean;
}

export const ChoiceGrid: React.FC<Props> = props => {
	const {
		question,
		indexQuestion,

		sections,
		setSections,
		indexSection,

		isEdit,
	} = props;

	const { gridOptions } = question;

	const hasMultipleChoice = question.hasMultipleChoice;

	// * Columns
	const allColumns = getGridColumns(gridOptions);

	// * Rows
	const allRows = getGridRows(gridOptions);

	const addNewRow = (indexSection: number, indexQuestion: number) => {
		const nextRowNumber = allRows.length + 1;
		const newRow = `Строка ${nextRowNumber}`;

		const preparedSections: SectionProps[] = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;
					const { gridOptions } = question;

					const newGridOptions = allColumns.reduce(
						(acc, column) => {
							return [
								...acc,
								{
									id: `new_${v4()}`,
									columnSignature: column.columnSignature,
									rowSignature: newRow,
								},
							];
						},
						[...gridOptions],
					);

					return {
						...question,
						gridOptions: [...newGridOptions],
					};
				}),
			};
		});

		setSections([...preparedSections]);
	};

	const removeRow = (indexSection: number, indexQuestion: number, rowValue: string) => {
		const preparedSections: SectionProps[] = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;
					const { gridOptions } = question;

					// Удаляем строку. Если есть дефолтные значения, меняем у них номер строки
					const cleanedGridOptions = gridOptions
						.filter(opt => opt.rowSignature !== rowValue)
						.map((opt, i) => {
							if (!/^Строка \d*$/.test(opt.rowSignature)) return { ...opt };

							const newRows = allRows.filter(item => item.rowSignature !== rowValue).map(item => item.rowSignature);
							const newNum = newRows.indexOf(opt.rowSignature);

							return {
								...opt,
								rowSignature: `Строка ${newNum + 1}`,
							};
						});

					return {
						...question,
						gridOptions: [...cleanedGridOptions],
					};
				}),
			};
		});

		setSections([...preparedSections]);
	};

	const onRowSignatureChange = (value: string, prevValue: string, indexSection: number, indexQuestion: number) => {
		const preparedSections: SectionProps[] = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;

					const { gridOptions } = question;

					const newGridOptions = gridOptions.map(opt => {
						if (opt.rowSignature !== prevValue) return opt;

						return {
							id: opt.id,
							columnSignature: opt.columnSignature,
							rowSignature: value,
						};
					});

					return {
						...question,
						gridOptions: [...newGridOptions],
					};
				}),
			};
		});

		setSections([...preparedSections]);
	};

	const addNewColumn = (indexSection: number, indexQuestion: number) => {
		const nextColumnNumber = allColumns.length + 1;
		const newColumn = `Столбец ${nextColumnNumber}`;

		const preparedSections: SectionProps[] = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;
					const { gridOptions } = question;

					const newGridOptions = allRows.reduce(
						(acc, row) => {
							return [
								...acc,
								{
									id: `new_${v4()}`,
									columnSignature: newColumn,
									rowSignature: row.rowSignature,
								},
							];
						},
						[...gridOptions],
					);

					return {
						...question,
						gridOptions: [...newGridOptions],
					};
				}),
			};
		});

		setSections([...preparedSections]);
	};

	const onColumnSignatureChange = (value: string, prevValue: string, indexSection: number, indexQuestion: number) => {
		const preparedSections: SectionProps[] = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;
					const { gridOptions } = question;

					const newGridOptions = gridOptions.map(opt => {
						if (opt.columnSignature !== prevValue) return opt;

						return {
							id: opt.id,
							columnSignature: value,
							rowSignature: opt.rowSignature,
						};
					});

					return {
						...question,
						gridOptions: [...newGridOptions],
					};
				}),
			};
		});

		setSections([...preparedSections]);
	};

	const removeColumn = (indexSection: number, indexQuestion: number, columnValue: string) => {
		const preparedSections: SectionProps[] = sections.map((section, i) => {
			if (i !== indexSection) return section;

			return {
				...section,
				questions: section.questions.map((question, ind) => {
					if (ind !== indexQuestion) return question;
					const { gridOptions } = question;

					// Удаляем столбец. Если есть дефолтные значения, меняем у них номер столбца
					const cleanedGridOptions = gridOptions
						.filter(opt => opt.columnSignature !== columnValue)
						.map((opt, i) => {
							if (!/^Столбец \d*$/.test(opt.columnSignature)) return { ...opt };

							const newColumns = allColumns.filter(item => item.columnSignature !== columnValue).map(item => item.columnSignature);
							const newNum = newColumns.indexOf(opt.columnSignature);

							return {
								...opt,
								columnSignature: `Столбец ${newNum + 1}`,
							};
						});

					return {
						...question,
						gridOptions: [...cleanedGridOptions],
					};
				}),
			};
		});

		setSections([...preparedSections]);
	};

	// * Render
	return (
		<div className={s.container}>
			<div className={s.rows}>
				<DndChoiceGrid
					sections={sections}
					setSections={setSections}
					indexSection={indexSection}
					indexQuestion={indexQuestion}
					gridOptions={gridOptions}
				>
					{allRows.map((option, indexRow) => (
						<ChoiceGridOptionRow
							key={option.id}
							option={option}
							indexes={{
								indexSection,
								indexQuestion,
								indexRow,
							}}
							allRows={allRows}
							onRowSignatureChange={onRowSignatureChange}
							removeRow={removeRow}
							isEdit={isEdit}
						/>
					))}
				</DndChoiceGrid>

				{!isEdit && (
					<div className={s.rows_button}>
						<Button
							wide
							variant="secondary"
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								addNewRow(indexSection, indexQuestion);
							}}
						>
							Добавить строку
						</Button>
					</div>
				)}
			</div>

			<div className={s.columns}>
				<DndChoiceGrid
					sections={sections}
					setSections={setSections}
					indexSection={indexSection}
					indexQuestion={indexQuestion}
					gridOptions={gridOptions}
				>
					<SortableContext
						items={gridOptions}
						strategy={verticalListSortingStrategy}
					>
						{allColumns.map((option, indexColumn) => (
							<ChoiceGridOptionColumn
								key={option.id}
								option={option}
								indexes={{
									indexSection,
									indexQuestion,
									indexColumn,
								}}
								allColumns={allColumns}
								onColumnSignatureChange={onColumnSignatureChange}
								removeColumn={removeColumn}
								hasMultipleChoice={hasMultipleChoice}
								isEdit={isEdit}
							/>
						))}
					</SortableContext>
				</DndChoiceGrid>

				{!isEdit && (
					<div className={s.columns_button}>
						<Button
							wide
							variant="secondary"
							onClick={e => {
								e.preventDefault();
								e.stopPropagation();
								addNewColumn(indexSection, indexQuestion);
							}}
						>
							Добавить столбец
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};
