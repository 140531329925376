import { LazyQueryTrigger } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { BaseQueryFn, QueryDefinition } from '@reduxjs/toolkit/query';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { GetEventFindApiArg } from '@/app/redux/queries/event-service/types/reqTypes';
import { EventInfoFindResultResponse } from '@/app/redux/queries/event-service/types/resTypes';
import SearchSVG from '@/shared/assets/svg/action/search.svg?react';
import { SelectSingle } from '@/shared/ui/_inputs/selects/SelectSingle';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { getYears } from '../../../../lib/getYears/getYears';
import s from './FilterEvents.module.scss';

interface Props {
	filterEvents: LazyQueryTrigger<
		QueryDefinition<
			GetEventFindApiArg,
			BaseQueryFn,
			'Category' | 'Event' | 'EventCategory' | 'EventComment' | 'EventUser' | 'File' | 'Image' | 'UserBirthday',
			EventInfoFindResultResponse,
			'eventservice'
		>
	>;
	activeTabId: string;
	setIsLoading: (loading: boolean) => void;
	categoriesIds: string[];
	setShowAllMonths: React.Dispatch<React.SetStateAction<boolean>>;
	setIsCurrentYear: React.Dispatch<React.SetStateAction<boolean>>;
}

const CURRENT_DATE = new Date();
const CURRENT_YEAR = CURRENT_DATE.getFullYear();

export const FilterEvents: React.FC<Props> = props => {
	const {
		filterEvents, //
		activeTabId,
		setIsLoading,
		categoriesIds,
		setShowAllMonths,
		setIsCurrentYear,
	} = props;

	const years = getYears(activeTabId);
	const currentDateISO = CURRENT_DATE.toISOString();

	// * Filter
	const [name, setName] = useState('');
	const [year, setYear] = useState(years[0]);

	useEffect(() => {
		setName('');
		setYear(years[0]);
	}, [activeTabId]);

	// * Filter apply
	useEffect(() => {
		const selectedYear = year.id > 0 ? year.id : null;

		let startTime = selectedYear ? DateTime.fromObject({ year: selectedYear, month: 1, day: 1 }).startOf('day').toString() : '';
		let endTime = selectedYear ? DateTime.fromObject({ year: selectedYear, month: 12, day: 31 }).startOf('day').toString() : '';

		if (activeTabId === 'events') {
			if (selectedYear) {
				startTime = selectedYear === CURRENT_YEAR ? currentDateISO : DateTime.fromObject({ year: selectedYear, month: 1, day: 1 }).startOf('day').toString();
				endTime = DateTime.fromObject({ year: selectedYear, month: 12, day: 31 }).endOf('day').toString();
			} else {
				startTime = currentDateISO;
			}
		} else if (activeTabId === 'past') {
			if (selectedYear) {
				startTime = DateTime.fromObject({ year: selectedYear, month: 1, day: 1 }).startOf('day').toString();
				endTime = selectedYear === CURRENT_YEAR ? currentDateISO : DateTime.fromObject({ year: selectedYear, month: 12, day: 31 }).endOf('day').toString();
			} else {
				endTime = currentDateISO;
			}
		}

		setShowAllMonths(!(name || categoriesIds.length > 0));
		setIsCurrentYear(selectedYear === CURRENT_YEAR);
		setIsLoading(true);

		filterEvents(
			{
				skipcount: 0,
				takecount: 1000,
				access: 'Opened',
				...(activeTabId === 'archive' && { includeDeactivated: true }),
				...(name && { nameIncludeSubstring: name.toLocaleLowerCase() }),
				...(startTime && { startTime }),
				...(endTime && { endTime }),
				...(categoriesIds.length > 0 && { categoriesIds }),
			},
			true,
		).finally(() => {
			setIsLoading(false);
		});
	}, [name, year, activeTabId, categoriesIds]);

	// * Render
	return (
		<div className={s.wrapper}>
			<SelectSingle
				placeholder="Год"
				keyNames={{
					name: 'name',
					value: 'id',
				}}
				selectedOption={year}
				setSelectedOption={selected => setYear({ name: selected.name, id: +selected.id })}
				options={years}
			/>
			<TextInput
				value={name}
				onChange={e => setName(e.target.value)}
				placeholder="Поиск по названию и описанию"
				RightIcon={<SearchSVG />}
			/>
		</div>
	);
};
