import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { ArticleData } from './types';

const NAME = `${ServiceName.WIKI_SERVICE}/common`;

const isVisited = localStorage.getItem('wikiVisited');

// * Reducer
interface State {
	searchMode: boolean;
	archiveMode: boolean;

	editModeWiki: boolean;
	editModeArticle: boolean;

	activeRubricId: string | null;
	activeSubRubricData: ArticleData | null;

	status: RequestStatus;

	isVisited: boolean;
}

export const initialState: State = {
	searchMode: false,
	archiveMode: false,

	editModeWiki: false,
	editModeArticle: false,

	activeRubricId: null,
	activeSubRubricData: null,

	status: RequestStatus.still,

	isVisited: !!isVisited,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setSearchMode: (state, action: PayloadAction<boolean>) => {
			state.searchMode = action.payload;
		},
		setArchiveMode: (state, action: PayloadAction<boolean>) => {
			state.archiveMode = action.payload;
		},
		setEditModeWiki: (state, action: PayloadAction<boolean>) => {
			state.editModeWiki = action.payload;
		},
		setEditModeArticle: (state, action: PayloadAction<boolean>) => {
			state.editModeArticle = action.payload;
		},
		setActiveRubricId: (state, action: PayloadAction<string | null>) => {
			state.activeRubricId = action.payload;
		},
		setActiveSubRubricData: (state, action: PayloadAction<ArticleData | null>) => {
			state.activeSubRubricData = action.payload;
		},
		setVisited: state => {
			state.isVisited = true;
		},
	},
});

export const actionsWiki = slice.actions;
