import { DateTime } from 'luxon';
import { dateToTimeString, isoStringToTuple, tupleToIsoString } from '@/shared/lib/date';
import { Hours, Minutes } from '@/shared/lib/date/types';

// * Минимальное возможное время в зависимости от выбранной даты и времени.
// TODO: Сделать startTime не обязательным значением. В таком случае время брать из selectedDate.
// Функция возвращает начальное значение времени в зависимости от нажатой ячейки, уже забронированного времени и ограничений. Формат startTime 00:00:00
export const getInitialTime = (selectedDate: Date, startTime: string, excludedTimes?: Date[]) => {
	const currentDate = DateTime.fromJSDate(new Date()).toJSDate();
	const startTimeValue = new Date(selectedDate).setHours(+startTime.split(':')[0], +startTime.split(':')[1], 0, 0);
	const initialTime = startTimeValue > currentDate.getTime() ? startTime : dateToTimeString(currentDate);

	// Учитываем уже забронированное время
	if (excludedTimes && excludedTimes.find(excludedTime => excludedTime.toLocaleTimeString('sv') === initialTime)) {
		let freeTime = isoStringToTuple(initialTime);

		excludedTimes.forEach(time => {
			const tempTime = isoStringToTuple(time.toLocaleTimeString('sv'));
			if (tempTime[0] === freeTime[0] && tempTime[1] === freeTime[1]) {
				if (tempTime[1] === 59) {
					freeTime = [(freeTime[0] + 1) as Hours, 0];
				} else {
					freeTime = [freeTime[0], (freeTime[1] + 1) as Minutes];
				}
			}
		});

		return tupleToIsoString(freeTime);
	}

	return initialTime;
};
