import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReAuth } from '@/app/api/apiSlice';
import { ServiceName } from '@/app/api/api_types';
import {
	PostEducationCreateApiArg,
	PatchEducationEditApiArg,
	DeleteEducationRemoveApiArg,
	PostEducationFormCreateApiArg,
	GetEducationFormGetApiArg,
	PostEducationTypeCreateApiArg,
	GetEducationTypeGetApiArg,
	PostImageCreateApiArg,
	DeleteImageRemoveApiArg,
	PostUniversityCreateApiArg,
	GetUniversityFindApiArg,
	GetUserFindApiArg,
} from './types/regTypes';
import {
	PostEducationCreateApiResponse,
	PatchEducationEditApiResponse,
	DeleteEducationRemoveApiResponse,
	PostEducationFormCreateApiResponse,
	GetEducationFormGetApiResponse,
	PostEducationTypeCreateApiResponse,
	GetEducationTypeGetApiResponse,
	PostImageCreateApiResponse,
	DeleteImageRemoveApiResponse,
	PostUniversityCreateApiResponse,
	GetUniversityFindApiResponse,
	GetUserFindApiResponse,
} from './types/resTypes';

export const reducerPath = `${ServiceName.EDUCATION_SERVICE}` as const;

export const education_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: [
		'Education', //
		'EducationForm',
		'EducationType',
		'Image',
		'University',
		'User',
	] as const,
	endpoints: build => ({
		postEducationCreate: build.mutation<PostEducationCreateApiResponse, PostEducationCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Education/create`,
				method: 'POST',
				body: queryArg.createEducationRequest,
			}),
			invalidatesTags: ['Education', 'User'],
		}),
		patchEducationEdit: build.mutation<PatchEducationEditApiResponse, PatchEducationEditApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Education/edit`,
				method: 'PATCH',
				body: queryArg.body,
				params: { educationId: queryArg.educationId },
			}),
			invalidatesTags: ['Education'],
		}),
		deleteEducationRemove: build.mutation<DeleteEducationRemoveApiResponse, DeleteEducationRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Education/remove`,
				method: 'DELETE',
				params: { educationId: queryArg.educationId },
			}),
			invalidatesTags: ['Education'],
		}),
		postEducationFormCreate: build.mutation<PostEducationFormCreateApiResponse, PostEducationFormCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/EducationForm/create`,
				method: 'POST',
				body: queryArg.createEducationFormRequest,
			}),
			invalidatesTags: ['EducationForm'],
		}),
		getEducationFormGet: build.query<GetEducationFormGetApiResponse, GetEducationFormGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/EducationForm/get`,
			}),
			providesTags: ['EducationForm'],
		}),
		postEducationTypeCreate: build.mutation<PostEducationTypeCreateApiResponse, PostEducationTypeCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/EducationType/create`,
				method: 'POST',
				body: queryArg.createEducationTypeRequest,
			}),
			invalidatesTags: ['EducationType'],
		}),
		getEducationTypeGet: build.query<GetEducationTypeGetApiResponse, GetEducationTypeGetApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/EducationType/get`,
			}),
			providesTags: ['EducationType'],
		}),
		postImageCreate: build.mutation<PostImageCreateApiResponse, PostImageCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Image/create`,
				method: 'POST',
				body: queryArg.createImagesRequest,
			}),
			invalidatesTags: ['Image', 'Education'],
		}),
		deleteImageRemove: build.mutation<DeleteImageRemoveApiResponse, DeleteImageRemoveApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/Image/remove`,
				method: 'DELETE',
				body: queryArg.removeImagesRequest,
			}),
			invalidatesTags: ['Image', 'Education'],
		}),
		postUniversityCreate: build.mutation<PostUniversityCreateApiResponse, PostUniversityCreateApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/University/create`,
				method: 'POST',
				body: queryArg.createUniversityRequest,
			}),
			invalidatesTags: ['University'],
		}),
		getUniversityFind: build.query<GetUniversityFindApiResponse, GetUniversityFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/University/find`,
				params: { nameIncludeSubstring: queryArg.nameIncludeSubstring, skipcount: queryArg.skipcount, takecount: queryArg.takecount },
			}),
			providesTags: ['University'],
		}),
		getUserFind: build.query<GetUserFindApiResponse, GetUserFindApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/User/find`,
				params: {
					userid: queryArg.userid,
					educationformid: queryArg.educationformid,
					educationtypeid: queryArg.educationtypeid,
					completeness: queryArg.completeness,
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
				},
			}),
			providesTags: ['User', 'Education'],
		}),
	}),
});

export const {
	usePostEducationCreateMutation,
	usePatchEducationEditMutation,
	useDeleteEducationRemoveMutation,
	usePostEducationFormCreateMutation,
	useGetEducationFormGetQuery,
	usePostEducationTypeCreateMutation,
	useGetEducationTypeGetQuery,
	usePostImageCreateMutation,
	useDeleteImageRemoveMutation,
	usePostUniversityCreateMutation,
	useGetUniversityFindQuery,
	useGetUserFindQuery,
} = education_serviceAPI;
