import React from 'react';
import { useGetDepartmentFindQuery } from '@/app/redux/queries/department-service/department_serviceAPI';
import { project_serviceAPI } from '@/app/redux/queries/project-service/project_serviceAPI';
import { useGetUserFindQuery } from '@/app/redux/queries/user-service/user_serviceAPI';
import { AddUsersMC } from '@/features/_modal_contents/AddUsersMC';

interface Props {
	projectId: string;
	userIds: string[];
	onCloseModal: () => void;
}

export const UserAddContent: React.FC<Props> = props => {
	const { projectId, userIds, onCloseModal } = props;

	// * API
	const [createProjectUsers, { isLoading: isCreateLoading }] = project_serviceAPI.useCreateProjectUsersMutation();

	const { data: usersListData, isLoading: isLoadingUsersList } = useGetUserFindQuery({
		skipcount: 0,
		takecount: 2000,
		includepositions: true,
		includedepartments: true,
		isactive: true,
		includecurrentavatar: true,
		isascendingsort: true,
	});
	const usersList = usersListData?.body ?? [];

	const { data: departmentData, isLoading: isLoadingDepartments } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});

	const allDepartments = departmentData?.body ?? [];

	// * All users
	const allUsers =
		usersList.map(user => {
			return {
				id: user.user.id,
				name: `${user.user.lastName} ${user.user.firstName}`,
				position: user.positionUser?.name ?? '',
				department: user.departmentUser ? user.departmentUser.department.name : '',
				avatar: user.user?.avatar,
			};
		}) || [];

	const nonOfficeUsers = allUsers?.filter(item => {
		if (userIds?.find(id => id === item.id)) {
			return;
		}
		return item;
	});

	// * Conditions
	const isLoading = isLoadingDepartments || isCreateLoading || isLoadingUsersList;

	const onAddUser = (ids: string[]) => {
		if (ids.length === 0) {
			onCloseModal();
			return;
		}

		createProjectUsers({
			createProjectUsersRequest: {
				projectId,
				users: ids.map(id => ({ userId: id, role: 'Employee' })),
			},
		})
			.unwrap()
			.then(() => onCloseModal())
			.catch(err => console.log('Error: ', err));
	};

	// * Render
	return (
		<AddUsersMC
			title="Добавить участника" // Done
			allUsers={nonOfficeUsers}
			allDepartments={allDepartments}
			setSelectedUsers={onAddUser}
			isLoading={isLoading} // Мы сохраняем в Редакс, загрузки нет, но показывается загрузка departmentTree
			toggleModal={onCloseModal}
		/>
	);
};
