import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { CreateFileREQ, GetFilesREQ } from './types';

const NAME = `${ServiceName.FILE_SERVICE}/file`;

// * Async thunks
const createFiles = createAppAsyncThunk(`${NAME}/createFiles`, async (arg: CreateFileREQ, thunkAPI) => {
	const { params, payload } = arg;

	await apiRequest.postFormRequest({
		url: `${NAME}/create`,
		params,
		payload,
		thunkAPI,
		action: () => createFiles(arg),
	});
});

const getFiles = createAppAsyncThunk(`${NAME}/getFiles`, async (arg: GetFilesREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<any[]>({
		url: `${NAME}/get`,
		params,
		thunkAPI,
		action: () => getFiles(arg),
		options: {
			noContentType: true,
		},
	});
});

// * Reducer
export interface State {
	status: RequestStatus;
}

export const initialState: State = {
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(createFiles.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createFiles.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(createFiles.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getFiles.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getFiles.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(getFiles.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	createFiles,
	getFiles,
};
