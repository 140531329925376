import React from 'react';
import s from './DragDropContent.module.scss';
import { UnitCard } from '../UnitCard/UnitCard';
import { AddButton } from '@/shared/ui/_buttons/AddButton';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { UnitProps } from '../UnitsContent';

interface Props {
	addButton?: () => void;
	droppableId: string;
	arrayUnits: UnitProps[];
}

export const DragDropContent: React.FC<Props> = props => {
	const { addButton, droppableId, arrayUnits } = props;

	return (
		<Droppable droppableId={droppableId}>
			{provided => (
				<div
					className={s.wrapper_edit}
					{...provided.droppableProps}
					ref={provided.innerRef}
				>
					{addButton && (
						<AddButton
							title="Добавить организационную единицу"
							onClick={addButton}
						/>
					)}
					{arrayUnits.map((unit, index) => (
						<Draggable
							draggableId={unit.id}
							key={unit.id}
							index={index}
						>
							{provided => (
								<div
									className={s.unit_card}
									{...provided.dragHandleProps}
									{...provided.draggableProps}
									ref={provided.innerRef}
								>
									<UnitCard
										edit
										isCustom={unit.isCustom}
										id={unit.id}
										name={unit.name}
										fullname={unit.fullname}
										usersCount={unit.usersCount}
										description={unit.description || ''}
									/>
								</div>
							)}
						</Draggable>
					))}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);
};
