import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGetDepartmentFindQuery } from '@/app/redux/queries/department-service/department_serviceAPI';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';
import { ButtonPair } from '@/entities/_buttons/ButtonPair';
import { type Stage } from '@/pages/_claims/types';
import { AddButton } from '@/shared/ui/_buttons/AddButton';
import { Button } from '@/shared/ui/_buttons/Button/Button';
import { IconButton } from '@/shared/ui/_buttons/IconButton';
import { SelectSingleField } from '@/shared/ui/_fields/SelectSingleField';
import { StringField } from '@/shared/ui/_fields/StringField';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { Heading } from '@/shared/ui/Heading';
import * as yup from 'yup';
import AddSVG from '../../../../../../../../shared/assets/svg/action/add.svg?react';
import RemoveStageSVG from '../../../../../../../../shared/assets/svg/action/close.svg?react';
import s from './RouteTemplate.module.scss';

type FormData = {
	title: string;
	stages: Stage[];
};

const schema = yup.object().shape({
	title: yup.string().min(1, 'Минимум 1 символ').max(50, 'Максимум 50 символов').required('Обязательное поле'),
});

export const RouteTemplate = () => {
	const { claimId } = useParams();
	const [isRouteTemplateOpen, setIsRouteTemplateOpen] = useState(false);
	const formMethods = useForm<FormData>({
		defaultValues: {
			title: '',
			stages: [{ department: { id: '', name: '' }, user: { id: '', name: '' } }],
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, control, register, reset } = formMethods;
	const { fields, append, remove } = useFieldArray({
		name: 'stages',
		control: control,
	});

	// * Api
	const { data: departmentData } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
	});
	const departments = departmentData?.body ?? [];

	const departmentsAll = departments.map(department => ({
		id: department.id,
		name: department.shortName,
		fullName: department.name,
	}));

	const { data: usersData } = user_serviceAPI.useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		includedepartments: true,
		isactive: true,
	});

	// * Filter
	const users =
		usersData?.body
			?.filter(item => {
				return item.departmentUser?.role === 'Manager';
			})
			.map(item => {
				const { firstName, lastName, middleName, id } = item.user;
				return {
					id: id,
					name: `${firstName} ${middleName || ''} ${lastName}`,
				};
			}) || [];

	// * Add department
	const addDepartmentStage = () => {
		append({ department: { id: '', name: '' }, user: { id: '', name: '' } });
	};

	const toggleModalForm = () => {
		setIsRouteTemplateOpen(!isRouteTemplateOpen);
		reset();
	};

	// * OnSubmit
	const onSubmit = (data: FormData) => {
		// console.log(data);
	};

	// * Render
	return (
		<>
			{claimId ? (
				<Button
					wide
					variant={'secondary'}
					LeftIcon={<AddSVG />}
					className={s.button}
					onClick={toggleModalForm}
				>
					Добавить маршрут
				</Button>
			) : (
				<AddButton
					title={'Создать шаблон маршрута'}
					onClick={toggleModalForm}
				/>
			)}
			{isRouteTemplateOpen && (
				<ModalNewProto
					width="l"
					isOpen={isRouteTemplateOpen}
					onClose={() => toggleModalForm()}
				>
					<FormProvider {...formMethods}>
						<form
							className={s.container}
							onSubmit={handleSubmit(onSubmit)}
						>
							<Heading
								level={2}
								className={s.title}
							>
								Новый шаблон маршрута
							</Heading>
							<StringField
								name="title"
								label="Название"
								placeholder="Введите название шаблона"
							/>

							{fields.map((item, index) => {
								return (
									<div
										className={s.form_select}
										key={item.id}
									>
										<SelectSingleField
											placeholder="Департамент"
											options={departmentsAll}
											label={`Этап ${index + 1}`}
											keyNames={{ name: 'name', value: 'id', tooltipText: 'fullName' }}
											{...register(`stages.${index}.department`)}
										/>

										<div className={cn(s.form_select_bottom, { [s.form_select_inline]: index > 0 })}>
											<SelectSingleField
												placeholder="Выберите сотрудника"
												options={users}
												{...register(`stages.${index}.user`)}
											/>

											{index > 0 && (
												<IconButton
													Icon={<RemoveStageSVG />}
													onClick={() => remove(index)}
												/>
											)}
										</div>
									</div>
								);
							})}
							{fields.length < 3 && (
								<Button
									wide
									variant="secondary"
									LeftIcon={<AddSVG />}
									className={s.button_add}
									onClick={addDepartmentStage}
								>
									Добавить этап
								</Button>
							)}
							<ButtonPair
								primaryText="Создать"
								primaryIsLoading={false}
								secondaryText="Отменить"
								secondaryOnClick={() => toggleModalForm()}
								secondaryIsLoading={false}
							/>
						</form>
					</FormProvider>
				</ModalNewProto>
			)}
		</>
	);
};
