import { UseFormSetError } from 'react-hook-form';
import { rights_serviceAPI } from '@/app/redux/queries/rights-service/rights_serviceAPI';

export const useCreateRole = (
	onCloseModal: () => void, //
	setError: UseFormSetError<{ name: string; description: string; rights: string[] }>,
) => {
	const [createRole, { isLoading: isCreateLoading }] = rights_serviceAPI.useCreateRoleMutation();

	const onCreateSubmit = (data: { name: string; description: string; rights: string[] }) => {
		const { name, description, rights } = data;

		createRole({
			createRoleRequest: {
				localizations: [
					{
						locale: 'ru',
						name: name,
						description: description,
					},
				],
				rights: rights.map(right => Number(right)),
			},
		})
			.unwrap()
			.then(() => onCloseModal())
			.catch(err => {
				const errors = err?.data?.errors || [];
				errors.forEach((item: string) => {
					if (item.includes('Role')) {
						setError('name', { message: 'Роль с таким названием уже существует.' });
					}

					if (item.includes('rights')) {
						setError('rights', { message: 'Роль с таким набором прав уже существует.' });
					}
				});
			});
	};

	return { onCreateSubmit, isCreateLoading };
};
