import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { useGetDepartmentFindQuery } from '@/app/redux/queries/department-service/department_serviceAPI';
import { PageTitle } from '@/entities/PageTitle/PageTitle';
import { useRights } from '@/shared/hooks/useRights';
import { ConditionalRendering } from '@/shared/providers';
import { AddEditDepartmentButton } from '../../../department_page/ui/AddEditDepartmentButton/ui/AddEditDepartmentButton/AddEditDepartmentButton';
import { DepartmentsCardsList } from '../DepartmentsCardsList/DepartmentsCardsList';

export const DepartmentsPage = () => {
	// * Rights
	const departmentRightsOrAdmin = useRights(4);

	// * API
	const { data, isLoading } = useGetDepartmentFindQuery({
		skipcount: 0,
		takecount: 1000,
		isActive: departmentRightsOrAdmin ? undefined : true,
	});

	const departments = data?.body ?? [];

	// * Title buttons
	const buttons = [];

	if (departmentRightsOrAdmin) {
		buttons.push(<AddEditDepartmentButton key="0" />);
	}

	// * Render
	return (
		<PageDecorator>
			<PageTitle
				title="Департаменты"
				buttons={buttons}
			/>

			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={departments.length > 0}
				LoadedResult={<DepartmentsCardsList departments={departments} />}
			/>
		</PageDecorator>
	);
};
