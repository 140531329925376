import { AccessorKeyColumnDef, CellContext, createColumnHelper } from '@tanstack/react-table';
import { ExtraColumn } from './types';

const columnHelper = createColumnHelper<any>();

const getCellContext = (context: CellContext<any, unknown>) => {
	if (context.row.original.checked) {
		return {
			style: { backgroundColor: 'var(--color-bg-hover-50)' },
		};
	}
};

export const COLUMNS = [
	columnHelper.accessor('avatar', {
		header: '',
		cell: info => info.getValue(), // JSX элемент
		meta: {
			style: {
				width: '1%',
			},
			getCellContext,
		},
	}),

	columnHelper.accessor('name', {
		header: 'ФИО',
		cell: info => info.getValue(), // JSX элемент
		meta: {
			getCellContext,
		},
	}),
];

export const getColumns = (extraColumns: ExtraColumn[] = []): AccessorKeyColumnDef<any, any>[] => {
	return [
		...COLUMNS,
		...extraColumns.map(({ key, header }) =>
			columnHelper.accessor(key, {
				header,
				meta: {
					getCellContext,
				},
			}),
		),
	];
};
