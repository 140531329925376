import { FC } from 'react';
import { PageDecoratorHamburger } from '@/app/decorators/page_decorators/PageDecoratorHamburger/PageDecoratorHamburger';
import { Birthdays } from '../Birthdays/Birthdays';
import { Booking } from '../Booking/Booking';
import { TimeTracking } from '../TimeTracking/TimeTracking';
import { UsersSearch } from '../UsersSearch/UsersSearch';
import { Wiki } from '../Wiki/Wiki';
import s from './MainPage.module.scss';

export const MainPage: FC = () => {
	// * Render
	return (
		<PageDecoratorHamburger>
			<div className={s.container}>
				<div className={s.wrapper}>
					<TimeTracking />
					<Wiki />
				</div>

				<Booking />

				<div className={s.wrapper}>
					<Birthdays />
					<UsersSearch />
				</div>
			</div>
		</PageDecoratorHamburger>
	);
};
