import cn from 'classnames';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { company_structure_serviceAPI } from '@/app/redux/queries/company-structure-service/company_structure_serviceAPI';
import { DatePickerInput } from '@/shared/ui/_inputs/date_pickers/DatePickerInput';
import s from './UpdateInfo.module.scss';

interface Props {
	date: Date | null;
	isEdit: boolean;
	setDate: (date: Date) => void;
}

export const UpdateInfo: React.FC<Props> = props => {
	const { date, isEdit, setDate } = props;

	const { data, isLoading } = company_structure_serviceAPI.useGetSurveyStatisticsParametersQuery({
		ParameterName: 'ValidFor',
	});

	useEffect(() => {
		const dateFromString = data && DateTime.fromFormat(data, 'dd.LL.yyyy').toJSDate();

		dateFromString && setDate(dateFromString);
	}, [data]);

	return (
		<div className={s.container}>
			<div className={cn(s.text, !isEdit && s.default_color)}>Информация актуальна на</div>

			<div className={cn(s.date, !isEdit && s.default_color)}>
				{isEdit ? (
					<DatePickerInput
						selectedDate={date}
						setSelectedDate={newDate => setDate(newDate || new Date())}
					/>
				) : (
					date?.toLocaleDateString() || ' - '
				)}
			</div>
		</div>
	);
};
