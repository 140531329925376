import React, { ChangeEvent, DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react';
import { RubricData } from '@/app/redux/queries/wiki-service/types/types';
import { wiki_serviceAPI } from '@/app/redux/queries/wiki-service/wiki_serviceAPI';
import { ButtonPair } from '@/entities/_buttons/ButtonPair';
import { useCreateArticle } from '@/pages/_wiki/ui/pages/wiki/_hooks/useCreateArticle';
import { SelectSingle } from '@/shared/ui/_inputs/selects/SelectSingle';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { BooleanSwitch } from '@/shared/ui/_switches/BooleanSwitch/BooleanSwitch';
import { useNameErrors } from '../../../hooks/useNameErrors';
import s from './ModalContentCreateNew.module.scss';

type Props = DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
	initialRubricId?: string;
	createType?: 'article' | 'sub-rubric' | 'rubric';

	toggleModal: () => void;
};

export const ModalContentCreateNew: React.FC<Props> = props => {
	const { initialRubricId, toggleModal, createType } = props;

	// * API
	const { data: wikiData } = wiki_serviceAPI.useGetWikiQuery({
		includedeactivated: false,
	});

	const wikiTree = wikiData || [];

	const [createRubric, { isLoading }] = wiki_serviceAPI.useCreateRubricMutation();

	// * Rubric name
	const [rubricName, setRubricName] = useState<string>('');

	// * Is created rubric
	const [rubricCreated, setRubricCreated] = useState(false);

	// - initial
	const initialRubric = wikiTree.find(item => item.id === initialRubricId) || wikiTree[0];

	// * Menu
	const nullOption = { name: 'Не выбрано', id: null };

	const types = [
		{
			name: 'Рубрику' as const,
			id: 'rubric',
		},
		{
			name: 'Подрубрику' as const,
			id: 'sub-rubric',
		},
		{
			name: 'Статью' as const,
			id: 'article',
		},
	];

	// * Input states
	const [type, setType] = useState(types.find(item => item.id === createType) || types[0]);
	const [selectedRubric, setSelectedRubric] = useState<RubricData | typeof nullOption>(initialRubric ? initialRubric : nullOption);
	const [selectedSubRubric, setSelectedSubRubric] = useState<RubricData | typeof nullOption>(nullOption);
	const [isRubricPublish, setIsRubricPublish] = useState<boolean>(false); // ! Rubric only. Not required in sub-rubric.

	// * Error states
	const { nameErrors, handleNameErrors, clearErrors } = useNameErrors();

	useEffect(() => {
		clearErrors();
	}, [type]);

	// * on change handlers
	const onRubricNameChange = (event: ChangeEvent<HTMLInputElement>) => {
		const value = event.currentTarget.value;

		handleNameErrors(value);

		setRubricName(value);
	};

	// * Create
	const onCreateClick = () => {
		if (type.id === 'rubric') {
			createRubric({
				createRubricRequest: {
					name: rubricName,
					access: isRubricPublish ? 'Public' : 'Private',
				},
			})
				.unwrap()
				.then(() => setRubricCreated(true))
				.catch(err => console.log(err));
		}

		if (type.id === 'sub-rubric' && selectedRubric.id) {
			createRubric({
				createRubricRequest: {
					name: rubricName,
					parentId: selectedRubric.id,
				},
			})
				.unwrap()
				.then(() => setRubricCreated(true))
				.catch(err => console.log(err));
		}

		if (type.id === 'article') {
			toNewArticlePage();
		}
	};

	const addMore = () => {
		setRubricCreated(false);
		setRubricName('');
	};

	// - Create article
	const { toNewArticlePage } = useCreateArticle(selectedRubric.id, selectedSubRubric.id, null);

	// * Render
	return (
		<div className={s.container}>
			{rubricCreated ? (
				<>
					<div>
						<h3 className={s.title}>{type.id === 'rubric' ? 'Рубрика' : 'Подрубрика'} добавлена</h3>

						<div className={s.inner_success}>
							<p>
								Вы добавили {type.id === 'rubric' ? 'рубрику' : 'подрубрику'} <span className={s.inner_success__name}>{rubricName}</span>
							</p>
						</div>
					</div>

					<ButtonPair
						primaryText="Ок"
						primaryOnClick={toggleModal}
						primaryIsLoading={false}
						secondaryText="Добавить еще"
						secondaryOnClick={addMore}
						secondaryIsLoading={false}
					/>
				</>
			) : (
				<>
					<div>
						<h3 className={s.title}>Добавить в базу знаний</h3>

						<div className={s.inner}>
							<SelectSingle
								label="Что вы хотите добавить?"
								keyNames={{
									name: 'name',
									value: 'id',
								}}
								selectedOption={type}
								setSelectedOption={option => {
									setType(option);
									setSelectedSubRubric(nullOption);
									setRubricName('');
								}}
								options={types}
							/>

							{(type.id === 'sub-rubric' || type.id === 'article') && (
								<SelectSingle
									label="Выберите рубрику"
									keyNames={{
										name: 'name',
										value: 'id',
									}}
									selectedOption={selectedRubric}
									setSelectedOption={option => {
										if (!option.id) return;
										setSelectedRubric(option);
										setSelectedSubRubric(nullOption);
									}}
									options={[...wikiTree]}
								/>
							)}

							{type.id === 'article' && (
								<SelectSingle
									label="Выберите подрубрику"
									keyNames={{
										name: 'name',
										value: 'id',
									}}
									selectedOption={selectedSubRubric}
									setSelectedOption={option => setSelectedSubRubric(option)}
									options={selectedRubric.id ? [nullOption, ...selectedRubric.children] : [nullOption]}
								/>
							)}

							{(type.id === 'rubric' || type.id === 'sub-rubric') && (
								<TextInput
									label={type.id === 'rubric' ? 'Название рубрики' : 'Название подрубрики'}
									placeholder={type.id === 'rubric' ? 'Введите название рубрики' : 'Введите название подрубрики'}
									value={rubricName}
									onChange={onRubricNameChange}
									errorMessage={nameErrors}
								/>
							)}

							{type.id === 'rubric' && (
								<BooleanSwitch
									text="Публичная рубрика"
									checked={isRubricPublish}
									onChange={() => setIsRubricPublish(!isRubricPublish)}
								/>
							)}
						</div>
					</div>

					<ButtonPair
						primaryIsLoading={isLoading}
						primaryText="Добавить"
						primaryOnClick={onCreateClick}
						primaryDisabled={!!nameErrors || (type.id !== 'article' && rubricName === '')}
						secondaryIsLoading={isLoading}
						secondaryText="Отменить"
						secondaryOnClick={toggleModal}
					/>
				</>
			)}
		</div>
	);
};
