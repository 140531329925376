import { useParams, useSearchParams } from 'react-router-dom';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';
import { ConditionalRendering } from '@/shared/providers';
import { ContactsCard } from '../ContactsCard';
import { InfoCard } from '../InfoCard';
import s from './UserCardPage.module.scss';
import { useEffect } from 'react';
import { useAppDispatch } from '@/app/redux/utils';
import { actionsNotifications } from '@/features/notifications/_BLL/slice';

export const UserCardPage = () => {
	// * Routing
	const { userId } = useParams() as { userId: string };
	const [searchParams] = useSearchParams();

	// * Actions
	const dispatch = useAppDispatch();
	const { addNotification } = actionsNotifications;

	// * API
	const [confirmCommunication] = user_serviceAPI.usePutCommunicationConfirmMutation();
	const { data: userData, isLoading } = user_serviceAPI.useGetUserGetQuery(
		{
			userid: userId,
			includecurrentavatar: true,
			includecommunications: true,
			includecompany: true,
			includedepartment: true,
			includeoffice: true,
			includeposition: true,
			includerole: true,
			locale: 'ru',
		},
		{ skip: !userId },
	);

	// - Confirm communication
	useEffect(() => {
		const secret = searchParams.get('secret');
		const communicationId = searchParams.get('communicationId');

		if (secret && communicationId) {
			confirmCommunication({ communicationId, secret })
				.unwrap()
				.then(() => {
					dispatch(
						addNotification({
							type: 'success',
							message: 'Почта успешно подтверждена',
						}),
					);
				})
				.catch(() => {
					dispatch(
						addNotification({
							type: 'error',
							message: 'Почта не подтверждена',
						}),
					);
				});
		}
	}, [searchParams]);

	// - Data
	const userInfo = userData?.body;

	// * Render
	return (
		<PageDecorator>
			<ConditionalRendering
				initialLoading={isLoading}
				isSuccessful={!!userInfo}
				LoadedResult={
					userInfo && (
						<div className={s.container}>
							<ContactsCard user={userInfo.user} />
							<InfoCard userInfo={userInfo} />
						</div>
					)
				}
			/>
		</PageDecorator>
	);
};
