import { useParams } from 'react-router-dom';
import { PageDecorator } from '@/app/decorators/page_decorators/PageDecorator/PageDecorator';
import { SurveyForm } from '../../components/SurveyForm/SurveyForm';

export const NewTemplatePage = () => {
	// * survey id
	const { surveyId } = useParams<'surveyId'>();

	const isNewTemplate = surveyId === 'new';

	// * Render
	return (
		<PageDecorator>
			<SurveyForm
				isTemplate
				surveyId={!isNewTemplate && surveyId ? surveyId : null}
			/>
		</PageDecorator>
	);
};
