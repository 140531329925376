import { SortByState } from '../../../types';
import { User } from '../../../../../types';

export const sortUsers = (users: User[], sortBy: SortByState): User[] => {
	return sortBy
		? users.sort((a, b) => {
				const aSortKey = a[sortBy.by];
				const bSortKey = b[sortBy.by];

				if (!aSortKey && !bSortKey) return 0;
				if (!aSortKey) return sortBy.ascending ? 1 : -1;
				if (!bSortKey) return sortBy.ascending ? -1 : 1;

				if (aSortKey && bSortKey) {
					if (sortBy.ascending) {
						return aSortKey > bSortKey ? 1 : -1;
					} else {
						return aSortKey < bSortKey ? 1 : -1;
					}
				}
				return 0;
			})
		: users;
};
