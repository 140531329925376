import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { actionsNotifications } from '@/features/notifications/_BLL/slice';
import { createAppAsyncThunk } from '../../../utils';
import { Office } from '../types';
import { CreateOfficeREQ_BODY, EditOfficeREQ_BODY, FindOfficeREQ_PARAMS, FindOfficesRES, GetOfficeREQ, GetOfficeRES, OfficeRoom } from './types';

const NAME = `${ServiceName.OFFICE_SERVICE}/office`;

// * Async thunks
const createOffice = createAppAsyncThunk(`${NAME}/createOffice`, async (payload: CreateOfficeREQ_BODY, thunkAPI) => {
	const { dispatch } = thunkAPI;

	await apiRequest.postRequest({
		url: `${NAME}/create`,
		payload,
		action: () => createOffice(payload),
		thunkAPI,
	});

	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: `Офис ${payload.name} успешно создан`,
		}),
	);

	dispatch(
		findOffices({
			takeCount: 1000,
			skipCount: 0,
			includeDeactivated: true,
		}),
	);
});

const editOffice = createAppAsyncThunk(`${NAME}/editOffice`, async (arg: { params: { officeId: string }; payload: EditOfficeREQ_BODY[] }, thunkAPI) => {
	const { dispatch } = thunkAPI;
	const { params, payload } = arg;

	await apiRequest.patchRequest({
		url: `${NAME}/edit`,
		params,
		payload,
		action: () => editOffice(arg),
		thunkAPI,
	});

	dispatch(
		actionsNotifications.addNotification({
			type: 'success',
			message: 'Офис успешно редактирован',
		}),
	);

	dispatch(
		findOffices({
			takeCount: 1000,
			skipCount: 0,
			includeDeactivated: true,
		}),
	);
});

const findOffices = createAppAsyncThunk(`${NAME}/findOffices`, async (params: FindOfficeREQ_PARAMS, thunkAPI) => {
	return await apiRequest.getRequest<FindOfficesRES>({
		url: `${NAME}/find`,
		params,
		action: () => findOffices(params),
		thunkAPI,
	});
});

const getOffice = createAppAsyncThunk(`${NAME}/getOffice`, async (arg: GetOfficeREQ, thunkAPI) => {
	const { params } = arg;

	return await apiRequest.getRequest<GetOfficeRES>({
		url: `${NAME}/get`,
		params,
		action: () => getOffice(arg),
		thunkAPI,
	});
});

export interface State {
	officesList: Office[] | null;
	office: Office | null;
	userIds: string[] | null;
	rooms: OfficeRoom[];
	status: RequestStatus;
}

export const initialState: State = {
	officesList: null,
	office: null,
	userIds: null,
	rooms: [],
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		clearOfficeInfo: state => {
			state.office = null;
			state.userIds = null;
			state.rooms = [];
		},
	},
	extraReducers: builder => {
		builder.addCase(createOffice.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(createOffice.fulfilled, state => {
			state.status = RequestStatus.success;
		});
		builder.addCase(createOffice.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(findOffices.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findOffices.fulfilled, (state, action) => {
			state.officesList = action.payload.body;
			state.status = RequestStatus.still;
		});
		builder.addCase(findOffices.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(getOffice.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getOffice.fulfilled, (state, action) => {
			state.office = action.payload.office;
			state.userIds = action.payload.userIds;
			state.rooms = action.payload.rooms;
			state.status = RequestStatus.still;
		});
		builder.addCase(getOffice.rejected, state => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(editOffice.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(editOffice.fulfilled, state => {
			state.status = RequestStatus.still;
		});
		builder.addCase(editOffice.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const asyncActions = {
	getOffice,
	findOffices,
	createOffice,
	editOffice,
};
