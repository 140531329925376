import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReAuth } from '@/app/api/apiSlice';
import { ServiceName } from '@/app/api/api_types';
import { CreatePositionApiArg, EditPositionApiArg, EditPositionUserApiArg, FindPositionsApiArg, GetPositionApiArg } from './types/regTypes';
import { CreatePositionApiResponse, EditPositionApiResponse, EditPositionUserApiResponse, FindPositionsApiResponse, GetPositionApiResponse } from './types/resTypes';

const reducerPath = `${ServiceName.POSITION_SERVICE}` as const;

export const position_serviceAPI = createApi({
	baseQuery: baseQueryWithReAuth,
	reducerPath,
	tagTypes: ['Position', 'PositionUser'],
	endpoints: build => ({
		getPosition: build.query<GetPositionApiResponse, GetPositionApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/position/get`,

				params: { positionId: queryArg.positionId },
			}),
			providesTags: ['Position'],
		}),
		findPositions: build.query<FindPositionsApiResponse, FindPositionsApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/position/find`,
				params: {
					skipcount: queryArg.skipcount,
					takecount: queryArg.takecount,
					isascendingsort: queryArg.isascendingsort,
					includeDeactivated: queryArg.includeDeactivated,
					nameincludesubstring: queryArg.nameincludesubstring,
				},
			}),
			providesTags: ['Position'],
		}),
		createPosition: build.mutation<CreatePositionApiResponse, CreatePositionApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/position/create`,
				method: 'POST',
				body: queryArg.createPositionRequest,
			}),
			invalidatesTags: ['Position'],
		}),
		editPosition: build.mutation<EditPositionApiResponse, EditPositionApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/position/edit`,
				method: 'PATCH',
				body: queryArg.editPositionRequest,
				params: { positionId: queryArg.positionId },
			}),
			invalidatesTags: ['Position'],
		}),
		editPositionUser: build.mutation<EditPositionUserApiResponse, EditPositionUserApiArg>({
			query: queryArg => ({
				url: `${reducerPath}/user/edit`,
				method: 'PUT',
				body: queryArg.createPositionUserRequest,
			}),
			invalidatesTags: ['PositionUser'],
		}),
	}),
});

export const { useGetPositionQuery, useFindPositionsQuery, useCreatePositionMutation, useEditPositionMutation, useEditPositionUserMutation } = position_serviceAPI;
