import React from 'react';
import { useDeleteBookingRemoveMutation } from '@/app/redux/queries/office-service/office_serviceAPI';
import { tupleToIsoString } from '@/shared/lib/date';
import { ModalHeader } from '../../../../../shared/ui/_modals/ModalHeader/ModalHeader';
import { BookingItem } from '../../../types';
import { BookingInfoCard } from '../../BookingInfoCard/BookingInfoCard';
import s from './CancelUserBookingMC.module.scss';
import { ButtonPair } from '@/entities/_buttons/ButtonPair';

export interface CancelBookingInfo {
	bookingItem: Pick<BookingItem, 'startDate' | 'endDate' | 'selectedInterval' | 'userName' | 'bookingId' | 'isBan' | 'userId'>;
	roomName: string;
	address: string;
	workspaceName?: string;
}

interface Props extends CancelBookingInfo {
	closeModal: () => void;
}

// ! Admin only
export const CancelUserBookingMC: React.FC<Props> = props => {
	const {
		bookingItem: { startDate, endDate, selectedInterval, userName, bookingId, isBan },
		roomName,
		address,
		workspaceName,
		closeModal,
	} = props;

	// * API
	const [removeBooking, { isLoading }] = useDeleteBookingRemoveMutation();

	// * Time interval
	const startTime = tupleToIsoString(selectedInterval.start);
	const endTime = tupleToIsoString(selectedInterval.end);

	// * User actions
	const onApplyClick = () => {
		removeBooking({
			bookingId,
		})
			.unwrap()
			.then(() => closeModal());
	};

	// * Render
	return (
		<div className={s.container}>
			<ModalHeader
				title={isBan ? 'Открытие бронирования' : 'Отмена бронирования'}
				extraParagraph={`Вы действительно хотите ${isBan ? 'открыть' : 'отменить'} это бронирование?`}
			/>

			<BookingInfoCard
				date={startDate}
				startDate={startDate}
				endDate={endDate || null}
				startTime={startTime}
				endTime={endTime}
				roomName={roomName}
				address={address}
				userName={userName}
				workspaceName={workspaceName}
				readonly={true}
			/>

			<ButtonPair
				primaryText="Да"
				primaryIsLoading={isLoading}
				primaryOnClick={onApplyClick}
				secondaryText="Нет"
				secondaryOnClick={closeModal}
				secondaryIsLoading={isLoading}
			/>
		</div>
	);
};
