import cn from 'classnames';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import CloseSVG from '@/shared/assets/svg/action/close.svg?react';
import s from './Chip.module.scss';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	variant?: 'green' | 'red' | 'blue';
	text?: string;
	handleClose?: () => void;
}

export const Chip: React.FC<Props> = props => {
	const {
		className, //
		variant = 'blue',
		text,
		handleClose,
		...divProps
	} = props;

	// * Render
	return (
		<div
			className={cn(s.container, s[`container__${variant}`], !text && s.container__empty, className)}
			{...divProps}
		>
			<span>{text}</span>
			{handleClose && (
				<div className={s.svg}>
					<CloseSVG onClick={handleClose} />
				</div>
			)}
		</div>
	);
};
