import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';
import { useDropzone } from 'react-dropzone';
import { ImageInfo } from '@/app/redux/state/image/image/types';
import AttachmentSVG from '@/shared/assets/svg_icons/file/attachment.svg?react';
import { galleyFileToImageContent } from '@/shared/lib/file';
import { GalleyFile } from '@/shared/ui/_galleries/Gallery/_types';
import { TextInput } from '@/shared/ui/_inputs/text_Inputs/TextInput';
import { v4 } from 'uuid';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
	value: string;
	attachImages: (images: ImageInfo[]) => void;
}

export const AttachmentInput: FC<Props> = props => {
	const {
		value, //
		attachImages,
		autoFocus,
		maxLength,
		onChange,
	} = props;

	// * Drop
	const onDropImage = async (acceptedFiles: any) => {
		const imgs = acceptedFiles.map((img: any) => {
			return Object.assign(img, {
				id: `new_${v4()}`,
				preview: URL.createObjectURL(img),
			});
		});

		const imgFormat = Promise.all(
			imgs.map((img: GalleyFile) => {
				return galleyFileToImageContent(img);
			}),
		);

		const imgFormatData = (await imgFormat).map(item => ({
			id: `new_${v4()}`,
			...item,
		}));

		attachImages([...imgFormatData]);
	};

	const { getInputProps, open } = useDropzone({
		onDrop: onDropImage,
		// maxSize,
		maxFiles: 10,
		accept: { 'image/*': ['.png', '.tiff', '.jpg', '.mpeg', '.avi', '.wmv', '.mp4', '.webm', '.gif'] },
		noClick: true,
	});

	// * Render
	return (
		<>
			<input {...getInputProps()} />
			<TextInput
				placeholder="Введите текст комментария"
				value={value}
				onChange={onChange}
				autoFocus={autoFocus}
				maxLength={maxLength}
				LeftIcon={<AttachmentSVG />}
				onLeftIconClick={e => {
					e.preventDefault();
					open();
				}}
			/>
		</>
	);
};
