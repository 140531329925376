import { FC, useState } from 'react';
import { ConfirmModal } from '@/entities/_modals/ConfirmModal/ConfirmModal';
import DeleteSVG from '@/shared/assets/svg_icons/action/delete.svg?react';
import EditSVG from '@/shared/assets/svg_icons/action/edit.svg?react';
import { ModalNewProto } from '@/shared/ui/_modals/ModalNewProto/ModalNewProto';
import { DropDownMenu } from '@/shared/ui/DropDownMenu/DropDownMenu';
import { SubGroupType } from '../../types';
import { AddSubGroupMC } from '../AddEditSubGroupMC';
import { SUB_GROUP } from '../AddEditSubGroupMC/consts';
import { SubGroupUsers } from '../SubGroupUsers';
import s from './SubGroupCard.module.scss';

interface Props {
	id: string;
	name: string;
	count: string | number;
	type: SubGroupType;
	editAction: (id: string, name: string) => void;
	deleteAction: (id: string) => void;
	isLoading: boolean;
}

export const SubGroupCard: FC<Props> = props => {
	const {
		id, //
		name,
		count,
		type,
		editAction,
		deleteAction,
		isLoading,
	} = props;

	// * Modals
	const [isEditModal, setIsEditModal] = useState(false);
	const toggleEditModal = () => setIsEditModal(prevState => !prevState);

	const [isDeleteModal, setIsDeleteModal] = useState(false);
	const toggleDeleteModal = () => setIsDeleteModal(prevState => !prevState);

	const [isOpenUsers, setIsOpenUsers] = useState(false);
	const toggleUsersModal = () => setIsOpenUsers(prevState => !prevState);

	// * Actions
	const onEditSubGroup = (data: { name: string }) => {
		editAction(id, data.name);
		toggleEditModal();
	};

	const onDeleteSubGroup = () => {
		deleteAction(id);
	};

	// * Render
	return (
		<>
			{isEditModal && (
				<ModalNewProto
					isOpen={isEditModal}
					onClose={toggleEditModal}
					width="s"
				>
					<AddSubGroupMC
						type={type}
						name={name}
						closeModal={toggleEditModal}
						onSubmitAction={onEditSubGroup}
						isLoading={isLoading}
					/>
				</ModalNewProto>
			)}

			{isDeleteModal && (
				<ConfirmModal
					isOpen={isDeleteModal}
					onClose={toggleDeleteModal}
					title={SUB_GROUP[type].delete}
					description={`${SUB_GROUP[type].deleteInfo}\nОтменить это действие будет невозможно.`}
					primaryOnClick={onDeleteSubGroup}
					isLoading={isLoading}
				/>
			)}

			{isOpenUsers && (
				<ModalNewProto
					isOpen={isOpenUsers}
					onClose={toggleUsersModal}
					width="l"
				>
					<SubGroupUsers
						subgroupId={id}
						type={type}
						name={name}
					/>
				</ModalNewProto>
			)}

			<div
				className={s.container}
				onClick={toggleUsersModal}
			>
				<div>
					<div className={s.title}>{name}</div>
					<div className={s.count}>{`${count} чел.`}</div>
				</div>

				<DropDownMenu
					items={[
						{
							icon: <EditSVG />,
							title: 'Редактировать',
							action: toggleEditModal,
						},
						{
							icon: <DeleteSVG />,
							title: 'Удалить',
							action: toggleDeleteModal,
						},
					]}
				/>
			</div>
		</>
	);
};
