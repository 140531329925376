import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import s from './WorkspaceInfoCard.module.scss';
import cn from 'classnames';
import { TagInfo } from '@/app/redux/state/office/tag/types';
import { ChipList } from '@/shared/ui/_chips/ChipList/ChipList';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	workspaceName: string;
	tags: TagInfo[];
}

export const WorkspaceInfoCard: React.FC<Props> = props => {
	const {
		workspaceName, //
		tags,
		className,
		...divProps
	} = props;

	// * Render
	return (
		<div
			{...divProps}
			className={cn(s.container, className)}
		>
			<h4>
				Характеристики места <span className={s.name}>{workspaceName}</span>
			</h4>
			{tags.length > 0 && <ChipList chips={tags} />}
		</div>
	);
};
