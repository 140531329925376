import { createSlice } from '@reduxjs/toolkit';
import { apiRequest } from '@/app/api/api';
import { RequestStatus, ServiceName } from '@/app/api/api_types';
import { createAppAsyncThunk } from '../../../utils';
import { DepartmentInfo, FindDepartmentsREQ, FindDepartmentsRES } from './types';

const NAME = `${ServiceName.DEPARTMENT_SERVICE}/department`;

// * Async thunks
const findDepartments = createAppAsyncThunk(`${NAME}/findDepartments`, async (arg: FindDepartmentsREQ, thunkAPI) => {
	const { params } = arg;

	const res = await apiRequest.getRequest<FindDepartmentsRES>({
		url: `${NAME}/find`,
		params,
		thunkAPI,
		action: () => findDepartments(arg),
	});

	return res.body;
});

// * Slice
export interface State {
	departmentInfo: DepartmentInfo[] | null;
	status: RequestStatus;
}

export const initialState: State = {
	departmentInfo: null,
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setDepartmentInfo: (state, action) => {
			state.departmentInfo = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(findDepartments.pending, state => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(findDepartments.fulfilled, (state, action) => {
			state.departmentInfo = action.payload;
			state.status = RequestStatus.success;
		});
		builder.addCase(findDepartments.rejected, state => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsDepartmentService = {
	...slice.actions,
};

export const asyncActions = {
	findDepartments,
};
