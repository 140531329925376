import React, { useState } from 'react';
import s from './DatePickerInput.module.scss';
import cn from 'classnames';
import DatePicker, { CalendarContainer, CalendarContainerProps, ReactDatePickerProps } from 'react-datepicker';
import { useFloatingWrapper } from '@/shared/hooks/useFloatingWrapper';
import { FloatingFocusManager } from '@floating-ui/react';
import { TextInput } from '../../text_Inputs/TextInput';
import CalendarSVG from '@/shared/assets/svg/action/calendar.svg?react';
import { Button } from '../../../_buttons/Button';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
	selectedDate: Date | null;
	setSelectedDate: (date: Date | null) => void;
	label?: string;
	errorBorderDate?: boolean;
	errorMessage?: string;
}

export const DatePickerInput: React.FC<Props> = props => {
	const {
		label,
		placeholderText,
		selectedDate,
		setSelectedDate,
		errorBorderDate,
		errorMessage,
		required,

		disabled,
		isClearable,
		...datePickerProps
	} = props;

	const [isOpen, setIsOpen] = useState(false);

	const handleOnChange = (date: Date | null) => {
		setIsOpen(!isOpen);
		date && setSelectedDate(date);
	};

	const getDateString = () => {
		return `${selectedDate ? selectedDate.toLocaleDateString('ru-RU') : ''}`;
	};

	// * Floating
	const { floatingStyles, refs, context, getReferenceProps, getFloatingProps, headingId } = useFloatingWrapper(isOpen, setIsOpen);

	const Container = (props: CalendarContainerProps) => {
		const { className, children } = props;

		return (
			<CalendarContainer className={cn(s.calendar, className)}>
				<div>{children}</div>

				{isClearable && (
					<Button
						variant="tertiary"
						onClick={() => {
							setSelectedDate(null);
							setIsOpen(false);
						}}
						disabled={!selectedDate}
					>
						Сбросить дату
					</Button>
				)}
			</CalendarContainer>
		);
	};

	// * Render
	return (
		<>
			<div className={s.container}>
				<div
					className={s.text_input}
					ref={refs.setReference}
					{...getReferenceProps()}
					onClick={event => {
						event.preventDefault();
						setIsOpen(prevState => !prevState);
					}}
				>
					<TextInput
						label={label}
						placeholder={placeholderText}
						value={getDateString()}
						onClick={event => {
							event.preventDefault();
							setIsOpen(prevState => !prevState);
						}}
						errorBorder={errorBorderDate}
						errorMessage={errorMessage}
						RightIcon={<CalendarSVG />}
						onRightIconClick={event => {
							event.preventDefault();
							setIsOpen(prevState => !prevState);
						}}
						disabled={disabled}
						required={required}
						isPickerPart={true}
					/>
				</div>
			</div>

			{isOpen && !disabled && (
				<FloatingFocusManager
					context={context}
					modal={false}
				>
					<div
						ref={refs.setFloating}
						style={{
							...floatingStyles,
							zIndex: 'var(--z-index-floating)',
						}}
						aria-labelledby={headingId}
						{...getFloatingProps()}
					>
						<DatePicker
							{...datePickerProps}
							locale={'ru'}
							selected={selectedDate}
							onChange={handleOnChange}
							inline
							calendarContainer={Container}
						/>
					</div>
				</FloatingFocusManager>
			)}
		</>
	);
};
