import { ChangeInfo } from '@/app/redux/queries/claim-service/types/types';
import s from './HistoryElement.module.scss';
import { OPTION_PRIORITY, STATUS } from '@/pages/_claims/consts/consts';
import { claim_serviceAPI } from '@/app/redux/queries/claim-service/claim_serviceAPI';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';
import { Avatar } from '@/shared/ui/Avatar/Avatar';
import { backendISOUTCToLocalLuxon, tupleToIsoString } from '@/shared/lib/date';
import { preparedData } from '@/pages/_claims/lib/preparedData';
import { Hours, Minutes } from '@/shared/lib/date/types';
import { type Value } from '@/pages/_claims/types';
import { DateTime } from 'luxon';

interface Props {
	key: string | undefined;
	history: ChangeInfo;
}

export const HistoryElement = (props: Props) => {
	const {
		key, //
		history,
	} = props;

	// * Api
	const { data: userData } = user_serviceAPI.useGetUserGetQuery({ userid: history.createdBy, includecurrentavatar: true });
	const { currentData: categoriesTreeData } = claim_serviceAPI.useGetCategoriesQuery({
		skipcount: 0,
		takecount: 1000,
	});
	const user = userData?.body?.user;

	// * Categories
	const categories =
		categoriesTreeData?.body?.map(item => ({
			id: item.id,
			name: item.name,
		})) || [];

	// * Date converter
	const formatDate = (dateFormatString: string | undefined, isBackend?: boolean) => {
		const formatDate = isBackend ? backendISOUTCToLocalLuxon(dateFormatString as string) : DateTime.fromISO(dateFormatString as string);
		const newDate = preparedData(dateFormatString);
		const newTime = tupleToIsoString([formatDate.hour as Hours, formatDate.minute as Minutes]);

		return `${newDate} ${newTime}`;
	};

	const formatDateHistory = (value: Value) => {
		return value && value.split(' ')[0].split('/').join('.');
	};

	// * History changes
	const getChanges = (item: ChangeInfo) => {
		const { changedProperty, previousValue, newValue } = item;

		switch (changedProperty) {
			case 'Status': {
				const status = previousValue
					? `c "${STATUS.find(item => item.id === previousValue)?.name || '-'}" на "${STATUS.find(item => item.id === newValue)?.name}".`
					: `на "${STATUS.find(item => item.id === previousValue)?.name}".`;

				return `изменил статус ${status}`;
			}
			case 'Priority': {
				const priority = previousValue
					? `c "${OPTION_PRIORITY.find(item => item.id === previousValue)?.name || '-'}" на "${OPTION_PRIORITY.find(item => item.id === newValue)?.name}".`
					: `на "${OPTION_PRIORITY.find(item => item.id === previousValue)?.name}".`;

				return `изменил приоритетность ${priority}`;
			}
			case 'DeadLine': {
				const date = newValue ? `c "${formatDateHistory(previousValue) || '-'}" на "${formatDateHistory(newValue)}".` : `на "${formatDateHistory(previousValue)}".`;
				return `изменил дедлайн ${date}`;
			}
			case 'CategoryId': {
				const newCategory = categories.filter(item => item.id === newValue);
				const previousCategory = categories.filter(item => item.id === previousValue);
				const category = newValue ? `изменил категорию с "${previousCategory[0]?.name || '-'}" на "${newCategory[0]?.name}".` : `выбрал категорию "${previousCategory[0]?.name}".`;
				return category;
			}
			case 'ResponsibleUserId': {
				return 'выбрал ответственным.';
			}
			case 'DepartmentId': {
				return 'выбрал Департамент.';
			}

			default:
				return 'изменил информацию.';
		}
	};

	return (
		<div
			key={key}
			className={s.history}
		>
			<Avatar
				size="extra-small"
				name={`${user?.lastName} ${user?.firstName}`}
				userAvatar={user?.avatar}
			/>
			<div>
				<p>
					<b>{`${user?.lastName} ${user?.firstName} ${user?.middleName || ''} `}</b>
					{getChanges(history)}
				</p>
				<p className={s.date}>{formatDate(history.createdAtUtc, true)}</p>
			</div>
		</div>
	);
};
