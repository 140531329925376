import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import s from './AddButton.module.scss';
import AddSVG from '@/shared/assets/svg/action/add.svg?react';
import cn from 'classnames';

interface Props extends DetailedHTMLProps<HTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	title: string;
	disabled?: boolean;
}

export const AddButton: React.FC<Props> = props => {
	const {
		title, //
		disabled,
		className,
		...buttonProps
	} = props;

	return (
		<button
			className={cn(s.button, className)}
			type="button"
			aria-disabled={disabled}
			{...buttonProps}
		>
			<AddSVG />
			{title}
		</button>
	);
};
