import { Heading } from '@/shared/ui/Heading';
import s from './CategoriesModalForm.module.scss';
import { StringField } from '@/shared/ui/_fields/StringField';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { type Category } from '../../../../../../types';
import { claim_serviceAPI } from '@/app/redux/queries/claim-service/claim_serviceAPI';
import { ButtonPair } from '@/entities/_buttons/ButtonPair';
import * as yup from 'yup';

type Props = {
	currentCategory: Category | null;
	toggleModalForm: () => void;
};

const schema = yup.object().shape({
	name: yup.string().min(1, 'Минимум 1 символ').max(20, 'Максимум 20 символов').required('Обязательное поле'),
});

export const CategoriesModalForm = ({ toggleModalForm, currentCategory }: Props) => {
	const formMethods = useForm({
		defaultValues: {
			name: currentCategory ? currentCategory.name : '',
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, watch } = formMethods;

	// * Api
	const [createCategory, { isLoading: postLoading }] = claim_serviceAPI.usePostCategoriesMutation();
	const [patchCategoriesByCategoryId, { isLoading: patchLoading }] = claim_serviceAPI.usePatchCategoriesByCategoryIdMutation();

	const isLoading = postLoading || patchLoading;

	const category = watch('name');

	// * Action
	const handleCreateCategory = (name: string) => {
		createCategory({
			createCategoryCommand: {
				name,
			},
		})
			.unwrap()
			.then(() => toggleModalForm())
			.catch(error => console.log(error));
	};

	const handleEditCategory = (data: { name: string }, currentCategory: Category) => {
		patchCategoriesByCategoryId({
			categoryId: currentCategory.id,
			body: [
				{
					op: 'replace',
					value: data.name,
					path: '/Name',
				},
			],
		})
			.unwrap()
			.then(() => toggleModalForm())
			.catch(error => console.log(error));
	};

	const onSubmit = (data: { name: string }) => {
		const { name } = data;
		currentCategory ? handleEditCategory(data, currentCategory) : handleCreateCategory(name);
	};

	// * Render
	return (
		<FormProvider {...formMethods}>
			<form
				className={s.container}
				onSubmit={handleSubmit(onSubmit)}
			>
				<Heading
					level={2}
					size={3}
					className={s.title}
				>
					{currentCategory ? 'Редактирование категории' : 'Новая категория'}
				</Heading>
				<StringField
					name="name"
					label="Название"
					placeholder="Введите название категории"
					maxLength={20}
					required
				/>
				<ButtonPair
					primaryText={currentCategory ? 'Сохранить' : 'Создать'}
					primaryIsLoading={isLoading}
					secondaryText="Отменить"
					secondaryOnClick={() => toggleModalForm()}
					secondaryIsLoading={isLoading}
					primaryDisabled={!category}
				/>
			</form>
		</FormProvider>
	);
};
