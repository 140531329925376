import { LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO } from '@/shared/consts/consts';
import { getCurrentDayNumber } from '@/shared/lib/date';

export const getHasEditingRight = (month?: number) => {
	const TODAY = new Date();
	const TODAY_MONTH = TODAY.getMonth() + 1;

	const currentDayNumber = getCurrentDayNumber(TODAY);

	if (currentDayNumber <= LAST_DAY_TO_CHANGE_PREVIOUS_MONTH_INFO) {
		return month === TODAY_MONTH || month === TODAY_MONTH - 1;
	} else {
		return month === TODAY_MONTH;
	}
};
