import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { usePostBookingCreateMutation } from '@/app/redux/queries/office-service/office_serviceAPI';
import { user_serviceAPI } from '@/app/redux/queries/user-service/user_serviceAPI';
import { Room } from '@/app/redux/state/office/room/types';
import { UserInfo } from '@/app/redux/state/user/user/types';
import { generateExcludedDates, getInitialTime } from '@/pages/_booking/lib';
import { useRights } from '@/shared/hooks/useRights';
import { dateToTimeString, injectTimeToDate } from '@/shared/lib/date';
import { TimeInterval } from '@/shared/lib/date/types';
import { Button } from '@/shared/ui/_buttons/Button';
import { ChipList } from '@/shared/ui/_chips/ChipList/ChipList';
import { SelectSingleWithFilterField } from '@/shared/ui/_fields/SelectSingleWithFilterField';
import { TimeRangeField } from '@/shared/ui/_fields/TimeRangeField';
import * as yup from 'yup';
import s from './FullBookingMC.module.scss';

export interface SelectedCellInfo {
	room: Room;
	selectedDate: Date;
	startTime: string; // 00:00:00
	unavailableIntervals: TimeInterval[];
}

interface Props {
	selectedCellInfo: SelectedCellInfo;
	userInfo: UserInfo;
	closeModal: () => void;
	openForbidModal: (selectedCellInfo: SelectedCellInfo) => void;
}

export const FullBookingMC: React.FC<Props> = props => {
	const {
		selectedCellInfo: {
			startTime, // Начальное время выбранной ячейки. Формат: 00:00:00
			selectedDate, // Дата выбранной ячейки. Формат jsDate
			room,
			unavailableIntervals, // Интервалы всех забронированных ячеек выбранной комнаты и разницы между время м бронирования офиса и выбранной комнаты.
		},
		userInfo,
		closeModal,
		openForbidModal,
	} = props;

	// * API
	const [createBooking, { isLoading }] = usePostBookingCreateMutation();
	const { data: usersList } = user_serviceAPI.useGetUserFindQuery({
		skipcount: 0,
		takecount: 1000,
		isactive: true,
	});

	const users = usersList?.body ?? [];

	// * Rights
	const isAdmin = useRights();

	// * Exclude times
	const excludedTimes = generateExcludedDates(selectedDate, unavailableIntervals);

	// * InitialTime
	const initialTime = getInitialTime(selectedDate, startTime, excludedTimes);

	// * Form
	const schema = yup.object().shape({
		endTime: yup.string().required('Введите время окончания бронирования').nullable(),
	});

	const formMethods = useForm<any>({
		defaultValues: {
			user: {
				id: userInfo.user.id,
				name: `${userInfo.user.firstName} ${userInfo.user.lastName}`,
			},
			startTime: initialTime,
			endTime: null,
			roomId: room.id,
		},
		resolver: yupResolver(schema),
	});

	const { handleSubmit, setError, clearErrors } = formMethods;

	const onSubmit = (values: any) => {
		const excludedTimeStrings = excludedTimes.map(time => dateToTimeString(time).slice(0, 5));
		const startTimeNotAvailable = excludedTimeStrings.includes(values.startTime.slice(0, 5));
		const endTimeNotAvailable = excludedTimeStrings.includes(values.endTime.slice(0, 5));

		if (startTimeNotAvailable) {
			setError('startTime', { message: 'Начальное время недоступно' });
		} else if (endTimeNotAvailable) {
			setError('endTime', { message: 'Конечное время недоступно' });
		} else {
			clearErrors();

			const startTime = injectTimeToDate(selectedDate, values.startTime);
			let endTime: Date | null = null;

			if (values.endTime) {
				endTime = injectTimeToDate(selectedDate, values.endTime);
			}

			createBooking({
				createBookingRequest: {
					startTime: startTime.toLocaleString('sv'),
					endTime: endTime ? endTime.toLocaleString('sv') : null,
					userId: isAdmin ? values.user.id : undefined,
					parentId: values.roomId,
				},
			})
				.unwrap()
				.then(() => closeModal())
				.catch(error => console.log('error', error));
		}
	};

	const options = users.map(user => ({
		id: user.user.id,
		name: `${user.user.firstName} ${user.user.lastName}`,
	}));

	// * Forbid booking
	const onForbidBookingClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.preventDefault();
		closeModal();
		openForbidModal({ room, startTime: initialTime, selectedDate, unavailableIntervals });
	};

	// * Render
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<FormProvider {...formMethods}>
				<h3 className={s.title}>{room.name}</h3>

				<div className={s.inner}>
					{isAdmin && options && (
						<SelectSingleWithFilterField
							name="user"
							label="Кому"
							options={options}
							sortOptions
							isNullable={false}
						/>
					)}

					<TimeRangeField
						firstName="startTime"
						secondName="endTime"
						labels={['Начало', 'Окончание']}
						minTime={!(selectedDate.getDate() > new Date().getDate()) ? new Date() : undefined}
						excludeTimes={excludedTimes}
					/>

					{room.tags.length > 0 && <ChipList chips={room.tags} />}
				</div>

				<div className={s.buttons}>
					{isAdmin && (
						<Button
							type="button"
							variant="tertiary"
							isLoading={isLoading}
							onClick={onForbidBookingClick}
							wide
						>
							Запрет бронирования
						</Button>
					)}

					<Button
						type="submit"
						isLoading={isLoading}
						fixedWidth
					>
						Забронировать
					</Button>
				</div>
			</FormProvider>
		</form>
	);
};
